import React from "react";
import { TextField, Autocomplete } from "@mui/material";

// --------------------------------------------------------------------

export const geographyArr = [
  { code: "AF", name: "Africa" },
  { code: "AN", name: "Antarctica" },
  { code: "AS", name: "Asia" },
  { code: "EU", name: "Europe" },
  { code: "NA", name: "North America" },
  { code: "OC", name: "Oceania" },
  { code: "SA", name: "South America" },
];

const MuiAutocomplete = ({
  name,
  options,
  setValue,
  value,
  optionFocus,
  innerValue,
  optionValue,
  optionSecValue,
  optionThirdValue,
  label,
  required,
  error,
  helperText,
  tabIndex,
  ...other
}) => {
  return (
    <Autocomplete
     tabIndex={tabIndex}
      name={name}
      options={options.length > 0 && options}
      getOptionLabel={
        innerValue
          ? (option) =>
              optionThirdValue
                ? optionThirdValue === "lastName"
                  ? `${option[optionValue]?.[optionSecValue]} ${option[optionValue]?.[optionThirdValue]}`
                  : `${option[optionValue]?.[optionSecValue]}-${option[optionValue]?.[optionThirdValue]}`
                : option[optionValue]?.[optionSecValue]
          : (option) =>
              optionSecValue
                ? optionSecValue === "lastName"
                  ? `${option[optionValue]} ${option[optionSecValue]}`
                  : `${option[optionValue]}-${option[optionSecValue]}`
                : option[optionValue]
      }
      value={value ? value : null}
      onChange={(event, newValue) => setValue(name, newValue)}
      renderInput={(params) => (
        <TextField
         
          {...params}
          label={label}
          required={required}
          error={error}
          helperText={helperText}
         
        />
      )}
      sx={{ marginTop: "1rem" }}
      disabled={options.length === 0}
      {...other}
    />
  );
};

export default MuiAutocomplete;
