import React from "react";
// material ui
import { Container, Box,Grid } from "@mui/material";
// image
import BgImage from "../assets/images/X-light-gray.png";

// -------------------------------------------------------------------------

const Layout = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={10} sm={10} xs={10}>
          <div
            // maxWidth="lg"
            // component="div"
            style={{
              marginTop: "6rem",
              marginBottom: "4rem",
              // paddingTop: props?.isClient ? "1rem" : 0,
 
              // pr: "3rem !important",
              height: "80vh",
              background: `url(${BgImage})`,
              backgroundSize: "contain",
              backgroundPosition: "right bottom",
              backgroundRepeat: "no-repeat",
            }}
          >
            {children}
          </div>
        </Grid>
      </Grid>
      {/* <Container
        maxWidth="lg"
        component="div"
        sx={{
          mt: 10,
          mb: 4,
          pt: "1rem !important",
          pr: "3rem !important",
          height: "80vh",
          background: `url(${BgImage})`,
          backgroundSize: "contain",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        {children}
      </Container> */}
    </Box>
  );
};

export default Layout;
