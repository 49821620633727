import { ADMIN_BASE_URL, ADMIN_MICRO_SITE_URL } from "./config";

// ----------------------------------------------------------

// login authentication apis
export const IS_LOGGED_IN = `${ADMIN_BASE_URL}/v1/api/authentication/is-admin-logged-in`;

export const EMAIL_VERIFY = `${ADMIN_BASE_URL}/v1/api/authentication/verify/login`;

export const RESEND_EXCO_USER_EMAIL = `${ADMIN_BASE_URL}/v1/api/authentication/resend-verification-email`;

export const GET_ALL_ROLES = `${ADMIN_BASE_URL}/v1/api/authentication/roles`;

export const CREATE_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/authentication/create`;

export const UPDATE_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/authentication/update-exco-user`;

export const CHANGE_PRIMARY_ROLE = `${ADMIN_BASE_URL}/v1/api/authentication/change-primary-role`;

export const DELETE_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/authentication/delete-exco-user`;

export const ACTIVATE_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/authentication/confirm-account`;

export const GET_ACCOUNTS = `${ADMIN_BASE_URL}/v1/api/authentication/all/exco-user`;

export const GET_ALL_EXEC = `${ADMIN_BASE_URL}/v1/api/authentication/all/exco-exec`;

export const GET_ALL_AM = `${ADMIN_BASE_URL}/v1/api/authentication/all/exco-am`;

export const LOGIN_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/authentication/login`;

export const LOGOUT_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/authentication/logout`;

export const GET_CURRENT_USER = `${ADMIN_BASE_URL}/v1/api/authentication/current-user`;

export const GET_CURRENT_USER_BY_ROLE = `${ADMIN_BASE_URL}/v1/api/authentication/current-user`;

export const GET_CURRENT_ROLES = `${ADMIN_BASE_URL}/v1/api/authentication/get-all-roles-of-user`;

export const UPLOAD_PROFILE_PIC = `${ADMIN_BASE_URL}/v1/api/authentication/profile-pic`;

// forgot password
export const FORGOT_PASSWORD_EMAIL_VERIFY = `${ADMIN_BASE_URL}/v1/api/authentication/forgot-password`;

export const FORGOT_PASSWORD_RESET = `${ADMIN_BASE_URL}/v1/api/authentication/forgot-password/reset`;

//customer creation

export const CURD_CUSTOMER = `${ADMIN_BASE_URL}/v1/api/customer`;

// customer user onboarding
export const RESEND_CUST_USER_EMAIL = `${ADMIN_BASE_URL}/v1/api/customer/customer-user/resend-verification-email`;

export const CREATE_CUST_USER = `${ADMIN_BASE_URL}/v1/api/customer/create-customer-user`;

export const CREATE_CUST_NOMINATE_USER = `${ADMIN_BASE_URL}/v1/api/nomination/create-user-from-nomination`;

export const UPDATE_CUST_USER = `${ADMIN_BASE_URL}/v1/api/customer/update-exco-customer-user`;

export const GET_CUST_USER = `${ADMIN_BASE_URL}/v1/api/customer/customer-clients`;

export const DELETE_CUST_USER = `${ADMIN_BASE_URL}/v1/api/customer/delete-exco-customer-user`;

export const GET_CUST_CLIENTS = `${ADMIN_BASE_URL}/v1/api/customer/customer-clients/client`;

export const GET_CUST_HR = `${ADMIN_BASE_URL}/v1/api/customer/hr`;

export const GET_CUST_MANAGER = `${ADMIN_BASE_URL}/v1/api/customer/manager`;

export const GET_CLIENT_BY_CUST = `${ADMIN_BASE_URL}/v1/api/customer`;

export const GET_ALL_CLIENT_BY_ACC = `${ADMIN_BASE_URL}/v1/api/customer/get-all-client`;

export const GET_CLIENTS_BY_ACC = `${ADMIN_BASE_URL}/v1/api/customer/client-id`;

export const GET_ACTIVE_ENG_BY_ACC = `${ADMIN_BASE_URL}/v1/api/client-engagement/active-engagement-id`;

// get customer client roles
export const GET_CUST_USER_ROLES = `${ADMIN_BASE_URL}/v1/api/customer/customer-client-roles`;

export const GET_CUST_USER_NOT_MAPPED = `${ADMIN_BASE_URL}`;

export const GET_CUST_USER_WITHOUT_OPP = `${ADMIN_BASE_URL}`;

// account
export const CURD_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/exco-customer-account`;


export const TOGGLE_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/microsite-feature-flags`;

// customer development areas
export const CURD_CUST_DEV_AREAS = `${ADMIN_BASE_URL}/v1/api/customer-development-areas`;

// Engagement Checkin Frequency
export const CURD_ENG_CHECKIN_FREQUENCY = `${ADMIN_BASE_URL}/v1/api/engagement-checkin-frequency`;

// Progress Review Frequency
export const CURD_PROGRESS_REVIEW_FREQUENCY = `${ADMIN_BASE_URL}/v1/api/progress-review-frequency`;

//mentor creation
export const RESEND_MENTOR_EMAIL = `${ADMIN_BASE_URL}/v1/api/mentor-onboarding/resend-verification-email`;

export const CREATE_MENTOR = `${ADMIN_BASE_URL}/v1/api/mentor-onboarding/create`;

export const UPDATE_MENTOR = `${ADMIN_BASE_URL}/v1/api/mentor-onboarding/update`;

export const ACTIVATE_MENTOR_ACCOUNT = `${ADMIN_BASE_URL}/v1/api/mentor-onboarding/confirm-account`;

export const GET_ALL_MENTOR = `${ADMIN_BASE_URL}/v1/api/mentor-onboarding/all/mentor`;

export const CURD_MENTOR = `${ADMIN_BASE_URL}/v1/api/mentor-onboarding`;

// mentor mapping
export const CURD_MENTOR_MAPPING = `${ADMIN_BASE_URL}/v1/api/client-mentor-mapping`;

export const GET_UNMAPPED_CLIENTS = `${ADMIN_BASE_URL}/v1/api/client-mentor-mapping/unmapped`;

//microsite creation

export const CREATE_MICROSITE = `${ADMIN_MICRO_SITE_URL}/v2/api/microsite`;

export const TOGGLE_MICROSITE = `${ADMIN_MICRO_SITE_URL}/v2/api/microsite`;

export const CURD_MICROSITE = `${ADMIN_MICRO_SITE_URL}/v1/api/microsite`;

export const STOP_MICROSITE = `${ADMIN_MICRO_SITE_URL}/v1/api/microsite/stop`;

export const START_MICROSITE = `${ADMIN_MICRO_SITE_URL}/v1/api/microsite/start`;

export const DELETE_MICROSITE = `${ADMIN_BASE_URL}/v1/api/microsite`;

export const GET_MICROSITE = `${ADMIN_BASE_URL}/v1/api/microsite`;

export const GET_ALL_CUSTOMER_API = `${ADMIN_MICRO_SITE_URL}/v1/api/customer`;

// nomination
export const GET_ALL_NOMINATION = `${ADMIN_BASE_URL}/v1/api/nomination`;

// engagements
export const CURD_ADMIN_ENGAGEMENT = `${ADMIN_BASE_URL}/v1/api/client-engagement/admin/all-client-engagement`;

// reports
export const GET_ALL_LEADERSHIP_PROFILE = `${ADMIN_BASE_URL}`;

export const GET_ALL_COACHING_FOCUS_AREAS = `${ADMIN_BASE_URL}`;

export const GET_ALL_PROGRESS_REVIEW = `${ADMIN_BASE_URL}`;
export const GET_ADMIN_LATEST_SUMMARY = `${ADMIN_BASE_URL}/v1/api/summary/admin/latest-summary`;

export const GET_ALL_ENGAGEMENT_CHECKIN = `${ADMIN_BASE_URL}/v1/api/client-engagement-checkin/report-admin`;

// exec role routes
// opportunity routes
export const CURD_OPPORTUNITY = `${ADMIN_BASE_URL}/v1/api/opportunity-detail`;

export const CURD_OPP_GOALS = `${ADMIN_BASE_URL}/v1/api/milestone-goal`;

export const CURD_OPP_SCOPE = `${ADMIN_BASE_URL}/v1/api/scope-deliverable`;

export const GET_CUSTOMER_CLIENT = `${ADMIN_BASE_URL}/v1/api/customer/customer-clients`;

// Customers routes
export const GET_CUSTOMERS = `${ADMIN_BASE_URL}/v1/api/customer`;

// Mentors routes
export const GET_MENTORS = `${ADMIN_BASE_URL}/v1/api/mentor-onboarding/all/mentor`;

export const GET_CLIENTS_BY_MENTOR = `${ADMIN_BASE_URL}/v1/api/client-mentor-mapping/client-id`;

// Clients routes
export const GET_CLIENTS = `${ADMIN_BASE_URL}/v1/api/customer/customer-clients`;

// engagement routes
export const CURD_ENGAGEMENT = `${ADMIN_BASE_URL}/v1/api/client-engagement`;

export const SEARCH_ENGAGEMENT = `${ADMIN_BASE_URL}/v1/api/client-engagement/search`;

export const GET_ENGAGEMENT_BY_CLIENT = `${ADMIN_BASE_URL}/v1/api/client-engagement`;

export const GET_ENGAGEMENT_BY_ACC = `${ADMIN_BASE_URL}/v1/api/client-engagement/all-engagement`;

export const CURD_KEY_ENGAGEMENT = `${ADMIN_BASE_URL}/v1/api/key-client-engagements`;

export const CURD_NET_PROMOTER_SCORE = `${ADMIN_BASE_URL}/v1/api/client-nps-rating`;

export const GET_CUST_USER_NET_PROMOTER_SCORE = `${ADMIN_BASE_URL}/v1/api/client-nps-rating/client`;

export const GET_NOT_ENGAGED_CLIENTS = `${ADMIN_BASE_URL}/v1/api/client-engagement/all-unmapped-client-engagement`;

// config
export const CURD_OPPORTUNITY_STAGE = `${ADMIN_BASE_URL}/v1/api/opportunity-stage`;

export const CURD_OPPORTUNITY_STATUS = `${ADMIN_BASE_URL}/v1/api/opportunity-status`;

export const CURD_CONTACT_DESIGNATION = `${ADMIN_BASE_URL}/v1/api/contact-designation`;

export const CURD_ENG_TIER_NAME = `${ADMIN_BASE_URL}/v1/api/engagement-tier`;

export const CURD_ENGAGEMENT_DELIVERABLE = `${ADMIN_BASE_URL}/v1/api/engagement-deliverable`;

export const GET_ENGAGEMENT_DELIVERABLE_BY_TIER = `${ADMIN_BASE_URL}/v1/api/engagement-deliverable/engagement-deliverable`;

export const CURD_ENGAGEMENT_STATUS = `${ADMIN_BASE_URL}/v1/api/engagement-status`;

export const CURD_ENGAGEMENT_STAGE = `${ADMIN_BASE_URL}/v1/api/client-engagement-stages`;

export const GET_ENGAGEMENT_STAGE_BY_TIER = `${ADMIN_BASE_URL}/v1/api/client-engagement-stages/tire`;

export const CURD_DEVELOPMENT_AREAS = `${ADMIN_BASE_URL}/v1/api/development-areas`;

export const CURD_COACHING_FOCUS_AREA_DEV_AREAS = `${ADMIN_BASE_URL}/v1/api/development-areas-coaching-focus`;
export const COACHING_FOCUS_AREA_PURPOSE = `${ADMIN_BASE_URL}/v1/api/coaching-focus/approved-purpose-of-engagement`;
export const CURD_NPS_RATING = `${ADMIN_BASE_URL}/v1/api/la-nps-rating`;

export const CURD_LEVEL_OF_ENGAGEMENT = `${ADMIN_BASE_URL}/v1/api/level-of-engagement`;

export const CURD_EXECUTIVE_SPONSAR = `${ADMIN_BASE_URL}/v1/api/executive-sponsor`;
export const CURD_IMPACT_SCALE_RATING = `${ADMIN_BASE_URL}/v1/api/impact-scale-rating`;

export const CURD_NOMINATION_MODE = `${ADMIN_BASE_URL}/v1/api/nomination-mode`;

export const CURD_NOMINATION_STATUS = `${ADMIN_BASE_URL}/v1/api/nomination-status`;

export const CURD_L1_MENU = `${ADMIN_BASE_URL}/v1/api/level1-menu`;

export const CURD_L2_MENU = `${ADMIN_BASE_URL}/v1/api/level2-menu`;

export const CURD_L3_MENU = `${ADMIN_BASE_URL}/v1/api/level3-menu`;

export const CURD_API_MANAGEMENT = `${ADMIN_BASE_URL}/v1/api/application-service`;

export const CURD_USER_GROUPS = `${ADMIN_BASE_URL}/v1/api/user-group`;

export const CURD_ROLES = `${ADMIN_BASE_URL}/v1/api/user-role`;

export const CURD_L1_MENU_PERMISSIONS = `${ADMIN_BASE_URL}/v1/api/user-role-l1menu-permission`;

export const GET_L1_MENU_PERMISSIONS_ROLE = `${ADMIN_BASE_URL}/v1/api/user-role-l1menu-permission/get-all-menu`;

export const CURD_L1_SERVICE_PERMISSIONS = `${ADMIN_BASE_URL}/v1/api/user-role-l1service-permission`;

export const GET_L1_SERVICE_PERMISSIONS_ROLE = `${ADMIN_BASE_URL}/v1/api/user-role-l1service-permission/get-all-service`;

export const CURD_L2_MENU_PERMISSIONS = `${ADMIN_BASE_URL}/v1/api/user-role-l2menu-permission`;

export const GET_L2_MENU_PERMISSIONS_ROLE = `${ADMIN_BASE_URL}/v1/api/user-role-l2menu-permission/get-all-menu`;

export const CURD_L2_SERVICE_PERMISSIONS = `${ADMIN_BASE_URL}/v1/api/user-role-l2service-permission`;

export const GET_L2_SERVICE_PERMISSIONS_ROLE = `${ADMIN_BASE_URL}/v1/api/user-role-l2service-permission/get-all-service`;

export const CURD_L3_MENU_PERMISSIONS = `${ADMIN_BASE_URL}/v1/api/user-role-l3menu-permission`;

export const GET_L3_MENU_PERMISSIONS_ROLE = `${ADMIN_BASE_URL}/v1/api/user-role-l3menu-permission/get-all-menu`;

export const CURD_L3_SERVICE_PERMISSIONS = `${ADMIN_BASE_URL}/v1/api/user-role-l3service-permission`;

export const GET_L3_SERVICE_PERMISSIONS_ROLE = `${ADMIN_BASE_URL}/v1/api/user-role-l3service-permission/get-all-service`;

// ------------
export const GET_REPORTS_AREA_FOCUS = `${ADMIN_MICRO_SITE_URL}/area-of-focus/3dcbb765-20af-4a4a-9127-e84c81576faa`;
export const GET_REPORTS_ENGAGEMENT_STATUS = `${ADMIN_MICRO_SITE_URL}/engagement-notes/3dcbb765-20af-4a4a-9127-e84c81576faa`;
export const GET_ENGAGEMENT_DETAIL = `${ADMIN_MICRO_SITE_URL}/engagement-detail`;
export const CREATE_MENTOR_ENGAGEMENT_NOTES = `${ADMIN_BASE_URL}/v1/api/engagement-checkin-note`;
export const CREATE_MENTOR_REPORTS_FOCUS = `${ADMIN_BASE_URL}/v1/api/engagement-checkin-cfa`;
export const GET_MENTOR_REPORTS_CHECKIN = `${ADMIN_BASE_URL}/v1/api/summary/admin/latest-summary`;
export const PUBLISH_MENTOR_REPORTS_SUBMIT = `${ADMIN_BASE_URL}/v1/api/summary/mentor/submit-summary`;
export const UPDATE_MENTOR_RATING_SUBMIT = `${ADMIN_BASE_URL}/v1/api/client-engagement-checkin/update-rating`;
export const GET_MENTOR_GENERATE_SUMMARY = `${ADMIN_BASE_URL}/v1/api/summary/admin/generate-summary`;
export const GET_MENTOR_LATEST_SUMMARY = `${ADMIN_BASE_URL}/v1/api/summary/admin/latest-summary`;
export const GET_MENTOR_SUMMARY_DATE_API = `${ADMIN_BASE_URL}/v1/api/summary/admin/get-all-generate-summary-date`;
export const GET_MENTOR_SUMMARY_DATE_API_BY_ID = `${ADMIN_BASE_URL}/v1/api/summary/admin/get-generated-summary-by-id`;

// outcomes routes
export const MENTOR_RATING_API = `${ADMIN_BASE_URL}/v1/api/mentor-to-client-rating/admin/generate-rating`;
export const GET_MENTOR_RATING_DATE_API = `${ADMIN_BASE_URL}/v1/api/outcome/admin/get-all-generate-outcome-date`;
export const GET_MENTOR_RATING_DATE_API_BY_ID = `${ADMIN_BASE_URL}/v1/api/outcome/admin/get-generated-outcome-by-id`;
export const LATEST_MENTOR_RATING_API = `${ADMIN_BASE_URL}/v1/api/outcome/admin/latest-outcome`;
export const UPDATE_MENTOR_RATING_API = `${ADMIN_BASE_URL}/v1/api/mentor-to-client-rating/admin/update-rating`;

// coaching focus area
export const CURD_COACHING_FOCUS_AREA = `${ADMIN_BASE_URL}/v1/api/coaching-focus/admin`;

// notification
export const GET_ALL_NOTIFICATION = `${ADMIN_BASE_URL}/v1/api/notification-service/admin`;

export const UPDATE_NOTIFICATION_BY_ID = `${ADMIN_BASE_URL}/v1/api/notification-service/update-admin-notification`;

export const UPDATE_NOTIFICATION_ALL = `${ADMIN_BASE_URL}/v1/api/notification-service/all-update-admin-notification`

//toggle



export const TOGGLE_ADMIN_API = `${ADMIN_BASE_URL}/v1/api/microsite-feature-flags/admin`;
