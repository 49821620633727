import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countries } from "countries-list";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
// material ui
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  FormGroup,
  Grid,
  Avatar,
} from "@mui/material";
import { GridActionsCellItem } from '@mui/x-data-grid';
// icons
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// components
import Layout from "../../../layout/Layout";
import {
  MuiDataGrid,
  MuiAutocomplete,
  GridCellExpand,
  FormTextField as MyTextInput,
  ModalPopUp,
  FormSubmitButton,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  MuiTooltip
} from "../../../components";
import { ADMIN_MICRO_SITE_URL } from "../../../utils/config";
import MicrositeConfirmDialog from "./MicrositeConfirmDialog";
// redux
import {
  createMicrosite,
  updateMicrosite,
  deleteMicrosite,
  getMicrosite,
  startMicrosite,
  stopMicrosite,
  resetMessage,
  resetErrMessage,
  rowSelect
} from "../../../features/microsite/micrositeSlice";

import { getCustomer } from "../../../features/customer/customerSlice";

// -----------------------------------------------------------------------

const validationSchema = Yup.object({
  customerId: Yup.object().required("Customer Name is required"),
  microSiteUrl: Yup.string().required("Microsite URL is required").matches(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/, "Invalid URL"),
  description: Yup.string().required("Description is required"),
  micrositeTitle: Yup.string().required("Microsite Title is required"),
  micrositeIcon: Yup.mixed()
    .nullable()
    .required('A file is required')
    .test('Fichier taille',
      'File size must be less than 1mb', (value) => !value || (value && value.size <= 1024 * 1024)),
});

const editValidationSchema = Yup.object({
  microSiteUrl: Yup.string().required("Microsite URL is required").matches(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/, "Invalid URL"),
  description: Yup.string().required("Description is required"),
  micrositeTitle: Yup.string().required("Microsite Title is required"),
  micrositeIcon: Yup.mixed()
    .nullable()
    .test('Fichier taille',
      'File size must be less than 1mb', (value) => !value || (value && value.size <= 1024 * 1024)),
});

// ------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// -------------------------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({

  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width: '18rem !important',
      height: '60% !important',
      display: 'block !important',
      overflow: 'scroll !important',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft: '-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom: '1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText: {
    width: '50rem',
    [theme.breakpoints.down('md')]: {
      width: '100%'

    }

  },
  filter_grid: {
    [theme.breakpoints.down('md')]: {
      display: 'flex !important',
      flexDirection: 'column !important'

    },
  },
  close_btn: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '-2.563rem'

    },
  }

}))
const Microsites = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();
  const location = useLocation()

  const { message, errMessage, micrositesData, selectedData } =
    useSelector((state) => ({ ...state.microsite }));

  const { customersData } = useSelector((state) => ({ ...state.customer }));

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        customerId: "",
        microSiteUrl: "",
        description: "",
        micrositeStatus: "",
        micrositeIcon: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
    formikEdit.resetForm({
      values: {

        microSiteUrl: "",
        description: "",
        micrositeStatus: "",
        micrositeIcon: "",
      },
    });

  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: (password) => handleDeleteMicrosite(password),
    })

  const handleDeleteMicrosite = (password) => {
    let data = {
      id: selectedData?.id,
      password: password
    };
    dispatch(deleteMicrosite(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getMicrosite());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getCustomerName(params) {
    return <GridCellExpand value={params?.row?.excoCustomer?.customerName || ''} width={params.colDef.computedWidth} />;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }

  const data = {
    rows: micrositesData,
    columns: [
      {
        field: "customerName",
        headerName: "Customer Name",
        width: 200,
        renderCell: getCustomerName,
        valueGetter: (params) => params?.row?.excoCustomer?.customerName
      },
      {
        field: "micrositeLOGO",
        headerName: "Logo",
        width: 100,
        renderCell: (params) => {
          return (
            <Avatar
              alt="Icon"
              src={`${ADMIN_MICRO_SITE_URL}/static/${params?.row?.excoCustomer?.id}`}
              sx={{ width: 30, height: 30 }}
            />
          );
        },
      },

      {
        field: "micrositeURL",
        headerName: "URL",
        width: 200,
        renderCell: renderCellExpand
      },

      { field: "micrositeStatus", headerName: "Service Status", width: 200, renderCell: renderCellExpand },
      { field: "micrositeUIStatus", headerName: "UI Status", width: 150, renderCell: renderCellExpand },
      {
        field: "micrositeDescription",
        headerName: "Description",
        width: 250,
        renderCell: renderCellExpand
      },
    ],
  };

  const dataWithPermission = {
    rows: micrositesData,
    columns: [
      {
        field: "customerName",
        headerName: "Customer Name",
        width: 200,
        renderCell: getCustomerName,
        valueGetter: (params) => params?.row?.excoCustomer?.customerName
      },
      {
        field: "micrositeLOGO",
        headerName: "Logo",
        width: 100,
        renderCell: (params) => {
          return (
            <Avatar
              alt="Icon"
              src={`${ADMIN_MICRO_SITE_URL}/static/${params?.row?.excoCustomer?.id}`}
              sx={{ width: 30, height: 30 }}
            />
          );
        },
      },
      {
        field: "micrositeURL",
        headerName: "URL",
        width: 200,
        renderCell: renderCellExpand
      },
      {
        field: "micrositeUiTitle",
        headerName: "Microsite Title",
        width: 200,
        renderCell: renderCellExpand,

      },
      { field: "micrositeStatus", headerName: "Service Status", width: 200, renderCell: renderCellExpand },
      { field: "micrositeUIStatus", headerName: "UI Status", width: 150, renderCell: renderCellExpand },
      {
        field: "micrositeDescription",
        headerName: "Description",
        width: 250,
        renderCell: renderCellExpand
      },
      {
        field: showEditModal ? 'actions' : null,
        headerName: 'Actions',
        type: 'actions',
        width: 100,
        getActions: (params) => [
          <GridActionsCellItem icon={<MuiTooltip title="Stop" placement="bottom" ><NotInterestedIcon color="red" onClick={() => dispatch(stopMicrosite({ excoCustomerId: params?.row?.excoCustomer?.id })).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
              }, 2000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
              }, 3000);
            }
          })
          } /></MuiTooltip>} label="Edit" />,
          <GridActionsCellItem icon={<MuiTooltip title="Start" placement="bottom"><RestartAltIcon color="green" onClick={() => dispatch(startMicrosite({ excoCustomerId: params?.row?.excoCustomer?.id })).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
              }, 2000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
              }, 3000);
            }
          })

          } /></MuiTooltip>} label="Delete" />,
        ],
      },
    ],
  };

  const formik = useFormik({
    initialValues: {
      customerId: "",
      microSiteUrl: "",
      description: "",
      micrositeIcon: "",
      micrositeTitle: ""
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let formdata = new FormData();
      formdata.append("micrositeURL", values.microSiteUrl);
      formdata.append("micrositeDescription", values.description);
      formdata.append("excoCustomerId", values.customerId?.id);
      formdata.append("micrositeIcon", values.micrositeIcon);
      formdata.append("micrositeUiTitle", values.micrositeTitle)
      dispatch(createMicrosite(formdata)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            dispatch(getMicrosite());
            closeModel();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: selectedData?.micrositeDescription,
      microSiteUrl: selectedData?.micrositeURL,
      micrositeTitle: selectedData?.micrositeUiTitle,
      micrositeIcon: null
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      let formdata = new FormData();

      formdata.append("id", selectedData?.id)
      formdata.append("micrositeURL", values.microSiteUrl);
      formdata.append("micrositeDescription", values.description);
      formdata.append("micrositeUiTitle", values.micrositeTitle)
      values.micrositeIcon && formdata.append("micrositeIcon", values.micrositeIcon);

      dispatch(
        updateMicrosite(formdata)
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getMicrosite());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });



  useEffect(() => {
    dispatch(getMicrosite());
    dispatch(getCustomer());
  }, []);

  const handleFile = (e) => {
    showAdd && formik.setFieldValue("micrositeIcon", e.target.files[0]);
    showEdit && formikEdit.setFieldValue("micrositeIcon", e.target.files[0])
  };
  const classes = useStyles();

  console.log(selectedData)
  return (
    <Layout>
      <>
        <PageHeader variant="pageHeader">Microsite Management</PageHeader>
        <MuiDataGrid
          className={classes.filter_grid}
          setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddModel}
          setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditModal}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
          }

          toolbarColumn={true}
          toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={(location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "stop") && location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "start")) ? dataWithPermission : data}
          select={(data) => dispatch(rowSelect(data))}

        />
      </>
      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div
            className={classes.responsiveText}
          >
            <MuiAutocomplete
              name="customerId"
              options={customersData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.customerId}
              optionValue="customerName"
              label="Customer Name"
              required={true}
              error={
                formik.touched.customerId && Boolean(formik.errors.customerId)
              }
              helperText={formik.touched.customerId && formik.errors.customerId}
            />
            <MyTextInput
              label="Microsite URL"
              type="text"
              name="microSiteUrl"
              required={true}
              fullWidth={true}
              margin="normal"
              id="microSiteUrl"
              value={formik.values.microSiteUrl}
              onChange={formik.handleChange}
              error={
                formik.touched.microSiteUrl &&
                Boolean(formik.errors.microSiteUrl)
              }
              helperText={
                formik.touched.microSiteUrl && formik.errors.microSiteUrl
              }
            />
            <MyTextInput
              label="Microsite Title"
              type="text"
              name="micrositeTitle"
              required={true}
              fullWidth={true}
              margin="normal"
              id="micrositeTitle"
              value={formik.values.micrositeTitle}
              onChange={formik.handleChange}
              error={
                formik.touched.micrositeTitle && Boolean(formik.errors.micrositeTitle)
              }
              helperText={
                formik.touched.micrositeTitle && formik.errors.micrositeTitle
              }
            />
            <MyTextInput
              label="Microsite Description"
              type="text"
              name="description"
              required={true}
              fullWidth={true}
              margin="normal"
              id="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />

            <Grid container spacing={1} sx={{ marginTop: "0.5rem" }}>
              <Grid item xs={12} sm={12} md={8}>
                <FormGroup className="file-display mr-1">
                  <InputLabel className="bold label-sm">Icon *</InputLabel>
                  <MyTextInput
                    disabled
                    type="text"
                    name="micrositeIcon"
                    value={formik?.values?.micrositeIcon?.name || ""}
                    className="form-control-sm "
                    required={true}
                    size="small"
                    error={
                      formik.touched.micrositeIcon &&
                      Boolean(formik.errors.micrositeIcon)
                    }
                    helperText={
                      formik.touched.micrositeIcon &&
                      formik.errors.micrositeIcon
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <MyTextInput
                      type="file"
                      name="micrositeIcon"
                      sx={{
                        display: "none !important",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"

                      onChange={handleFile}
                      required={true}
                      size="small"
                    />
                  </label>
                </FormGroup>
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton
            type="submit"
            variant="contained"
          >
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={closeEditModal}
        closeModel={closeEditModal}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={closeEditModal} />
          <div className={classes.responsiveText}>
            <MyTextInput
              label="Microsite URL"
              type="text"
              name="microSiteUrl"
              required={true}
              fullWidth={true}
              margin="normal"
              id="microSiteUrl"
              value={formikEdit.values.microSiteUrl}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.microSiteUrl &&
                Boolean(formikEdit.errors.microSiteUrl)
              }
              helperText={
                formikEdit.touched.microSiteUrl && formikEdit.errors.microSiteUrl
              }
            />

            <MyTextInput
              label="Microsite Title"
              type="text"
              name="micrositeTitle"
              required={true}
              fullWidth={true}
              margin="normal"
              id="micrositeTitle"
              value={formikEdit.values.micrositeTitle}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.micrositeTitle && Boolean(formikEdit.errors.micrositeTitle)
              }
              helperText={
                formikEdit.touched.micrositeTitle && formikEdit.errors.micrositeTitle
              }
            />
            <MyTextInput
              label="Microsite Description"
              type="text"
              name="description"
              required={true}
              fullWidth={true}
              margin="normal"
              id="description"
              value={formikEdit.values.description}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.description && Boolean(formikEdit.errors.description)
              }
              helperText={
                formikEdit.touched.description && formikEdit.errors.description
              }
            />

            <Grid container spacing={1} sx={{ marginTop: "0.5rem" }}>
              <Grid item xs={12} sm={12} md={8}>
                <FormGroup className="file-display mr-1">
                  <InputLabel className="bold label-sm">Icon</InputLabel>
                  <MyTextInput
                    disabled
                    type="text"
                    name="micrositeIcon"
                    value={formikEdit?.values?.micrositeIcon?.name || ""}
                    className="form-control-sm "
                    size="small"
                    error={
                      formikEdit.touched.micrositeIcon &&
                      Boolean(formikEdit.errors.micrositeIcon)
                    }
                    helperText={
                      formikEdit.touched.micrositeIcon &&
                      formikEdit.errors.micrositeIcon
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <MyTextInput
                      type="file"
                      name="micrositeIcon"
                      sx={{
                        display: "none !important",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      value=""
                      onChange={handleFile}
                      size="small"

                    />
                  </label>
                </FormGroup>
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton
            type="submit"
            variant="contained"
          >
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <MicrositeConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Microsites;