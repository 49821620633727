import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  CURD_EXECUTIVE_SPONSAR } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createExecutiveSponsar = createAsyncThunk(
  "ExecutiveSponsar/createExecutiveSponsar",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post( CURD_EXECUTIVE_SPONSAR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getExecutiveSponsar = createAsyncThunk(
  "ExecutiveSponsar/getExecutiveSponsar",
  async () => {
    return axios.get( CURD_EXECUTIVE_SPONSAR, { withCredentials: true });
  }
);

export const updateExecutiveSponsar = createAsyncThunk(
  "ExecutiveSponsar/updateExecutiveSponsar",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( CURD_EXECUTIVE_SPONSAR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteExecutiveSponsar = createAsyncThunk(
  "ExecutiveSponsar/deleteExecutiveSponsar",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete( CURD_EXECUTIVE_SPONSAR, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const ExecutiveSponsarSlice = createSlice({
  name: "ExecutiveSponsar",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    executiveSponsarData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createExecutiveSponsar.pending]: (state, action) => {
      state.loading = true;
    },
    [createExecutiveSponsar.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createExecutiveSponsar.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getExecutiveSponsar.pending]: (state, action) => {
      state.loading = true;
    },
    [getExecutiveSponsar.fulfilled]: (state, action) => {
      state.loading = false;
      state.executiveSponsarData = action?.payload?.data;
    },
    [getExecutiveSponsar.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateExecutiveSponsar.pending]: (state, action) => {
      state.loading = true;
    },
    [updateExecutiveSponsar.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateExecutiveSponsar.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteExecutiveSponsar.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteExecutiveSponsar.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteExecutiveSponsar.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  ExecutiveSponsarSlice.actions;
export default ExecutiveSponsarSlice.reducer;
