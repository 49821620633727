import React, { useState, useRef } from "react";
import { Outlet, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// materail ui
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DateTime } from "luxon";
import io from "socket.io-client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import moment from "moment";
import ErrorNotifier from "../../components/common/ErrorNotifier";

import { ADMIN_SERVER_URL } from "../../utils/config";

import {
  Box,
  Toolbar,
  List,
  Divider,
  IconButton,
  Badge,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Fab,
  Typography,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
// icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import VerifiedIcon from '@mui/icons-material/Verified';
// components
import logo from "../../assets/images/exco-white-logo-one.png";
import shortLogo from "../../assets/images/signin-logo.png";
import { MuiAutocomplete, MuiTooltip } from "../../components";
import { AdminMainListItems, ExecMainListItems } from "./ListItems";
import Notifier from "../../components/common/Notifier";
// redux
import {
  logout,
  verifyLogin,
  resetErrMessage,
  resetMessage,
  getCurrentUser,
  getCurrentRoles,
  changePrimaryRole,
} from "../../features/auth/authSlice";
import {
  getNotification,
  updateNotification,
  updateNotificationAll,
  resetErrMessage as noticeErrMessage,
  resetMessage as noticeMessage,
} from "../../features/notifications/notificationSlice";
import { ADMIN_BASE_URL, ADMIN_CUSTOM_TEXT } from "../../utils/config";
import { format } from "date-fns";

// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  MuiLinks: {
    textDecoration: "none",
    color: "inherit",
  },
  closeMenu: {
    [theme.breakpoints.up("md")]: {
      display: "none !important",
      position: "absolute !important",
    },
  },
  MuiFabBtn: {
    position: "absolute !important",
    top: "2rem",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
    left: (open) => (open ? "14rem" : "4rem"),
    background: `${theme.palette.primary.light} !important`,
    "&:hover": {
      background: `${theme.palette.primary.main} !important`,
    },
    color: "white !impartant",
    zIndex: "1500 !important",
    width: "1.563rem !important",
    height: "1.563rem !important",
    minHeight: "0.625rem !important",
    transition: (open) =>
      open
        ? theme.transitions.create(["left"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })
        : theme.transitions.create(["left"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
  },
  MuiToolbar: {
    paddingRight: "3rem !important",
  },
}));

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  width: `calc(100% - ${theme.spacing(7)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(100% - ${theme.spacing(10)})`,
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px) !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.sidebarBg.main,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(10),
      },
    }),
  },
}));

const BasicMenu = ({
  anchorEl,
  handleClose,
  open,
  data,
  handleOpen,
  handleTwoOpen,
  handleReadAll,
  permission
  
}) => {
  console.log(data);
  return (
    <Menu
      PaperProps={{
        style: {
          maxHeight: "80vh", // Example max height, adjust as needed
          // Example width, adjust as needed
          overflowX: "auto", // Enable horizontal scrolling
        },
      }}
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      {  permission?.applicationService?.name?.split(".")[permission?.applicationService?.name?.split(".").length-1] === "readAllNotifs" &&   data.length > 0 && <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <MuiTooltip title="Read All">
          <IconButton onClick={handleReadAll}>
            <VerifiedIcon color="primary" sx={{ fontSize: 26 }} />
          </IconButton>

        </MuiTooltip>
      </div>}
      {data.length > 0 &&
        data
          .filter((item) => item.isRead === false)
          .map((notif) => {
            const timestamp = new Date(notif.createdOn).toString();
            const formattedDate = moment(timestamp)
              .local()
              .format("DD MMM YYYY HH:mm:ss Z");
            // let data = DateTime.fromISO(values?.engEndDate).set({
            //   hour: 0,
            //   minute: 0,
            //   second: 0,
            //   millisecond: 0,
            // });
            // const localIsoStringTwo = dateTwo.toISO();

            const data = DateTime.fromISO(notif?.createdOn).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });

            let localString = data.toISO();

            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <MenuItem title={formattedDate}>{notif.title}</MenuItem>
                <div>
                  <MuiTooltip title="Read">
                    <IconButton onClick={() => handleTwoOpen(notif)}>
                      <MarkAsUnreadIcon color="primary" sx={{ fontSize: 26 }} />
                    </IconButton>
                  </MuiTooltip>
                </div>
              </div>
            );
          })}
    </Menu>
  );
};

const SecondBasicMenu = ({
  anchorEl,
  handleClose,
  open,
  data,
  handleIsReadClick,
  handleOpen,
}) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "red",
            }}
          >
            <CloseIcon color="red" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Notification
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {data?.title}
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}  >Disagree</Button>
        <Button onClick={handleIsReadClick} autoFocus>Agree</Button>
      </DialogActions> */}
    </Dialog>
  );
};

const DashboardContent = (props) => {
  const [menuToggle, setmenuToggle] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [notifyData, setNotifyData] = React.useState({});
  const [notifyTwoOpen, setNotifyTwoOpen] = React.useState(false);
  const [notificationOpen, setNotificationOpen] = React.useState(false);

  const [notifyTwoAnchor, setNotifyTwoAnchor] = React.useState(null);

  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [userPrimaryRole, setUserPrimaryRole] = React.useState({});
  const [time, setTime] = useState(null);
  const [blink, setBlink] = useState(false);
  const socketRef = useRef(null);
  const classes = useStyles(open);

  const { message, loginData, userRolesData, isLoggedIn } = useSelector(
    (state) => ({
      ...state.auth,
    })
  );

  const { notificationData, messageNot, errMessage } = useSelector((state) => ({
    ...state.notification,
  }));

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseOver = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIsReadClick = (data) => {
    let body = {
      //  excoUserId:loginData?.data?.getExcoUser?.id,
      id: data?.id,
      //  isRead : "true",
      //  title : notifyData?.title
    };
    dispatch(updateNotification(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(noticeMessage());
          // dispatch(getNotification())
          // navigate("/");
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(noticeErrMessage());
        }, 2000);
      }
    });
  };

  const handleIsReadClickAll = () => {

    dispatch(updateNotificationAll()).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(noticeMessage());
          dispatch(getNotification())
          // navigate("/");
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(noticeErrMessage());
        }, 2000);
      }
    });
  };


  const handleClickMenu = (data) => {
    setNotificationOpen(false);
    setNotificationAnchorEl(null);
    setNotifyTwoOpen(true);
    setNotifyData(data);
  };

  const handleNotificationTwoClick = (data) => {
    setNotifyTwoOpen(true);
    setNotifyData(data);
    handleIsReadClick(data);
  };

  const handleNotificationTwoClose = () => {
    setNotifyTwoOpen(false);
  };

  React.useEffect(() => {
    dispatch(getNotification());
  }, [notifyTwoOpen]);

  const handleMouseOverClose = () => {
    setAnchorE2(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    setNotificationOpen(true);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout()).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(verifyLogin());
          navigate("/");
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const popoverOpen = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const popoverOpen2 = Boolean(anchorE2);
  const id2 = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getNotification());
    dispatch(verifyLogin());
    dispatch(getCurrentRoles());
    navigate("/exco/dashboard");
    setUserPrimaryRole(loginData?.data?.getExcoUser?.excoUserRole);
    // return <ProfileAvatar />;
  }, []);

  React.useEffect(() => {
    return isLoggedIn === false && navigate("/");
  }, [isLoggedIn]);

  // React.useEffect(() => {
  //   const imageUrl = `${ADMIN_BASE_URL}/v1/api/authentication/profile-pic`;
  //   fetch(imageUrl, { credentials: "include" }).then((response) => {
  //     if (response?.ok) {
  //       setBlink(true);
  //     } else if (!response?.ok) {
  //       setBlink(false);
  //     }
  //   });
  // }, [loginData]);

  // const ProfileAvatar = () => {
  //   if (blink) {
  //     return (
  //       <Avatar
  //         src={`${ADMIN_BASE_URL}/v1/api/authentication/profile-pic`}
  //         sx={{ fontSize: 34 }}
  //       />
  //     );
  //   } else {
  //     return <Avatar sx={{ fontSize: 34 }} />;
  //   }
  // };

  const changePrimaryRoleHandler = (role) => {
    setUserPrimaryRole(role);
    let data = {
      userRoleId: role?.userRole?.id,
    };
    dispatch(changePrimaryRole(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          handleLogout();
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const matches2 = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  React.useEffect(() => {
    if (matches) {
      setOpen(false);
      setmenuToggle(true);
    } else if (matches2) {
      setOpen(true);
      setmenuToggle(false);
    }
  }, [matches, matches2]);

  const fullSidebar = () => {
    setmenuToggle(false);
    setOpen(true);
  };

  const clickSideMenu = () => {
    if (matches) {
      setmenuToggle(true);
      setOpen(false);
    }
  };

  React.useEffect(() => {
    const socket = io(ADMIN_SERVER_URL, {
      path: "/socket.io",
      autoConnect: true,
      transports: ["websocket"],
    });

    // socket.on("connect", () => {});

    socket.on("serverTime", (timestamp) => {
      const formattedDate = moment(timestamp)
        .local()
        .format("DD MMM YYYY HH:mm:ss Z");
      setTime(formattedDate);
    });

    // socket.on("connect_error", (err) => {
    //   // console.log("Connection Failed", err);
    // });

    return () => {
      socket.disconnect();
    };
  }, []);

  // console.log(loginData?.data?.getExcoUserRoles)

  // console.log(loginData?.data?.getExcoUserRoles?.
  //   userRoleL1MenuPermission.find((dataTwo) => dataTwo?.level1Menu.l1MenuName === "Dashboard"
  //   )?.level1Menu?.userRoleL1ServicePermission.find((dataThree) => dataThree?.applicationService?.name.split(".")[dataThree?.applicationService?.name?.split(".").length - 1] === "readAllNotifs"
  // ))

    let result = loginData?.data?.getExcoUserRoles?.
    userRoleL1MenuPermission.find((dataTwo) => dataTwo?.level1Menu.l1MenuName === "Dashboard"
    )

    let resultTwo = result?.level1Menu?.userRoleL1ServicePermission?.find((dataThree) => dataThree?.applicationService?.name.split(".")[dataThree?.applicationService?.name?.split(".").length - 1]=== "readAllNotifs")

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={open} sx={{ boxShadow: "none" }}>
        <Toolbar className={classes.MuiToolbar}>
          <Box sx={{ flexGrow: 1, color: "#000000", textAlign: "center" }}>
            {ADMIN_CUSTOM_TEXT && (
              <Typography variant="h5">{ADMIN_CUSTOM_TEXT}</Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "row" : "column",
              marginRight: "1.5rem",
            }}
          >
            <span style={{ color: "grey", alignSelf: "flex-end" }}>
              PLATFORM TIME
            </span>
            <span style={{ color: "black", display: "block" }}>{time}</span>
          </Box>

          <MuiTooltip title="Notifications">
            <IconButton
              onClick={handleNotificationClick}
              anchorEl={notificationAnchorEl}
              sx={{ marginRight: "1.5rem" }}
              disabled={
                notificationData.filter((data) => data.isRead === false)
                  .length === 0
              }
              
            >
              <Badge
                sx={{ fontSize: 30 }}
                badgeContent={
                  notificationData.filter((data) => data.isRead === false)
                    .length
                }
                color="error"
              >
                <NotificationsIcon sx={{ fontSize: 36 }} />
              </Badge>
            </IconButton>
          </MuiTooltip>

          <BasicMenu
            handleOpen={(data) => handleClickMenu(data)}
            handleTwoOpen={(data) => handleNotificationTwoClick(data)}
            open={notificationOpen}
            anchorEl={notificationAnchorEl}
            handleClose={handleNotificationClose}
            handleReadAll={handleIsReadClickAll}
            data={notificationData} 
            permission ={resultTwo}
          />

          <SecondBasicMenu
            handleIsReadClick={handleIsReadClick}
            open={notifyTwoOpen}
            anchorEl={notifyTwoAnchor}
            handleClose={handleNotificationTwoClose}
            data={notifyData}
          />

          <Box>
            <MuiTooltip title="User Profile">
              <IconButton
                sx={{
                  marginTop: "1rem",
                  paddingBottom: 0,
                  paddingTop: "0.5rem",
                }}
                aria-describedby={id}
                onClick={handleClick}
              >
               <Avatar
          src={`${ADMIN_BASE_URL}/v1/api/authentication/profile-pic`}
          sx={{ fontSize: 34 }}
        />
              </IconButton>
            </MuiTooltip>
            <Typography
              varient="body2"
              sx={{
                color: "black !important",
                paddingRight: "0.5rem !important",
              }}
              aria-describedby={id}
              onMouseOver={handleMouseOver}
            >
              {loginData?.data?.getExcoUser?.excoUserDetail.firstName.charAt(0)}
              {loginData?.data?.getExcoUser?.excoUserDetail?.lastName.charAt(0)}
              , {loginData?.data?.getExcoUser?.excoUserRole?.userRole?.roleCode}
            </Typography>
            <Popover
              id={id2}
              open={popoverOpen2}
              anchorEl={anchorE2}
              onClose={handleMouseOverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              {userRolesData.length > 0 &&
                userRolesData.map((item) => {
                  return (
                    <List key={item.id} disablePadding>
                      <ListItem
                        sx={{
                          paddingTop: "0 !important",
                          paddingBottom: "0 !important",
                        }}
                      >
                        <ListItemButton
                          onClick={() =>
                            !item.isPrimary && changePrimaryRoleHandler(item)
                          }
                          sx={{ padding: "0 !important" }}
                        >
                          <ListItemText sx={{ textAlign: "center" }}>
                            {item.userRole.roleCode}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  );
                })}
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      {!menuToggle && (
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <CloseIcon
              style={{ color: "white" }}
              onClick={clickSideMenu}
              className={classes.closeMenu}
            />
            <Box
              edge="start"
              aria-label="open drawer"
              sx={{
                flexGrow: 1,
                textAlign: "start",
                ...(open && { display: "none" }),
              }}
            >
              <img alt="shortLogo" src={shortLogo} width="52" height="52" />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                textAlign: "center",
                ...(!open && { display: "none" }),
                paddingTop: "1rem",
              }}
            >
              <img src={logo} alt="nav logo" width="120" height="52" />
            </Box>
          </Toolbar>

          <Divider />
          <List component="nav">
            <AdminMainListItems show={open} clickSideMenu={clickSideMenu} />
          </List>
        </Drawer>
      )}

      {menuToggle && (
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <Box>
              <MenuIcon style={{ color: "white" }} onClick={fullSidebar} />
            </Box>
          </Toolbar>

          <Divider />
          {/* <List component="nav">
        <AdminMainListItems show={open} />
        </List> */}
        </Drawer>
      )}

      <Outlet />
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <List>
          {/* <ListItem>
            <MuiAutocomplete
              name="userPrimaryRole"
              options={userRolesData}
              setValue={(name, newValue) =>
                changePrimaryRoleHandler(newValue)
              }
              value={userPrimaryRole}
              innerValue={true}
              optionValue="userRole"
              optionSecValue="roleCode"
              label="Roles"
              required={true}
              // error={formik.touched.geography && Boolean(formik.errors.geography)}
              // helperText={formik.touched.geography && formik.errors.geography}
            />
            </ListItem> */}
          <Link to="/exco/my-profile" className={classes.MuiLinks}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleClose()}>
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary="My Profile" />
              </ListItemButton>
            </ListItem>
          </Link>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {open ? (
        <Fab onClick={toggleDrawer} className={classes.MuiFabBtn}>
          <ChevronLeftIcon />
        </Fab>
      ) : (
        <Fab onClick={toggleDrawer} className={classes.MuiFabBtn}>
          <ChevronRightIcon />
        </Fab>
      )}
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
      {/* {messageNot && <Notifier message={messageNot} />} */}
    </Box>
  );
};

const Sidebar = (props) => {
  return <DashboardContent {...props} />;
};

export default Sidebar;
