import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countries, continents } from "countries-list";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from "@mui/material";
// components
import Layout from "../../../layout/Layout";
import {
  MuiDataGrid,
  MuiAutocomplete,
  geographyArr,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
} from "../../../components";
// redux
import {
  createCustomer,
  getCustomer,
  updateCustomer,
  deleteCustomer,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../features/customer/customerSlice";
import { getContactDesignation } from "../../../features/config/contactDesignationSlice";
import { useLocation } from "react-router-dom";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  customerName: Yup.string().required("Customer Name is required"),
  // firstName: Yup.string().required("First Name is required"),
  // lastName: Yup.string().required("Last Name is required"),
  // geography: Yup.object().required("Geo Name is required"),
  // dialingCode: Yup.string().required("Dialing Code is required"),
  // contactNumber: Yup.string()

  //   .matches(/^[0-9]+$/, "Must be only digits")
  //   .min(10, "Must be exactly 10 digits")
  //   .max(10, "Must be exactly 10 digits"),
  // country: Yup.object().required("country is required "),
  // designation: Yup.object().required("Designation is required"),

  comments: Yup.string(),
});

const editValidationSchema = Yup.object({
  customerName: Yup.string().required("Customer Name is required"),
  // firstName: Yup.string().required("First Name is required"),
  // lastName: Yup.string().required("Last Name is required"),
  // geography: Yup.string().required("Geo Name is required"),
  // dialingCode: Yup.string().required("Dialing Code is required"),
  // contactNumber: Yup.string()
  //    .nullable()
  //   .matches(/^[0-9]+$/, "Must be only digits")
  //   .min(10, "Must be exactly 10 digits")
  //   .max(10, "Must be exactly 10 digits"),
  // country: Yup.string().required("country is required "),
  // designation: Yup.string().required("Designation is required"),
  // contactEmail: Yup.string("Enter your email")
  //   .required("Email is required")
  //   // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
  //   .email("Email is not valid"),
  comments: Yup.string(),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("md")]: {
      width: "18rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  filter_grid: {
    [theme.breakpoints.down("md")]: {
      display: "flex !important",
      flexDirection: "column !important",
    },
  },
  close_btn: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "-2.563rem",
    },
  },
}));

const Onboarding = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [countryArr, setCountryArr] = useState([]);

  const dispatch = useDispatch();

  const location = useLocation();

  const { message, errMessage, customersData, selectedData } = useSelector(
    (state) => ({ ...state.customer })
  );

  const { contactDesignationData } = useSelector((state) => ({
    ...state.contactDesig,
  }));

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        firstName: "",
        lastName: "",
        customerName: "",
        geography: "",
        designation: "",
        contactEmail: "",
        country: "",
        contactNumber: "",
        dialingCode: "",
        custStatus: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
    formikEdit.resetForm({
      values: {
        firstName: "",
        lastName: "",
        customerName: "",
        geography: "",
        designation: "",
        contactEmail: "",
        country: "",
        contactNumber: "",
        dialingCode: "",
        custStatus: "",
        comments: "",
      },
    });
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteCustomer(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getCustomer());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getDialContact(params) {
    return `${params.row.dialingCode || ""}-${params.row.contactNumber || ""}`;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: customersData,
    columns: [
      {
        field: "customerName",
        headerName: "Customer Name",
        width: 250,
        renderCell: renderCellExpand,
      },
      { field: "geoName", headerName: "Geography", width: 150 },
      { field: "country", headerName: "Country", width: 150 },
      // { field: "contactName", headerName: "Contact Name", width: 150 },

      // {
      //   field: "contactNumber",
      //   headerName: "Contact Number",
      //   width: 150,
      //   valueGetter: getDialContact,
      // },
      // {
      //   field: "contactEmail",
      //   headerName: "Contact Email",
      //   width: 250,
      //   renderCell: renderCellExpand,
      // },

      {
        field: "custStatus",
        headerName: "Customer Status",
        width: 150,
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 350,
        renderCell: renderCellExpand,
      },
    ],
  };

  const formik = useFormik({
    reset: true,
    initialValues: {
      firstName: "",
      lastName: "",
      customerName: "",
      geography: "",
      designation: "",
      contactEmail: "",
      country: "",
      contactNumber: "",
      dialingCode: "",
      custStatus: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createCustomer({
          customerName: values.customerName,
          geoName: values.geography?.code,
          country: values.country?.name,


          custStatus: "New",
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getCustomer());
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: selectedData?.contactName?.split(" ")[0],
      lastName: selectedData?.contactName?.split(" ")[1],
      customerName: selectedData?.customerName,
      geography: selectedData?.geoName,
      designation: selectedData?.designation,
      contactEmail: selectedData?.contactEmail,
      country: selectedData?.country,
      contactNumber: selectedData?.contactNumber,
      dialingCode: selectedData?.dialingCode,
      custStatus: selectedData?.custStatus,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateCustomer({
          id: selectedData?.id,
          customerName: values.customerName,
          geoName: values.geography,
          country: values.country,

          custStatus: values.custStatus,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getCustomer());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getCustomer());
    dispatch(getContactDesignation());
  }, []);

  useEffect(() => {
    if (!formik?.values?.geography) {
      formik.setFieldValue("country", "")


    }

    let countries = [];
    options.map((option) => {
      if (option.continent === formik?.values?.geography?.code) {
        return countries.push(option);
      }
    });
    setCountryArr(countries);
  }, [formik?.values?.geography]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("dialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formikEdit?.values?.country) {
        return formikEdit?.setFieldValue("dialingCode", option?.phone);
      }
    });
  }, [formikEdit?.values?.country]);
  const classes = useStyles();
  return (
    <Layout>
      <>
        <PageHeader variant="pageHeader">
          Enterprise Customer Management
        </PageHeader>
        <MuiDataGrid
          setShowAdd={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "create"
            ) && showAddModel
          }
          setShowEdit={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "update"
            ) && showEditModal
          }
          setShowDelete={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "delete"
            ) && showDelete
          }
          toolbarColumn={true}
          toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>
      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div className={classes.responsiveText}>
            {/* <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Customer Name"
                  type="text"
                  name="customerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="customerName"
                  value={formik.values.customerName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.customerName &&
                    Boolean(formik.errors.customerName)
                  }
                  helperText={
                    formik.touched.customerName && formik.errors.customerName
                  }
                />
               <MuiAutocomplete
                  name="country"
                  options={countryArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.country}
                  optionValue="name"
                  label="Country"
                  required={true}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />

                
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="Dialing Code"
                      type="number"
                      name="dialingCode"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="dialingCode"
                      value={formik.values.dialingCode}
                      disabled
                      error={
                        formik.touched.dialingCode &&
                        Boolean(formik.errors.dialingCode)
                      }
                      helperText={
                        formik.touched.dialingCode && formik.errors.dialingCode
                      }
                    />
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Contact Number"
                      type="string"
                      name="contactNumber"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="contactNumber"
                      value={formik.values.contactNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.contactNumber &&
                        Boolean(formik.errors.contactNumber)
                      }
                      helperText={
                        formik.touched.contactNumber &&
                        formik.errors.contactNumber
                      }
                    />
                  </div>
                </div>
                <MuiAutocomplete
                  name="designation"
                  options={contactDesignationData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.designation}
                  optionValue="designationName"
                  label="Designation"
                  required={true}
                  error={
                    formik.touched.designation && Boolean(formik.errors.designation)
                  }
                  helperText={formik.touched.designation && formik.errors.designation}
                />
               
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  name="geography"
                  options={geographyArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.geography}
                  optionValue="code"
                  optionSecValue="name"
                  label="Geography"
                  required={true}
                  error={
                    formik.touched.geography && Boolean(formik.errors.geography)
                  }
                  helperText={formik.touched.geography && formik.errors.geography}
                />
<div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label=" Contact First Name"
                      type="text"
                      name="firstName"
                      required={true}
                      margin="normal"
                      id="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </div>

                  <div>
                    <MyTextInput
                      label=" Contact Last Name"
                      type="text"
                      name="lastName"
                      required={true}
                      margin="normal"
                      id="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </div>
                </div>
                <MyTextInput
                  label="Contact Email"
                  type="contactEmail"
                  name="contactEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactEmail"
                  value={formik.values.contactEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactEmail &&
                    Boolean(formik.errors.contactEmail)
                  }
                  helperText={
                    formik.touched.contactEmail && formik.errors.contactEmail
                  }
                />

                
                <MyTextInput
                  label="Comments"
                  type="comments"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                />
              </Grid>
            </Grid> */}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Customer Name"
                  type="text"
                  name="customerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="customerName"
                  value={formik.values.customerName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.customerName &&
                    Boolean(formik.errors.customerName)
                  }
                  helperText={
                    formik.touched.customerName && formik.errors.customerName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>

                <MuiAutocomplete
                  name="geography"
                  options={geographyArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.geography}
                  optionValue="code"
                  optionSecValue="name"
                  label="Geography"
                  // required={true}
                  error={
                    formik.touched.geography && Boolean(formik.errors.geography)
                  }
                  helperText={
                    formik.touched.geography && formik.errors.geography
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  name="country"
                  options={countryArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.country}
                  optionValue="name"
                  label="Country"
                  // required={true}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Comments"
                  type="comments"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={3}>
                <MyTextInput
                  label=" Contact First Name"
                  type="text"
                  name="firstName"
                  required={true}
                  margin="normal"
                  id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <MyTextInput
                  label=" Contact Last Name"
                  type="text"
                  name="lastName"
                  required={true}
                  margin="normal"
                  id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid> */}
              {/* 
              <Grid item xs={4} sm={4} md={2}>
                <MyTextInput
                  label="Dialing Code"
                  type="number"
                  name="dialingCode"
                 
                  fullWidth={true}
                  margin="normal"
                  id="dialingCode"
                  value={formik.values.dialingCode}
                  disabled
                  error={
                    formik.touched.dialingCode &&
                    Boolean(formik.errors.dialingCode)
                  }
                  helperText={
                    formik.touched.dialingCode && formik.errors.dialingCode
                  }
                />
              </Grid>
              <Grid item xs={8} sm={8} md={4}>
                <MyTextInput
                  label="Contact Number"
                  type="string"
                  name="contactNumber"
                  // required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactNumber"
                  value={formik.values.contactNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactNumber &&
                    Boolean(formik.errors.contactNumber)
                  }
                  helperText={
                    formik.touched.contactNumber && formik.errors.contactNumber
                  }
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Contact Email"
                  type="contactEmail"
                  name="contactEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactEmail"
                  value={formik.values.contactEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactEmail &&
                    Boolean(formik.errors.contactEmail)
                  }
                  helperText={
                    formik.touched.contactEmail && formik.errors.contactEmail
                  }
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  name="designation"
                  options={contactDesignationData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.designation}
                  optionValue="designationName"
                  label="Contact Type"
                  required={true}
                  error={
                    formik.touched.designation &&
                    Boolean(formik.errors.designation)
                  }
                  helperText={
                    formik.touched.designation && formik.errors.designation
                  }
                />
              </Grid> */}

            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <div className={classes.responsiveText}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Customer Name"
                  type="text"
                  name="customerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="customerName"
                  defaultValue={formikEdit.values.customerName}
                  value={formikEdit.values.customerName}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.customerName &&
                    Boolean(formikEdit.errors.customerName)
                  }
                  helperText={
                    formikEdit.touched.customerName &&
                    formikEdit.errors.customerName
                  }
                />

                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    defaultValue={formikEdit.values.country}
                    value={formikEdit.values.country}
                    label="Country"
                    onChange={formikEdit.handleChange}
                    error={
                      formikEdit.touched.country &&
                      Boolean(formikEdit.errors.country)
                    }
                  >
                    {options.map((option) => {
                      if (formikEdit.values.geography === option.continent) {
                        return (
                          <MenuItem key={option.name} value={option.name}>
                            {option.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.country && formikEdit.errors.country}
                  </FormHelperText>
                </FormControl>
                {/* <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="Dialing Code"
                      type="number"
                      name="dialingCode"
                     
                      fullWidth={true}
                      margin="normal"
                      id="dialingCode"
                      value={formikEdit.values.dialingCode}
                      disabled
                      error={
                        formikEdit.touched.dialingCode &&
                        Boolean(formikEdit.errors.dialingCode)
                      }
                      helperText={
                        formikEdit.touched.dialingCode &&
                        formikEdit.errors.dialingCode
                      }
                    />
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Contact Number"
                      type="number"
                      name="contactNumber"
                    
                      fullWidth={true}
                      margin="normal"
                      id="contactNumber"
                      value={formikEdit.values.contactNumber}
                      onChange={formikEdit.handleChange}
                      error={
                        formikEdit.touched.contactNumber &&
                        Boolean(formikEdit.errors.contactNumber)
                      }
                      helperText={
                        formikEdit.touched.contactNumber &&
                        formikEdit.errors.contactNumber
                      }
                    />
                  </div>
                </div> */}
                {/* <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Contact Type *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="designation"
                    value={formikEdit.values.designation}
                    error={
                      formikEdit.touched.designation &&
                      Boolean(formikEdit.errors.designation)
                    }
                    label="Designation"
                    onChange={formikEdit.handleChange}
                  >
                    {contactDesignationData.length > 0 &&
                      contactDesignationData.map((item) => (
                        <MenuItem key={item.id} value={item.designationName}>
                          {item.designationName}
                        </MenuItem>
                      ))}
                  </Select>
                  {formikEdit?.errors?.designation && (
                    <FormHelperText error={true} sx={{ color: "red" }}>
                      {formikEdit.touched.designation &&
                        formikEdit.errors.designation}
                    </FormHelperText>
                  )}
                </FormControl> */}
                <MyTextInput
                  label="Comments"
                  type="comments"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formikEdit.values.comments}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.comments &&
                    Boolean(formikEdit.errors.comments)
                  }
                  helperText={
                    formikEdit.touched.comments && formikEdit.errors.comments
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Geography
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="geography"
                    defaultValue={formikEdit?.values?.geography?.toString()}
                    value={formikEdit.values.geography}
                    error={
                      formikEdit.touched.geography &&
                      Boolean(formikEdit.errors.geography)
                    }
                    label="Geography"
                    onChange={formikEdit.handleChange}
                  >
                    {Object.keys(continents).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}-{continents[item]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.geography &&
                      formikEdit.errors.geography}
                  </FormHelperText>
                </FormControl>
                {/* <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label=" Contact First Name"
                      type="text"
                      name="firstName"
                      required={true}
                      margin="normal"
                      id="firstName"
                      value={formikEdit.values.firstName}
                      onChange={formikEdit.handleChange}
                      error={
                        formikEdit.touched.firstName &&
                        Boolean(formikEdit.errors.firstName)
                      }
                      helperText={
                        formikEdit.touched.firstName &&
                        formikEdit.errors.firstName
                      }
                    />
                  </div>

                  <div>
                    <MyTextInput
                      label=" Contact Last Name"
                      type="text"
                      name="lastName"
                      required={true}
                      margin="normal"
                      id="lastName"
                      value={formikEdit.values.lastName}
                      onChange={formikEdit.handleChange}
                      error={
                        formikEdit.touched.lastName &&
                        Boolean(formikEdit.errors.lastName)
                      }
                      helperText={
                        formikEdit.touched.lastName &&
                        formikEdit.errors.lastName
                      }
                    />
                  </div>
                </div> */}
                {/* <MyTextInput
                  label="Contact Email"
                  type="contactEmail"
                  name="contactEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactEmail"
                  value={formikEdit.values.contactEmail}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.contactEmail &&
                    Boolean(formikEdit.errors.contactEmail)
                  }
                  helperText={
                    formikEdit.touched.contactEmail &&
                    formikEdit.errors.contactEmail
                  }
                /> */}

                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Customer Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="custStatus"
                    defaultValue={formikEdit?.values?.custStatus?.toString()}
                    value={formikEdit.values.custStatus}
                    error={
                      formikEdit.touched.custStatus &&
                      Boolean(formikEdit.errors.custStatus)
                    }
                    label="Customer Status"
                    onChange={formikEdit.handleChange}
                  >
                    <MenuItem key="New" value="New">
                      New
                    </MenuItem>
                    <MenuItem key="Active" value="Active">
                      Active
                    </MenuItem>
                    <MenuItem key="InActive" value="InActive">
                      InActive
                    </MenuItem>
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.geography &&
                      formikEdit.errors.geography}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Onboarding;
