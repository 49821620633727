import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// routes
import { GET_ALL_NOTIFICATION ,UPDATE_NOTIFICATION_BY_ID,UPDATE_NOTIFICATION_ALL } from "../../utils/routes";

// ---------------------------------------------------------------------------------

export const getNotification = createAsyncThunk(
  "notification/getNotification",
  async () => {
    console.log("getNotification")
    return axios.get(GET_ALL_NOTIFICATION, { withCredentials: true });
  }
);

export const updateNotificationAll = createAsyncThunk(
  "notification/updatenotificationAll",
  async () => {

    const result = axios.get(UPDATE_NOTIFICATION_ALL, {

      withCredentials: true,
    });
    return result;

  }
);




export const updateNotification = createAsyncThunk(
  "notification/updatenotification",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.put(UPDATE_NOTIFICATION_BY_ID ,data, {
     
        withCredentials: true,
      });
      return result;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/deletenotofication",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.delete(GET_ALL_NOTIFICATION, {
        data,
        withCredentials: true,
      });
      return result;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

// ---------------------------------------------------------------------------------

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    loading: false,
    notificationData: [],
    selectedData: {},
    messageNot: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.messageNot = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getNotification.pending]: (state, action) => {
      state.loading = true;
    },
    [getNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.notificationData = action?.payload?.data;
    },
    [getNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.message;
    },

    [updateNotificationAll.pending]: (state, action) => {
      state.loading = true
    },
    [updateNotificationAll.fulfilled]: (state, action) => {
      state.loading = false
      state.notifyMessage = action?.payload?.data?.message
    },
    [updateNotificationAll.rejected]: (state, action) => {
      state.loading = false
      state.errornotifyMessage = action?.payload?.data?.message
    },

    [updateNotification.pending] : (state,action)=>{
      state.loading = true
    },

    [updateNotification.fulfilled] : (state,action)=>{
      state.loading = false
      state.messageNot = action?.payload?.data?.message
    },
    [updateNotification.rejected] : (state,action)=>{
      state.loading = false
      state.errMessage = action?.payload?.data?.message
    },

    [deleteNotification.pending] : (state,action)=>{
      state.loading = true
    },
    [deleteNotification.fulfilled] : (state,action)=>{
      state.loading = false
      state.messageNot = action?.payload?.data?.message
    },
    [deleteNotification.rejected] : (state,action)=>{
      state.loading = false
      state.errMessage = action?.payload?.data?.message
    }

  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  notificationSlice.actions;

export default notificationSlice.reducer;
