import React, { useEffect, useState } from 'react'
import { PageHeader } from '@excoleadershipui/pionweblib'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// material ui
import {Stack, Box, Grid, Paper} from '@mui/material'
import { makeStyles } from "@mui/styles";

// components
import { MuiDataGrid, ConfirmDialog, Notifier, ErrorNotifier, GridCellExpand } from '../../../../components';
import Layout from '../../../../layout/Layout'
import AddL1MenuPermission from './AddL1MenuPermission'
import AddL1ServicePermission from './AddL1ServicePermission'
import AddL2MenuPermission from './AddL2MenuPermission'
import AddL2ServicePermission from './AddL2ServicePermission'
import AddL3MenuPermission from './AddL3MenuPermission'
import AddL3ServicePermission from './AddL3ServicePermission'
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// redux
import {  getL1MenuPermissionRole, getL1ServicePermissionRole, deleteL1MenuPermission, deleteL1ServicePermission, l1MenuRowSelect, l1ServiceRowSelect, resetMessage as l1ResetMessage, resetErrMessage as l1ResetErrMessage } from '../../../../features/platform/role_management/access_permissions/l1AccessPermissionSlice';
import { getL2MenuPermissionRole, getL2ServicePermissionRole, deleteL2MenuPermission, deleteL2ServicePermission, l2MenuRowSelect, l2ServiceRowSelect, resetMessage as l2ResetMessage, resetErrMessage as l2ResetErrMessage} from '../../../../features/platform/role_management/access_permissions/l2AccessPermissionSlice';
import {getL3MenuPermissionRole, getL3ServicePermissionRole, deleteL3MenuPermission, deleteL3ServicePermission, l3MenuRowSelect, l3ServiceRowSelect, resetMessage as l3ResetMessage, resetErrMessage as l3ResetErrMessage} from '../../../../features/platform/role_management/access_permissions/l3AccessPermissionSlice'

// ------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: "0.5rem",
      borderRadius: "1rem !important",
    },
  }));

// -----------------------------------------------------------------------------

const MenuAccessPermissions = () => {
  const [showL1MenuAdd, setShowL1MenuAdd] = useState("")
  const [showL1ServiceAdd, setShowL1ServiceAdd]  = useState("")
  const [l1MenuConfirmDialog, setL1MenuConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [l1ServiceConfirmDialog, setL1ServiceConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showL2MenuAdd, setShowL2MenuAdd] = useState("")
  const [showL2ServiceAdd, setShowL2ServiceAdd]  = useState("")
  const [l2MenuConfirmDialog, setL2MenuConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [l2ServiceConfirmDialog, setL2ServiceConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showL3MenuAdd, setShowL3MenuAdd] = useState("")
  const [showL3ServiceAdd, setShowL3ServiceAdd]  = useState("")
  const [l3MenuConfirmDialog, setL3MenuConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [l3ServiceConfirmDialog, setL3ServiceConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const classes = useStyles();
  const location = useLocation()
const dispatch = useDispatch()
const l1PermissionData = useSelector((state) => ({...state.l1AccessPermission}))
const l2PermissionData = useSelector((state) => ({...state.l2AccessPermission}))
const l3PermissionData = useSelector((state) => ({...state.l3AccessPermission}))
const {selectedData} = useSelector((state) => ({...state.roles}))

useEffect(() => {
    dispatch(getL1MenuPermissionRole(selectedData?.id))
    dispatch(getL1ServicePermissionRole(selectedData?.id))
    dispatch(getL2MenuPermissionRole(selectedData?.id))
    dispatch(getL2ServicePermissionRole(selectedData?.id))
    dispatch(getL3MenuPermissionRole(selectedData?.id))
    dispatch(getL3ServicePermissionRole(selectedData?.id))
}, [])

function getL1MenuName(params) {
  return params?.row?.level1Menu?.l1MenuName
}

const l1MenuData = {
    rows: l1PermissionData?.l1MenuPermissionData,
    columns: [
      { field: "l1MenuName", headerName: "Menu Name", width: 550, valueGetter: getL1MenuName },
      
    ],
  };

  const handleSetL1MenuConfirmDialog = (obj) => {
    setL1MenuConfirmDialog({
      ...l1MenuConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleL1MenuDelete = () => {
    let data = {
      id: l1PermissionData?.l1MenuSelectedData?.id,
    };
    dispatch(deleteL1MenuPermission(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l1ResetMessage());
          setL1MenuConfirmDialog({
            ...l1MenuConfirmDialog,
            isOpen: false,
          });
          dispatch(getL1MenuPermissionRole(selectedData?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l1ResetErrMessage());
        }, 3000);
      }
    });
  };

  function getL1ServiceName(params){
    return  <GridCellExpand
    value={params?.row?.applicationService?.name || ""}
    width={params.colDef.computedWidth}
  />
  }

const l1ServiceData = {
    rows: l1PermissionData?.l1ServicePermissionData,
    columns: [
      { field: "service", headerName: "Service Name", width: 550, renderCell: getL1ServiceName },
      
    ],
  };

  const handleSetL1ServiceConfirmDialog = (obj) => {
    setL1ServiceConfirmDialog({
      ...l1ServiceConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleL1ServiceDelete = () => {
    let data = {
      id: l1PermissionData?.l1ServiceSelectedData?.id,
    };
    dispatch(deleteL1ServicePermission(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l1ResetMessage());
          setL1ServiceConfirmDialog({
            ...l1ServiceConfirmDialog,
            isOpen: false,
          });
          dispatch(getL1ServicePermissionRole(selectedData?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l1ResetErrMessage());
        }, 3000);
      }
    });
  };

function getL2MenuName(params) {
  return params?.row?.level2Menu?.l2MenuName
}

const l2MenuData = {
    rows: l2PermissionData?.l2MenuPermissionData,
    columns: [
      { field: "l2MenuName", headerName: "Menu Name", width: 550, valueGetter: getL2MenuName },
      
    ],
  };

  const handleSetL2MenuConfirmDialog = (obj) => {
    setL2MenuConfirmDialog({
      ...l2MenuConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleL2MenuDelete = () => {
    let data = {
      id: l2PermissionData?.l2MenuSelectedData?.id,
    };
    dispatch(deleteL2MenuPermission(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l2ResetMessage());
          setL2MenuConfirmDialog({
            ...l2MenuConfirmDialog,
            isOpen: false,
          });
          dispatch(getL2MenuPermissionRole(selectedData?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l2ResetErrMessage());
        }, 3000);
      }
    });
  };

  function getL2ServiceName(params){
    return  <GridCellExpand
    value={params?.row?.applicationService?.name || ""}
    width={params.colDef.computedWidth}
  />
  }

const l2ServiceData = {
    rows: l2PermissionData?.l2ServicePermissionData,
    columns: [
      { field: "service", headerName: "Service Name", width: 550, renderCell: getL2ServiceName },
      
    ],
  };

  const handleSetL2ServiceConfirmDialog = (obj) => {
    setL2ServiceConfirmDialog({
      ...l2ServiceConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleL2ServiceDelete = () => {
    let data = {
      id: l2PermissionData?.l2ServiceSelectedData?.id,
    };
    dispatch(deleteL2ServicePermission(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l2ResetMessage());
          setL2ServiceConfirmDialog({
            ...l2ServiceConfirmDialog,
            isOpen: false,
          });
          dispatch(getL2ServicePermissionRole(selectedData?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l2ResetErrMessage());
        }, 3000);
      }
    });
  };


function getL3MenuName(params) {
  return params?.row?.level3Menu?.l3MenuName
}

const l3MenuData = {
    rows: l3PermissionData?.l3MenuPermissionData,
    columns: [
      { field: "l3MenuName", headerName: "Menu Name", width: 550, valueGetter: getL3MenuName },
      
    ],
  };

  const handleSetL3MenuConfirmDialog = (obj) => {
    setL3MenuConfirmDialog({
      ...l3MenuConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleL3MenuDelete = () => {
    let data = {
      id: l3PermissionData?.l3MenuSelectedData?.id,
    };
    dispatch(deleteL3MenuPermission(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l3ResetMessage());
          setL3MenuConfirmDialog({
            ...l3MenuConfirmDialog,
            isOpen: false,
          });
          dispatch(getL3MenuPermissionRole(selectedData?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l3ResetErrMessage());
        }, 3000);
      }
    });
  };

  function getL3ServiceName(params){
    return  <GridCellExpand
    value={params?.row?.applicationService?.name || ""}
    width={params.colDef.computedWidth}
  />
  }

const l3ServiceData = {
    rows: l3PermissionData?.l3ServicePermissionData,
    columns: [
      { field: "service", headerName: "Service Name", width: 550, renderCell: getL3ServiceName },
      
    ],
  };

  const handleSetL3ServiceConfirmDialog = (obj) => {
    setL3ServiceConfirmDialog({
      ...l3ServiceConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleL3ServiceDelete = () => {
    let data = {
      id: l3PermissionData?.l3ServiceSelectedData?.id,
    };
    dispatch(deleteL3ServicePermission(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l3ResetMessage());
          setL3ServiceConfirmDialog({
            ...l3ServiceConfirmDialog,
            isOpen: false,
          });
          dispatch(getL3ServicePermissionRole(selectedData?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l3ResetErrMessage());
        }, 3000);
      }
    });
  };

  return (
    <Layout>
<Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <PageHeader variant="pageHeader">Access Permissions</PageHeader>
            </Box>
            <Link to="/exco/role-management/access-permissions" state={{l3Menu : location?.state?.l3Menu, permissions: location?.state?.permissions}}>
              <CancelIcon color="red" />
            </Link>
          </Stack>
          <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} >
          <Paper elevation={4} className={classes.paper}>
          <PageHeader variant="pageSecHeader">L1 Menus</PageHeader>
          <Grid container >
          <Grid item xs={12} sm={12} md={6}>
            <PageHeader variant="pageSecHeader">Menu Permissions</PageHeader>
            <MuiDataGrid
            setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l1MenuPermissionCreate") ? () => setShowL1MenuAdd(true) : null}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l1MenuPermissionDelete") ? () =>
            setL1MenuConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => handleL1MenuDelete(),
            }) : null
          }
          toolbarFilter = {true}
          data={l1MenuData}
          select={(data) => {
            dispatch(l1MenuRowSelect(data))
          }}
        />
        <AddL1MenuPermission showAdd={showL1MenuAdd} setShowAdd={setShowL1MenuAdd} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PageHeader variant="pageSecHeader">Service Permissions</PageHeader>
            <MuiDataGrid
            setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l1MenuServicePermissionCreate") ? () => setShowL1ServiceAdd(true) : null}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l1MenuServicePermissionDelete") ? () =>
            setL1ServiceConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => handleL1ServiceDelete(),
            }) : null
          }
          toolbarFilter = {true}
          data={l1ServiceData}
          select={(data) => {
            dispatch(l1ServiceRowSelect(data))
          }}
        />
        <AddL1ServicePermission showAdd={showL1ServiceAdd} setShowAdd={setShowL1ServiceAdd} />

          </Grid>
          </Grid>
          </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} >
          <Paper elevation={4} className={classes.paper}>
          <PageHeader variant="pageSecHeader">L2 Menus</PageHeader>
          <Grid container >
          <Grid item xs={12} sm={12} md={6}>
            <PageHeader variant="pageSecHeader">Menu Permissions</PageHeader>
            <MuiDataGrid
            setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l2MenuPermissionCreate") ? () => setShowL2MenuAdd(true) : null}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l2MenuPermissionDelete") ? () =>
            setL2MenuConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => handleL2MenuDelete(),
            }) : null
          }
          toolbarFilter = {true}
          data={l2MenuData}
          select={(data) => {
            dispatch(l2MenuRowSelect(data))
          }}
        />
        <AddL2MenuPermission showAdd={showL2MenuAdd} setShowAdd={setShowL2MenuAdd} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PageHeader variant="pageSecHeader">Service Permissions</PageHeader>
            <MuiDataGrid
            setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l2MenuServicePermissionCreate") ? () => setShowL2ServiceAdd(true) : null}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l2MenuServicePermissionDelete") ? () =>
            setL2ServiceConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => handleL2ServiceDelete(),
            }) : null
          }
          toolbarFilter = {true}
          data={l2ServiceData}
          select={(data) => {
            dispatch(l2ServiceRowSelect(data))
          }}
        />
        <AddL2ServicePermission showAdd={showL2ServiceAdd} setShowAdd={setShowL2ServiceAdd} />

          </Grid>
          </Grid>
          </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} >
          <Paper elevation={4} className={classes.paper}>
          <PageHeader variant="pageSecHeader">L3 Menus</PageHeader>
          <Grid container >
          <Grid item xs={12} sm={12} md={6}>
            <PageHeader variant="pageSecHeader">Menu Permissions</PageHeader>
            <MuiDataGrid
            setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l3MenuPermissionCreate") ? () => setShowL3MenuAdd(true) : null}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l3MenuPermissionDelete") ? () =>
            setL3MenuConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => handleL3MenuDelete(),
            }) : null
          }
          toolbarFilter = {true}
          data={l3MenuData}
          select={(data) => {
            dispatch(l3MenuRowSelect(data))
          }}
        />
        <AddL3MenuPermission showAdd={showL3MenuAdd} setShowAdd={setShowL3MenuAdd} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PageHeader variant="pageSecHeader">Service Permissions</PageHeader>
            <MuiDataGrid
            setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l3MenuServicePermissionCreate") ? () => setShowL3ServiceAdd(true) : null}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l3MenuServicePermissionDelete") ? () =>
            setL3ServiceConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => handleL3ServiceDelete(),
            }) : null
          }
          toolbarFilter = {true}
          data={l3ServiceData}
          select={(data) => {
            dispatch(l3ServiceRowSelect(data))
          }}
        />
        <AddL3ServicePermission showAdd={showL3ServiceAdd} setShowAdd={setShowL3ServiceAdd} />

          </Grid>
          </Grid>
          </Paper>
          </Grid>
          </Grid>
          <ConfirmDialog
        confirmDialog={l1MenuConfirmDialog}
        setConfirmDialog={(obj) => handleSetL1MenuConfirmDialog(obj)}
      />
          <ConfirmDialog
        confirmDialog={l1ServiceConfirmDialog}
        setConfirmDialog={(obj) => handleSetL1ServiceConfirmDialog(obj)}
      />
          <ConfirmDialog
        confirmDialog={l2MenuConfirmDialog}
        setConfirmDialog={(obj) => handleSetL2MenuConfirmDialog(obj)}
      />
          <ConfirmDialog
        confirmDialog={l2ServiceConfirmDialog}
        setConfirmDialog={(obj) => handleSetL2ServiceConfirmDialog(obj)}
      />
          <ConfirmDialog
        confirmDialog={l3MenuConfirmDialog}
        setConfirmDialog={(obj) => handleSetL3MenuConfirmDialog(obj)}
      />
          <ConfirmDialog
        confirmDialog={l3ServiceConfirmDialog}
        setConfirmDialog={(obj) => handleSetL3ServiceConfirmDialog(obj)}
      />
      {l1PermissionData?.message && <Notifier message={l1PermissionData?.message} />}
  {l1PermissionData?.errMessage && <ErrorNotifier message={l1PermissionData?.errMessage} />}
      {l2PermissionData?.message && <Notifier message={l2PermissionData?.message} />}
  {l2PermissionData?.errMessage && <ErrorNotifier message={l2PermissionData?.errMessage} />}
      {l3PermissionData?.message && <Notifier message={l3PermissionData?.message} />}
  {l3PermissionData?.errMessage && <ErrorNotifier message={l3PermissionData?.errMessage} />}
    </Layout>
  )
}

export default MenuAccessPermissions