import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Stack, Box,Grid } from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import AddEngagementStage from "./AddEngagementStage";
import UpdateEngagementStage from "./UpdateEngagementStage";
import { MuiDataGrid, MuiAutocomplete, GridCellExpand } from "../../../components";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import Layout from "../../../layout/Layout";
// redux
import {
  getEngagementStageByTier,
  deleteEngagementStage,
  rowSelect,
  resetMessage,
  resetErrMessage,
  resetEngStageData
} from "../../../features/config/engagementStageSlice";
import { getEngTierName } from "../../../features/config/engTierNameSlice";

// ----------------------------------------------------------------------------------

const EngagementStage = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [engTier, setEngTier] = useState("")

const location = useLocation()
  const dispatch = useDispatch();

  const { engStageData, selectedData } =
    useSelector((state) => ({ ...state.engStage }));
const { engTierNameData } = useSelector((state) => ({
      ...state.engTierName,
    }));

  function getEngTier(params) {
    return params?.row?.engagementTier?.engagementTierName;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: engStageData,
    columns: [
      {
        field: "engagementTierId",
        headerName: "Engagement Tier",
        width: 250,
        valueGetter: getEngTier,
      },
      {
        field: "engagementStageSequenceNo",
        headerName: "Eng Stage Sequence NO",
        width: 200,
      },
      {
        field: "engagementStageName",
        headerName: "Eng Stage Name",
        width: 200,
      },
      { field: "comments", headerName: "Comments", width: 400, renderCell: renderCellExpand },
    ],
  };

  const showAddPage = () => {
    setShowAdd(!showAdd);
  };

  const showEditPage = () => {
    setShowEdit(true);
  };

  const showDelete = () =>
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure to delete this record?",
                subTitle: "You can't undo this operation",
                onConfirm: () => handleDelete(),
              })

  useEffect(() => {
    dispatch(getEngTierName())
  }, []);

  useEffect(() => {
engTier && dispatch(getEngagementStageByTier(engTier?.id))
!engTier && dispatch(resetEngStageData())
  }, [engTier])

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDelete = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteEngagementStage(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getEngagementStageByTier(engTier?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  return (
    <Layout>
      {!showAdd && !showEdit && (
        <>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
            <Stack
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid container style={{display:'flex',alignItems:'center'}}>
            <Grid item xs={12} sm={12} md={6}>
              <PageHeader variant="pageHeader">
                Client Engagement Stages for
              </PageHeader>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
              <Box
              sx={{ m: "0 !important", mb: "0.5rem !important", ml: "0.5rem !important", width: "16rem" }}
            >
              <MuiAutocomplete
                name="engTier"
                options={engTierNameData}
                setValue={(name, newValue) => setEngTier(newValue)}
                value={engTier}
                optionValue="engagementTierName"
                label="Engagement Tier Name"
              />
            </Box>
            </Grid>
            </Grid>
              </Stack>
            </Box>
            <Link to="/exco/config" state={{l3Menu: location?.state?.l3Menu}}>
              <CancelIcon color="red" onClick={() => dispatch(resetEngStageData())}/>
            </Link>
          </Stack>
          <MuiDataGrid
           setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddPage}
           setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditPage}
           setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
           }
            toolbarColumn = {true}
            toolbarFilter = {true}
            toolbarDensity = {true}
            toolbarExport = {true}
            data={data}
            select={(data) => dispatch(rowSelect(data))}
          />
        </>
      )}
      {showAdd && (
        <AddEngagementStage showAdd={showAdd} close={() => setShowAdd(false)} engTier={engTier} />
      )}
      {showEdit && (
        <UpdateEngagementStage
          showEdit={showEdit}
          close={() => setShowEdit(false)}
          engTier={engTier}
        />
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
    </Layout>
  );
};

export default EngagementStage;
