import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_CONTACT_DESIGNATION } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createContactDesignation = createAsyncThunk(
  "contactDesignation/createContactDesignation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_CONTACT_DESIGNATION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContactDesignation = createAsyncThunk(
  "contactDesignation/getContactDesignation",
  async () => {
    return axios.get(CURD_CONTACT_DESIGNATION, { withCredentials: true });
  }
);

export const updateContactDesignation = createAsyncThunk(
  "contactDesignation/updateContactDesignation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_CONTACT_DESIGNATION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteContactDesignation = createAsyncThunk(
  "contactDesignation/deleteContactDesignation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_CONTACT_DESIGNATION, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const contactDesignationSlice = createSlice({
  name: "contactDesignation",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    contactDesignationData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createContactDesignation.pending]: (state, action) => {
      state.loading = true;
    },
    [createContactDesignation.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createContactDesignation.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getContactDesignation.pending]: (state, action) => {
      state.loading = true;
    },
    [getContactDesignation.fulfilled]: (state, action) => {
      state.loading = false;
      state.contactDesignationData = action?.payload?.data;
    },
    [getContactDesignation.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateContactDesignation.pending]: (state, action) => {
      state.loading = true;
    },
    [updateContactDesignation.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateContactDesignation.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteContactDesignation.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteContactDesignation.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteContactDesignation.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  contactDesignationSlice.actions;
export default contactDesignationSlice.reducer;
