import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import {
  createDevelopmentAreas,
  createCoachingFocusArea,
  fetchCoachingFocusById,
  getCoachingFocus,
  getDevelopmentAreas,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/developmentAreasSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  coachingFocusName: Yup.string().required(
    "Coaching Focus Name is required"
  ),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const AddDevelopmentAreas = (props) => {
  const {  message, errMessage } = useSelector((state) => ({
    ...state.devAreas,
  }));

  

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      coachingFocusName: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createCoachingFocusArea({
          developmentAreaCoachingFocusName : values.coachingFocusName,
          developmentAreasId:props.storeId,
          comments:values.comments
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(fetchCoachingFocusById(props.storeId));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">Add Coaching Focus</PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
              <MyTextInput
                label="Coaching Focus"
                type="text"
                name="coachingFocusName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="coachingFocusName"
                value={formik.values.coachingFocusName}
                onChange={formik.handleChange}
                error={
                  formik.touched.coachingFocusName &&
                  Boolean(formik.errors.coachingFocusName)
                }
                helperText={
                  formik.touched.coachingFocusName &&
                  formik.errors.coachingFocusName
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddDevelopmentAreas;
