import React, {useState} from "react";
// material ui
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// components
import {FormTextField as MyTextInput,} from '../../../components'

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.grey.main,
    "&:hover": {
      backgroundColor: theme.palette.grey.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

//   -------------------------------------------------------------------------------

const MicrositeConfirmDialog = (props) => {
    const [password, setPassword] = useState("")

  const { confirmDialog} = props;

  const classes = useStyles();
  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
        <MyTextInput
                    label="Login Password"
                    type="password"
                    name="password"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    autoFocus
                  />
      </DialogContent>
      <DialogActions className={classes.dialogAction} sx={{justifyContent: 'center'}}>
        <Button
          inputProps={{ tabIndex: "1" }}
          variant="contained"
          color="primary"
          onClick={() => props.setConfirmDialog({ isOpen: false })}
        >
          No
        </Button>
        
        <Button
          variant="contained"
          color="red"
          onClick={password ? () => confirmDialog.onConfirm(password) : null}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MicrositeConfirmDialog;
