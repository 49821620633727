import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Box, Stack, Grid, ListItemIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import Layout from "../../../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  MuiAutocomplete,
  FormTextField as MyTextInput,
  MuiTooltip,
  Notifier,
  ErrorNotifier,
  ConfirmDialog,
  ModalCloseBtn,
} from "../../../../components";
// redux
import { getAccount } from "../../../../features/customer/accountSlice";
import {
  createEngCheckinFrequency,
  getEngCheckinFrequency,
  updateEngCheckinFrequency,
  deleteEngCheckinFrequency,
  rowSelect,

} from "../../../../features/customer/engCheckinFrequencySlice";

import {
  getToggleAccount, updateToggleAccount, resetMessage,
  resetErrMessage,
} from "../../../../features/customer/toggleSlice"

import SettingsIcon from '@mui/icons-material/Settings';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

// ----------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  accountName: Yup.object().required("Account  Name is required"),
  engCheckinFrequency: Yup.number().integer("Value should be Integer").required("Engagement Checkin Frequency"),
  comments: Yup.string().nullable(),
});

// ------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ---------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({

  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width: '20rem !important',
      height: '60% !important',
      display: 'block !important',
      overflow: 'scroll !important',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft: '-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom: '1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText: {
    width: '50rem',
    [theme.breakpoints.down('md')]: {
      width: '100%'

    }

  },
  filter_grid: {
    [theme.breakpoints.down('md')]: {
      display: 'flex !important',
      flexDirection: 'column !important'

    },
  },
  close_btn: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '-2.563rem'

    },
  }

}))

let featureData = ["progressRatingScale", "dateOfLastHrTouchpoint", "dateOfLastManagerTouchpoint", "dateOfLastMeeting", "anticipatedStartDate", "expectedOutcome", "focusAreasTab", "impactUpdatesTab", "progressSummaryTab", "subCategory"]


const EngCheckinFrequency = (props) => {
  const [account, setAccount] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isToggle, setIsToggle] = useState({
    id: null,
    value: false
  })
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [toggleId, setToggleId] = useState(null)
  const [toggleFeaturedata, setToggleFeatureData] = useState([])

  const location = useLocation()
  const dispatch = useDispatch();
  const {

    engCheckinFrequencyData,
    selectedData,
  } = useSelector((state) => ({
    ...state.engCheckinFrequency,
  }));

  const { accountData } = useSelector((state) => ({ ...state.account }));

  const { toggleAccountData, message,
    errMessage, } = useSelector((state) => ({ ...state.toggleAccount }));

  function getAccountName(params) {
    return params?.row?.excoCustomerAccount?.accountName;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }

  // const data = {
  //   rows: engCheckinFrequencyData,
  //   columns: [
  //     {
  //       field: "accountName",
  //       headerName: "Account Name",
  //       width: 200,
  //       valueGetter: getAccountName,
  //     },

  //     {
  //       field: "engagementCheckinFrequency",
  //       headerName: "Eng Checkin Frequency(Months)",
  //       width: 250,
  //     },
  //     {
  //       field: "comment",
  //       headerName: "Comments",
  //       width: 700,
  //       renderCell: renderCellExpand
  //     },
  //   ],
  // };

  const getCustamizedNames = (params) => {
    if (params?.row?.feature === "progressRatingScale") {
      return "Progress Scale Rating"
    }
    if (params?.row?.feature === "dateOfLastHrTouchpoint") {
      return "Date of Last HR Touchpoint"
    }
    if (params?.row?.feature === "dateOfLastManagerTouchpoint") {
      return "Date of Last Manager Touchpoint"
    }
    if (params?.row?.feature === "dateOfLastMeeting") {
      return "Date of Last Meeting"
    }
    if (params?.row?.feature === "anticipatedStartDate") {
      return "Anticipated Start Date"
    }
    if (params?.row?.feature === "expectedOutcome") {
      return "Expected Outcome"
    }
    if (params?.row?.feature === "focusAreasTab") {
      return "Focus Area Tab"
    }
    if (params?.row?.feature === "impactUpdatesTab") {
      return "Impact Updates Tab"
    }
    if (params?.row?.feature === "progressSummaryTab") {
      return "Progress Summary Tab"
    }
    if (params?.row?.feature === "subCategory") {
      return "Sub-Category"
    }


  }

  const data = {
    rows: toggleFeaturedata,

    columns: [

      {
        field: "feature",
        headerName: "Features",
        width: 400,
        valueGetter: getCustamizedNames
      },


      {
        field: "setting",
        headerName: "Toggle",
        width: 100,
        renderCell: (params) => {
          console.log(featureData.includes(params.row?.feature) && params?.row[params?.row?.feature])
          return (

            // <ListItemIcon sx={{ cursor: "pointer" }} onClick={() => setIsToggle((prevVal) => ({
            //   ...prevVal,
            //   id: params?.row?.id,
            //   value: !prevVal?.value
            // }))} >
            //   {isToggle.id === params?.row.id && isToggle?.value ? <ToggleOnIcon fontSize="large" color="green" /> : <ToggleOffIcon fontSize="large" color="grey" />}
            // </ListItemIcon>

            <MuiTooltip title={featureData.includes(params.row?.feature) && params?.row[params?.row?.feature] ? "Disable" : "Enable"} placement="bottom">
              <ListItemIcon sx={{ cursor: "pointer" }}
                onClick={() => handleToggleClick({
                  row: params.row,
                  value: params?.row[params?.row?.feature] === true ? false : true

                })}
              // onClick={() => setIsToggle((prevVal) => ({
              //   ...prevVal,
              //   id: params?.row?.id,
              //   value: !prevVal?.value
              // }))} 


              >
                {featureData.includes(params.row?.feature) && params?.row[params?.row?.feature] ? <ToggleOnIcon fontSize="large" color="green" /> : <ToggleOffIcon fontSize="large" color="grey" />}
              </ListItemIcon>

            </MuiTooltip>


          );
        },
      }

    ],
  };

  const handleToggleClick = (obj) => {

    let body = {
      id: toggleId,
      progressRatingScale: obj.row?.feature === "progressRatingScale" ? obj.value : obj.row?.progressRatingScale,
      dateOfLastHrTouchpoint: obj.row?.feature === "dateOfLastHrTouchpoint" ? obj.value : obj.row?.dateOfLastHrTouchpoint,
      dateOfLastManagerTouchpoint: obj.row?.feature === "dateOfLastManagerTouchpoint" ? obj.value : obj.row?.dateOfLastManagerTouchpoint,
      dateOfLastMeeting: obj.row?.feature === "dateOfLastMeeting" ? obj.value : obj.row?.dateOfLastMeeting,
      anticipatedStartDate: obj.row?.feature === "anticipatedStartDate" ? obj.value : obj.row?.anticipatedStartDate,
      expectedOutcome: obj.row?.feature === "expectedOutcome" ? obj.value : obj.row?.expectedOutcome,
      focusAreasTab: obj.row?.feature === "focusAreasTab" ? obj.value : obj.row?.focusAreasTab,
      impactUpdatesTab: obj.row?.feature === "impactUpdatesTab" ? obj.value : obj.row?.impactUpdatesTab,
      progressSummaryTab: obj.row?.feature === "progressSummaryTab" ? obj.value : obj.row?.progressSummaryTab,
      subCategory: obj.row?.feature === "subCategory" ? obj.value : obj.row?.subCategory,
      excoCustomerAccountId: account?.id,
    }
    dispatch(updateToggleAccount(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getToggleAccount(account?.id))
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  }



  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        accountName: "",
        engCheckinFrequency: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteEngCheckinFrequency(),
    })

  const handleDeleteEngCheckinFrequency = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteEngCheckinFrequency(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getEngCheckinFrequency());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const formik = useFormik({
    initialValues: {
      accountName: "",
      engCheckinFrequency: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createEngCheckinFrequency({
          excoCustomerAccountId: values.accountName?.id,
          engagementCheckinFrequency: Number(values?.engCheckinFrequency),
          comment: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getEngCheckinFrequency());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountName: selectedData?.excoCustomerAccount,
      engCheckinFrequency: selectedData?.engagementCheckinFrequency,
      comments: selectedData?.comment,
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateEngCheckinFrequency({
          id: selectedData?.id,
          excoCustomerAccountId: values?.accountName?.id,
          engagementCheckinFrequency: values?.engCheckinFrequency,
          comment: values?.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getEngCheckinFrequency());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getAccount());
    dispatch(getEngCheckinFrequency());
    setToggleFeatureData([])
  }, []);

  useEffect(() => {
    dispatch(getToggleAccount(account?.id))

  }, [account])

  useEffect(() => {

  if (toggleAccountData.length > 0) {
      let toggleObj = toggleAccountData[0]
      setToggleId(toggleObj?.id)
      let count = 1;
      let data = []
      for (let key of featureData) {
        let obj = {
          ...toggleObj,
          id: count++,
          feature: key
        }
        data.push(obj)

      }
      setToggleFeatureData(data)
    }
    else {
      setToggleFeatureData([])
    }

  }, [toggleAccountData])

  console.log(toggleFeaturedata)
  console.log(toggleId)


  const classes = useStyles();
  return (
    <Layout>
      <>
        <Stack
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid container style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={6}>
              <PageHeader variant="pageHeader">Account Feature Toggles</PageHeader>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Box
                sx={{ m: "0 !important", mb: "0.5rem !important", width: '17rem' }}
              >
                <MuiAutocomplete
                  name="account"
                  options={accountData}
                  setValue={(name, newValue) => setAccount(newValue)}
                  value={account}
                  optionValue="accountName"
                  label="Customer Account Name"
                />
              </Box>
            </Grid>
            <Grid textAlign="end"  item xs={12} sm={12} md={1}>
            <Link
            to="/exco/customers/account-container"
            state={{
              l3Menu: location?.state?.l3Menu,
            }}
          >
            <CancelIcon color="red" />
          </Link>

            </Grid>
            
          </Grid>
        </Stack>
        
        {/* <Stack flexDirection="row" justifyContent="space-between">
          <Box sx={{ width: "100%" }}>
            <PageHeader variant="pageHeader">
              Engagement Checkin Frequency
            </PageHeader>
          </Box>
          <Link to="/exco/customers/account-container" state={{
            l3Menu: location?.state?.l3Menu
          }}>
            <CancelIcon color="red" />
          </Link>
        </Stack> */}
        <MuiDataGrid
          setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddModel}
          setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditModal}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
          }
          toolbarColumn={true}
          toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>

      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />

          <div className={classes.responsiveText}>
            <MuiAutocomplete
              name="accountName"
              options={accountData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.accountName}
              optionValue="accountName"
              label="Account Name"
              required={true}
              error={
                formik.touched.accountName && Boolean(formik.errors.accountName)
              }
              helperText={
                formik.touched.accountName && formik.errors.accountName
              }
            />

            <MyTextInput
              label="Engagement Checkin Frequency (months)"
              type="number"
              name="engCheckinFrequency"
              required={true}
              fullWidth={true}
              margin="normal"
              id="engCheckinFrequency"
              value={formik.values.engCheckinFrequency}
              onChange={formik.handleChange}
              tabIndex={5}
              error={
                formik.touched.engCheckinFrequency &&
                Boolean(formik.errors.engCheckinFrequency)
              }
              helperText={
                formik.touched.engCheckinFrequency &&
                formik.errors.engCheckinFrequency
              }
            />
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              tabIndex={8}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </div>
          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={closeEditModal}
        closeModel={closeEditModal}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={closeEditModal} />

          <div className={classes.responsiveText}>
            <MuiAutocomplete
              name="accountName"
              options={accountData}
              setValue={(name, newValue) =>
                formikEdit.setFieldValue(name, newValue)
              }
              value={formikEdit.values.accountName}
              optionValue="accountName"
              label="Account Name"
              required={true}
              error={
                formikEdit.touched.accountName &&
                Boolean(formikEdit.errors.accountName)
              }
              helperText={
                formikEdit.touched.accountName && formikEdit.errors.accountName
              }
            />

            <MyTextInput
              label="Engagement Checkin Frequency (months)"
              type="number"
              name="engCheckinFrequency"
              required={true}
              fullWidth={true}
              margin="normal"
              id="engCheckinFrequency"
              value={formikEdit.values.engCheckinFrequency}
              onChange={formikEdit.handleChange}
              tabIndex={5}
              error={
                formikEdit.touched.engCheckinFrequency &&
                Boolean(formikEdit.errors.engCheckinFrequency)
              }
              helperText={
                formikEdit.touched.engCheckinFrequency &&
                formikEdit.errors.engCheckinFrequency
              }
            />
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              tabIndex={8}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default EngCheckinFrequency;
