import React from 'react'
import Alert from '@mui/material/Alert';
function ErrorNotifier(props) {
  return (
    <div className="error-notification-box">
      <span className="error-notification">
      <Alert variant="filled" severity="error">
      {props.message}
     </Alert>
      
      </span>
    </div>
  )
}
export default ErrorNotifier;