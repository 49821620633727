import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_IMPACT_SCALE_RATING } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createImpactScaleRating = createAsyncThunk(
  "impactScaleRating/createImpactScaleRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_IMPACT_SCALE_RATING, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getImpactScaleRating = createAsyncThunk(
  "impactScaleRating/getImpactScaleRating",
  async () => {
    return axios.get(CURD_IMPACT_SCALE_RATING, { withCredentials: true });
  }
);

export const updateImpactScaleRating = createAsyncThunk(
  "impactScaleRating/updateImpactScaleRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_IMPACT_SCALE_RATING, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteImpactScaleRating = createAsyncThunk(
  "impactScaleRating/deleteImpactScaleRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_IMPACT_SCALE_RATING, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const impactScaleRatingSlice = createSlice({
  name: "impactScaleRating",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    impactScaleRatingData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createImpactScaleRating.pending]: (state, action) => {
      state.loading = true;
    },
    [createImpactScaleRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createImpactScaleRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getImpactScaleRating.pending]: (state, action) => {
      state.loading = true;
    },
    [getImpactScaleRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.impactScaleRatingData = action?.payload?.data;
    },
    [getImpactScaleRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateImpactScaleRating.pending]: (state, action) => {
      state.loading = true;
    },
    [updateImpactScaleRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateImpactScaleRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteImpactScaleRating.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteImpactScaleRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteImpactScaleRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  impactScaleRatingSlice.actions;
export default impactScaleRatingSlice.reducer;
