import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import {
  createExecutiveSponsar,
  getExecutiveSponsar,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/executiveSponsarSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  executiveSponsor: Yup.string().required("Executive Sponsor is required"),

});

//   ------------------------------------------------------------------------------------------

const AddLevelOfEngagement = (props) => {
  const { message, errMessage } = useSelector((state) => ({
    ...state.executiveSponsors,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      executiveSponsor: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createExecutiveSponsar({
          sponsorName: values.executiveSponsor,
          comments: values.description,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getExecutiveSponsar());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">Add Executive Sponsor</PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
              <MyTextInput
                label="Executive Sponsor"
                type="string"
                name="executiveSponsor"
                required={true}
                fullWidth={true}
                margin="normal"
                id="executiveSponsor"
                value={formik.values.executiveSponsor}
                onChange={formik.handleChange}
                error={
                  formik.touched.executiveSponsor &&
                  Boolean(formik.errors.executiveSponsor)
                }
                helperText={
                  formik.touched.executiveSponsor &&
                  formik.errors.executiveSponsor
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="description"
                fullWidth={true}
                margin="normal"
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                // required={true}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddLevelOfEngagement;
