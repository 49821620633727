import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
// material ui
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Grid,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
// components
import { MuiDataGrid } from "../../../components";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import {
  UpdateOpportunity,
  AddOppGoals,
  UpdateOppGoals,
  AddOppScope,
  UpdateOppScope,
} from "../opportunity";
// redux
import {
  getOppGoals,
  deleteOppGoals,
  rowSelect as goalRowSelect,
  resetMessage as goalResetMessage,
  resetErrMessage as goalResetErrMessage,
} from "../../../features/exec/opportunity/oppGoalsSlice";
import {
  getOppScope,
  deleteOppScope,
  rowSelect as scopeRowSelect,
  resetMessage as scopeResetMessage,
  resetErrMessage as scopeResetErrMessage,
} from "../../../features/exec/opportunity/oppScopeSlice";

// --------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  muiList: {
    padding: "0 !important",
  },
  muiListHeadText: {
    width: "3.4rem",
  },
  muiListDataText: {
    width: "3rem",
  },
}));

// -----------------------------------------------------------------------------

const OpportunityDetails = (props) => {
  const [edit, setEdit] = useState(false);
  const [showGoalAdd, setShowGoalAdd] = useState(false);
  const [showGoalEdit, setShowGoalEdit] = useState(false);
  const [goalConfirmDialog, setGoalConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showScopeAdd, setShowScopeAdd] = useState(false);
  const [showScopeEdit, setShowScopeEdit] = useState(false);
  const [scopeConfirmDialog, setScopeConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const classes = useStyles();

  const dispatch = useDispatch();
  const goalsResp = useSelector((state) => ({ ...state.oppGoals }));
  const scopeResp = useSelector((state) => ({ ...state.oppScope }));

  const milestoneData = {
    rows: goalsResp.oppGoalsData,
    columns: [
      { field: "opportunityGoal", headerName: "Goal", width: 150 },
      { field: "opportunityDate", headerName: "Date", width: 150 },
      { field: "opportunityStatus", headerName: "Status", width: 150 },
      { field: "comments", headerName: "Comments", width: 150 },
    ],
  };
  const scopeData = {
    rows: scopeResp.oppScopeData,
    columns: [
      { field: "opportunityScope", headerName: "Scope Name", width: 150 },
      {
        field: "opportunityDeliverables",
        headerName: "Deliverable",
        width: 150,
      },
      { field: "opportunityDate", headerName: "Date", width: 150 },
      { field: "comments", headerName: "Comments", width: 150 },
    ],
  };

  const handleDeleteGoals = () => {
    let data = {
      id: goalsResp.selectedData?.id,
    };
    dispatch(deleteOppGoals(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(goalResetMessage());
          setGoalConfirmDialog({
            ...goalConfirmDialog,
            isOpen: false,
          });
          dispatch(getOppGoals());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(goalResetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetGoalConfirmDialog = (obj) => {
    setGoalConfirmDialog({
      ...goalConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDeleteScope = () => {
    let data = {
      id: scopeResp.selectedData?.id,
    };
    dispatch(deleteOppScope(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(scopeResetMessage());
          setScopeConfirmDialog({
            ...scopeConfirmDialog,
            isOpen: false,
          });
          dispatch(getOppScope());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(scopeResetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetScopeConfirmDialog = (obj) => {
    setScopeConfirmDialog({
      ...scopeConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  useEffect(() => {
    dispatch(getOppGoals());
    dispatch(getOppScope());
  }, []);

  return (
    <>
      {!edit && (
        <Card sx={{ marginTop: "0.5rem" }}>
          <CardHeader
            title={
              <PageHeader variant="pageHeader">Opportunity Details</PageHeader>
            }
            action={
              <IconButton aria-label="cancel" onClick={() => props.close()}>
                <CancelIcon color="red" />
              </IconButton>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Stack flexDirection="row" justifyContent="space-between">
                  <PageHeader variant="pageSecHeader">Prospect</PageHeader>
                </Stack>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Cust Client Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.excoCustomerClientDetail?.firstName}&nbsp;
                    {props?.values?.excoCustomerClientDetail?.lastName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Opportunity Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.opportunityName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Opportunity Tier
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.opportunityTier}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Opportunity Summary
                  </ListItemText>
                  &nbsp;:&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.opportunitySummary}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Contact Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.contactName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Designation
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.designation}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Contact Email
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.contactEmail}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Contact Number
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.dialingCode}-{props?.values?.contactNumber}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Opportunity Stage
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.opportunityStage}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Opportunity Status
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.opportunityStatus}
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Comments
                  </ListItemText>
                  &nbsp;:&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.comments}
                  </ListItemText>
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <PageHeader variant="pageSecHeader">Milestone/Goals</PageHeader>
                <MuiDataGrid
                  setShowAdd={() => setShowGoalAdd(true)}
                  setShowEdit={() => setShowGoalEdit(true)}
                  setShowDelete={() =>
                    setGoalConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => handleDeleteGoals(),
                    })
                  }
                  toolbarColumn = {true}
                  toolbarFilter = {true}
                  toolbarDensity = {true}
                  toolbarExport = {true}
                  data={milestoneData}
                  select={(data) => dispatch(goalRowSelect(data))}
                  height={300}
                />
                <PageHeader variant="pageSecHeader">
                  Scope/Deliverable
                </PageHeader>
                <MuiDataGrid
                  setShowAdd={() => setShowScopeAdd(true)}
                  setShowEdit={() => setShowScopeEdit(true)}
                  setShowDelete={() =>
                    setScopeConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => handleDeleteScope(),
                    })
                  }
                  select={(data) => dispatch(scopeRowSelect(data))}
                  toolbarColumn = {true}
                  toolbarFilter = {true}
                  toolbarDensity = {true}
                  toolbarExport = {true}
                  data={scopeData}
                  height={300}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {edit && (
        <UpdateOpportunity close={() => setEdit(false)} values={props.values} />
      )}
      {showGoalAdd && (
        <AddOppGoals
          showAdd={showGoalAdd}
          closeModel={() => setShowGoalAdd(false)}
          oppId={props?.values?.id}
        />
      )}
      {showGoalEdit && (
        <UpdateOppGoals
          showEdit={showGoalEdit}
          closeModel={() => setShowGoalEdit(false)}
          oppId={props?.values?.id}
        />
      )}
      {showScopeAdd && (
        <AddOppScope
          showAdd={showScopeAdd}
          closeModel={() => setShowScopeAdd(false)}
          oppId={props?.values?.id}
        />
      )}
      {showScopeEdit && (
        <UpdateOppScope
          showEdit={showScopeEdit}
          closeModel={() => setShowScopeEdit(false)}
          oppId={props?.values?.id}
        />
      )}
      <ConfirmDialog
        confirmDialog={goalConfirmDialog}
        setConfirmDialog={(obj) => handleSetGoalConfirmDialog(obj)}
      />
      <ConfirmDialog
        confirmDialog={scopeConfirmDialog}
        setConfirmDialog={(obj) => handleSetScopeConfirmDialog(obj)}
      />
    </>
  );
};

export default OpportunityDetails;
