import React, { useEffect } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// material ui 
import {Grid, Box, FormGroup, InputLabel,FormControl, Select, MenuItem, FormHelperText} from '@mui/material'
// components
import { MuiAutocomplete, ModalPopUp, FormSubmitButton, ModalCloseBtn, FormTextField as MyTextInput, Notifier, ErrorNotifier } from '../../../../components'
// redux
import {createL3Menu, getL3Menu, resetMessage, resetErrMessage} from '../../../../features/platform/app_setup/menu_management/l3MenuSlice'

// ----------------------------------------------------------------------------------
const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

// ------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
    level2MenuId: Yup.object().required("Level 2 Menu is required"),
    l3MenuName: Yup.string().required("L3 Menu Name is required"),
    l3MenuGroupId: Yup.string().required("L3 Menu Group Id is required"),
    l3PageLink: Yup.string().required("L3 Page Link is required"),
    l3MenuIcon: Yup.string().required("L3 Menu Icon is required"),
  });

//   -------------------------------------------------------------------------------------------

const AddL3Menu = ({showAdd, setShowAdd}) => {

    const {l2MenuData} = useSelector((state) => ({...state.l2Menus}))
    const {message, errMessage} = useSelector((state) => ({...state.l3Menus}))
  const dispatch = useDispatch();

    const handleFile = (e) => {
        formik.setFieldValue("l3MenuIcon", e.target.files[0]);
      };

      const formRefresh = () => {
        formik.resetForm({
            values: {
                level2MenuId: "",
              l3MenuName: "",
              l3MenuGroupId: "",
              l3PageLink: "",
              l3MenuIcon: ""
            },
          });
      }

    const formik = useFormik({
        reset: true,
        initialValues: {
            level2MenuId: "",
          l3MenuName: "",
          l3MenuGroupId: "",
          l3PageLink: "",
          l3MenuIcon: "",
        },
    
        validationSchema: validationSchema,
        onSubmit: (values) => {
          let formdata = new FormData();
          formdata.append("level2MenuId", values.level2MenuId?.id)
          formdata.append("l3MenuName", values.l3MenuName);
          formdata.append("l3MenuGroupId", values.l3MenuGroupId.toString());
          formdata.append("l3PageLink", values.l3PageLink);
          formdata.append("l3MenuIcon", values.l3MenuIcon);
          dispatch(createL3Menu(formdata)).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
                dispatch(getL3Menu());
                setShowAdd(false);
                formRefresh()
              }, 2000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
              }, 3000);
            }
          });
        },
      });

  return (
    <>
    <ModalPopUp
    open={showAdd}
  >
    <Box
      sx={{ ...style, width: 900 }}
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <ModalCloseBtn 
      close={() => {
        setShowAdd(false)
        formRefresh()
    }} 
      />
      <div style={{ width: "50rem" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} sx={{padding: '4rem'}}>
            <MuiAutocomplete
              name="level2MenuId"
              options={l2MenuData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.level2MenuId}
              optionValue="l2MenuName"
              label="L2 Menu Name"
              required={true}
              error={formik.touched.level2MenuId && Boolean(formik.errors.level2MenuId)}
              helperText={formik.touched.level2MenuId && formik.errors.level2MenuId}
            />
          <MyTextInput
                  label="L3 Menu Name"
                  type="text"
                  name="l3MenuName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l3MenuName"
                  value={formik.values.l3MenuName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l3MenuName &&
                    Boolean(formik.errors.l3MenuName)
                  }
                  helperText={
                    formik.touched.l3MenuName && formik.errors.l3MenuName
                  }
                />
          <MyTextInput
                  label="L3 Menu Group Id"
                  type="text"
                  name="l3MenuGroupId"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l3MenuGroupId"
                  value={formik.values.l3MenuGroupId}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l3MenuGroupId &&
                    Boolean(formik.errors.l3MenuGroupId)
                  }
                  helperText={
                    formik.touched.l3MenuGroupId && formik.errors.l3MenuGroupId
                  }
                />
          <MyTextInput
                  label="L3 Page Link"
                  type="text"
                  name="l3PageLink"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l3PageLink"
                  value={formik.values.l3PageLink}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l3PageLink &&
                    Boolean(formik.errors.l3PageLink)
                  }
                  helperText={
                    formik.touched.l3PageLink && formik.errors.l3PageLink
                  }
                />
          <Grid container spacing={1} sx={{ marginTop: "0.5rem" }}>
              <Grid item xs={6} sm={6} md={8}>
                <FormGroup className="file-display mr-1">
                  <InputLabel className="bold label-sm">Icon *</InputLabel>
                  <MyTextInput
                    disabled
                    type="text"
                    name="l3MenuIcon"
                    value={formik?.values?.l3MenuIcon?.name || ""}
                    className="form-control-sm "
                    required={true}
                    size="small"
                    error={
                      formik.touched.l3MenuIcon &&
                      Boolean(formik.errors.l3MenuIcon)
                    }
                    helperText={
                      formik.touched.l3MenuIcon &&
                      formik.errors.l3MenuIcon
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <MyTextInput
                      type="file"
                      name="l3MenuIcon"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={handleFile}
                      required={true}
                      size="small"
                      error={
                        formik.touched.l3MenuIcon &&
                        Boolean(formik.errors.l3MenuIcon)
                      }
                      helperText={
                        formik.touched.l3MenuIcon &&
                        formik.errors.l3MenuIcon
                      }
                    />
                  </label>
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <FormSubmitButton type="submit" variant="contained">
        Submit
      </FormSubmitButton>
    </Box>
  </ModalPopUp>
  {message && <Notifier message={message} />}
  {errMessage && <ErrorNotifier message={errMessage} />}
  </>
  )
}

export default AddL3Menu