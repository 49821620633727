import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useLocation, Link } from "react-router-dom";

// material ui
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  Stack,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
// components
import {
  MuiDataGrid,
  GridCellExpand,
  ConfirmDialog,
  ModalCloseBtn,
  ModalPopUp,
  Notifier,
  ErrorNotifier,
} from "../../../components";
import {
  UpdateEngagement,
  AddKeyEngagements,
  UpdateKeyEngagements,
  AddNetPromoterScore,
  UpdateNetPromoterScore,
} from "../engagements";
// redux
import {
  getKeyEngagement,
  deleteKeyEngagement,
  rowSelect as keyEngRowSelect,
  resetMessage as keyEngResetMessage,
  resetErrMessage as keyEngResetErrMessage,
} from "../../../features/exec/engagement/keyEngagementSlice";
import {
  getCustUserNetPromoterScore,
  deleteNetPromoterScore,
  rowSelect as netScoreRowSelect,
  resetMessage as netScoreResetMessage,
  resetErrMessage as netScoreResetErrMessage,
} from "../../../features/exec/engagement/netPromoterScoreSlice";

import { CommonGridData } from "../../../components/common/CommonGridData";

// --------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  muiList: {
    padding: "0 !important",
  },
  muiListHeadText: {
    width: "3.4rem",
  },
  muiListDataText: {
    width: "3rem",
  },
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "40.25rem !important",
    [theme.breakpoints.down("md")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
}));

// -----------------------------------------------------------------------------

const EngagementDetails = (props) => {
  const [edit, setEdit] = useState(false);
  const [showKeyEngAdd, setShowKeyEngAdd] = useState(false);
  const [showKeyEngEdit, setShowKeyEngEdit] = useState(false);
  const [deliverablesData, setDeliverablesData] = useState([]);
  const [keyEngConfirmDialog, setKeyEngConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showNetScoreAdd, setShowNetScoreAdd] = useState(false);
  const [showNetScoreEdit, setShowNetScoreEdit] = useState(false);
  const [netScoreConfirmDialog, setNetScoreConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [engStageArr, setEngStageArr] = useState([]);
  const [accOpen, setAccOpen] = useState(false);
  const classes = useStyles();

  const location = useLocation();

  const dispatch = useDispatch();
  const keyEngResp = useSelector((state) => ({ ...state.keyEngagement }));
  const netScoreResp = useSelector((state) => ({ ...state.netPromoterScore }));
  const { engStageData } = useSelector((state) => ({ ...state.engStage }));

  function getEngDate(params) {
    return params?.row?.engagementStartDate
      ? format(new Date(params?.row?.engagementStartDate), "MM-dd-yyyy")
      : "";
  }
  function getEngEndDate(params) {
    return params?.row?.engagementEndDate
      ? format(new Date(params?.row?.engagementEndDate), "MM-dd-yyyy")
      : "";
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const keyEngagementData = {
    rows: keyEngResp.keyEngagementData,
    columns: [
      {
        field: "engagementStageName",
        headerName: "Engagement Stage Name",
        width: 300,
      },
      {
        field: "engagemenStartDate",
        headerName: "Date",
        width: 150,
        valueGetter: getEngDate,
      },
     
      { field: "status", headerName: "Status", width: 100 },
      {
        field: "comments",
        headerName: "Comments",
        width: 250,
        renderCell: renderCellExpand,
      },
    ],
  };

  const netScoreData = {
    rows: netScoreResp.netPromoterScoreData,
    columns: [
      { field: "npsRatingScore", headerName: "Rating Score", width: 150 },
      { field: "npsRatingName", headerName: "Rating Name", width: 150 },
      {
        field: "comments",
        headerName: "Comments",
        width: 300,
        renderCell: renderCellExpand,
      },
    ],
  };

  const handleDeleteKeyEng = () => {
    let data = {
      id: keyEngResp.selectedData?.id,
    };
    dispatch(deleteKeyEngagement(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          setKeyEngConfirmDialog({
            ...keyEngConfirmDialog,
            isOpen: false,
          });
          dispatch(keyEngResetMessage());
          dispatch(getKeyEngagement(props?.values?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(keyEngResetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetKeyEngConfirmDialog = (obj) => {
    setKeyEngConfirmDialog({
      ...keyEngConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDeleteNetScore = () => {
    let data = {
      id: netScoreResp.selectedData?.id,
    };

    let npsData = {
      excoCustomerUserId: props?.values?.excoCustomerUser?.id,
    };

    dispatch(deleteNetPromoterScore(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(netScoreResetMessage());
          setNetScoreConfirmDialog({
            ...netScoreConfirmDialog,
            isOpen: false,
          });
          dispatch(getCustUserNetPromoterScore(npsData));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(netScoreResetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetNetScoreConfirmDialog = (obj) => {
    setNetScoreConfirmDialog({
      ...netScoreConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  useEffect(() => {
    let data = {
      excoCustomerUserId: props?.values?.excoCustomerUser?.id,
    };
    dispatch(getCustUserNetPromoterScore(data));
    dispatch(getKeyEngagement(props?.values?.id));

    let engStages = [];
    engStageData.length > 0 &&
      engStageData.map((item) => {
        if (
          item?.engagementTier?.engagementTierName ===
          props?.values?.engTierName
        ) {
          return engStages.push(item);
        }
      });
    setEngStageArr(
      engStages.sort(
        (a, b) =>
          Number(a.engagementStageSequenceNo) -
          Number(b.engagementStageSequenceNo)
      )
    );
  }, []);

  let deliverables =
    props?.values?.clientEngagementDeliverableMapping?.length &&
    props?.values?.clientEngagementDeliverableMapping?.reduce((acc, data) => {
      acc += `${data?.engagementDeliverable?.deliverableName}, `;
      return acc;
    }, "");

  useEffect(() => {
    // props?.values?.clientEngagementDeliverableMapping?.length &&
    let delivar = [];
    for (let key of props?.values?.clientEngagementDeliverableMapping) {
      delivar.push(key?.engagementDeliverable);
    }
    setDeliverablesData(delivar);
  }, []);

  console.log(deliverablesData);

  let engagementData = props?.values;


  return (
    <>
      {!edit && (
        <Card sx={{ marginTop: "0.5rem" }}>
          <CardHeader
            title={
              <PageHeader variant="pageHeader">
                Engagement Details for{" "}
                {
                  engagementData?.excoCustomerUser?.excoCustomerUserDetail
                    ?.firstName
                }
                &nbsp;
                {
                  engagementData?.excoCustomerUser?.excoCustomerUserDetail
                    ?.lastName
                }
              </PageHeader>
            }
            action={
              <IconButton aria-label="cancel" onClick={() => props.close()}>
                <CancelIcon color="red" />
              </IconButton>
            }
          />
          <CardContent>
            <Stack flexDirection="row" justifyContent="space-between">
              <PageHeader variant="pageSecHeader">
                {props?.values?.engagementStatus}
              </PageHeader>
              {location?.state?.permissions?.find(
                (e) =>
                  e.applicationService?.name?.split(".")[
                  e?.applicationService?.name?.split(".")?.length - 1
                  ] === "update"
              ) && (
                  <IconButton onClick={() => setEdit(true)}>
                    <EditIcon color="#00527d" />
                  </IconButton>
                )}
            </Stack>
            <Grid container spacing={2}>
              <Grid container item xs={12} sm={12} md={6}>
                <CommonGridData
                  heading="Account Name"
                  data={
                    engagementData.excoCustomerUser?.excoAccount?.accountName}
                />


                <CommonGridData
                  heading="Exco Account Team"
                  data={null
                  }
                />
                <CommonGridData
                  heading="Professional Team Member"
                  data={engagementData.excoCustomerUser?.excoAccount?.professionalTeamMember1
                  }
                />

                <Grid item md={4}>
                  <ListItemText
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => setAccOpen(true)}
                    className={classes.muiListDataText}
                  >
                    See More {">"}
                  </ListItemText>
                </Grid>


                <Grid item md={8}>

                </Grid>

                {/* <ListItem className={classes.muiList}>
                    <ListItemText className={classes.muiListHeadText}>
                      Exco Account Team
                    </ListItemText>
                    &nbsp; :&nbsp;{" "}
                    <ListItemText
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => setAccOpen(true)}
                      className={classes.muiListDataText}
                    >
                      Click here
                    </ListItemText>
                    &nbsp; :&nbsp;{" "}
                  </ListItem> */}

                <CommonGridData
                  heading="Client Name"
                  data={`${engagementData?.excoCustomerUser?.excoCustomerUserDetail
                    ?.firstName
                    }${" "}${engagementData?.excoCustomerUser?.excoCustomerUserDetail
                      ?.lastName
                    }`}
                />

                <CommonGridData
                  heading="Mentor/Coach Name"
                  data={`${engagementData?.clientMentorMapping?.excoMentorDetail
                    ?.firstName
                    }${" "}${engagementData?.clientMentorMapping?.excoMentorDetail
                      ?.lastName
                    }`}
                />
                <CommonGridData
                  heading="Mentor/Coach Email"
                  data={
                    engagementData?.clientMentorMapping?.excoMentorDetail
                      ?.excoMentor?.email
                  }
                />
                <CommonGridData
                  heading="Offering Tier"
                  data={engagementData?.engTierName}
                />
                <CommonGridData
                  heading="Deliverables"
                  data={deliverables && deliverables}
                />

                <CommonGridData
                  heading="Manager Name"
                  data={engagementData?.mangerName}
                />
                <CommonGridData
                  heading="Manager's Email"
                  data={engagementData?.mangerEmail}
                />

                <CommonGridData
                  heading="HR Partner Name"
                  data={engagementData?.contactName}
                />
                <CommonGridData
                  heading="HR Partner's Email"
                  data={engagementData?.contactEmail}
                />

                <CommonGridData
                  heading="Eng Start Date"
                  data={
                    engagementData?.engagementStartDate
                      ? format(
                        new Date(engagementData?.engagementStartDate),
                        "MM-dd-yyyy"
                      )
                      : ""
                  }
                />

                <CommonGridData
                  heading="Eng End Date"
                  data={
                    engagementData?.engagementEndDate
                      ? format(
                        new Date(engagementData?.engagementEndDate),
                        "MM-dd-yyyy"
                      )
                      : ""
                  }
                />
                <CommonGridData
                  heading="Next Update Due"
                  data={
                    engagementData?.nextUpdateDue
                      ? format(
                        new Date(engagementData?.nextUpdateDue),
                        "MM-dd-yyyy"
                      )
                      : ""
                  }
                />
                {/* <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Account Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.excoCustomerUser?.excoAccount?.accountName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Exco Account Team
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => setAccOpen(true)}
                    className={classes.muiListDataText}
                  >
                    Click here
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Client Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {
                      props?.values?.excoCustomerUser?.excoCustomerUserDetail
                        ?.firstName
                    }
                    &nbsp;
                    {
                      props?.values?.excoCustomerUser?.excoCustomerUserDetail
                        ?.lastName
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Mentor Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {
                      props?.values?.clientMentorMapping?.excoMentorDetail
                        ?.firstName
                    }
                    &nbsp;
                    {
                      props?.values?.clientMentorMapping?.excoMentorDetail
                        ?.lastName
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Mentor Email
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText
                    style={{ wordBreak: "break-word" }}
                    className={classes.muiListDataText}
                  >
                    {
                      props?.values?.clientMentorMapping?.excoMentorDetail
                        ?.excoMentor?.email
                    }
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Offering Tier
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText
                    style={{ wordBreak: "break-word" }}
                    className={classes.muiListDataText}
                  >
                    {props?.values?.engTierName}
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Deliverables
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText
                    style={{ wordBreak: "break-word" }}
                    className={classes.muiListDataText}
                  >
                    {deliverables && deliverables}
                  </ListItemText>
                </ListItem>

              

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Manager Name
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText
                    style={{ wordBreak: "break-word" }}
                    className={classes.muiListDataText}
                  >
                    {props?.values?.mangerName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Manager Email
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.mangerEmail}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    HR Contact Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.contactName}
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    HR Contact Email
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText
                    style={{ wordBreak: "break-word" }}
                    className={classes.muiListDataText}
                  >
                    {props?.values?.contactEmail}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    HR Contact Number
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.dialingCode}-{props?.values?.contactNumber}
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Eng Start Date
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {format(
                      new Date(props?.values?.engagementStartDate),
                      "MM-dd-yyyy"
                    )}
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Eng End Date
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {format(
                      new Date(props?.values?.engagementEndDate),
                      "MM-dd-yyyy"
                    )}
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Next Update Due
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {format(
                      new Date(props?.values?.nextUpdateDue),
                      "MM-dd-yyyy"
                    )}
                  </ListItemText>
                </ListItem> */}
              </Grid>
              <Grid container item xs={12} sm={12} md={6}>
                <Grid item md={12} xs={12} sm={12}>


                  <PageHeader variant="pageSecHeader">
                    Key Engagement Dates
                  </PageHeader>
                  <MuiDataGrid
                    setShowAdd={() => setShowKeyEngAdd(true)}
                    setShowEdit={() => setShowKeyEngEdit(true)}
                    setShowDelete={() =>
                      setKeyEngConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => handleDeleteKeyEng(),
                      })
                    }
                    toolbarColumn={true}
                    toolbarFilter={true}
                    toolbarDensity={true}
                    toolbarExport={true}
                    data={keyEngagementData}
                    select={(data) => dispatch(keyEngRowSelect(data))}
                    height={300}
                  />
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  <Box sx={{ marginTop: "2rem" }}>
                    <PageHeader variant="pageSecHeader">
                      Net Promoter Score
                    </PageHeader>
                  </Box>
                  <MuiDataGrid
                    setShowAdd={() => setShowNetScoreAdd(true)}
                    setShowEdit={() => setShowNetScoreEdit(true)}
                    setShowDelete={() =>
                      setNetScoreConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => handleDeleteNetScore(),
                      })
                    }
                    select={(data) => dispatch(netScoreRowSelect(data))}
                    toolbarColumn={true}
                    toolbarFilter={true}
                    toolbarDensity={true}
                    toolbarExport={true}
                    data={netScoreData}
                    height={300}
                  />
                </Grid>
              </Grid>
            </Grid>

            <ModalPopUp
              closeModel={() => setAccOpen(false)}
              open={accOpen}
              handleClose={() => setAccOpen(false)}
            >
              <Box
                // sx={{ ...style, width: 700 }}
                className={classes.rootbox}
              >
                <h3 style={{ textAlign: "center" }}>
                  ExCo Account Team for{" "}
                  {props?.values?.excoCustomerUser?.excoAccount?.accountName}
                </h3>

                <ModalCloseBtn close={() => setAccOpen(false)} />
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Executive Sponsor
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {
                      props?.values?.excoCustomerUser?.excoAccount
                        ?.executiveSponsor
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Lead Mentor
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText sx={{
                    paddingLeft: !props?.values?.excoCustomerUser?.excoAccount
                      ?.leadMentor && "2rem"
                  }} className={classes.muiListDataText}>
                    {
                      props?.values?.excoCustomerUser?.excoAccount
                        ?.leadMentor ?  props?.values?.excoCustomerUser?.excoAccount
                        ?.leadMentor :"-"
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>

                    Professional Team Member 1
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {
                      props?.values?.excoCustomerUser?.excoAccount
                        ?.professionalTeamMember1
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Professional Team Member 2
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText sx={{
                    paddingLeft: !props?.values?.excoCustomerUser?.excoAccount
                      ?.professionalTeamMember2 && "2rem"
                  }} className={classes.muiListDataText}>
                    {
                      props?.values?.excoCustomerUser?.excoAccount
                        ?.professionalTeamMember2 ?
                        props?.values?.excoCustomerUser?.excoAccount
                          ?.professionalTeamMember2 : "-"
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Professional Team Member 3
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText sx={{
                    paddingLeft: !props?.values?.excoCustomerUser?.excoAccount
                      ?.professionalTeamMember3 && "2rem"
                  }} className={classes.muiListDataText}>
                    {
                      props?.values?.excoCustomerUser?.excoAccount
                        ?.professionalTeamMember3 ?
                        props?.values?.excoCustomerUser?.excoAccount
                          ?.professionalTeamMember3 : "-"
                    }
                  </ListItemText>
                </ListItem>
              </Box>
            </ModalPopUp>
          </CardContent>
        </Card>
      )}

      {edit && (
        <UpdateEngagement
          accountId={props?.accountId}
          close={() => props.close()}
          values={props.values}
          deliverablesData={deliverablesData}
        />
      )}

      {showKeyEngAdd && (
        <AddKeyEngagements
          showAdd={showKeyEngAdd}
          closeModel={() => setShowKeyEngAdd(false)}
          engagement={props?.values?.id}
          engStartDate={props?.values?.engagementStartDate}
          custClient={props?.values?.excoCustomerUser?.id}
          engStageArr={engStageArr}
        />
      )}
      {showKeyEngEdit && (
        <UpdateKeyEngagements
          showEdit={showKeyEngEdit}
          closeModel={() => setShowKeyEngEdit(false)}
          engagement={props?.values?.id}
          engStartDate={props?.values?.engagementStartDate}
          custClient={props?.values?.excoCustomerUser?.id}
          tierName={props?.values?.engTierName}
        />
      )}
      {showNetScoreAdd && (
        <AddNetPromoterScore
          showAdd={showNetScoreAdd}
          closeModel={() => setShowNetScoreAdd(false)}
          engagement={props?.values?.id}
          custClient={props?.values?.excoCustomerUser?.id}
          tierName={props?.values?.engTierName}
        />
      )}
      {showNetScoreEdit && (
        <UpdateNetPromoterScore
          showEdit={showNetScoreEdit}
          closeModel={() => setShowNetScoreEdit(false)}
          engagement={props?.values?.id}
          custClient={props?.values?.excoCustomerUser?.id}
          tierName={props?.values?.engTierName}
        />
      )}
      <ConfirmDialog
        confirmDialog={keyEngConfirmDialog}
        setConfirmDialog={(obj) => handleSetKeyEngConfirmDialog(obj)}
      />
      <ConfirmDialog
        confirmDialog={netScoreConfirmDialog}
        setConfirmDialog={(obj) => handleSetNetScoreConfirmDialog(obj)}
      />

      {keyEngResp?.errMessage && (
        <ErrorNotifier message={keyEngResp?.errMessage} />
      )}
      {keyEngResp?.message && <Notifier message={keyEngResp?.message} />}

      {netScoreResp?.errMessage && (
        <ErrorNotifier message={netScoreResp?.errMessage} />
      )}
      {netScoreResp?.message && <Notifier message={netScoreResp?.message} />}
    </>
  );
};

export default EngagementDetails;
