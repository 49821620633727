import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_OPP_SCOPE } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createOppScope = createAsyncThunk(
  "oppScope/createOppScope",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_OPP_SCOPE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOppScope = createAsyncThunk(
  "oppScope/getOppScope",
  async () => {
    return axios.get(CURD_OPP_SCOPE, { withCredentials: true });
  }
);

export const updateOppScope = createAsyncThunk(
  "oppScope/updateOppScope",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_OPP_SCOPE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOppScope = createAsyncThunk(
  "oppScope/deleteOppScope",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_OPP_SCOPE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const oppScopeSlice = createSlice({
  name: "oppScope",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    oppScopeData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createOppScope.pending]: (state, action) => {
      state.loading = true;
    },
    [createOppScope.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createOppScope.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getOppScope.pending]: (state, action) => {
      state.loading = true;
    },
    [getOppScope.fulfilled]: (state, action) => {
      state.loading = false;
      state.oppScopeData = action?.payload?.data;
    },
    [getOppScope.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateOppScope.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOppScope.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateOppScope.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteOppScope.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteOppScope.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteOppScope.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  oppScopeSlice.actions;
export default oppScopeSlice.reducer;
