import { createSlice } from "@reduxjs/toolkit";

const reusableSlice = createSlice({
  name: "reusable",
  initialState: {
    message: "",
    errMessage: "",
  },
  reducers: {
    setMessage: (state, message) => {
      state.message = message;
      setTimeout(() => {
        state.message = "";
      }, 3000);
    },
  },
});

export const { setMessage } = reusableSlice.actions;

export default reusableSlice.reducer;
