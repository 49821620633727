import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_OPPORTUNITY_STATUS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createOpportunityStatus = createAsyncThunk(
  "opportunityStatus/createOpportunityStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_OPPORTUNITY_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOpportunityStatus = createAsyncThunk(
  "opportunityStatus/getOpportunityStatus",
  async () => {
    return axios.get(CURD_OPPORTUNITY_STATUS, { withCredentials: true });
  }
);

export const updateOpportunityStatus = createAsyncThunk(
  "opportunityStatus/updateOpportunityStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_OPPORTUNITY_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOpportunityStatus = createAsyncThunk(
  "opportunityStatus/deleteOpportunityStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_OPPORTUNITY_STATUS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const opportunityStatusSlice = createSlice({
  name: "opportunityStatus",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    opportunityStatusData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createOpportunityStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [createOpportunityStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createOpportunityStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getOpportunityStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [getOpportunityStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.opportunityStatusData = action?.payload?.data;
    },
    [getOpportunityStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateOpportunityStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOpportunityStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateOpportunityStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteOpportunityStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteOpportunityStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteOpportunityStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  opportunityStatusSlice.actions;
export default opportunityStatusSlice.reducer;
