import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { GET_CLIENTS, GET_CLIENTS_BY_ACC, GET_ACTIVE_ENG_BY_ACC } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const getClients = createAsyncThunk("clients/getClients", async () => {
  return axios.get(GET_CLIENTS, { withCredentials: true });
});

export const getClientsByAcc = createAsyncThunk("clients/getClientsByAcc", async (id) => {
  return axios.get(`${GET_CLIENTS_BY_ACC}/${id}`, { withCredentials: true });
});

export const getActiveEngByAcc = createAsyncThunk("clients/getActiveEngByAcc", async (id) => {
  return axios.get(`${GET_ACTIVE_ENG_BY_ACC}/${id}`, { withCredentials: true });
});

const clientSlice = createSlice({
  name: "clients",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    clientsData: [],
    clientsByAccData: [],
    activeEngByAccData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getClients.pending]: (state, action) => {
      state.loading = true;
    },
    [getClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientsData = action?.payload?.data;
    },
    [getClients.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getClientsByAcc.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientsByAcc.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientsByAccData = action?.payload?.data;
    },
    [getClientsByAcc.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getActiveEngByAcc.pending]: (state, action) => {
      state.loading = true;
    },
    [getActiveEngByAcc.fulfilled]: (state, action) => {
      state.loading = false;
      state.activeEngByAccData = action?.payload?.data;
    },
    [getActiveEngByAcc.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } = clientSlice.actions;
export default clientSlice.reducer;
