import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Stack, Box, Avatar, IconButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
// components
import AddDevelopmentAreas from "./AddDevelopmentAreas";
import UpdateDevelopmentAreas from "./UpdateDevelopmentAreas";

import AddCoachingFocus from "./addCoachingDev";
import UpdateCoachingFocus from "./updateCoachingFocus";

import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";

import { MuiDataGrid, GridCellExpand } from "../../../components";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import {MuiTooltip} from "../../../components"
// redux
import {
  getDevelopmentAreas,
  deleteDevelopmentAreas,
  
  getCoachingFocus,
  fetchCoachingFocusById,
  deleteCoachingDevelopmentArea,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/developmentAreasSlice";
import Layout from "../../../layout/Layout";

// ----------------------------------------------------------------------------------

const DevelopmentAreas = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [showCoachingFocus, setShowCoachingFocus] = useState(false);
  const [showEditCoachingFocus, setShowEditCoachingFocus] = useState(false);

  const [show, setShow] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const { devAreasData, selectedData,message,errMessage, coachingFocusList, coachingFocusAreaData } = useSelector(
    (state) => ({
      ...state.devAreas,
    })
  );

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const handleSettingClick = (data) => {
    setShow(!show);
    setStoreId(data.row.id);
    dispatch(fetchCoachingFocusById(data.row.id));
  };

  const data = {
    rows: devAreasData,
    columns: [
      {
        field: "developmentAreaName",
        headerName: "Development Area Name",
        width: 300,
      },
      {
        width: 250,
        headerName:"Coaching Focus",
        renderCell: (params) => {
          return (
            <MuiTooltip title="Coaching Focus"  placement="bottom">
            <ListItemIcon onClick={() => handleSettingClick(params)}>
              <SettingsApplicationsIcon />
            </ListItemIcon>
            </MuiTooltip>
          );
        },
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 450,
        renderCell: renderCellExpand,
      },
     
    ],
  };

  const dataTwo = {
    rows:  coachingFocusList,
    columns: [
      {
        field: "developmentAreaCoachingFocusName",
        headerName: "Coaching Focus",
        width: 300,
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 450,
        renderCell: renderCellExpand,
      },
    ],
  };

  const showAddPage = () => {
    setShowAdd(!showAdd);
  };

  const showEditPage = () => {
    setShowEdit(true);
  };

  const showCoachingChange = () => {
    setShowCoachingFocus(!showCoachingFocus);
  };

  const showEditCoachingChange = () => {
    setShowEditCoachingFocus(!showEditCoachingFocus);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: !show ? () => handleDelete() : () => handleCoachingDelete(),
    });

  useEffect(() => {
    dispatch(getDevelopmentAreas());
  }, []);



  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDelete = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteDevelopmentAreas(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getDevelopmentAreas());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleCoachingDelete = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteCoachingDevelopmentArea(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(fetchCoachingFocusById(storeId));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  return (
    <Layout>
      {!showAdd &&
        !showEdit &&
        !showCoachingFocus &&
        !showEditCoachingFocus && (
          <>
            <Stack flexDirection="row" justifyContent="space-between">
              <Box sx={{ width: "100%" }}>
                {!show ? (
                  <PageHeader variant="pageHeader">
                    Development Areas
                  </PageHeader>
                ) : (
                  <PageHeader variant="pageHeader">Coaching Focus</PageHeader>
                )}
              </Box>

              {!show ? (
                <Link
                  to="/exco/config"
                  state={{ l3Menu: location?.state?.l3Menu }}
                >
                  <CancelIcon color="red" />
                </Link>
              ) : (
                <ListItemIcon onClick={() => setShow(false)}>
                  <CancelIcon color="red" />
                </ListItemIcon>
              )}
            </Stack>

            {!show ? (
              <MuiDataGrid
                setShowAdd={
                  location?.state?.permissions?.find(
                    (e) =>
                      e.applicationService?.name?.split(".")[
                        e?.applicationService?.name?.split(".")?.length - 1
                      ] === "create"
                  ) && showAddPage
                }
                setShowEdit={
                  location?.state?.permissions?.find(
                    (e) =>
                      e.applicationService?.name?.split(".")[
                        e?.applicationService?.name?.split(".")?.length - 1
                      ] === "update"
                  ) && showEditPage
                }
                setShowDelete={
                  location?.state?.permissions?.find(
                    (e) =>
                      e.applicationService?.name?.split(".")[
                        e?.applicationService?.name?.split(".")?.length - 1
                      ] === "delete"
                  ) && showDelete
                }
                toolbarColumn={true}
                toolbarFilter={true}
                toolbarDensity={true}
                toolbarExport={true}
                data={data}
                select={(data) => dispatch(rowSelect(data))}
              />
            ) : (
              <MuiDataGrid
                setShowAdd={
                  location?.state?.permissions?.find(
                    (e) =>
                      e.applicationService?.name?.split(".")[
                        e?.applicationService?.name?.split(".")?.length - 1
                      ] === "create"
                  ) && showCoachingChange
                }
                setShowEdit={
                  location?.state?.permissions?.find(
                    (e) =>
                      e.applicationService?.name?.split(".")[
                        e?.applicationService?.name?.split(".")?.length - 1
                      ] === "update"
                  ) && showEditCoachingChange
                }
                setShowDelete={
                  location?.state?.permissions?.find(
                    (e) =>
                      e.applicationService?.name?.split(".")[
                        e?.applicationService?.name?.split(".")?.length - 1
                      ] === "delete"
                  ) && showDelete
                }
                toolbarColumn={true}
                toolbarFilter={true}
                toolbarDensity={true}
                toolbarExport={true}
                data={dataTwo}
                select={(data) => dispatch(rowSelect(data))}
              />
            )}
          </>
        )}
      {showAdd && (
        <AddDevelopmentAreas
          showAdd={showAdd}
          close={() => setShowAdd(false)}
        />
      )}
      {showEdit && (
        <UpdateDevelopmentAreas
          showEdit={showEdit}
          close={() => setShowEdit(false)}
        />
      )}

      {showCoachingFocus && (
        <AddCoachingFocus
          storeId={storeId}
          showAdd={showCoachingFocus}
          close={() => setShowCoachingFocus(false)}
        />
      )}
      {showEditCoachingFocus && (
        <UpdateCoachingFocus
          storeId={storeId}
          showEdit={showEditCoachingFocus}
          close={() => setShowEditCoachingFocus(false)}
        />
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

        {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Layout>
  );
};

export default DevelopmentAreas;
