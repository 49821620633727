import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ENGAGEMENT_STAGE, GET_ENGAGEMENT_STAGE_BY_TIER } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createEngagementStage = createAsyncThunk(
  "engagementStage/createEngagementStage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ENGAGEMENT_STAGE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementStage = createAsyncThunk(
  "engagementStage/getEngagementStage",
  async () => {
    return axios.get(CURD_ENGAGEMENT_STAGE, { withCredentials: true });
  }
);

export const getEngagementStageByTier = createAsyncThunk(
  "engagementStage/getEngagementStageByTier",
  async (id) => {
    return axios.get(`${GET_ENGAGEMENT_STAGE_BY_TIER}/${id}`, { withCredentials: true });
  }
);

export const updateEngagementStage = createAsyncThunk(
  "engagementStage/updateEngagementStage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ENGAGEMENT_STAGE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementStage = createAsyncThunk(
  "engagementStage/deleteEngagementStage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ENGAGEMENT_STAGE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementStageSlice = createSlice({
  name: "engagementStage",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engStageData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    resetEngStageData: (state) => {
      state.engStageData = [];
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createEngagementStage.pending]: (state, action) => {
      state.loading = true;
    },
    [createEngagementStage.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createEngagementStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getEngagementStage.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementStage.fulfilled]: (state, action) => {
      state.loading = false;
      state.engStageData = action?.payload?.data;
    },
    [getEngagementStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementStageByTier.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementStageByTier.fulfilled]: (state, action) => {
      state.loading = false;
      state.engStageData = action?.payload?.data;
    },
    [getEngagementStageByTier.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEngagementStage.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEngagementStage.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEngagementStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementStage.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementStage.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, resetEngStageData, rowSelect } =
  engagementStageSlice.actions;
export default engagementStageSlice.reducer;
