import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_LEVEL_OF_ENGAGEMENT } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createLevelOfEngagement = createAsyncThunk(
  "levelOfEngagement/createLevelOfEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_LEVEL_OF_ENGAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getLevelOfEngagement = createAsyncThunk(
  "levelOfEngagement/getLevelOfEngagement",
  async () => {
    return axios.get(CURD_LEVEL_OF_ENGAGEMENT, { withCredentials: true });
  }
);

export const updateLevelOfEngagement = createAsyncThunk(
  "levelOfEngagement/updateLevelOfEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_LEVEL_OF_ENGAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteLevelOfEngagement = createAsyncThunk(
  "levelOfEngagement/deleteLevelOfEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_LEVEL_OF_ENGAGEMENT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const levelOfEngagementSlice = createSlice({
  name: "levelOfEngagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    levelOfEngData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createLevelOfEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [createLevelOfEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createLevelOfEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getLevelOfEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [getLevelOfEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.levelOfEngData = action?.payload?.data;
    },
    [getLevelOfEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateLevelOfEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateLevelOfEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateLevelOfEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteLevelOfEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteLevelOfEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteLevelOfEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  levelOfEngagementSlice.actions;
export default levelOfEngagementSlice.reducer;
