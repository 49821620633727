import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_OPPORTUNITY_STAGE } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createOpportunityStage = createAsyncThunk(
  "opportunityStage/createOpportunityStage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_OPPORTUNITY_STAGE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOpportunityStage = createAsyncThunk(
  "opportunityStage/getOpportunityStage",
  async () => {
    return axios.get(CURD_OPPORTUNITY_STAGE, { withCredentials: true });
  }
);

export const updateOpportunityStage = createAsyncThunk(
  "opportunityStage/updateOpportunityStage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_OPPORTUNITY_STAGE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOpportunityStage = createAsyncThunk(
  "opportunityStage/deleteOpportunityStage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_OPPORTUNITY_STAGE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const opportunityStageSlice = createSlice({
  name: "opportunityStage",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    opportunityStageData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createOpportunityStage.pending]: (state, action) => {
      state.loading = true;
    },
    [createOpportunityStage.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createOpportunityStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getOpportunityStage.pending]: (state, action) => {
      state.loading = true;
    },
    [getOpportunityStage.fulfilled]: (state, action) => {
      state.loading = false;
      state.opportunityStageData = action?.payload?.data;
    },
    [getOpportunityStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateOpportunityStage.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOpportunityStage.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateOpportunityStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteOpportunityStage.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteOpportunityStage.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteOpportunityStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  opportunityStageSlice.actions;
export default opportunityStageSlice.reducer;
