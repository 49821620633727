import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  CURD_NET_PROMOTER_SCORE,
  GET_CUST_USER_NET_PROMOTER_SCORE,
} from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createNetPromoterScore = createAsyncThunk(
  "netPromoterScore/createNetPromoterScore",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_NET_PROMOTER_SCORE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustUserNetPromoterScore = createAsyncThunk(
  "netPromoterScore/getCustUserNetPromoterScore",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_CUST_USER_NET_PROMOTER_SCORE,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNetPromoterScore = createAsyncThunk(
  "netPromoterScore/getNetPromoterScore",
  async () => {
    return axios.get(CURD_NET_PROMOTER_SCORE, {
      withCredentials: true,
    });
  }
);

export const updateNetPromoterScore = createAsyncThunk(
  "netPromoterScore/updateNetPromoterScore",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_NET_PROMOTER_SCORE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNetPromoterScore = createAsyncThunk(
  "netPromoterScore/deleteNetPromoterScore",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_NET_PROMOTER_SCORE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const netPromoterScoreSlice = createSlice({
  name: "keyEngagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    netPromoterScoreData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createNetPromoterScore.pending]: (state, action) => {
      state.loading = true;
    },
    [createNetPromoterScore.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createNetPromoterScore.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getCustUserNetPromoterScore.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustUserNetPromoterScore.fulfilled]: (state, action) => {
      state.loading = false;
      state.netPromoterScoreData = action?.payload?.data;
    },
    [getCustUserNetPromoterScore.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getNetPromoterScore.pending]: (state, action) => {
      state.loading = true;
    },
    [getNetPromoterScore.fulfilled]: (state, action) => {
      state.loading = false;
      state.netPromoterScoreData = action?.payload?.data;
    },
    [getNetPromoterScore.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateNetPromoterScore.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNetPromoterScore.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateNetPromoterScore.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteNetPromoterScore.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteNetPromoterScore.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteNetPromoterScore.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  netPromoterScoreSlice.actions;
export default netPromoterScoreSlice.reducer;
