import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box, Grid, FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,

} from "@mui/material";

// components
import {
  ModalPopUp,
  MuiAutocomplete,
  FormSubmitButton,
  ModalCloseBtn,
  Notifier,
  ErrorNotifier,
  FormTextField as MyTextInput,
} from "../../../components";
// redux
import {
  createKeyEngagement,
  getKeyEngagement,
  resetMessage,
  resetErrMessage,
} from "../../../features/exec/engagement/keyEngagementSlice";
import { DateTime } from "luxon";
// --------------------------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "40.25rem !important",
    [theme.breakpoints.down("md")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText: {
    width: "28rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  filter_grid: {
    [theme.breakpoints.down("md")]: {
      display: "flex !important",
      flexDirection: "column !important",
    },
  },
  close_btn: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "-2.563rem",
    },
  },
}));
const AddKeyEngagements = (props) => {
  const [disableDate, setDisableDate] = useState(false);
  const [disableEndDate, setDisableEndDate] = useState(false)
  const dispatch = useDispatch();
  const { message, errMessage } = useSelector((state) => ({
    ...state.keyEngagement,
  }));

  const validationSchema = Yup.object({
    engName: Yup.object().required("Engagement Name is required"),
    engDate: disableDate ? Yup.date() : Yup.date().required("Start Date is required"),
    status: Yup.string().required("Status is required"),
    comments: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      engName: "",
      engDate: "",
     
      status: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const date = DateTime.fromISO(values?.engDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const localIsoString = date.toISO();

    
      dispatch(
        createKeyEngagement({
          clientEngagementId: props?.engagement,
          excoCustomerUserId: props?.custClient,
          engagementStageName: values.engName.engagementStageName,
          engagementStartDate: localIsoString,
        
          status: values?.status ? values?.status : values?.engDate
            ? Date.parse(localIsoString) <= Date.parse(new Date())
              ? "Completed"
              : "Scheduled"
            : "TBD",
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.closeModel();
            dispatch(getKeyEngagement(props?.engagement));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });
  const classes = useStyles();

  useEffect(() => {
    if (formik.values?.status === "N/A") {
      formik.setFieldValue("engDate", "")

      setDisableDate(true)

    }
    else if (formik.values?.status !== "N/A") {
      setDisableDate(false)

    }
    if (formik?.values.engName?.engagementStageName === "LA" && formik.values?.status !== "N/A") {

      setDisableEndDate(true)
    }

  }, [formik.values.status, formik?.values?.engName?.engagementStageName])

  return (
    <>
      <ModalPopUp open={props?.showAdd} closeModel={props?.closeModel}>
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={props.closeModel} />
          <div className={classes.responsiveText}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <MuiAutocomplete
                  name="engName"
                  options={props?.engStageArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.engName}
                  optionValue="engagementStageName"
                  label="Engagement Stage Name"
                  required={true}
                  error={
                    formik.touched.engName && Boolean(formik.errors.engName)
                  }
                  helperText={formik.touched.engName && formik.errors.engName}
                />
                <FormControl
                  sx={{ marginTop: "1rem" }}
                  fullWidth
                  error={
                    formik.touched.status && formik.errors.status ? true : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">
                    Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    defaultValue={formik?.values?.status}
                    value={formik?.values?.status}
                    label="Status *"
                    onChange={formik.handleChange}
                  // required={true}
                  >

                    <MenuItem
                      value="Scheduled"
                    >
                      Scheduled
                    </MenuItem>
                    <MenuItem
                      value="In Progress"
                    >
                      In Progress
                    </MenuItem>
                    <MenuItem
                      value="TBD"
                    >
                      TBD
                    </MenuItem>
                    <MenuItem
                      value="Completed"
                    >
                      Completed
                    </MenuItem>
                    <MenuItem
                      value="N/A"
                    >
                      N/A
                    </MenuItem>

                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formik.touched.status && formik.errors.status}
                  </FormHelperText>
                </FormControl>
                <Grid container md={12} columnSpacing={1}  >
                  <Grid item md={12} >
                    <MyTextInput
                      disabled={formik.values?.status === "N/A" ? true : false}
                      label="Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      name="engDate"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="outlined-name"
                      value={formik.values.engDate}
                      onChange={formik.handleChange}
                      error={formik.touched.engDate && Boolean(formik.errors.engDate)}
                      helperText={formik.touched.engDate && formik.errors.engDate}
                    />

                  </Grid>



                </Grid>

                <MyTextInput
                  label="Comments"
                  type="text"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                />
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  );
};

export default AddKeyEngagements;
