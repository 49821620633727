import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
// material ui
import {
  Stack,
  Box,
} from "@mui/material";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,
  MuiAutocomplete,
  Notifier,
  ErrorNotifier,
} from "../../components";

// redux
import { getEngagement } from "../../features/exec/engagement/engagementSlice";
import { getAccount } from "../../features/customer/accountSlice";

// ----------------------------------------------------------

const Engagement = (props) => {
  const [account, setAccount] = useState("");
  const dispatch = useDispatch();
  const { message, errMessage, engagementData } = useSelector((state) => ({
    ...state.engagement,
  }));
  const { accountData } = useSelector((state) => ({ ...state.account }));

  const data = {
    rows: engagementData,
    columns: [
      { field: "mentorName", headerName: "Mentor Name", width: 150 },
      { field: "clientName", headerName: "Client Name", width: 250 },
      {
        field: "matchingDate",
        headerName: "Matching Date",
        width: 150,
      },
      { field: "chemistryDate", headerName: "Chemistry Date", width: 150 },
      { field: "acceleratorDate", headerName: "Accelerator Date", width: 250 },
    ],
  };

  useEffect(() => {
    dispatch(getEngagement());
    dispatch(getAccount());
  }, []);

  return (
    <Layout>
      <>
        <Stack
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <PageHeader variant="pageHeader">Manage Engagement Date</PageHeader>
          <Box
            sx={{ m: "0 !important", mb: "0.5rem !important", width: "25%" }}
          >
            <MuiAutocomplete
              name="account"
              options={accountData}
              setValue={(name, newValue) => setAccount(newValue)}
              value={account}
              optionValue="accountName"
              label="Customer Account Name"
            />
          </Box>
        </Stack>
        <MuiDataGrid data={data}  toolbarColumn = {true}
toolbarFilter = {true}
toolbarDensity = {true}
toolbarExport = {true}/>
      </>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Engagement;
