import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Typography,
  Stack,
  InputLabel,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  Slider,
  Grid,
} from "@mui/material";
import SpeedoMeter from "../../components/common/speedo-meter/SpeedoMeter";
// components
import Layout from "../../layout/Layout";
import {
  ModalPopUp,
  ModalCloseBtn,
  MuiAutocomplete,
  FormSubmitButton,
  Notifier,
  ErrorNotifier,
  MuiDataGridForSummary,
  EyeInfo,
} from "../../components";
import { PageHeader } from "@excoleadershipui/pionweblib";

// redux
import {
  getAllRatingClients,
  resetErrMessage,
  resetMessage,
  getMentorLatestRatingByClientId,
  getMentorRatingDate,
  getMentorRatingDateById,
  emptyingArray,
} from "../../features/outcomes/outcomeSlice";

import { getToggleAdminData } from "../../features/auth/authSlice";

// -------------------------------------------------------------------------------------------------------------------

const Assesment = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showModelGenerate, setShowModelGenerate] = useState("");
  const [generateDateValue, setGenerateDateValue] = useState("");
  const [generateRowId, setGenerateRowId] = useState(null);
  const [generateRowCheckId, setGenerateRowCheckId] = useState(null);
  const [checkInRating, setCheckinRating] = useState(null);
  const [checkinValue, setCheckinValue] = useState("");
  const [clientName, setClientName] = useState("");
  const [dateObj, setDateObj] = useState({
    lastmeeting: "",
    managerTouchPoint: "",
    hrPartnerTouchPoint: "",
  });

  const {
    message,
    errMessage,
    getAllClientData,
    dateErrorMessageData,
    previousOutcomeData,
    getClientLatestRatingData,

    getRatingDateData,
  } = useSelector((state) => ({
    ...state.outcomes,
  }));

  const { toggleAdminData } = useSelector(
    (state) => ({
      ...state.auth,
    })
  );

  console.log(getClientLatestRatingData);

  const dispatch = useDispatch();

  const marks = [
    {
      value: "0",
      label: "0",
    },
    {
      value: "50",
      label: "1",
    },
    {
      value: "100",
      label: "2",
    },
    {
      value: "150",
      label: "3",
    },
  ];

  const useStyles = makeStyles((theme) => ({
    previousSummaryClass: {
      [theme.breakpoints.down("md")]: {
        width: "15rem!important",
        marginTop: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "50% !important",
        marginTop: "1rem",
      },
    },
    responsive_width: {
      [theme.breakpoints.down("md")]: {
        width: "19.875rem !important",
        overflowX: "scroll",
      },
    },
    text_width: {
      [theme.breakpoints.down("md")]: {
        width: "13rem !important",
      },
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  const closeShowModelGenerate = () => {
    setShowModelGenerate(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleDateGenerateChange = (e) => {
    setGenerateDateValue(e.target.value);
    setGenerateRowId(e.target.value);
  };

  const handleShowPreviousOutcomes = () => {
    setShowModelGenerate(!showModelGenerate);
    dispatch(getMentorRatingDate(clientName?.excoCustomerUser?.id));
  };

  const handleGenerateDateSubmit = (e) => {
    e.preventDefault();
    let body = {
      clientProgressReviewId: generateRowId,
    };

    dispatch(getMentorRatingDateById(body)).then((res) => {
      closeShowModelGenerate();
      setGenerateRowCheckId(generateRowId);
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  useEffect(() => {
    setStartDate(
      getClientLatestRatingData.length > 0 &&
        getClientLatestRatingData[0].checkin.ratingFromDate
        ? format(
          new Date(
            getClientLatestRatingData.length > 0 &&
            getClientLatestRatingData[0].checkin.ratingFromDate
          ),
          "yyyy-MM-dd"
        )
        : ""
    );
    setEndDate(
      getClientLatestRatingData.length > 0 &&
        getClientLatestRatingData[0].checkin.ratingFromDate
        ? format(
          new Date(
            getClientLatestRatingData.length > 0 &&
            getClientLatestRatingData[0].checkin.ratingToDate
          ),
          "yyyy-MM-dd"
        )
        : ""
    );
  }, [getClientLatestRatingData]);

  useEffect(() => {
    setStartDate(
      getClientLatestRatingData.length > 0 &&
        getClientLatestRatingData[0].checkin.ratingFromDate
        ? format(
          new Date(
            getClientLatestRatingData.length > 0 &&
            getClientLatestRatingData[0].checkin.ratingFromDate
          ),
          "yyyy-MM-dd"
        )
        : ""
    );
    setEndDate(
      getClientLatestRatingData.length > 0 &&
        getClientLatestRatingData[0].checkin.ratingFromDate
        ? format(
          new Date(
            getClientLatestRatingData.length > 0 &&
            getClientLatestRatingData[0].checkin.ratingToDate
          ),
          "yyyy-MM-dd"
        )
        : ""
    );
  }, [getClientLatestRatingData]);

  const dataThree = {
    rows:
      getClientLatestRatingData?.length > 0 &&
      getClientLatestRatingData[0]?.notes,
    columns: [
      {
        field: "engagementNote",
        headerName: "Engagement Notes",
        width: 480,
        // renderCell: renderCellExpand,
      },
    ],
  };

  const dataTwo = {
    rows:
      getClientLatestRatingData?.length > 0 &&
      getClientLatestRatingData[0]?.cfa,
    columns: [
      {
        field: "progressDescription",
        headerName: "Description",
        width: 480,
        // renderCell: renderCellExpand,
      },
    ],
  };

  useEffect(() => {
    setDateObj({
      lastmeeting:
        getClientLatestRatingData?.length > 0 &&
          getClientLatestRatingData[0].checkin.dateOfLastMeeting
          ? format(
            new Date(
              getClientLatestRatingData[0]?.checkin?.dateOfLastMeeting
            ),
            "yyyy-MM-dd"
          )
          : "",
      managerTouchPoint:
        getClientLatestRatingData?.length > 0 &&
          getClientLatestRatingData[0]?.checkin.dateOfLastManagerTouchPoint
          ? format(
            new Date(
              getClientLatestRatingData[0]?.checkin?.dateOfLastManagerTouchPoint
            ),
            "yyyy-MM-dd"
          )
          : "",
      hrPartnerTouchPoint:
        getClientLatestRatingData?.length > 0 &&
          getClientLatestRatingData[0]?.checkin.dateOfLastHrTouchPoint
          ? format(
            new Date(
              getClientLatestRatingData[0]?.checkin?.dateOfLastHrTouchPoint
            ),
            "yyyy-MM-dd"
          )
          : "",
    });
  }, [getClientLatestRatingData]);

  useEffect(() => {
    let result =
      getClientLatestRatingData.length > 0 &&
      getClientLatestRatingData[0]?.checkin?.engagementCheckInRating &&
      getClientLatestRatingData[0]?.checkin?.engagementCheckInRating;
    console.log(result);
    if (result === 1) {
      setCheckinRating(150);
      setCheckinValue("Red");
    } else if (result === 2) {
      setCheckinRating(450);
      setCheckinValue("Yellow");
    } else if (result === 3) {
      setCheckinRating(750);
      setCheckinValue("Green");
    } else {
      setCheckinRating(null);
      setCheckinValue("");
    }
  }, [getClientLatestRatingData]);

  ChartJS.register(ArcElement, Legend);

  const dataReports = {
    datasets: [
      {
        data: [300, 300, 300],
        needleValue: Number(checkInRating),
        // Change This for Meter
        backgroundColor: ["red", "#FFCE56", "#71B278"],
        hoverBackgroundColor: ["red", "#FFCE56", "#71B278"],
      },
    ],
    options: {
      layout: {},
      rotation: -95,
      circumference: 60 * Math.PI,
      legend: {
        display: false,
      },
      cutoutPercentage: 70,
    },
  };

  ChartJS.register({
    id: "doughnut",
    afterDraw: (chart, args, opts) => {
      var needleValue = chart.config.data.datasets[0].needleValue;
      var dataTotal = chart.config.data.datasets[0].data.reduce(
        (a, b) => a + b,
        0
      );
      var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
      var ctx = chart.ctx;
      var cw = chart.canvas.offsetWidth;
      var ch = chart.canvas.offsetHeight - 200;
      var cx = cw / 2;
      var cy = ch + 95;
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(ch - 5, 0);
      ctx.lineTo(0, 3);
      ctx.fillStyle = "rgb(0, 0, 0)";
      ctx.fill();
      ctx.rotate(-angle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, Math.PI * 2);
      ctx.fill();
    },
  });

  useEffect(() => {
    clientName &&
      dispatch(
        getMentorLatestRatingByClientId(clientName?.excoCustomerUser?.id)
      );
    dispatch(getToggleAdminData(clientName?.excoCustomerUser?.id))

    setTimeout(() => {
      dispatch(resetErrMessage());
    }, 2000);
  }, [clientName]);

  useEffect(() => {
    dispatch(emptyingArray());
    dispatch(getAllRatingClients());
  }, []);

  console.log(toggleAdminData)

  return (
    <Layout>

      <Grid container>
        <Grid item xs={11} sm={11} md={3} justifyContent="center" alignContent="center">
          <PageHeader variant="pageSecHeader">
            {" "}
            Impact Updates for
          </PageHeader>

          {/* <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              fontFamily: "dinBold,sans-serif",
            }}
            gutterBottom
            variant="h9"
            component="div"

          >
           
          </Typography> */}
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <MuiAutocomplete
            name="clientName"
            className={classes.text_width}
            options={getAllClientData}
            setValue={(name, newValue) => setClientName(newValue)}
            value={clientName}
            optionValue="firstName"
            optionSecValue="lastName"
            sx={{ margin: 0, width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4.5}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ marginLeft: "1rem" }}
              type="date"
              name="startDate"
              id="demo-simple-select"
              required={true}
              value={startDate}
              defaultValue=""
              disabled={true}
            />
            <Typography
              sx={{ fontSize: "1rem", marginLeft: "0.5rem" }}
              gutterBottom
              variant="h9"
              component="div"
            >
              To
            </Typography>
            <TextField
              sx={{ marginLeft: "0.5rem" }}
              type="date"
              name="startDate"
              value={endDate}
              id="demo-simple-select"
              required={true}
              disabled={true}
            />
          </Stack>
        </Grid>

        <Grid item xs={5} sm={5} md={2.5}>
          {clientName &&
            getClientLatestRatingData.length > 0 &&
            getClientLatestRatingData[0].ratings.length > 0 && (
              <FormSubmitButton
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  marginTop: "0.5rem",
                  marginLeft: "1rem",
                }}
                onClick={handleShowPreviousOutcomes}
              >
                See Previous Updates
              </FormSubmitButton>
            )}
        </Grid>
      </Grid>

      {clientName && (
        <Grid container spacing={2}>
          <Grid sx={{ width: "100%" }} item xs={12} sm={12} md={6}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",

                // alignItems: "center",
                marginTop: "2rem",
                fontFamily: "arial",
              }}
            >
              <PageHeader variant="pageSecHeader">
                {" "}
                Level Of Engagement
              </PageHeader>

              <Stack sx={{ width: "10rem", marginLeft: "1rem" }}>
                <FormControl size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled
                    // disabled={generateTwoRowId ? "disabled" : null}
                    // onChange={handleCheckinChange}
                    value={checkinValue}
                    size="large"
                  >
                    <MenuItem key={1} value="Red">
                      {" "}
                      Red{" "}
                    </MenuItem>
                    <MenuItem key={2} value="Yellow">
                      {" "}
                      Yellow{" "}
                    </MenuItem>
                    <MenuItem key={3} value="Green">
                      {" "}
                      Green{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>

            <Stack>
              <SpeedoMeter
                title="Cycle Time"
                leftColor="#90EE90"
                middleColor="#E6E600"
                rightColor="#FF7F7F"
                hoverLeftColor="#33FF33"
                hoverMiddleColor="#FFFF19"
                hoverRightColor="red"
                needleValue={checkInRating}
                needlePosition={78}
                needleHeight={30}
                fixHeight={180}
                sx={{
                  width: "350px",
                  height: "330px",
                  marginTop: "-4.5rem",
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <PageHeader variant="pageSecHeader">
                {" "}
                Engagement Notes
              </PageHeader>
            </Stack>

            <Stack >
              <MuiDataGridForSummary data={dataThree} />
            </Stack>
          </Grid>
        </Grid>
      )}

      {clientName && (
        <Grid container spacing={2}>
          {toggleAdminData?.length > 0 &&
            toggleAdminData[0]?.dateOfLastMeeting && (
              <Grid item xs={12} sm={12} md={4}>
                <span
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Date of Last Meeting:
                </span>
                <TextField
                  sx={{ width: "100%" }}
                  type="date"
                  name="lastmeeting"
                  id="outcome-start-date"
                  value={dateObj?.lastmeeting}
                  disabled
                />
              </Grid>
            )}

          {toggleAdminData?.length > 0 &&
            toggleAdminData[0]?.dateOfLastManagerTouchpoint && (
              <Grid item xs={12} sm={12} md={4}>
                <span
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: "bold",
                    display: "block",
                    whiteSpace: "nowrap"
                  }}
                >
                  Date of Last Manager Touch Point:
                </span>
                <TextField
                  sx={{ width: "100%" }}
                  type="date"
                  name="managerTouchPoint"
                  id="outcome-start-date"
                  value={dateObj?.managerTouchPoint}
                  disabled
                />
              </Grid>
            )}

          {toggleAdminData?.length > 0 &&
            toggleAdminData[0]?.dateOfLastHrTouchpoint && (
              <Grid item xs={12} sm={12} md={4}>
                <span
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Date of Last HR Touch Point:
                </span>
                <TextField
                  sx={{ width: "100%" }}
                  type="date"
                  name="hrPartnerTouchPoint"
                  id="outcome-start-date"
                  value={dateObj?.hrPartnerTouchPoint}
                  disabled
                />
              </Grid>
            )}
        </Grid>
      )}

      <ModalPopUp
        open={showModelGenerate}
        handleClose={closeShowModelGenerate}
        closeModel={closeShowModelGenerate}
      >
        <Box
          sx={{ ...style, width: 700 }}
          component="form"
          className={classes.responsive_width}
          noValidate
        >
          <ModalCloseBtn close={closeShowModelGenerate} />

          <div className={classes.previousSummaryClass}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Date</InputLabel>
              <Select
                type="select"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="generateDateValue"
                value={generateDateValue}
                label="Geography"
                onChange={handleDateGenerateChange}
              >
                {getRatingDateData?.length > 0 &&
                  getRatingDateData.map((itemValue) => {
                    console.log(itemValue.id);
                    return (
                      <MenuItem key={itemValue.id} value={itemValue.id}>
                        {format(
                          new Date(itemValue.ratingFromDate),
                          "MM-dd-yyyy"
                        )}{" "}
                        to{" "}
                        {format(
                          new Date(itemValue.ratingToDate),
                          "MM-dd-yyyy"
                        )}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>

          <Stack sx={{ marginTop: "1rem" }}>
            <FormSubmitButton
              type="submit"
              variant="contained"
              onClick={handleGenerateDateSubmit}
            >
              Submit
            </FormSubmitButton>
          </Stack>
        </Box>
      </ModalPopUp>

      {clientName &&
        getClientLatestRatingData?.length > 0 &&
        getClientLatestRatingData[0]?.ratings.length > 0 && (
          <Stack
            sx={{
              mt: "2rem",
            }}
          >
            <Grid container>
              <Grid md={6}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: "arial",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                    gutterBottom
                    variant="h9"
                    component="div"
                  >
                    Engagement Key
                  </Typography>
                  <Typography
                    sx={{ fontSize: "0.90rem" }}
                    gutterBottom
                    variant="h9"
                    component="div"
                  >
                    <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Green
                    </span>{" "}
                    Highly engaged with coach/set meetings regulary/leaning in.
                  </Typography>
                  <Typography
                    sx={{ fontSize: "0.90rem" }}
                    gutterBottom
                    variant="h9"
                    component="div"
                  >
                    <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Yellow
                    </span>{" "}
                    Engaged when meeting but challenging to schedule with.
                  </Typography>
                  <Typography
                    sx={{ fontSize: "0.90rem" }}
                    gutterBottom
                    variant="h9"
                    component="div"
                  >
                    <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Red
                    </span>{" "}
                    Goes long period without meeting with coach/not engaged.
                  </Typography>
                </Stack>
              </Grid>

              {toggleAdminData?.length > 0 &&
                toggleAdminData[0]?.progressRatingScale && (<Grid container item md={6}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: "bold",
                        fontWeight: "bold",
                      }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      Progress Scale Rating
                    </Typography>
                  </Grid>

                  <Grid item md={5} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        0
                      </span>{" "}
                      Baseline - No progress
                    </Typography>
                  </Grid>
                  <Grid item md={7} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        2
                      </span>{" "}
                      Significant Improvement
                    </Typography>
                  </Grid>

                  <Grid item md={5} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        1
                      </span>{" "}
                      Work in Progress
                    </Typography>
                  </Grid>

                  <Grid item md={7} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        3
                      </span>{" "}
                      Completed or Maintaining Successful Execution
                    </Typography>
                  </Grid>
                </Grid>)}
            </Grid>
          </Stack>
        )}

      {clientName && getClientLatestRatingData?.length > 0 && (
        <Stack sx={{ mt: "1rem" }}>
          <Grid container>
            <Grid md="6">
              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontSize: "1.125rem",
                    fontWeight: "bold",
                    marginTop: "0.5rem",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  Focus Areas
                </Typography>
              </Stack>
            </Grid>

            <Grid md="6">
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.125rem",
                    fontWeight: "bold",
                    marginTop: "0.5rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  Progress Updates
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}
      {clientName &&
        getClientLatestRatingData?.length > 0 &&
        getClientLatestRatingData[0]?.ratings.length > 0 &&
        getClientLatestRatingData[0].ratings.map((data) => {
          return (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "arial",
                marginTop: "2rem",
              }}
            >
              <Grid container>
                <Grid item xs={9} sm={9} md={3}>
                  <Typography
                    sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                    variant="h6"
                    gutterBottom
                    component="div"
                  >
                    {data?.otherDevelopmentAreaCoachingFocus}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <Stack
                    sx={{

                      display: "flex",
                      width: "100%",

                      flexDirection: "column",
                      fontFamily: "arial",
                    }}
                  >
                    <TextField
                      sx={{ width: "100%", height: "5rem" }}
                      hiddenLabel
                      variant="filled"
                      value={data.comment}

                    // onChange={(e)=>setRatingComments(e.target.value)}
                    />

                    {toggleAdminData?.length > 0 &&
                      toggleAdminData[0]?.progressRatingScale && (<div
                        style={{
                          width: "100%",
                          "& .super-app-theme": {
                            "&:nth-of-type(odd)": {
                              bgcolor: (theme) => theme.palette.primary.light,
                            },
                          },
                        }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            paddingBottom: "1rem",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "1rem", color: "blue" }}
                            gutterBottom
                            component="div"
                          >
                            Rating
                          </Typography>
                          <Slider
                            disabled
                            sx={{
                              marginLeft: "1rem",
                            }}
                            aria-label="Custom marks"
                            value={data?.rating}
                            step={50}
                            max={150}
                            marks={marks}
                          />
                        </Stack>
                      </div>)}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          );
        })}

      {clientName && (
        <Grid item xs={12} sm={12} md={12}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <PageHeader variant="pageSecHeader">
              Coaching Summary – Additional Commentary
            </PageHeader>

            {/* <Stack sx={{ marginLeft: "1rem" }}>
                    <EyeInfo eyeText="Please provide commentary on why you rated your client at the level of engagement noted. For example – Jane is highly engaged in our mentoring relationship. We have a regular cadence of meetings scheduled…" />
                  </Stack> */}
          </Stack>

          <Stack sx={{}}>
            <MuiDataGridForSummary viewAdd={true} data={dataTwo} />
          </Stack>
        </Grid>
      )}

      {getClientLatestRatingData.length > 0 &&
        getClientLatestRatingData[0].ratings.length > 0 && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "arial",
              marginTop: "1rem",
              paddingBottom: "1rem",
            }}
          ></Stack>
        )}

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Assesment;
