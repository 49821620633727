import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// routes
import {
  CREATE_MICROSITE,
  CURD_MICROSITE,
  DELETE_MICROSITE,
  GET_MICROSITE,
  GET_ALL_CUSTOMER_API,
  STOP_MICROSITE,
  START_MICROSITE,
  TOGGLE_MICROSITE,
  TOGGLE_ACCOUNT
} from "../../utils/routes";

// ---------------------------------------------------------------------------------

export const createToggleAccount = createAsyncThunk(
  "ToggleAccount/createMicrosite",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(TOGGLE_MICROSITE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getToggleAccount = createAsyncThunk(
  "ToggleAccount/getAccountToogle",
  async (data) => {
    return axios.get(`${TOGGLE_ACCOUNT}/${data}`, { withCredentials: true });
  }
);

export const stopToggleMicrosite = createAsyncThunk(
  "ToggleAccount/stopAccountToogle",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(STOP_MICROSITE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const startToggleMicrosite = createAsyncThunk(
  "ToggleAccount/startAccountToogle",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(START_MICROSITE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerName = createAsyncThunk(
  "ToggleAccount/getCustomerName",
  async (data) => {
    return axios.get(GET_ALL_CUSTOMER_API, data, { withCredentials: true });
  }
);

export const updateToggleAccount = createAsyncThunk(
  "ToggleAccount/updateAccountToogle",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(TOGGLE_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteToggleMicrosite = createAsyncThunk(
  "ToggleAccount/deleteAccountToogle",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(TOGGLE_MICROSITE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ---------------------------------------------------------------------------------

const accountToggleSlice = createSlice({
  name: "ToggleAccount",
  initialState: {
    loading: false,
    micrositesData: [],
    customersNameData: [],
    toggleAccountData :[],
    selectedData: {},
    message: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getToggleAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getToggleAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.toggleAccountData = action?.payload?.data;
    },
    [getToggleAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateToggleAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [updateToggleAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateToggleAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  accountToggleSlice.actions;

export default accountToggleSlice.reducer;
