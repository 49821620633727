import React, { useEffect } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// material ui 
import {Grid, Box, FormGroup, InputLabel,} from '@mui/material'
// components
import { ModalPopUp, FormSubmitButton, ModalCloseBtn, FormTextField as MyTextInput, Notifier, ErrorNotifier } from '../../../../components'
// redux
import {updateL1Menu, getL1Menu, resetMessage, resetErrMessage} from '../../../../features/platform/app_setup/menu_management/l1MenuSlice'

// ----------------------------------------------------------------------------------
const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

// ------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
    l1MenuName: Yup.string().required("L1 Menu Name is required"),
    l1MenuGroupId: Yup.string().required("L1 Menu Group Id is required"),
    l1PageLink: Yup.string().required("L1 Page Link is required"),
    l1MenuIcon: Yup.string().required("L1 Menu Icon is required"),
  });

//   -------------------------------------------------------------------------------------------

const EditL1Menu = ({showEdit, setShowEdit}) => {

  const dispatch = useDispatch();
const {message, errMessage, l1SelectedData} = useSelector((state) => ({
    ...state.l1Menus
}))

    const handleFile = (e) => {
        formik.setFieldValue("l1MenuIcon", e.target.files[0]);
      };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          l1MenuName: l1SelectedData?.l1MenuName,
          l1MenuGroupId: l1SelectedData?.l1MenuGroupId,
          l1PageLink: l1SelectedData?.l1PageLink,
          l1MenuIcon: l1SelectedData?.l1MenuIcon,
        },
    
        validationSchema: validationSchema,
        onSubmit: (values) => {
          let formdata = new FormData();
          formdata.append('id', l1SelectedData?.id)
          formdata.append("l1MenuName", values.l1MenuName);
          formdata.append("l1MenuGroupId", values.l1MenuGroupId.toString());
          formdata.append("l1PageLink", values.l1PageLink);
          formdata.append("l1MenuIcon", values.l1MenuIcon);
          dispatch(updateL1Menu(formdata)).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
                dispatch(getL1Menu());
                setShowEdit(false);

              }, 2000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
              }, 3000);
            }
          });
        },
      });

  return (
    <>
    <ModalPopUp
    open={showEdit}
  >
    <Box
      sx={{ ...style, width: 900 }}
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <ModalCloseBtn 
      close={() => {
        setShowEdit(false)
    }} 
      />
      <div style={{ width: "50rem" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} sx={{padding: '4rem'}}>
          <MyTextInput
                  label="L1 Menu Name"
                  type="text"
                  name="l1MenuName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l1MenuName"
                  value={formik.values.l1MenuName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l1MenuName &&
                    Boolean(formik.errors.l1MenuName)
                  }
                  helperText={
                    formik.touched.l1MenuName && formik.errors.l1MenuName
                  }
                />
          <MyTextInput
                  label="L1 Menu Group Id"
                  type="text"
                  name="l1MenuGroupId"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l1MenuGroupId"
                  value={formik.values.l1MenuGroupId}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l1MenuGroupId &&
                    Boolean(formik.errors.l1MenuGroupId)
                  }
                  helperText={
                    formik.touched.l1MenuGroupId && formik.errors.l1MenuGroupId
                  }
                />
          <MyTextInput
                  label="L1 Page Link"
                  type="text"
                  name="l1PageLink"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l1PageLink"
                  value={formik.values.l1PageLink}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l1PageLink &&
                    Boolean(formik.errors.l1PageLink)
                  }
                  helperText={
                    formik.touched.l1PageLink && formik.errors.l1PageLink
                  }
                />
          <Grid container spacing={1} sx={{ marginTop: "0.5rem" }}>
              <Grid item xs={6} sm={6} md={8}>
                <FormGroup className="file-display mr-1">
                  <InputLabel className="bold label-sm">Icon *</InputLabel>
                  <MyTextInput
                    disabled
                    type="text"
                    name="l1MenuIcon"
                    value={formik?.values?.l1MenuIcon?.name || ""}
                    className="form-control-sm "
                    required={true}
                    size="small"
                    error={
                      formik.touched.l1MenuIcon &&
                      Boolean(formik.errors.l1MenuIcon)
                    }
                    helperText={
                      formik.touched.l1MenuIcon &&
                      formik.errors.l1MenuIcon
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <MyTextInput
                      type="file"
                      name="l1MenuIcon"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={handleFile}
                      required={true}
                      size="small"
                      error={
                        formik.touched.l1MenuIcon &&
                        Boolean(formik.errors.l1MenuIcon)
                      }
                      helperText={
                        formik.touched.l1MenuIcon &&
                        formik.errors.l1MenuIcon
                      }
                    />
                  </label>
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <FormSubmitButton type="submit" variant="contained">
        Submit
      </FormSubmitButton>
    </Box>
  </ModalPopUp>
  {message && <Notifier message={message} />}
  {errMessage && <ErrorNotifier message={errMessage} />}
  </>
  )
}

export default EditL1Menu