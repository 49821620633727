import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import axios from "axios"
import { IS_LOGGED_IN } from "./utils/routes";

// fonts
import "./fonts/din/DIN-Bold.ttf";
// css
import "./index.css";
// material ui
import { ThemeProvider } from "@mui/material";
// components
import App from "./App";
import { store } from "./app/store";
import { theme } from "./theme/Theme";

// ------------------------------------------------------------
axios.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  if (error.response.status === 403) {
    // const checkStatusUrl = 'https://dev.piedap.com/piedapiam/v1/api/authentication/is-piedap-user-logged-in';

    axios.get(IS_LOGGED_IN , {withCredentials:true}).then(response => {

      console.log("response " , response)
      if (response.data === false) {
        window.location.href = '/';
      }
    }).catch(error => {
      console.error('Error checking user login status:', error);
    });
  }
  return Promise.reject(error);
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
