import React, { useState } from "react";
// material ui
import { makeStyles } from "@mui/styles";
import { DataGrid, GridRow, GridToolbarContainer } from "@mui/x-data-grid";
import { Button } from "@mui/material";
// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  dataGridStyle: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  hidePagination: {
    "& .MuiDataGrid-footerContainer": {
      display: "none",
    },
  },
}));

// ----------------------------------------------------------------------------------

const CustomRow = (props) => {
  const classes = useStyles();
  const { className, index, ...other } = props;

  return <GridRow index={index} className={classes.dataGridStyle} {...other} />;
};

//   ------------------------------------------------------------------------------------

const GridToolbarAddButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<AddCircleIcon />}
      onClick={() => props.handleClick()}
    >
      New
    </Button>
  );
};

// ------------------------------------------------------------------------------------------

const GridToolbarEditButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<EditIcon />}
      onClick={() => props.handleClick()}
    >
      Edit
    </Button>
  );
};

// ---------------------------------------------------------------------------------------------

const GridToolbarDeleteButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<DeleteIcon />}
      onClick={() => props.handleClick()}
    >
      Delete
    </Button>
  );
};

// ----------------------------------------------------------------------------------------------

export default function MuiDataGridForSummary(props, { handleClick }) {
  const [setSelectedRow] = useState([]);
  const classes = useStyles();

  const CustomToolbar = () => {
    return <GridToolbarContainer></GridToolbarContainer>;
  };

  return (
    <div
      style={{
        width: "100%",
        "&.superAppTheme": {
          "&:nth-of-type(odd)": {
            bgcolor: (theme) => theme.palette.primary.light,
          },
        },
      }}
    >
      <DataGrid
        autoHeight
        {...props.data}
        components={{
          Toolbar: CustomToolbar,
          Row: CustomRow,
        }}
        onSelectionModelChange={(ids) => {
          const selectedRowData =
            props.data.rows.length > 0 &&
            props.data.rows.filter(
              (row) => ids.length > 0 && row.id === ids[0]
            );
          props.select(selectedRowData);
          setSelectedRow(selectedRowData);
        }}
        className={props?.hidePagination && classes.hidePagination}
      />
    </div>
  );
}
