import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Stack, Box } from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import AddExecutiveSponsor from "./AddExecutiveSponser";
import UpdateExecutiveSponsor from "./UpdateExecutiveSponser";
import { MuiDataGrid, GridCellExpand, Notifier, ErrorNotifier } from "../../../components";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
// redux
import {
  getExecutiveSponsar,
  deleteExecutiveSponsar,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/executiveSponsarSlice";
import Layout from "../../../layout/Layout";

// ----------------------------------------------------------------------------------

const LevelOfEngagement = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const {  executiveSponsarData, selectedData,message,errMessage } = useSelector((state) => ({
    ...state.executiveSponsors,
  }));

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  

  const data = {
    rows: executiveSponsarData,
    columns: [
      {
        field: "sponsorName",
        headerName: "Executive Sponsor",
        width: 250,
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 850,
        renderCell: renderCellExpand,
      },
    ],
  };

  const showAddPage = () => {
    setShowAdd(!showAdd);
  };

  const showEditPage = () => {
    setShowEdit(true);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDelete(),
    });

  useEffect(() => {
    dispatch(getExecutiveSponsar());
  }, []);

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDelete = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteExecutiveSponsar(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getExecutiveSponsar());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  console.log( executiveSponsarData)

  return (
    <Layout>
      {!showAdd && !showEdit && (
        <>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <PageHeader variant="pageHeader">Executive Sponsor</PageHeader>
            </Box>
            <Link to="/exco/config" state={{ l3Menu: location?.state?.l3Menu }}>
              <CancelIcon color="red" />
            </Link>
          </Stack>
          <MuiDataGrid
            setShowAdd={
              // location?.state?.permissions?.find(
              //   (e) =>
              //     e.applicationService?.name?.split(".")[
              //       e?.applicationService?.name?.split(".")?.length - 1
              //     ] === "create"
              // ) && 
              showAddPage
            }
            setShowEdit={
              // location?.state?.permissions?.find(
              //   (e) =>
              //     e.applicationService?.name?.split(".")[
              //       e?.applicationService?.name?.split(".")?.length - 1
              //     ] === "update"
              // ) && 
              showEditPage
            }
            setShowDelete={
              // location?.state?.permissions?.find(
              //   (e) =>
              //     e.applicationService?.name?.split(".")[
              //       e?.applicationService?.name?.split(".")?.length - 1
              //     ] === "delete"
              // ) && 
              showDelete
            }
            toolbarColumn={true}
            toolbarFilter={true}
            toolbarDensity={true}
            toolbarExport={true}
            data={data}
            select={(data) => dispatch(rowSelect(data))}
          />
        </>
      )}
      {showAdd && (
        <AddExecutiveSponsor
          showAdd={showAdd}
          close={() => setShowAdd(false)}
        />
      )}
      {showEdit && (
        <UpdateExecutiveSponsor
          showEdit={showEdit}
          close={() => setShowEdit(false)}
        />
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
        {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Layout>
  );
};

export default LevelOfEngagement;
