import * as React from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    marginTop: "1rem !important",
  },
}));

function NestedModal(props) {

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        {props.children}
      </Modal>
    </div>
  );
}

export default NestedModal;
