import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_L3_MENU_PERMISSIONS, GET_L3_MENU_PERMISSIONS_ROLE, CURD_L3_SERVICE_PERMISSIONS, GET_L3_SERVICE_PERMISSIONS_ROLE } from "../../../../utils/routes";

// ---------------------------------------------------------------------

export const createL3MenuPermission = createAsyncThunk(
  "l3AccessPermission/createL3MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_L3_MENU_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getL3MenuPermission = createAsyncThunk("l3AccessPermission/getL3MenuPermission", async () => {
  return axios.get(CURD_L3_MENU_PERMISSIONS, { withCredentials: true });
});

export const getL3MenuPermissionRole = createAsyncThunk("l3AccessPermission/getL3MenuPermissionRole", async (id) => {
  return axios.get(`${GET_L3_MENU_PERMISSIONS_ROLE}/${id}`, { withCredentials: true });
});

export const updateL3MenuPermission = createAsyncThunk(
  "l3AccessPermission/updateL3MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_L3_MENU_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteL3MenuPermission = createAsyncThunk(
  "l3AccessPermission/deleteL3MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_L3_MENU_PERMISSIONS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createL3ServicePermission = createAsyncThunk(
  "l3AccessPermission/createL3ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_L3_SERVICE_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getL3ServicePermission = createAsyncThunk("l3AccessPermission/getL3ServicePermission", async () => {
  return axios.get(CURD_L3_SERVICE_PERMISSIONS, { withCredentials: true });
});

export const getL3ServicePermissionRole = createAsyncThunk("l3AccessPermission/getL3ServicePermissionRole", async (id) => {
  return axios.get(`${GET_L3_SERVICE_PERMISSIONS_ROLE}/${id}`, { withCredentials: true });
});

export const updateL3ServicePermission = createAsyncThunk(
  "l3AccessPermission/updateL3ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_L3_SERVICE_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteL3ServicePermission = createAsyncThunk(
  "l3AccessPermission/deleteL3ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_L3_SERVICE_PERMISSIONS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const l3AccessPermissionSlice = createSlice({
  name: "l3AccessPermission",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    l3MenuPermissionData: [],
    l3MenuSelectedData: {},
    l3ServicePermissionData: [],
    l3ServiceSelectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    l3MenuRowSelect: (state, action) => {
      state.l3MenuSelectedData = action?.payload.length > 0 && action?.payload[0];
    },
    l3ServiceRowSelect: (state, action) => {
      state.l3ServiceSelectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createL3MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [createL3MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createL3MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getL3MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [getL3MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.l3MenuPermissionData = action?.payload?.data;
    },
    [getL3MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getL3MenuPermissionRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getL3MenuPermissionRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.l3MenuPermissionData = action?.payload?.data;
    },
    [getL3MenuPermissionRole.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateL3MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [updateL3MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateL3MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteL3MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteL3MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteL3MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createL3ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [createL3ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createL3ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getL3ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [getL3ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.l3ServicePermissionData = action?.payload?.data;
    },
    [getL3ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getL3ServicePermissionRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getL3ServicePermissionRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.l3ServicePermissionData = action?.payload?.data;
    },
    [getL3ServicePermissionRole.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateL3ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [updateL3ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateL3ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteL3ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteL3ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteL3ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, l3MenuRowSelect, l3ServiceRowSelect } = l3AccessPermissionSlice.actions;
export default l3AccessPermissionSlice.reducer;
