import React, { useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// material ui
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Grid,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton, Notifier, ErrorNotifier } from "../../components";
import UpdateNomination from "./UpdateNomination";
// redux
import {
  createCustUser,
  createCustNominateUser,
  getCustUser,
  resetMessage,
  resetErrMessage,
} from "../../features/customer/custUserSlice";

//   -----------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  muiList: {
    padding: "0 !important",
  },
  muiListHeadText: {
    width: "5rem",
  },
  muiListDataText: {
    width: "12rem",
   
    wordBreak: "break-word"
  },
  cancel_padding: {
    padding: "0!important",
  },
}));

//   ------------------------------------------------------------

function NominationDetail(props) {
  const [showEdit, setShowEdit] = useState(false);

  const { selectedData } = useSelector((state) => ({
    ...state.nomination,
  }));

  const { message, errMessage } = useSelector((state) => ({
    ...state.custUser,
  }));

  const classes = useStyles();

  const dispatch = useDispatch();

  const location = useLocation();

  const createClient = () => {
    console.log("function working");
    dispatch(
      createCustNominateUser({
        nominationId: selectedData?.id,
        // clientFirstName: selectedData.executiveName.split(" ")[0],
        // clientLastName: selectedData.executiveName.split(" ")[1],
        // geoName: selectedData.geoName,
        // country: selectedData.country,
        // designation: selectedData.designation,
        // contactEmail: selectedData.executiveEmail,
        // dialingCode: selectedData.executiveDialingCode,
        // contactNumber: selectedData.executiveMobileNo.toString(),
        // roles: ["CLIENT"],
        // hrID: selectedData.excoCustomerUser?.id,
        // comments: selectedData?.comment,
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getCustUser());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  return (
    <>
      {!showEdit && (
        <Card sx={{ marginTop: "0.5rem" }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <CardHeader
                title={
                  <PageHeader variant="pageHeader">
                    Nomination Details
                  </PageHeader>
                }
              />
            </Grid>
            <Grid item xs={10} sm={10} md={5}>
              <CardHeader
                action={
                  <>
                    {location?.state?.permissions?.find(
                      (e) =>
                        e.applicationService?.name?.split(".")[
                        e?.applicationService?.name?.split(".")?.length - 1
                        ] === "update"
                    ) && (
                        <FormSubmitButton
                          variant="contained"
                          sx={{ marginRight: "0.5rem" }}
                          onClick={() => setShowEdit(true)}
                        >
                          Edit
                        </FormSubmitButton>
                      )}
                    {location?.state?.permissions?.find(
                      (e) =>
                        e.applicationService?.name?.split(".")[
                        e?.applicationService?.name?.split(".")?.length - 1
                        ] === "createClient"
                    ) &&
                      selectedData?.status === "Ready to Engage" && (
                        <FormSubmitButton
                          variant="contained"
                          sx={{ marginRight: "0.5rem" }}
                          onClick={() => createClient()}
                        >
                          Create Users
                        </FormSubmitButton>
                      )}
                  </>
                }
              />
            </Grid>

            <Grid item xs={2} sm={2} md={2}>
              <IconButton
                className={classes.cancel_padding}
                aria-label="cancel"
                onClick={() => props.close()}
              >
                <CancelIcon color="red" />
              </IconButton>
            </Grid>
          </Grid>

          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12} md={6}>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Account Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.excoCustomerAccount?.accountName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Leader Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {
                      selectedData?.
                        leaderFirstName


                    }
                    {" "}
                    {
                      selectedData?.

                        leaderLastName


                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Leader's Email
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.leaderEmail}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Title/Position
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText
                    style={{ wordWrap: "break-word" }}
                    className={classes.muiListDataText}
                  >
                    {selectedData?.title}
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Level
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.level}
                  </ListItemText>
                </ListItem>
               
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Manager
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                  {selectedData?.managerFirstName
                    }
                    {" "}
                    {selectedData?.managerLastName
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Manager Email
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.managerEmail}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    HR Partner
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText
                    style={{ wordWrap: "break-word" }}
                    className={classes.muiListDataText}
                  >
                    {selectedData?.hrPartnerFirstName
                    }
                    {" "}
                    {selectedData?.hrPartnerLastName
                    }

                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    HR Partner Email
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText sx={{}} className={classes.muiListDataText}>
                    {selectedData?.hrPartnerEmail
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Assistant
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.assistantName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Assistant Email
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.assistantEmail}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Geography Name
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.geoName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Country
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.country}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    City/Location
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.location}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText sx={{ wordBreak: "break-word" }} className={classes.muiListHeadText}>
                    Reason for Coaching/Mentoring
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.reasonForCoaching}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Comments
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.additionalComments}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Status
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.status}
                  </ListItemText>
                </ListItem>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {showEdit && (
        <UpdateNomination
          showEdit={showEdit}
          close={() => props.close()}
          account={props.account}
        />
      )}
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  );
}

export default NominationDetail;
