import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// routes
import { GET_ALL_NOMINATION } from "../../utils/routes";

// ---------------------------------------------------------------------------------

export const getNomination = createAsyncThunk(
  "nomination/getNomination",
  async () => {
    return axios.get(GET_ALL_NOMINATION, { withCredentials: true });
  }
);

export const getNominationByCust = createAsyncThunk(
  "nomination/getNominationByCust",
  async (id) => {
    let body = {
      excoCustomerAccountId : id
    }
    return axios.post(`${GET_ALL_NOMINATION}/details`,body, { withCredentials: true });
  }
);

export const createNomination = createAsyncThunk(
  "nomination/createNomination",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_ALL_NOMINATION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateNomination = createAsyncThunk(
  "nomination/updateNomination",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ALL_NOMINATION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ---------------------------------------------------------------------------------

const nominationSlice = createSlice({
  name: "nomination",
  initialState: {
    loading: false,
    nominationData: [],
    selectedData: {},
    message: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getNomination.pending]: (state, action) => {
      state.loading = true;
    },
    [getNomination.fulfilled]: (state, action) => {
      state.loading = false;
      state.nominationData = action?.payload?.data;
    },
    [getNomination.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.message;
    },
    [getNominationByCust.pending]: (state, action) => {
      state.loading = true;
    },
    [getNominationByCust.fulfilled]: (state, action) => {
      state.loading = false;
      state.nominationData = action?.payload?.data;
    },
    [getNominationByCust.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.message;
    },
    [createNomination.pending]: (state, action) => {
      state.loading = true;
    },
    [createNomination.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createNomination.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateNomination.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNomination.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateNomination.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } = nominationSlice.actions;

export default nominationSlice.reducer;
