import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_CUST_DEV_AREAS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createCustDevAreas = createAsyncThunk(
  "account/createCustDevAreas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_CUST_DEV_AREAS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustDevAreas = createAsyncThunk(
  "account/getCustDevAreas",
  async () => {
    return axios.get(CURD_CUST_DEV_AREAS, { withCredentials: true });
  }
);

export const updateCustDevAreas = createAsyncThunk(
  "account/updateCustDevAreas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_CUST_DEV_AREAS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustDevAreas = createAsyncThunk(
  "account/deleteCustDevAreas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_CUST_DEV_AREAS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const custDevAreasSlice = createSlice({
  name: "custDevAreas",
  initialState: {
    loading: false,
    messageArea: "",
    errMessageArea: "",
    custDevAreasData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.messageArea = "";
    },
    resetErrMessage: (state) => {
      state.errMessageArea = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createCustDevAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [createCustDevAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.messageArea = action?.payload?.data?.message;
    },
    [createCustDevAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessageArea = action?.payload?.message;
    },
    [getCustDevAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustDevAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.custDevAreasData = action?.payload?.data;
    },
    [getCustDevAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessageArea = action?.payload?.data?.message;
    },
    [updateCustDevAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCustDevAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.messageArea = action?.payload?.data?.message;
    },
    [updateCustDevAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessageArea = action?.payload?.message;
    },
    [deleteCustDevAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCustDevAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.messageArea = action?.payload?.data?.message;
    },
    [deleteCustDevAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessageArea = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  custDevAreasSlice.actions;
export default custDevAreasSlice.reducer;
