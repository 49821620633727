import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ACCOUNT } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAccount = createAsyncThunk(
  "account/createAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAccount = createAsyncThunk("account/getAccount", async () => {
  return axios.get(CURD_ACCOUNT, { withCredentials: true });
});

export const getCustAccount = createAsyncThunk(
  "account/getCustAccount",
  async (id) => {
    return axios.get(`${CURD_ACCOUNT}/${id}}`, {
      withCredentials: true,
    });
  }
);

export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "account/deleteAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ACCOUNT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    accountData: [],
    custAccountData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [createAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.accountData = action?.payload?.data;
    },
    [getAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getCustAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.custAccountData = action?.payload?.data;
    },
    [getCustAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  accountSlice.actions;
export default accountSlice.reducer;
