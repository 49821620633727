import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { MyTextInput } from "@excoleadershipui/pionweblib";
// material ui
import { Box } from "@mui/material";
// components
import {
  ModalPopUp,
  FormSubmitButton,
  ModalCloseBtn,
  Notifier,
  ErrorNotifier,
} from "../../../components";
// redux
import {
  updateOppGoals,
  getOppGoals,
  resetMessage,
  resetErrMessage,
} from "../../../features/exec/opportunity/oppGoalsSlice";

// ------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  goal: Yup.string().required("Goal is required"),
  date: Yup.date().required("Date is required"),
  status: Yup.string().required("Status is required"),
  comments: Yup.string(),
});

// ---------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ----------------------------------------------------------------------------------------

const UpdateOppGoals = (props) => {
  const dispatch = useDispatch();
  const { selectedData, message, errMessage } =
    useSelector((state) => ({ ...state.oppGoals }));

  const formik = useFormik({
    initialValues: {
      goal: selectedData?.opportunityGoal,
      date: selectedData?.opportunityDate,
      status: selectedData?.opportunityStatus,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateOppGoals({
          id: selectedData?.id,
          opportunityDetailId: props?.oppId,
          opportunityGoal: values.goal,
          opportunityDate: values.date,
          opportunityStatus: values.status,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.closeModel();
            dispatch(getOppGoals());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <>
      <ModalPopUp open={props.showEdit} closeModel={props.closeModel}>
        <Box
          sx={{ ...style, width: 700 }}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={props.closeModel} />
          <div style={{ width: "25rem" }}>
            <MyTextInput
              label="Goal"
              type="text"
              name="goal"
              required={true}
              fullWidth={true}
              margin="normal"
              id="goal"
              value={formik.values.goal}
              onChange={formik.handleChange}
              error={formik.touched.goal && Boolean(formik.errors.goal)}
              helperText={formik.touched.goal && formik.errors.goal}
            />
            <MyTextInput
              label="Date"
              type="date"
              name="date"
              required={true}
              fullWidth={true}
              margin="normal"
              id="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            />
            <MyTextInput
              label="Status"
              type="text"
              name="status"
              required={true}
              fullWidth={true}
              margin="normal"
              id="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
            />
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  );
};

export default UpdateOppGoals;
