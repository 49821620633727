import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {MENTOR_RATING_API, UPDATE_MENTOR_RATING_API,LATEST_MENTOR_RATING_API, GET_MENTOR_RATING_DATE_API,GET_MENTOR_RATING_DATE_API_BY_ID, GET_CUST_CLIENTS
} from "../../utils/routes";

// ---------------------------------------------------------------------


export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
  }
);




export const deleteMentorCoaching = createAsyncThunk(
  "coaching/deleteCoaching",
  async (data, { rejectWithValue }) => {
    
  }
);

export const getMentorRatingByClientId = createAsyncThunk(
  "mentorRating/getrating",
  async (data,{rejectWithValue})=>{
   
    try{
    const response = await axios.post(MENTOR_RATING_API ,data,{
      withCredentials:true
    })
    return response
    }
    catch(err){
    return rejectWithValue(err.response.data)
    }
  }
  )

  export const getMentorRatingDate = createAsyncThunk(
    "mentorRatingDate/getrating",
    async (data,{rejectWithValue})=>{
      let body = {
        excoCustomerUserId:data
      }
      try{
      const response = await axios.post(GET_MENTOR_RATING_DATE_API ,body,{
        withCredentials:true
      })
      return response
      }
      catch(err){
      return rejectWithValue(err.response.data)
      }
    }
    )

    export const getMentorRatingDateById = createAsyncThunk(
      "mentorRatingDateById/getrating",
      async (data,{rejectWithValue})=>{
      try{
        const response = await axios.post(GET_MENTOR_RATING_DATE_API_BY_ID,data,{
          withCredentials:true
        })
        return response
        }
        catch(err){
        return rejectWithValue(err.response.data)
        }
      }
      )

    export const getMentorLatestRatingByClientId = createAsyncThunk(
      "mentorRating/getrating",
      async (data,{rejectWithValue})=>{
        let body = {
          excoCustomerUserId:data
        }
        try{
        const response = await axios.post(LATEST_MENTOR_RATING_API ,body,{
          withCredentials:true
        })
        return response
        }
        catch(err){
        return rejectWithValue(err.response.data)
        }
      }
      )

  export const submitMentorRating = createAsyncThunk(
    "mentorRating/submit",
    async (data,{rejectWithValue})=>{
      
    }
    )


  export const updateMentorRating = createAsyncThunk(
    "MentorUpdate/rating",
    async (data,{rejectWithValue})=>{
      try{
      const response = await axios.put(UPDATE_MENTOR_RATING_API,data,{
        withCredentials:true
      })
      return response
      }
      catch(err){
      return rejectWithValue(err.response.data)
      }
    }
    )

  

  export const getMentorCoachingByClientId = createAsyncThunk(
    "coaching/getClientId",
    async (data) => {
    }
  );

  export const getMentorApprovedButton = createAsyncThunk(
    "coaching/buttonType",
    async (data) => {
      
    }
  );

  export const getAllClients = createAsyncThunk(
    "assesment/getAllClients",
    async () => {
      return axios.get(GET_CUST_CLIENTS , { withCredentials: true });
    }
  );

  export const MentorCoachingApproved = createAsyncThunk(
    "MentorCoaching/approved",
    async (data,{rejectWithValue})=>{
    }
    )

    export const getAllRatingClients = createAsyncThunk(
      "rating/getAllClients",
      async () => {
        return axios.get(GET_CUST_CLIENTS , { withCredentials: true });
      }
    );
  
  







const  engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    dateErrorMessageData:"",
    mentorCoachingData: [],
    buttonStatus:{},
    getAllClientData:[],
    selectedData: {},
    getClientRatingData:[],
    getRatingDateData:[],
    getClientLatestRatingData:[],
    previousOutcomeData:[],
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    
    emptyingArray:(state,action) =>{
    state.getClientLatestRatingData = []
    },
    dateErrorMessage: (state, action) => {
      state.dateErrorMessageData = action.payload;
    },
  },
  extraReducers: {
    [updateMentorRating.pending]:(state,action)=>{
      state.loading = true
    },
    [updateMentorRating.fulfilled]:(state,action)=>{
      state.loading = false
      state.message = action?.payload?.data?.message;
    },
    [updateMentorRating.rejected]:(state,action)=>{
      state.loading = false
      state.errMessage = action?.payload?.message;
    },
 
    [submitMentorRating.pending]:(state,action)=>{
      state.loading = true
    },
    [submitMentorRating.fulfilled]:(state,action)=>{
      state.loading = false
      state.message = action?.payload?.data?.message;
    },
    [submitMentorRating.rejected]:(state,action)=>{
      state.loading = false
      state.errMessage = action?.payload?.message;
    },
    [getMentorRatingByClientId.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentorRatingByClientId.fulfilled]: (state, action) => {
      state.loading = false;
      state.getClientLatestRatingData = action?.payload?.data;
    },
    [getMentorRatingByClientId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getMentorRatingDate.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentorRatingDate.fulfilled]: (state, action) => {
      state.loading = false;
      state.getRatingDateData = action?.payload?.data;
    },
    [getMentorRatingDate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    
    [getMentorRatingDateById.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentorRatingDateById.fulfilled]: (state, action) => {
      state.loading = false;
      state.getClientLatestRatingData = action?.payload?.data;
    },
    [getMentorRatingDateById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getMentorLatestRatingByClientId.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentorLatestRatingByClientId.fulfilled]: (state, action) => {
      state.loading = false;
      state.getClientLatestRatingData = action?.payload?.data;
    },
    [getMentorLatestRatingByClientId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.getClientLatestRatingData = []
    },
  
  
    [MentorCoachingApproved.pending]:(state,action)=>{
      state.loading = true
    },
    [MentorCoachingApproved.fulfilled]:(state,action)=>{
      state.loading = false
      state.message = action?.payload?.data?.message;
    },
    [MentorCoachingApproved.rejected]:(state,action)=>{
      state.loading = false
      state.errMessage = action?.payload?.message;
    },
    [getMentorCoachingByClientId.pending]: (state, action) => {
      state.loading = true;
    },
    [ getMentorCoachingByClientId.fulfilled]: (state, action) => {
      state.loading = false;
      state.mentorCoachingData = action?.payload?.data;
    },
    [ getMentorCoachingByClientId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getMentorApprovedButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentorApprovedButton.fulfilled]: (state, action) => {
      state.loading = false;
      state.buttonStatus = action?.payload?.data;
    },
    [ getMentorApprovedButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAllRatingClients.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllRatingClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [getAllRatingClients.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
  
   [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
   
    [deleteMentorCoaching.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMentorCoaching.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteMentorCoaching.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage,dateErrorMessage,emptyingArray, rowSelect } = engagementSlice.actions;
export default  engagementSlice.reducer;


