import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
// material ui
import { Stack, Box } from "@mui/material";
// components
import Layout from "../../../layout/Layout";
import {
  MuiDataGrid,
  MuiAutocomplete,
  Notifier,
  ErrorNotifier,
} from "../../../components";

// redux
import {
  getCoachingFocusAreas,
  getCoachingFocusAreasByClient,
} from "../../../features/reports/coaching_focus_areas/coachingFocusAreasSlice";
import { getAllClients } from "../../../features/customer/custUserSlice";

// ----------------------------------------------------------

const CoachingFocusAreas = (props) => {
  const [client, setClient] = useState("");
  const dispatch = useDispatch();
  const { message, errMessage, coachingFocusAreasData } = useSelector(
    (state) => ({
      ...state.coachingFocusAreas,
    })
  );
  const { custClientsData } = useSelector((state) => ({ ...state.custUser }));

  function getExecutiveContact(params) {
    return `${params.row.executiveDialingCode || ""} ${
      params.row.executiveMobileNo || ""
    }`;
  }

  function getManagerContact(params) {
    return `${params.row.managerDialingCode || ""} ${
      params.row.managerMobileNo || ""
    }`;
  }

  const data = {
    rows: coachingFocusAreasData,
    columns: [
      { field: "executiveName", headerName: "Executive Name", width: 150 },
      { field: "executiveEmail", headerName: "Email", width: 250 },
      {
        field: "executiveNumber",
        headerName: "Contact Number",
        width: 150,
        valueGetter: getExecutiveContact,
      },
      { field: "managerName", headerName: "Manager Name", width: 150 },
      { field: "managerEmail", headerName: "Manager Email", width: 250 },
      {
        field: "managerNumber",
        headerName: "Mobile Number",
        width: 150,
        valueGetter: getManagerContact,
      },
      { field: "geo", headerName: "Geography", width: 150 },
      { field: "country", headerName: "Country", width: 150 },
      { field: "status", headerName: "Status", width: 150 },
      { field: "department", headerName: "Department", width: 150 },
      {
        field: "nominatorRole",
        headerName: "Nominator Role",
        width: 150,
      },
      {
        field: "comment",
        headerName: "Comments",
        width: 250,
      },
    ],
  };

  useEffect(() => {
    dispatch(getCoachingFocusAreas());
    dispatch(getAllClients());
  }, []);

  useEffect(() => {
    client && dispatch(getCoachingFocusAreasByClient(client));
  }, [client]);

  return (
    <Layout>
      <>
        <Stack
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <PageHeader variant="pageHeader">Coaching Focus Areas</PageHeader>
          <Box
            sx={{ m: "0 !important", mb: "0.5rem !important", width: "25%" }}
          >
            <MuiAutocomplete
              name="client"
              options={custClientsData}
              setValue={(name, newValue) => setClient(newValue)}
              value={client}
              optionValue="userName"
              label="Client Name"
            />
          </Box>
        </Stack>
        <MuiDataGrid data={data}  toolbarColumn = {true}
toolbarFilter = {true}
toolbarDensity = {true}
toolbarExport = {true}/>
      </>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default CoachingFocusAreas;
