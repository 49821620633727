import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_USER_GROUPS } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createUserGroups = createAsyncThunk(
  "userGroups/createUserGroups",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_USER_GROUPS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserGroups = createAsyncThunk(
  "userGroups/getUserGroups",
  async () => {
    return axios.get(CURD_USER_GROUPS, { withCredentials: true });
  }
);

export const updateUserGroups = createAsyncThunk(
  "userGroups/updateUserGroups",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_USER_GROUPS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserGroups = createAsyncThunk(
  "userGroups/deleteUserGroups",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_USER_GROUPS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const userGroupsSlice = createSlice({
  name: "userGroups",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    userGroupsData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createUserGroups.pending]: (state, action) => {
      state.loading = true;
    },
    [createUserGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createUserGroups.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getUserGroups.pending]: (state, action) => {
      state.loading = true;
    },
    [getUserGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.userGroupsData = action?.payload?.data;
    },
    [getUserGroups.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateUserGroups.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUserGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateUserGroups.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteUserGroups.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteUserGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteUserGroups.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  userGroupsSlice.actions;
export default userGroupsSlice.reducer;
