import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  CURD_ENGAGEMENT,
  GET_ENGAGEMENT_BY_CLIENT,
  GET_ENGAGEMENT_BY_ACC,
  SEARCH_ENGAGEMENT,
  GET_NOT_ENGAGED_CLIENTS
} from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createEngagement = createAsyncThunk(
  "engagement/createEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ENGAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagement = createAsyncThunk(
  "engagement/getEngagement",
  async () => {
    return axios.get(CURD_ENGAGEMENT, { withCredentials: true });
  }
);

export const getEngagementByClient = createAsyncThunk(
  "engagement/getEngagementByClient",
  async (id) => {
    return axios.get(`${GET_ENGAGEMENT_BY_CLIENT}/${id}`, {
      withCredentials: true,
    });
  }
);

export const getEngagementByAcc = createAsyncThunk(
  "engagement/getEngagementByAcc",
  async (id) => {
    return axios.get(`${GET_ENGAGEMENT_BY_ACC}/${id}`, {
      withCredentials: true,
    });
  }
);

export const getNotEngagedClients = createAsyncThunk(
  "engagement/getNotEngagedClients",
  async (id) => {
    return axios.get(`${GET_NOT_ENGAGED_CLIENTS}/${id}`, {
      withCredentials: true,
    });
  }
);

export const searchEngagement = createAsyncThunk(
  "engagement/searchEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(SEARCH_ENGAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEngagement = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ENGAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagement = createAsyncThunk(
  "engagement/deleteEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ENGAGEMENT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engagementData: [],
    engagementByClientData: [],
    engByAccData: [],
    notEngagedClientsData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [createEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementData = action?.payload?.data;
    },
    [getEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [searchEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [searchEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementData = action?.payload?.data;
    },
    [searchEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementByClient.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementByClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementByClientData = action?.payload?.data;
    },
    [getEngagementByClient.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementByAcc.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementByAcc.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementData = action?.payload?.data;
    },
    [getEngagementByAcc.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getNotEngagedClients.pending]: (state, action) => {
      state.loading = true;
    },
    [getNotEngagedClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.notEngagedClientsData = action?.payload?.data;
    },
    [getNotEngagedClients.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  engagementSlice.actions;
export default engagementSlice.reducer;
