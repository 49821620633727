import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_L2_MENU_PERMISSIONS, GET_L2_MENU_PERMISSIONS_ROLE, CURD_L2_SERVICE_PERMISSIONS, GET_L2_SERVICE_PERMISSIONS_ROLE } from "../../../../utils/routes";

// ---------------------------------------------------------------------

export const createL2MenuPermission = createAsyncThunk(
  "l2AccessPermission/createL2MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_L2_MENU_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getL2MenuPermission = createAsyncThunk("l2AccessPermission/getL2MenuPermission", async () => {
  return axios.get(CURD_L2_MENU_PERMISSIONS, { withCredentials: true });
});

export const getL2MenuPermissionRole = createAsyncThunk("l2AccessPermission/getL2MenuPermissionRole", async (id) => {
  return axios.get(`${GET_L2_MENU_PERMISSIONS_ROLE}/${id}`, { withCredentials: true });
});

export const updateL2MenuPermission = createAsyncThunk(
  "l2AccessPermission/updateL2MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_L2_MENU_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteL2MenuPermission = createAsyncThunk(
  "l2AccessPermission/deleteL2MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_L2_MENU_PERMISSIONS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createL2ServicePermission = createAsyncThunk(
  "l2AccessPermission/createL2ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_L2_SERVICE_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getL2ServicePermission = createAsyncThunk("l2AccessPermission/getL2ServicePermission", async () => {
  return axios.get(CURD_L2_SERVICE_PERMISSIONS, { withCredentials: true });
});

export const getL2ServicePermissionRole = createAsyncThunk("l2AccessPermission/getL2ServicePermissionRole", async (id) => {
  return axios.get(`${GET_L2_SERVICE_PERMISSIONS_ROLE}/${id}`, { withCredentials: true });
});

export const updateL2ServicePermission = createAsyncThunk(
  "l2AccessPermission/updateL2ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_L2_SERVICE_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteL2ServicePermission = createAsyncThunk(
  "l2AccessPermission/deleteL2ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_L2_SERVICE_PERMISSIONS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const l2AccessPermissionSlice = createSlice({
  name: "l2AccessPermission",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    l2MenuPermissionData: [],
    l2MenuSelectedData: {},
    l2ServicePermissionData: [],
    l2ServiceSelectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    l2MenuRowSelect: (state, action) => {
      state.l2MenuSelectedData = action?.payload.length > 0 && action?.payload[0];
    },
    l2ServiceRowSelect: (state, action) => {
      state.l2ServiceSelectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createL2MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [createL2MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createL2MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getL2MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [getL2MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.l2MenuPermissionData = action?.payload?.data;
    },
    [getL2MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getL2MenuPermissionRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getL2MenuPermissionRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.l2MenuPermissionData = action?.payload?.data;
    },
    [getL2MenuPermissionRole.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateL2MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [updateL2MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateL2MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteL2MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteL2MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteL2MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createL2ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [createL2ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createL2ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getL2ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [getL2ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.l2ServicePermissionData = action?.payload?.data;
    },
    [getL2ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getL2ServicePermissionRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getL2ServicePermissionRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.l2ServicePermissionData = action?.payload?.data;
    },
    [getL2ServicePermissionRole.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateL2ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [updateL2ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateL2ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteL2ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteL2ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteL2ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, l2MenuRowSelect, l2ServiceRowSelect } = l2AccessPermissionSlice.actions;
export default l2AccessPermissionSlice.reducer;
