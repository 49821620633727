import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
// material ui
import { Grid, Card, TextField, Stack } from "@mui/material";
// components
import Layout from "../../../layout/Layout";
import CollapsibleTable from "./CollaspibleTable";
// redux
import { getMentors } from "../../../features/exec/mentors/mentorSlice";

// --------------------------------------------------------------

const Mentor = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { mentorsData } = useSelector(
    (state) => ({ ...state.execMentor })
  );

  useEffect(() => {
    dispatch(getMentors());
  }, []);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card
            sx={{ minHeight: "80vh", padding: "0.5rem", textAlign: "center" }}
          >
            <Stack
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            sx={{marginBottom: 2}}
          >
            <PageHeader variant="pageHeader">Mentors</PageHeader>
            <TextField
              type="text"
              id="filled-size-small"
              name="search"
              label="Search Mentor..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="filled"
              size="small"
              sx={{ width: 300, margin: "auto", marginBottom: "0.5rem" }}
            />
            </Stack>
            <CollapsibleTable data={mentorsData} search={search} />
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Mentor;
