import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useSelector, useDispatch } from "react-redux";
// components
import Layout from "../../../layout/Layout";
import { AddOpportunity, OpportunityDetails } from "../opportunity";
// material ui
import { Grid, Paper, Button, Stack, Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
// redux
import { getOpportunity } from "../../../features/exec/opportunity/opportunitySlice";

// ------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1rem",
  },
}));

// -------------------------------------------------------------------

const Opportunity = () => {
  const [showAdd, setShowAdd] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const classes = useStyles();

  const { opportunityData } = useSelector((state) => ({
    ...state.opportunity,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOpportunity());
  }, []);

  return (
    <Layout>
      {!showAdd && !showDetail && (
        <>
          <Button
            variant="contained"
            sx={{ position: "absolute" }}
            onClick={() => setShowAdd(true)}
          >
            Add
          </Button>
          <PageHeader variant="pageHeader">Opportunity Kanban</PageHeader>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Paper elevation={4} className={classes.paper}>
                <Stack flexDirection="row" justifyContent="center">
                  <PageHeader variant="pageSecHeader">Prospect</PageHeader>
                </Stack>
                {opportunityData.length > 0 &&
                  opportunityData.map((item) => {
                    if (
                      item.opportunityStage === "Inquiry" ||
                      item.opportunityStage === "Lead" ||
                      item.opportunityStage === "Prospect"
                    ) {
                      return (
                        <Card
                          sx={{ marginBottom: "0.5rem", cursor: "pointer" }}
                          onClick={() => {
                            setShowDetail(true);
                            setSelectedValue(item);
                          }}
                        >
                          <CardContent>{item.opportunityName}</CardContent>
                        </Card>
                      );
                    }
                  })}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Paper elevation={4} className={classes.paper}>
                <Stack flexDirection="row" justifyContent="center">
                  <PageHeader variant="pageSecHeader">Opportunity</PageHeader>
                </Stack>
                {opportunityData.length > 0 &&
                  opportunityData.map((item) => {
                    if (item.opportunityStage === "Opportunity") {
                      return (
                        <Card
                          sx={{ marginBottom: "0.5rem", cursor: "pointer" }}
                          onClick={() => {
                            setShowDetail(true);
                            setSelectedValue(item);
                          }}
                        >
                          <CardContent>{item.opportunityName}</CardContent>
                        </Card>
                      );
                    }
                  })}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Paper elevation={4} className={classes.paper}>
                <Stack flexDirection="row" justifyContent="center">
                  <PageHeader variant="pageSecHeader">Closed</PageHeader>
                </Stack>
                {opportunityData.length > 0 &&
                  opportunityData.map((item) => {
                    if (item.opportunityStage === "Order") {
                      return (
                        <Card
                          sx={{ marginBottom: "0.5rem", cursor: "pointer" }}
                          onClick={() => {
                            setShowDetail(true);
                            setSelectedValue(item);
                          }}
                        >
                          <CardContent>{item.opportunityName}</CardContent>
                        </Card>
                      );
                    }
                  })}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      {showAdd && <AddOpportunity close={() => setShowAdd("")} />}
      {showDetail && (
        <OpportunityDetails
          close={() => setShowDetail(false)}
          values={selectedValue}
        />
      )}
    </Layout>
  );
};

export default Opportunity;
