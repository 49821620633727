
import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import { makeStyles } from "@mui/styles";
import { MuiDataGrid } from "../../../components";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { PageHeader } from "@excoleadershipui/pionweblib";

//redux-toolkit
const EngagementCheckin = () => {
  const [checkInRating, setCheckingRating] = useState("");
  
  const dataTwo = {
    rows: [],
    columns: [
      { field: "col1", headerName: "Theme", width: 300 },
      { field: "col2", headerName: "Description", width: 300 },
    ],
  };

  const dataThree = {
    rows: [
      {
        id: 1,
        col1: "new eng",
      },
      {
        id: 2,
        col1: "new eng",
      },
    ],
    columns: [{ field: "col1", headerName: "Engagement Notes", width: 300 }],
  };

  ChartJS.register(ArcElement, Legend);

  const dataReports = {
    datasets: [
      {
        data: [300, 300, 300],
        needleValue: checkInRating,
        backgroundColor: ["red", "#FFCE56", "lightgreen"],
        hoverBackgroundColor: ["red", "#FFCE56", "lightgreen"],
      },
    ],
    options: {
      layout: {
      },
      rotation: -95,
      circumference: 60 * Math.PI,
      legend: {
        display: false,
      },
      cutoutPercentage: 70,
    },
  };

  ChartJS.register({
    id: "doughnut",
    afterDraw: (chart, args, opts) => {
      var needleValue = chart.config.data.datasets[0].needleValue;
      var dataTotal = chart.config.data.datasets[0].data.reduce(
        (a, b) => a + b,
        0
      );
      var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
      var ctx = chart.ctx;
      var cw = chart.canvas.offsetWidth;
      var ch = chart.canvas.offsetHeight - 100;
      var cx = cw / 2;
      var cy = ch - 6;
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(ch - 5, 0);
      ctx.lineTo(0, 3);
      ctx.fillStyle = "rgb(0, 0, 0)";
      ctx.fill();
      ctx.rotate(-angle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, Math.PI * 2);
      ctx.fill();
    },
  });
  function DonutChart() {
    return (
      <>
        <Doughnut data={dataReports} options={dataReports.options} />
      </>
    );
  }


  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));

  return (
    <Layout>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          fontFamily: "arial",
        }}
      >
        <Typography
          sx={{ fontSize: "1.25rem", fontWeight: "700" }}
          gutterBottom
          variant="h9"
          component="div"
        >
          Engagement Checkin Report
        </Typography>
        <Stack sx={{ width: "10rem", marginLeft: "1rem" }}>
          <FormControl size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="clientName"
              label="Client Name"
            >
            </Select>
          </FormControl>
        </Stack>

        <TextField
          sx={{ marginLeft: "4rem" }}
          type="date"
          InputLabelProps={{ shrink: true }}
          name="startDate"
          id="demo-simple-select"
          required={true}
        />
        <Typography
          sx={{ fontSize: "1rem", marginLeft: "1rem" }}
          gutterBottom
          variant="h9"
          component="div"
        >
          To
        </Typography>
        <TextField
          sx={{ marginLeft: "1rem" }}
          type="date"
          InputLabelProps={{ shrink: true }}
          name="endDate"
          id="demo-simple-select"
          required={true}
        />
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "11rem",
          marginTop: "2rem",
          fontFamily: "arial",
        }}
      >
        <Typography
          sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
          gutterBottom
          variant="h9"
          component="div"
        >
          LEVEL OF ENGAGEMENT
        </Typography>

        <Stack sx={{ width: "10rem", marginLeft: "1rem" }}>
          <FormControl size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue="Clients"
            >
              <MenuItem key={1} value={1}>
                {" "}
                Red{" "}
              </MenuItem>
              <MenuItem key={2} value={2}>
                {" "}
                Yellow{" "}
              </MenuItem>
              <MenuItem key={3} value={3}>
                {" "}
                Green{" "}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            fontFamily: "arial",
            width: "45%",
            justifyContent: "center",
          }}
        >
          <DonutChart />
        </Stack>
      </Stack>

      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "arial",
          marginLeft: "2rem",
        }}
      >
        <Typography
          sx={{ fontSize: "1rem", fontWeight: "bold", marginTop: "2rem" }}
          gutterBottom
          variant="h9"
          component="div"
        >
          Engagement Key
        </Typography>
        <Typography
          sx={{ fontSize: "0.75rem" }}
          gutterBottom
          variant="h9"
          component="div"
        >
          <span style={{ fontSize: "1rem", fontWeight: "bold" }}>Green</span>{" "}
          Highly engaged with coach/set meetings regulary/leaning in.
        </Typography>
        <Typography
          sx={{ fontSize: "0.75rem" }}
          gutterBottom
          variant="h9"
          component="div"
        >
          <span style={{ fontSize: "1rem", fontWeight: "bold" }}>Yellow</span>{" "}
          Engaged when meeting but challenging to schedule with.
        </Typography>
        <Typography
          sx={{ fontSize: "0.75rem" }}
          gutterBottom
          variant="h9"
          component="div"
        >
          <span style={{ fontSize: "1rem", fontWeight: "bold" }}>Red</span> Goes
          long period without meeting with coach/not engaged.
        </Typography>
      </Stack>
      <Stack sx={{ marginTop: "2rem" }}>
        <PageHeader variant="pageHeader">
          {" "}
          Progress Update - Coaching Focus Area
        </PageHeader>
        <MuiDataGrid data={dataTwo}  toolbarColumn = {true}
toolbarFilter = {true}
toolbarDensity = {true}
toolbarExport = {true} />
      </Stack>

      <Stack sx={{ marginTop: "2rem" }}>
        <PageHeader variant="pageHeader">Engagement Notes</PageHeader>
        <MuiDataGrid data={dataThree}  toolbarColumn = {true}
toolbarFilter = {true}
toolbarDensity = {true}
toolbarExport = {true} />
      </Stack>
    </Layout>
  );
};

export default EngagementCheckin;
