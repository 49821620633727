import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput, PageHeader } from "@excoleadershipui/pionweblib";
import { useSelector, useDispatch } from "react-redux";
// material ui
import { Typography, Container, Box, Link, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import logo from "../../../assets/images/exco-nav-logo.svg";
// components
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
import { FormSubmitButton } from "../../../components";
// redux
import {
  activateMentorAccount,
  resetErrMessage,
  resetMessage,
} from "../../../features/mentor/mentorSlice";
// -----------------------------------------------------

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.excoleadership.com/">
        excoleadership
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

//   -----------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    marginTop: "1rem !important",
  },
}));

// ---------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Confirm Password is required"),
  secret: Yup.string().required("Secret is required"),
});

// ---------------------------------------------------------------------------------------------

const ActivateMentorAccount = (props) => {
  let navigate = useNavigate();

  const classes = useStyles();

  const dispatch = useDispatch();
  const { message, errMessage } = useSelector((state) => ({ ...state.mentor }));
  const queryParams = new URLSearchParams(window.location.search);
  const result1 = queryParams.get("neapea");
  const queryParamsTwo = new URLSearchParams(window.location.search);
  const result2 = queryParamsTwo.get("neapucae");

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 4 }}>
        <PageHeader sx={{ marginTop: 4 }}>Activate Mentor Account</PageHeader>
      </Box>

      <Paper
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          position: "relative",
        }}
        elevation={2}
      >
        <img alt="logo" src={logo} width="80rem" height="40rem" />

        <Formik
          validationSchema={validationSchema}
          initialValues={{
            secret: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={(values) => {
            let body = {
              neapea: result1,
              neapucae: result2,
              secret: values.secret.toString(),
              newPassword: values.newPassword,
              confirmPassword: values.confirmPassword,
            };
            dispatch(activateMentorAccount(body)).then((res) => {
              if (res?.payload?.data?.ok) {
                setTimeout(() => {
                  dispatch(resetMessage());
                  navigate("/");
                }, 2000);
              }
              if (res?.payload?.error) {
                setTimeout(() => {
                  dispatch(resetErrMessage());
                }, 2000);
              }
            });
          }}
        >
          {({ errors, touched, handleSubmit, handleChange }) => (
            <form onSubmit={handleSubmit} noValidate>
              <MyTextInput
                label="Secret"
                type="number"
                name="secret"
                required={true}
                fullWidth={true}
                margin="normal"
                id="newPassword"
                onChange={handleChange("secret")}
                error={touched.secret && Boolean(errors.secret)}
                helperText={touched.secret && errors.secret}
              />
              <MyTextInput
                label="New Password"
                type="password"
                name="newPassword"
                required={true}
                fullWidth={true}
                margin="normal"
                id="newPassword"
                onChange={handleChange("newPassword")}
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
              <MyTextInput
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                required={true}
                fullWidth={true}
                margin="normal"
                id="confirmPassword"
                onChange={handleChange("confirmPassword")}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
              />

              <FormSubmitButton
                type="submit"
                fullWidth={true}
                variant="contained"
                className={classes.btnSubmit}
              >
                Activate Account
              </FormSubmitButton>
            </form>
          )}
        </Formik>

      </Paper>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Container>
  );
};

export default ActivateMentorAccount;
