import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ACCOUNT, GET_ENGAGEMENT_BY_ACC } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const getCustomers = createAsyncThunk(
  "customers/getCustomers",
  async () => {
    return axios.get(CURD_ACCOUNT, { withCredentials: true });
  }
);

export const getEngagementByAcc = createAsyncThunk(
  "customers/getEngagementByAcc",
  async (id) => {
    return axios.get(`${GET_ENGAGEMENT_BY_ACC}/${id}`, { withCredentials: true });
  }
);

const customerSlice = createSlice({
  name: "customers",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    customersData: [],
    engByAccData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getCustomers.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomers.fulfilled]: (state, action) => {
      state.loading = false;
      state.customersData = action?.payload?.data;
    },
    [getCustomers.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementByAcc.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementByAcc.fulfilled]: (state, action) => {
      state.loading = false;
      state.engByAccData = action?.payload?.data;
    },
    [getEngagementByAcc.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  customerSlice.actions;
export default customerSlice.reducer;
