import React, { useEffect, useState } from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton, MuiAutocomplete } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  createApiManagement,
  getApiManagement,
  resetMessage,
  resetErrMessage,
} from "../../../../features/platform/app_setup/apiManagementSlice";
import { getL1Menu } from "../../../../features/platform/app_setup/menu_management/l1MenuSlice";
import { getL2Menu } from "../../../../features/platform/app_setup/menu_management/l2MenuSlice";
import { getL3Menu } from "../../../../features/platform/app_setup/menu_management/l3MenuSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  appName: Yup.string().required("Application Name is required"),
  moduleName: Yup.object().required("Module Name is required"),
  subModuleName: Yup.object(),
  featureName: Yup.object(),
  serviceName: Yup.string().required("Service Name is required"),
  url: Yup.string().required("URL is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const AddApiManagement = (props) => {
  const [l2MenuArr, setL2MenuArr] = useState([])
  const [l3MenuArr, setL3MenuArr] = useState([])

  const { message, errMessage } = useSelector((state) => ({
    ...state.apiManagement,
  }));
  const {l1MenuData} = useSelector((state) => ({
    ...state.l1Menus
  }))
  const {l2MenuData} = useSelector((state) => ({
    ...state.l2Menus
  }))
  const {l3MenuData} = useSelector((state) => ({
    ...state.l3Menus
  }))

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      appName: "",
      moduleName: "",
      subModuleName: "",
      featureName: "",
      serviceName: "",
      url: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createApiManagement({
          applicationName: values.appName,
          moduleName: values.moduleName?.l1MenuName,
          subModuleName: values?.subModuleName?.l2MenuName,
          featureName: values?.featureName?.l3MenuName,
          serviceName: values?.serviceName,
          url: values?.url,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getApiManagement());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
dispatch(getL1Menu())
dispatch(getL2Menu())
dispatch(getL3Menu())
  }, [])

  useEffect(() => {
    let l2Menus = []
l2MenuData.length > 0 && l2MenuData.map(item => {
  if(item?.level1Menu?.l1MenuName === formik?.values?.moduleName?.l1MenuName) {
    return l2Menus.push(item)
  }
  setL2MenuArr(l2Menus)
})
  }, [formik?.values?.moduleName?.l1MenuName])

  useEffect(() => {
    let l3Menus = []
l3MenuData.length > 0 && l3MenuData.map(item => {
  if(item?.level2Menu?.l2MenuName === formik?.values?.subModuleName?.l2MenuName) {
    return l3Menus.push(item)
  }
  setL3MenuArr(l3Menus)
})
  }, [formik?.values?.subModuleName?.l2MenuName])

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={<PageHeader variant="pageHeader">Add Api Management</PageHeader>}
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
            <MyTextInput
                label="Application Name"
                type="text"
                name="appName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="appName"
                value={formik.values.appName}
                onChange={formik.handleChange}
                error={
                  formik.touched.appName &&
                  Boolean(formik.errors.appName)
                }
                helperText={
                  formik.touched.appName && formik.errors.appName
                }
              />
              <MuiAutocomplete
              name="moduleName"
              options={l1MenuData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.moduleName}
              optionValue="l1MenuName"
              label="Module Name"
              required={true}
              error={formik.touched.moduleName && Boolean(formik.errors.moduleName)}
              helperText={formik.touched.moduleName && formik.errors.moduleName}
            />
              <MuiAutocomplete
              name="subModuleName"
              options={l2MenuArr}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.subModuleName}
              optionValue="l2MenuName"
              label="Sub Module Name"
              error={formik.touched.subModuleName && Boolean(formik.errors.subModuleName)}
              helperText={formik.touched.subModuleName && formik.errors.subModuleName}
            />
               <MuiAutocomplete
              name="featureName"
              options={l3MenuArr}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.featureName}
              optionValue="l3MenuName"
              label="Feature Name"
              error={formik.touched.featureName && Boolean(formik.errors.featureName)}
              helperText={formik.touched.featureName && formik.errors.featureName}
            />
              <MyTextInput
                label="Service Name"
                type="text"
                name="serviceName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="serviceName"
                value={formik.values.serviceName}
                onChange={formik.handleChange}
                error={
                  formik.touched.serviceName &&
                  Boolean(formik.errors.serviceName)
                }
                helperText={
                  formik.touched.serviceName && formik.errors.serviceName
                }
              />
              <MyTextInput
                label="URL"
                type="text"
                name="url"
                required={true}
                fullWidth={true}
                margin="normal"
                id="url"
                value={formik.values.url}
                onChange={formik.handleChange}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddApiManagement;
