import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useLocation } from "react-router-dom";

// material ui
import {
  Stack,
  Box,
  Grid,
} from "@mui/material";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  MuiAutocomplete,
  Notifier,
  ErrorNotifier,
} from "../../components";
import AddNomination from "./AddNomination";
import NominationDetail from "./NominationDetail";
// redux
import {
  getNomination,
  getNominationByCust,
  rowSelect
} from "../../features/nomination/nominationSlice";
import { getAccount } from "../../features/customer/accountSlice";

// ----------------------------------------------------------

const Nomination = (props) => {
  const [account, setAccount] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation()

  const { message, errMessage, nominationData } = useSelector((state) => ({
    ...state.nomination,
  }));
  const { accountData } = useSelector((state) => ({ ...state.account }));

  function getAccountName(params) {
    return <GridCellExpand value={params.row?.excoCustomerAccount?.accountName || ''} width={params.colDef.computedWidth} />
  }

  function getExecutiveContact(params) {
    return `${params.row.executiveDialingCode || ""}-${params.row.executiveMobileNo || ""
      }`;
  }

  function getManagerContact(params) {
    return `${params.row.managerDialingCode || ""}-${params.row.managerMobileNo || ""
      }`;
  }

  function getHrName(params) {
    return `${params.row.excoCustomerUser?.excoCustomerUserDetail?.firstName || ""} ${params.row.excoCustomerUser?.excoCustomerUserDetail?.lastName || ""
      }`;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }

  const data = {
    rows: nominationData,
    columns: [
      {
        field: "accountName",
        headerName: "Account Name ",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: (params) => params?.row?.excoCustomerAccount?.accountName

      },
      {
        field: "leaderName",
        headerName: "Leader Name ",
        width: 200,
        valueGetter: (params) => `${params?.row?.leaderFirstName}${" "} ${params?.row?.leaderLastName}`,
        renderCell: renderCellExpand,
      },
      {
        field: "leaderEmail",
        headerName: "Leader's Email",
        width: 200,
      },
      {
        field: "title",
        headerName: "Title/Position",
        width: 280,
        renderCell: renderCellExpand,

      },
      { field: "level", headerName: "Level ", width: 200 },
    
      {
        field: "managerName",
        headerName: "Manager Name",
        width: 200,
        valueGetter: (params) => `${params?.row?.managerFirstName}${" "} ${params?.row?.managerLastName}`,
        renderCell: renderCellExpand,
      },
      {
        field: "managerEmail",
        headerName: "Manager Email",
        width: 200,

      },

      {
        field: "hrPartnerName",
        headerName: "HR Partner",
        width: 280,
        renderCell: renderCellExpand,
        valueGetter: (params) => `${params?.row?.hrPartnerFirstName}${" "} ${params?.row?.hrPartnerLastName}`,
      },
      {
        field: "hrPartnerEmail",
        headerName: "HR Partner Email",
        width: 280,
        renderCell: renderCellExpand,
      },
      {
        field: "assistantName",
        headerName: "Assistant",
        width: 280,
        renderCell: renderCellExpand,
      },
      {
        field: "assistantEmail",
        headerName: "Assistant Email",
        width: 280,
        renderCell: renderCellExpand,
      },
      { field: "geoName", headerName: "Geography", width: 200 },
      { field: "country", headerName: "Country", width: 200 },
      { field: "location", headerName: "City/Location", width: 200 },
      {
        field: "reasonForCoaching",
        headerName: "Reason for Coaching/Mentoring",
        width: 280,
        renderCell: renderCellExpand,
      },

      {
        field: "additionalComments",
        headerName: "Comments",
        width: 300,
        renderCell: renderCellExpand,
      },
    ],
  };

  useEffect(() => {
    dispatch(getNomination());
    dispatch(getAccount());
  }, []);

  useEffect(() => {
    account && dispatch(getNominationByCust(account?.id));
    !account && dispatch(getNomination())
  }, [account]);

  return (
    <Layout>
      {!showEdit && !showAdd &&
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={12} sm={12} md={6}>
                <PageHeader variant="pageHeader">Nominations</PageHeader>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Box
                  sx={{ m: "0 !important", mb: "0.5rem !important", width: '17rem' }}
                >
                  <MuiAutocomplete
                    name="account"
                    options={accountData}
                    setValue={(name, newValue) => setAccount(newValue)}
                    value={account}
                    optionValue="accountName"
                    label="Customer Account Name"
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
          <MuiDataGrid
            setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") ? () => setShowAdd(true) : null}
            toolbarColumn={true}
            toolbarFilter={true}
            toolbarDensity={true}
            toolbarExport={true}
            data={data}
            select={(data) => {
              dispatch(rowSelect(data))
              setShowEdit(true)
            }
            } />

        </>
      }
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
      {showAdd && (
        <AddNomination
          showAdd={showAdd}
          close={() => setShowAdd(false)}
        />
      )}
      {showEdit && (
        <NominationDetail
          showEdit={showEdit}
          close={() => setShowEdit(false)}
          account={account}
        />
      )}
    </Layout>
  );
};

export default Nomination;
