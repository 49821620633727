import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countries, continents } from "countries-list";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useLocation } from "react-router-dom";

// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";
// components
import Layout from "../../../layout/Layout";
import {
  MuiAutocomplete,
  geographyArr,
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
} from "../../../components";
// redux
import {
  resendEmail,
  createCustUser,
  getCustUser,
  getCustHrByAcc,
  getCustManagerByAcc,
  updateCustUser,
  deleteCustUser,
  getCustUserRoles,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../features/customer/custUserSlice";
import { getAccount } from "../../../features/customer/accountSlice";

// --------------------------------------------------------------------------------------

const validationSchemaRoleClient = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  geography: Yup.object().required("Geo Name is required"),
  country: Yup.object().required("country is required "),
  // dialingCode: Yup.string().required("Dialing Code is required"),
  // contactNumber: Yup.string().required("Contact Number is required ").matches(/^[0-9]+$/, "Must be only digits")
  // .min(10, "Must be exactly 10 digits")
  // .max(10, "Must be exactly 10 digits"),

  designation: Yup.string().required("Designation is required"),
  contactEmail: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  roles: Yup.array().required("Roles is required"),
  account: Yup.object().required("Account is required").nullable(),
  hr: Yup.object().required("HR Name is required"),
  manager: Yup.object().nullable(),
  comments: Yup.string().nullable(),
});

const validationSchemaRoleNotClient = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  geography: Yup.object().required("Geo Name is required"),
  country: Yup.object().required("country is required "),
  // dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string().nullable().matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),

  designation: Yup.string().required("Designation is required"),
  contactEmail: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  roles: Yup.array().min(1, "Roles is required").required("Roles is required"),
  account: Yup.object().required("Account is required").nullable(),
  hr: Yup.object(),
  manager: Yup.object().nullable(),
  comments: Yup.string().nullable(),
});

const validationEditSchemaRoleClient = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  geography: Yup.string().required("Geography is required"),
  // dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  country: Yup.string().required("country is required "),
  designation: Yup.string().required("Designation is required"),
  contactEmail: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  roles: Yup.array().required("Roles is required"),
  account: Yup.string().required("Account is required").nullable(),
  hr: Yup.string().required("HR Name is required"),
  manager: Yup.string().nullable(),
  comments: Yup.string().nullable(),
});

const validationEditSchemaRoleNotClient = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  geography: Yup.string().required("Geography is required"),
  // dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  country: Yup.string().required("country is required "),
  designation: Yup.string().required("Designation is required"),
  contactEmail: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  roles: Yup.array().required("Roles is required"),
  account: Yup.string().required("Account is required").nullable(),
  hr: Yup.string(),
  manager: Yup.string().nullable(),
  comments: Yup.string().nullable(),
});

// ---------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("md")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  filter_grid: {
    [theme.breakpoints.down("md")]: {
      display: "flex !important",
      flexDirection: "column !important",
    },
  },
  close_btn: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "-2.563rem",
    },
  },
}));

const Onboarding = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [countryArr, setCountryArr] = useState([]);
  const [role, setRole] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();
  const {
    message,
    errMessage,
    custUserData,
    custHrData,
    custManagerData,
    custUserRolesData,
    selectedData,
  } = useSelector((state) => ({ ...state.custUser }));

  const { accountData } = useSelector((state) => ({
    ...state.account,
  }));

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        account: "",
        firstName: "",
        lastName: "",
        geography: "",
        country: "",
        designation: "",
        contactEmail: "",
        dialingCode: "",
        contactNumber: "",
        roles: [],
        hr: "",
        manager: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
    formikEdit.resetForm({
      values: {
        account: "",
        firstName: "",
        lastName: "",
        geography: "",
        country: "",
        designation: "",
        contactEmail: "",
        dialingCode: "",
        contactNumber: "",
        roles: [],
        hr: "",
        manager: "",
        comments: "",
      },
    });
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustUser(),
    });

  const handleDeleteCustUser = () => {
    let data = {
      id: selectedData?.excoCustomerUser?.id,
    };
    dispatch(deleteCustUser(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getCustUser());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getAccountName(params) {
    return params?.row?.excoCustomerUser?.excoAccount?.accountName;
  }

  function getClientName(params) {
    return (
      <GridCellExpand
        value={
          `${params.row.firstName || ""} ${params.row.lastName || ""}` || ""
        }
        width={params.colDef.computedWidth}
      />
    );
  }

  function getRoleName(params) {
    return (
      <GridCellExpand
        value={params?.row?.excoCustomerUserRole?.map(
          (item) => `${item.role} `
        )}
        width={params.colDef.computedWidth}
      />
    );
  }

  function getDialContact(params) {
    return `${params?.row?.dialingCode || ""}-${params?.row?.contactNumber || ""
      }`;
  }

  function getHrName(params) {
    return (
      params?.row?.excoCustomerUser?.reportingHRDetail?.firstName &&
      `${params?.row?.excoCustomerUser?.reportingHRDetail?.firstName} ${params?.row?.excoCustomerUser?.reportingHRDetail?.lastName}`
    );
  }

  function getManagerName(params) {
    return (
      params?.row?.excoCustomerUser?.reportingManagerDetail?.firstName &&
      `${params?.row?.excoCustomerUser?.reportingManagerDetail?.firstName} ${params?.row?.excoCustomerUser?.reportingManagerDetail?.lastName}`
    );
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: custUserData,
    columns: [
      {
        field: "account",
        headerName: "Account Name",
        width: 150,
        valueGetter: getAccountName,
      },
      {
        field: "clientName",
        headerName: "User Name",
        width: 200,
        renderCell: getClientName,
        valueGetter: (params) =>
          `${params.row.firstName || ""} ${params.row.lastName || ""}`,
      },
      {
        field: "role",
        headerName: "Role",
        width: 100,
        renderCell: getRoleName,
        valueGetter: (params) =>
          params?.row?.excoCustomerUserRole?.map((item) => item?.role),
      },
      {
        field: "contactEmail",
        headerName: "Email",
        width: 200,
        renderCell: (params) => (
          <GridCellExpand value={params?.row?.excoCustomerUser?.email || ""} />
        ),
        valueGetter: (params) => params?.row?.excoCustomerUser?.email,
      },
      {
        field: "contactNumber",
        headerName: "Contact Number",
        width: 150,
        valueGetter: getDialContact,
      },
      { field: "designation", headerName: "Title/Position", width: 150 },
      { field: "geoName", headerName: "Geography", width: 150 },
      { field: "country", headerName: "Country", width: 150 },
      {
        field: "hrID",
        headerName: "HR Name",
        width: 150,
        valueGetter: getHrName,
      },
      {
        field: "managerID",
        headerName: "Manager Name",
        width: 150,
        valueGetter: getManagerName,
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 250,
        renderCell: renderCellExpand,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        renderCell: (params) => {
          return (
            !params?.row?.customerClientActivity?.isActive && (
              <FormSubmitButton
                type="submit"
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  let data = { id: params?.row?.excoCustomerUser?.id };
                  dispatch(resendEmail(data)).then((res) => {
                    if (res?.payload?.data?.ok) {
                      setTimeout(() => {
                        dispatch(resetMessage());
                      }, 2000);
                    }
                    if (res?.payload?.error) {
                      setTimeout(() => {
                        dispatch(resetErrMessage());
                      }, 3000);
                    }
                  });
                }}
              >
                {" "}
                Re-Send Mail
              </FormSubmitButton>
            )
          );
        },
      },
    ],
  };

  const formik = useFormik({
    initialValues: {
      account: "",
      firstName: "",
      lastName: "",
      customerName: "",
      geography: "",
      country: "",
      designation: "",
      contactEmail: "",
      dialingCode: "",
      contactNumber: "",
      roles: [],
      hr: "",
      manager: "",
      comments: "",
    },

    validationSchema:
      role.length > 0 &&
        typeof role.find((item) => item.role === "CLIENT") !== "undefined" &&
        Object.keys(role.find((item) => item.role === "CLIENT")).length > 0
        ? validationSchemaRoleClient
        : validationSchemaRoleNotClient,
    onSubmit: (values, onSubmitProps) => {
      let roles = [];
      roles.push();
      dispatch(
        createCustUser(
          typeof formik?.values?.roles?.find(
            (item) => item.role === "CLIENT"
          ) !== "undefined" &&
            Object.keys(
              formik?.values?.roles?.find((item) => item.role === "CLIENT")
            ).length > 0
            ? {
              accountId: values.account?.id,
              clientFirstName: values.firstName,
              clientLastName: values.lastName,
              geoName: values.geography?.code,
              country: values.country?.name,
              designation: values.designation,
              contactEmail: values.contactEmail,
              dialingCode: values.dialingCode ? values.dialingCode : null,
              contactNumber: values.contactNumber
                ? values.contactNumber.toString()
                : null,
              roles: values.roles.map((item) => item.role),
              hrID: values.hr?.excoCustomerUser?.id,
              managerID: values.manager?.excoCustomerUser?.id,
              comments: values?.comments,
            }
            : {
              accountId: values.account?.id,
              clientFirstName: values.firstName,
              clientLastName: values.lastName,
              geoName: values.geography?.code,
              country: values.country?.name,
              designation: values.designation,
              contactEmail: values.contactEmail,
              dialingCode: values.dialingCode ? values.dialingCode : null,
              contactNumber: values.contactNumber
                ? values.contactNumber.toString()
                : null,
              roles: values.roles.map((item) => item.role),
              comments: values?.comments,
            }
        )
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getCustUser());
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,

    initialValues: {
      account: selectedData?.excoCustomerUser?.excoAccount?.id,
      firstName: selectedData?.firstName,
      lastName: selectedData?.lastName,
      geography: selectedData?.geoName,
      country: selectedData?.country,
      designation: selectedData?.designation,
      contactEmail: selectedData?.excoCustomerUser?.email,
      dialingCode: selectedData?.dialingCode,
      contactNumber: selectedData?.contactNumber,
      roles:
        typeof selectedData?.excoCustomerUserRole !== "undefined" &&
        selectedData?.excoCustomerUserRole.length > 0 &&
        selectedData?.excoCustomerUserRole.map((item) => item.role),
      hr: selectedData?.excoCustomerUser?.reportingHR?.id,
      manager: selectedData?.excoCustomerUser?.reportingManager?.id,
      comments: selectedData?.comments,
    },

    validationSchema:
      role.length > 0 &&
        typeof role.find((item) => item === "CLIENT") !== "undefined" &&
        Object.keys(role.find((item) => item === "CLIENT")).length > 0
        ? validationEditSchemaRoleClient
        : validationEditSchemaRoleNotClient,
    onSubmit: (values) => {
      let roles = [];
      roles.push(values.roles);
      dispatch(
        updateCustUser(
          typeof formikEdit?.values?.roles.find((item) => item === "CLIENT") !==
            "undefined" &&
            Object.keys(
              formikEdit?.values?.roles.find((item) => item === "CLIENT")
            ).length > 0
            ? {
              id: selectedData?.excoCustomerUser?.id,
              clientFirstName: values.firstName,
              clientLastName: values.lastName,
              geoName: values.geography,
              country: values.country,
              designation: values.designation,
              contactEmail: values.contactEmail,
              dialingCode: values.dialingCode ? values.dialingCode : null,
              contactNumber: values.contactNumber
                ? values.contactNumber.toString()
                : null,
              roles: values.roles,
              hrID: values.hr,
              managerID: values.manager,
              comments: values?.comments,
            }
            : {
              id: selectedData?.excoCustomerUser?.id,
              clientFirstName: values.firstName,
              clientLastName: values.lastName,
              geoName: values.geography,
              country: values.country,
              designation: values.designation,
              contactEmail: values.contactEmail,
              dialingCode: values.dialingCode ? values.dialingCode : null,
              contactNumber: values.contactNumber
                ? values.contactNumber.toString()
                : null,
              roles: values.roles,
              comments: values?.comments,
            }
        )
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getCustUser());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getCustUser());
    dispatch(getAccount());
    dispatch(getCustUserRoles());
  }, []);

  useEffect(() => {
    if (!formik?.values?.geography) {
      formik.setFieldValue("country", "")
      formik.setFieldValue("dialingCode", "")

    }
    let countries = [];
    options.map((option) => {
      if (option.continent === formik?.values?.geography?.code) {
        return countries.push(option);
      }
    });
    setCountryArr(countries);
  }, [formik?.values?.geography]);

  useEffect(() => {
    if (!formik?.values?.country) {

      formik.setFieldValue("dialingCode", "")

    }
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("dialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    if (
      formik?.values?.roles?.length > 0 &&
      typeof formik?.values?.roles?.find((item) => item.role === "CLIENT") !==
      "undefined" &&
      Object.keys(formik?.values?.roles?.find((item) => item.role === "CLIENT"))
        .length > 0
    ) {
      dispatch(getCustHrByAcc(formik?.values?.account?.id));
      dispatch(getCustManagerByAcc(formik?.values?.account?.id));
    }
    setRole(formik?.values?.roles);
  }, [formik?.values?.roles]);

  useEffect(() => {
   
    formikEdit.setFieldValue("dialingCode", "")

  }, [formikEdit?.values?.geography])


  useEffect(() => {
    if (!formikEdit?.values?.country) {
      formikEdit.setFieldValue("dialingCode", "")

    }
    options.map((option) => {
      if (option.name === formikEdit?.values?.country) {
        return formikEdit?.setFieldValue("dialingCode", option?.phone);
      }
    });
  }, [formikEdit?.values?.country]);

  useEffect(() => {
    if (
      formikEdit?.values?.roles?.length > 0 &&
      typeof formikEdit?.values?.roles.find((item) => item === "CLIENT") !==
      "undefined" &&
      Object.keys(formikEdit?.values?.roles.find((item) => item === "CLIENT"))
        .length > 0
    ) {
      dispatch(getCustHrByAcc(formikEdit?.values?.account));
      dispatch(getCustManagerByAcc(formikEdit?.values?.account));
    }
    setRole(formikEdit?.values?.roles);
  }, [formikEdit?.values?.roles]);

  const classes = useStyles();

  console.log(formik.errors)
  return (
    <Layout>
      <>
        <PageHeader variant="pageHeader">Users Management</PageHeader>
        <MuiDataGrid
          setShowAdd={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "create"
            ) && showAddModel
          }
          setShowEdit={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "update"
            ) && showEditModal
          }
          setShowDelete={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "delete"
            ) && showDelete
          }
          toolbarColumn={true}
          toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>
      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div className={classes.responsiveText}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  tabIndex="1"
                  name="account"
                  options={accountData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.account}
                  optionValue="accountName"
                  label="Account Name"
                  required={true}
                  error={
                    formik.touched.account && Boolean(formik.errors.account)
                  }
                  helperText={formik.touched.account && formik.errors.account}
                />
                <MyTextInput
                  tabIndex="4"
                  label="Email"
                  type="contactEmail"
                  name="contactEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactEmail"
                  value={formik.values.contactEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactEmail &&
                    Boolean(formik.errors.contactEmail)
                  }
                  helperText={
                    formik.touched.contactEmail && formik.errors.contactEmail
                  }
                />

                <MuiAutocomplete
                  tabIndex="6"
                  name="geography"
                  options={geographyArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.geography}
                  optionValue="code"
                  optionSecValue="name"
                  label="Geography"
                  required={true}
                  error={
                    formik.touched.geography && Boolean(formik.errors.geography)
                  }
                  helperText={
                    formik.touched.geography && formik.errors.geography
                  }
                />
                <Autocomplete
                  tabIndex="8"
                  name="roles"
                  multiple
                  id="tags-outlined"
                  options={custUserRolesData}
                  getOptionLabel={(option) => option.role}
                  value={formik.values.roles}
                  onChange={(event, newValue) =>
                    formik.setFieldValue("roles", [...newValue])
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Roles/Access"
                      required={true}
                      error={
                        formik.touched.roles && Boolean(formik.errors.roles)
                      }
                      helperText={formik.touched.roles && formik.errors.roles}
                    />
                  )}
                  sx={{ marginTop: "1rem" }}
                />

                {formik?.values?.roles.length > 0 &&
                  typeof formik?.values?.roles?.find(
                    (item) => item.role === "CLIENT"
                  ) !== "undefined" &&
                  Object.keys(
                    formik?.values?.roles?.find(
                      (item) => item.role === "CLIENT"
                    )
                  ).length > 0 && (
                    <MuiAutocomplete
                      tabIndex="11"
                      name="hr"
                      options={custHrData}
                      setValue={(name, newValue) =>
                        formik.setFieldValue(name, newValue)
                      }
                      value={formik.values.hr}
                      optionValue="firstName"
                      optionSecValue="lastName"
                      label="HR Name"
                      required={true}
                      error={formik.touched.hr && Boolean(formik.errors.hr)}
                      helperText={formik.touched.hr && formik.errors.hr}
                    />
                  )}
                <MyTextInput
                  tabIndex="13"
                  label="Comments"
                  type="text"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}

                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "0.5rem" }}>
                    <MyTextInput
                      tabIndex="2"
                      label="First Name"
                      type="text"
                      name="firstName"
                      required={true}
                      margin="normal"
                      id="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </div>

                  <div>
                    <MyTextInput
                      tabIndex="3"
                      label="Last Name"
                      type="text"
                      name="lastName"
                      required={true}
                      margin="normal"
                      id="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </div>
                </div>
                <MyTextInput
                  tabIndex="5"
                  label="Title/Position"
                  type="designation"
                  name="designation"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="designation"
                  value={formik.values.designation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.designation &&
                    Boolean(formik.errors.designation)
                  }
                  helperText={
                    formik.touched.designation && formik.errors.designation
                  }
                />

                <MuiAutocomplete
                  tabIndex="7"
                  name="country"
                  options={countryArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.country}
                  optionValue="name"
                  label="Country"
                  required={true}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      tabIndex="9"
                      label="Dialing Code"
                      type="number"
                      name="dialingCode"
                      // required={true}
                      fullWidth={true}
                      margin="normal"
                      id="dialingCode"
                      value={formik.values.dialingCode}
                      disabled
                      error={
                        formik.touched.dialingCode &&
                        Boolean(formik.errors.dialingCode)
                      }
                      helperText={
                        formik.touched.dialingCode && formik.errors.dialingCode
                      }
                    />
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      tabIndex="10"
                      label="Contact Number"
                      type="number"
                      name="contactNumber"
                      // required={true}
                      fullWidth={true}
                      margin="normal"
                      id="contactNumber"
                      value={formik.values.contactNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.contactNumber &&
                        Boolean(formik.errors.contactNumber)
                      }
                      helperText={
                        formik.touched.contactNumber &&
                        formik.errors.contactNumber
                      }
                    />
                  </div>
                </div>
                {formik?.values?.roles?.length > 0 &&
                  typeof formik?.values?.roles?.find(
                    (item) => item.role === "CLIENT"
                  ) !== "undefined" &&
                  Object.keys(
                    formik?.values?.roles?.find(
                      (item) => item.role === "CLIENT"
                    )
                  ).length > 0 && (
                    <MuiAutocomplete
                      tabIndex="12"
                      name="manager"
                      options={custManagerData}
                      setValue={(name, newValue) =>
                        formik.setFieldValue(name, newValue)
                      }
                      value={formik.values.manager}
                      optionValue="firstName"
                      optionSecValue="lastName"
                      label="Manager Name"
                      error={
                        formik.touched.manager && Boolean(formik.errors.manager)
                      }
                      helperText={
                        formik.touched.manager && formik.errors.manager
                      }
                    />
                  )}
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={closeEditModal}
        closeModel={closeEditModal}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={closeEditModal} />
          <div className={classes.responsiveText}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Account Name *
                  </InputLabel>
                  <Select
                    tabIndex="1"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="account"
                    defaultValue={formikEdit?.values?.account}
                    value={formikEdit.values.account}
                    error={
                      formikEdit.touched.account &&
                      Boolean(formikEdit.errors.account)
                    }
                    label="Account Name *"
                    onChange={formikEdit.handleChange}
                    disabled={true}
                  >
                    {accountData.length > 0 &&
                      accountData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.accountName}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.account && formikEdit.errors.account}
                  </FormHelperText>
                </FormControl>
                <MyTextInput
                  tabIndex="4"

                  label="Contact Email"
                  type="contactEmail"
                  name="contactEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactEmail"
                  value={formikEdit.values.contactEmail}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.contactEmail &&
                    Boolean(formikEdit.errors.contactEmail)
                  }
                  helperText={
                    formikEdit.touched.contactEmail &&
                    formikEdit.errors.contactEmail
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Geography *
                  </InputLabel>
                  <Select
                    tabIndex="6"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="geography"
                    defaultValue={formikEdit?.values?.geography?.toString()}
                    value={formikEdit.values.geography}
                    error={
                      formikEdit.touched.geography &&
                      Boolean(formikEdit.errors.geography)
                    }
                    label="Geography *"
                    onChange={formikEdit.handleChange}
                  >
                    {Object.keys(continents).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}-{continents[item]}
                      </MenuItem>
                    ))}
                  </Select>
                  {formikEdit?.errors?.geography && (
                    <FormHelperText error={true} sx={{ color: "red" }}>
                      {formikEdit.touched.geography &&
                        formikEdit.errors.geography}
                    </FormHelperText>
                  )}
                </FormControl>
                <Autocomplete
                  tabIndex="8"
                  name="roles"
                  multiple
                  id="tags-outlined"
                  options={custUserRolesData.map((option) => option.role)}
                  defaultValue={formikEdit.values.roles}
                  onChange={(event, newValue) =>
                    formikEdit.setFieldValue("roles", [...newValue])
                  }
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Roles/Access"
                      required={true}
                      error={
                        formikEdit.touched.roles &&
                        Boolean(formikEdit.errors.roles)
                      }
                      helperText={
                        formikEdit.touched.roles && formikEdit.errors.roles
                      }
                    />
                  )}
                  sx={{ marginTop: "1rem" }}
                />
                {formikEdit?.values?.roles?.length > 0 &&
                  typeof formikEdit?.values?.roles.find(
                    (item) => item === "CLIENT"
                  ) !== "undefined" &&
                  Object.keys(
                    formikEdit?.values?.roles.find((item) => item === "CLIENT")
                  ).length > 0 && (
                    <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        HR Name *
                      </InputLabel>
                      <Select
                        tabIndex="11"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="hr"
                        defaultValue={formikEdit?.values?.hr}
                        value={formikEdit.values.hr}
                        label="HR Name "
                        required={true}
                        onChange={formikEdit.handleChange}
                        error={
                          formikEdit.touched.hr && Boolean(formikEdit.errors.hr)
                        }
                      >
                        {custHrData
                          .filter(
                            (value) =>
                              value.excoCustomerUser?.id !==
                              selectedData?.excoCustomerUser?.id
                          )
                          .map((option) => {
                            return (
                              <MenuItem
                                key={option.id}
                                value={option.excoCustomerUser?.id}
                              >
                                {option.firstName} {option?.lastName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {formikEdit?.errors?.hr && (
                        <FormHelperText error={true} sx={{ color: "red" }}>
                          {formikEdit.touched.hr && formikEdit.errors.hr}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                <MyTextInput
                  tabIndex="13"
                  label="Comments"
                  type="text"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formikEdit.values.comments}
                  onChange={formikEdit.handleChange}

                  error={
                    formikEdit.touched.comments &&
                    Boolean(formikEdit.errors.comments)
                  }
                  helperText={
                    formikEdit.touched.comments && formikEdit.errors.comments
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "0.5rem" }}>
                    <MyTextInput
                      tabIndex="2"
                      label="Client First Name"
                      type="text"
                      name="firstName"
                      required={true}
                      margin="normal"
                      id="firstName"
                      value={formikEdit.values.firstName}
                      onChange={formikEdit.handleChange}
                      error={
                        formikEdit.touched.firstName &&
                        Boolean(formikEdit.errors.firstName)
                      }
                      helperText={
                        formikEdit.touched.firstName &&
                        formikEdit.errors.firstName
                      }
                    />
                  </div>

                  <div>
                    <MyTextInput
                      tabIndex="3"
                      label="Client Last Name"
                      type="text"
                      name="lastName"
                      required={true}
                      margin="normal"
                      id="lastName"
                      value={formikEdit.values.lastName}
                      onChange={formikEdit.handleChange}
                      error={
                        formikEdit.touched.lastName &&
                        Boolean(formikEdit.errors.lastName)
                      }
                      helperText={
                        formikEdit.touched.lastName &&
                        formikEdit.errors.lastName
                      }
                    />
                  </div>
                </div>
                <MyTextInput
                  tabIndex="5"
                  label="Title/Position"
                  type="designation"
                  name="designation"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="designation"
                  value={formikEdit.values.designation}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.designation &&
                    Boolean(formikEdit.errors.designation)
                  }
                  helperText={
                    formikEdit.touched.designation &&
                    formikEdit.errors.designation
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Country *
                  </InputLabel>
                  <Select
                    tabIndex="7"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    defaultValue={formikEdit.values.country}
                    value={formikEdit.values.country}
                    label="Country *"
                    onChange={formikEdit.handleChange}
                    error={
                      formikEdit.touched.country &&
                      Boolean(formikEdit.errors.country)
                    }
                  >
                    {options.map((option) => {
                      if (formikEdit.values.geography === option.continent) {
                        return (
                          <MenuItem key={option.name} value={option.name}>
                            {option.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  {formikEdit?.errors?.country && (
                    <FormHelperText error={true} sx={{ color: "red" }}>
                      {formikEdit.touched.country && formikEdit.errors.country}
                    </FormHelperText>
                  )}
                </FormControl>

                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      tabIndex="9"
                      label="Dialing Code"
                      type="number"
                      name="dialingCode"
                      // required={true}
                      fullWidth={true}
                      margin="normal"
                      id="dialingCode"
                      value={formikEdit.values.dialingCode}
                      disabled
                      error={
                        formikEdit.touched.dialingCode &&
                        Boolean(formikEdit.errors.dialingCode)
                      }
                      helperText={
                        formikEdit.touched.dialingCode &&
                        formikEdit.errors.dialingCode
                      }
                    />
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      tabIndex="10"
                      label="Contact Number"
                      type="number"
                      name="contactNumber"
                      // required={true}
                      fullWidth={true}
                      margin="normal"
                      id="contactNumber"
                      value={formikEdit.values.contactNumber}
                      onChange={formikEdit.handleChange}
                      error={
                        formikEdit.touched.contactNumber &&
                        Boolean(formikEdit.errors.contactNumber)
                      }
                      helperText={
                        formikEdit.touched.contactNumber &&
                        formikEdit.errors.contactNumber
                      }
                    />
                  </div>
                </div>
                {formikEdit?.values?.roles?.length > 0 &&
                  typeof formikEdit?.values?.roles.find(
                    (item) => item === "CLIENT"
                  ) !== "undefined" &&
                  Object.keys(
                    formikEdit?.values?.roles.find((item) => item === "CLIENT")
                  ).length > 0 && (
                    <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Manager Name
                      </InputLabel>
                      <Select
                        tabIndex="12"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="manager"
                        value={formikEdit.values.manager}
                        label="Manager Name "
                        onChange={formikEdit.handleChange}
                        error={
                          formikEdit.touched.manager &&
                          Boolean(formikEdit.errors.manager)
                        }
                      >
                        {custManagerData
                          .filter(
                            (value) =>
                              value?.excoCustomerUser?.id !==
                              selectedData?.excoCustomerUser?.id
                          )
                          .map((option) => {
                            return (
                              <MenuItem
                                key={option.id}
                                value={option.excoCustomerUser?.id}
                              >
                                {option.firstName} {option?.lastName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {formikEdit?.errors?.manager && (
                        <FormHelperText error={true} sx={{ color: "red" }}>
                          {formikEdit.touched.manager &&
                            formikEdit.errors.manager}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Onboarding;
