import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_NPS_RATING } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createNpsRating = createAsyncThunk(
  "npsRating/createNpsRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_NPS_RATING, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNpsRating = createAsyncThunk(
  "npsRating/getNpsRating",
  async () => {
    return axios.get(CURD_NPS_RATING, { withCredentials: true });
  }
);

export const updateNpsRating = createAsyncThunk(
  "npsRating/updateNpsRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_NPS_RATING, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNpsRating = createAsyncThunk(
  "npsRating/deleteNpsRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_NPS_RATING, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const npsRatingSlice = createSlice({
  name: "npsRating",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    npsRatingData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createNpsRating.pending]: (state, action) => {
      state.loading = true;
    },
    [createNpsRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createNpsRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getNpsRating.pending]: (state, action) => {
      state.loading = true;
    },
    [getNpsRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.npsRatingData = action?.payload?.data;
    },
    [getNpsRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateNpsRating.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNpsRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateNpsRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteNpsRating.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteNpsRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteNpsRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  npsRatingSlice.actions;
export default npsRatingSlice.reducer;
