import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ENGAGEMENT_DELIVERABLE, GET_ENGAGEMENT_DELIVERABLE_BY_TIER } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createEngagementDeliverable = createAsyncThunk(
  "engagementDeliverable/createEngagementDeliverable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ENGAGEMENT_DELIVERABLE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementDeliverable = createAsyncThunk(
  "engagementDeliverable/getEngagementDeliverable",
  async () => {
    return axios.get(CURD_ENGAGEMENT_DELIVERABLE, { withCredentials: true });
  }
);

export const getEngagementDeliverableByTier = createAsyncThunk(
  "engagementDeliverable/getEngagementDeliverableByTier",
  async (id) => {
    return axios.get(`${GET_ENGAGEMENT_DELIVERABLE_BY_TIER}/${id}`, { withCredentials: true });
  }
);

export const updateEngagementDeliverable = createAsyncThunk(
  "engagementDeliverable/updateEngagementDeliverable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ENGAGEMENT_DELIVERABLE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDeliverable = createAsyncThunk(
  "engagementDeliverable/deleteEngagementDeliverable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ENGAGEMENT_DELIVERABLE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementDeliverableSlice = createSlice({
  name: "engagementDeliverable",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engDeliverableData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    resetEngDeliverableData: (state) => {
state.engDeliverableData = []
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createEngagementDeliverable.pending]: (state, action) => {
      state.loading = true;
    },
    [createEngagementDeliverable.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createEngagementDeliverable.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getEngagementDeliverable.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementDeliverable.fulfilled]: (state, action) => {
      state.loading = false;
      state.engDeliverableData = action?.payload?.data;
    },
    [getEngagementDeliverable.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementDeliverableByTier.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementDeliverableByTier.fulfilled]: (state, action) => {
      state.loading = false;
      state.engDeliverableData = action?.payload?.data;
    },
    [getEngagementDeliverableByTier.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEngagementDeliverable.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEngagementDeliverable.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEngagementDeliverable.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDeliverable.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDeliverable.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDeliverable.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, resetEngDeliverableData, rowSelect } =
  engagementDeliverableSlice.actions;
export default engagementDeliverableSlice.reducer;
