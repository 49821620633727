import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalCloseBtn = (props) => {
  return (
    <IconButton
      sx={{ position: "absolute", left: "92%", top: "1%" }}
      onClick={() => props.close()}
    >
      <CloseIcon color="red" />
    </IconButton>
  );
};

export default ModalCloseBtn;
