import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// material ui
import { Grid, Stack, Typography } from "@mui/material";
// components
import Layout from "../../layout/Layout";
import { MuiDataGrid, MuiAutocomplete, GridCellExpand } from "../../components";
// redux
import { getAllClients } from "../../features/customer/custUserSlice";
import {
  getCoachingFocusArea,
  getCoachingFocusAreaPurpose,
} from "../../features/coaching_focus_area/coachingFocusAreaSlice";

import { PageHeader } from "@excoleadershipui/pionweblib";
import { getToggleAdminData } from "../../features/auth/authSlice";


// -----------------------------------------------------
import { format } from "date-fns";

const CoachingFocusArea = () => {
  const [clientName, setClientName] = useState("");

  const { custClientsData } = useSelector((state) => ({
    ...state.custUser,
  }));

  const { coachingFocusAreaData, coachingFocusPurposeData } = useSelector(
    (state) => ({
      ...state.coachingFocusArea,
    })
  );
  const { toggleAdminData } = useSelector(
    (state) => ({
      ...state.auth,
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllClients());

  }, []);

  useEffect(() => {
    clientName &&
      dispatch(getCoachingFocusArea(clientName?.excoCustomerUser?.id));
    dispatch(getCoachingFocusAreaPurpose(clientName?.excoCustomerUser?.id));
    dispatch(getToggleAdminData(clientName?.excoCustomerUser?.id))
  }, [clientName]);

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const mentorGetDevValue = (params) => {

    return params?.row?.developmentAreas
      ?.developmentAreaName;
  };

  const mentorGetCoachingValue = (params) => {
    return params?.row?.developmentAreasCoachingFocus?.
      developmentAreaCoachingFocusName



  };
  const getCreatedOnDate = (params) => {
    return params.row.createdOn
        ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
        : "";
  };
  
  const getUpdatedOnDate = (params) => {
    return params.row.updatedOn
      ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
      : ""
  
  };

  const data = {
    rows: coachingFocusAreaData,
    columns: [
      {
        field: "developmentArea",
        headerName: "Leadership Dimension",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: mentorGetDevValue,
      },

      {
        field: "focus",
        headerName: "Sub-Category",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: mentorGetCoachingValue,
        hide: toggleAdminData?.length > 0 &&
        toggleAdminData[0]?.subCategory ? false : true
      },

      {
        field: "otherDevelopmentAreaCoachingFocus",
        headerName: "Focus Area",
        width: 150,
        // valueGetter: mentorGetCoachingValue,
        renderCell: renderCellExpand,
      },

    

      {
        field: "anticipatedStartDate",
        headerName: "Anticipated Start Date",
        width: 300,
        renderCell: renderCellExpand,
        hide: toggleAdminData?.length > 0 &&
        toggleAdminData[0]?.anticipatedStartDate ? false : true

      },
      {
        field: "expectedOutcome",
        headerName: "Expected Outcomes",
        width: 300,
        renderCell: renderCellExpand,
        hide: toggleAdminData?.length > 0 &&
        toggleAdminData[0]?.expectedOutcome ? false : true
      },
      {
        field: "isDateAdded",
        headerName: "Date Added",

        width: 200,
        valueGetter: getCreatedOnDate
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 350,

        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const dataTwo = {
    rows: coachingFocusPurposeData,
    columns: [
      {
        field: "purposeOfEngagment",
        headerName: "Purpose Of Engagement",
        width: 900,
        renderCell: (params) => (
          <div style={{ whiteSpace: "pre-line" }}>{params.value}</div>
        ),
      },
    ],
  };

  return (
    <Layout>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",

          fontFamily: "arial",
          marginBottom: "2rem",
        }}
      >
        <Stack
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <Typography
                sx={{ fontSize: "1.25rem", fontWeight: "700" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Focus Areas for
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Stack sx={{ width: "10rem", marginLeft: "0.5rem" }}>
                <MuiAutocomplete
                  name="clientName"
                  options={custClientsData}
                  setValue={(name, newValue) => setClientName(newValue)}
                  value={clientName}
                  optionValue="firstName"
                  optionSecValue="lastName"
                  sx={{ margin: 0, width: "15rem" }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      {clientName && (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            fontFamily: "arial",
            marginTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <PageHeader variant="pageHeader">
            Initial Engagement Purpose for{" "}
            {clientName &&
              `${clientName?.firstName} ${clientName?.lastName}  ${clientName?.excoCustomerUser?.excoAccount?.accountName},`}{" "}
          </PageHeader>
        </Stack>
      )}

      {clientName && (
        <Stack sx={{ marginTop: "0.5rem" }}>
          <MuiDataGrid
            isRowHeight={true}
            // submitCheck={submitCheck}
            height={250}
            data={dataTwo}
            toolbarColumn={false}
            toolbarFilter={false}
            toolbarDensity={false}
            toolbarExport={false}
          />
        </Stack>
      )}
      {clientName && (
        <PageHeader variant="pageHeader">
          {clientName?.firstName} {clientName?.lastName} from{" "}
          {clientName?.excoCustomerUser?.excoAccount?.accountName}, Focus Areas
        </PageHeader>
      )}
      {clientName && (
        <MuiDataGrid
          height={300}
          toolbarColumn={true}
          toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
        />
      )}
    </Layout>
  );
};

export default CoachingFocusArea;
