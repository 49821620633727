import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid
} from "@mui/material";
// components
import {
  ModalPopUp,
  MuiAutocomplete,
  FormSubmitButton,
  ModalCloseBtn,
  Notifier,
  ErrorNotifier,
  FormTextField as MyTextInput
} from "../../../components";
// redux
import {
  createNetPromoterScore,
  getCustUserNetPromoterScore,
  resetMessage,
  resetErrMessage,
} from "../../../features/exec/engagement/netPromoterScoreSlice";
import { getNpsRating } from "../../../features/config/npsRatingSlice";

// --------------------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  ratingScore: Yup.object().required("Rating Score is required"),
  comments: Yup.string(),
});

// ---------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ----------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
 
  rootbox : {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width:'20rem !important',
      height:'60% !important' ,
      display:'block !important',
      overflow:'scroll !important',
    },
    [theme.breakpoints.between('sm','md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft:'-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom:'1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText : {
    width:'50rem',
    [theme.breakpoints.down('md')]: {
      width:'100%'
      
    }

  },
  filter_grid : {
    [theme.breakpoints.down('md')]: {
      display:'flex !important',
      flexDirection:'column !important'
  
    },
  },
  close_btn : {
    [theme.breakpoints.down('md')]: {
    marginLeft:'-2.563rem'
  
    },
  }
  
    }))


const AddNetPromoterScore = (props) => {
  const dispatch = useDispatch();
  const { message, errMessage } = useSelector(
    (state) => ({ ...state.netPromoterScore })
  );

  const { npsRatingData } = useSelector((state) => ({
    ...state.npsRating,
  }));

  const formik = useFormik({
    initialValues: {
      ratingScore: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createNetPromoterScore({
          excoCustomerUserId: props?.custClient,
          npsRatingScore: values.ratingScore.npsRatingScore,
          npsRatingName: values.ratingScore.npsRatingName,
          comments: values?.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.closeModel();
            dispatch(
              getCustUserNetPromoterScore({
                excoCustomerUserId: props?.custClient,
              })
            );
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getNpsRating());
  }, []);
  const classes = useStyles();
  return (
    <>
      <ModalPopUp open={props.showAdd} closeModel={props.closeModel}>
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={props.closeModel} />
          <div className={classes.responsiveText}>
          <Grid container >
              <Grid item xs={12} sm={12} md={12}>
             <MuiAutocomplete
              name="ratingScore"
              options={npsRatingData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.ratingScore}
              optionValue="npsRatingScore"
              label="Rating Score"
              required={true}
              error={
                formik.touched.ratingScore && Boolean(formik.errors.ratingScore)
              }
              helperText={
                formik.touched.ratingScore && formik.errors.ratingScore
              }
            />
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
            </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  );
};

export default AddNetPromoterScore;
