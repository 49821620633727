import React, { useEffect } from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton, MuiAutocomplete } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import {
  createEngagementStage,
  getEngagementStageByTier,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/engagementStageSlice";
import { getEngTierName } from "../../../features/config/engTierNameSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  engagementTier: Yup.object().required("Engagement Tier is required"),
  engStageSequenceNo: Yup.number().required(
    "Eng Stage Sequence No. is required"
  ),
  engStageName: Yup.string().required("Eng Stage Name is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const AddEngagementStage = (props) => {
  const { message, errMessage } = useSelector((state) => ({
    ...state.engStage,
  }));
  const { engTierNameData } = useSelector((state) => ({
    ...state.engTierName,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      engagementTier: "",
      engStageSequenceNo: "",
      engStageName: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createEngagementStage({
          engagementTierId: values.engagementTier?.id,
          engagementStageSequenceNo: values.engStageSequenceNo,
          engagementStageName: values.engStageName,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            props?.engTier && dispatch(getEngagementStageByTier(props?.engTier?.id));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getEngTierName());
  }, []);

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">Add Engagement Stage</PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
              <MuiAutocomplete
              name="engagementTier"
              options={engTierNameData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.engagementTier}
              optionValue="engagementTierName"
              label="Engagement Tier"
              required={true}
              error={formik.touched.engagementTier && Boolean(formik.errors.engagementTier)}
              helperText={formik.touched.engagementTier && formik.errors.engagementTier}
            />
              <MyTextInput
                label="Eng Stage Sequence No"
                type="text"
                name="engStageSequenceNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engStageSequenceNo"
                value={formik.values.engStageSequenceNo}
                onChange={formik.handleChange}
                error={
                  formik.touched.engStageSequenceNo &&
                  Boolean(formik.errors.engStageSequenceNo)
                }
                helperText={
                  formik.touched.engStageSequenceNo &&
                  formik.errors.engStageSequenceNo
                }
              />
              <MyTextInput
                label="Eng Stage Name"
                type="text"
                name="engStageName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engStageName"
                value={formik.values.engStageName}
                onChange={formik.handleChange}
                error={
                  formik.touched.engStageName &&
                  Boolean(formik.errors.engStageName)
                }
                helperText={
                  formik.touched.engStageName && formik.errors.engStageName
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddEngagementStage;
