// import React, { useRef, useEffect } from 'react';
// import Chart from 'chart.js/auto';
// import { Stack } from '@mui/material';

// const SpeedoMeter = ({ fixHeight, ...others }) => {
//   // const chartContainer = useRef(null);
//   // let myChart = null;

//   // useEffect(() => {
//   //   if (chartContainer.current) {
//   //     if (myChart) {
//   //       myChart.destroy(); // Destroy the existing chart before creating a new one
//   //     }

//   //     const doughnutPlugin = {
//   //       id: 'doughnut',
//   //       afterDraw: (chart) => {
//   //         const needleValue = 500; // Modify needle value as needed

//   //         const dataTotal = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
//   //         const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
//   //         const ctx = chart.ctx;
//   //         const cw = chart.canvas.offsetWidth;
//   //         const ch = chart.canvas.offsetHeight - fixHeight;

//   //         const cx = cw / 2;
//   //         const cy = ch / 2; // Center vertically

//   //         ctx.translate(cx, cy);
//   //         ctx.rotate(angle);
//   //         ctx.beginPath();
//   //         ctx.moveTo(0, -3);
//   //         ctx.lineTo(ch - 5, 0);
//   //         ctx.lineTo(0, 3);
//   //         ctx.fillStyle = 'rgb(0, 0, 0)';
//   //         ctx.fill();
//   //         ctx.rotate(-angle);
//   //         ctx.translate(-cx, -cy);
//   //         ctx.beginPath();
//   //         ctx.arc(cx, cy, 5, 0, Math.PI * 2);
//   //         ctx.fill();
//   //       },
//   //     };

//   //     const ctx = chartContainer.current.getContext('2d');
//   //     myChart = new Chart(ctx, {
//   //       type: 'doughnut',
//   //       data: {
//   //         datasets: [
//   //           {
//   //             data: [300, 300, 300],
//   //             backgroundColor: ['red', '#FFCE56', 'lightgreen'],
//   //             hoverBackgroundColor: ['red', '#FFCE56', 'lightgreen'],
//   //           },
//   //         ],
//   //       },
//   //       options: {
//   //         layout: {},
//   //         rotation: -95,
//   //         circumference: 60 * Math.PI,
//   //         legend: {
//   //           display: false,
//   //         },
//   //         cutoutPercentage: 70,
//   //         plugins: {
//   //           doughnut: {},
//   //         },
//   //       },
//   //       plugins: [doughnutPlugin],
//   //     });
//   //   }

//   //   return () => {
//   //     if (myChart) {
//   //       myChart.destroy(); // Destroy the chart instance when the component unmounts
//   //     }
//   //   };
//   // }, [fixHeight]);

//   return (
//     <Stack {...others}>
//       {/* <canvas ref={chartContainer} width={200} height={200} /> Set width and height as needed */}

//     </Stack>
//   );
// };

// export default SpeedoMeter;

// import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
// import { Doughnut } from "react-chartjs-2";
// import React from "react";
// import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
// // --------------------------------------------------------------------------------
// // const DonutMemo = (({ dataReports }) => {
// //   return <Doughnut data={dataReports} options={dataReports.options} />;
// // });
// const DonutMemo = React.memo(({ dataReports }) => {
//   return <Doughnut data={dataReports} options={dataReports.options} />;
// });
// // --------------------------------------------------------------------------------
// const SpeedoMeter = ({
//   hoverLeftColor,
//   hoverMiddleColor,
//   hoverRightColor,
//   leftColor,
//   middleColor,
//   rightColor,
//   fixHeight,
//   needlePosition,
//   needleValue,
//   needleHeight,
//   title,
//   ...others
// }) => {
//   // fixHeight, ...others
//   ChartJS.register(ArcElement, Legend);

//   const dataReports = {
//     // labels: ['Red', 'Yellow', 'Green'],
//     datasets: [
//       {
//         data: [300, 300, 300],
//         label: 'My Data',
//         needleValue: Boolean(needleValue) ? needleValue : 150, //checkInRating,
//         // Change This for Meter
//         // backgroundColor: ["red", "#FFCE56", "lightgreen"],
//         // hoverBackgroundColor: ["red", "#FFCE56", "lightgreen"],
//         backgroundColor: [
//           leftColor ? leftColor : "red",
//           middleColor ? middleColor : "#FFCE56",
//           rightColor ? rightColor : "lightgreen",
//         ],
//         // hoverBackgroundColor: ["red", "#FFCE56", "lightgreen"],
//         hoverBackgroundColor: [
//           hoverLeftColor ? hoverLeftColor : "red",
//           hoverMiddleColor ? hoverMiddleColor : "#FFCE56",
//           hoverRightColor ? hoverRightColor : "lightgreen",
//         ],
//       },
//     ],
//     options: {
//       layout: {},
//       rotation: -95,
//       circumference: 60 * Math.PI,
//       legend: {
//         display: false,
//       },
//       cutoutPercentage: 70,
//       plugins: {
//         tooltip: {
//           enabled: true,
//           callbacks: {
//             label: function (context) {
//               console.log("label");
//               let label = context.dataset.label || "";

//               // if (context.parsed.y !== null) {
//               //   label += `Value: ${context.parsed.y}`;
//               // }
//               return label;
//             },
//           },
//         },
//       },
//     },
//   };

//   // const mactchesReport = useMediaQuery((theme) => theme.breakpoints.down("md"));
//   // let fixHeight = mactchesReport ? 200 : 180;

//   ChartJS.register({
//     id: "doughnut",
//     afterDraw: (chart, args, opts) => {
//       var needleValue = chart.config.data.datasets[0].needleValue;
//       var dataTotal = chart.config.data.datasets[0].data.reduce(
//         (a, b) => a + b,
//         0
//       );
//       var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
//       var ctx = chart.ctx;
//       var cw = chart.canvas.offsetWidth;
//       var ch = chart.canvas.offsetHeight - fixHeight;

//       var cx = cw / 2;
//       var cy = needlePosition ? needlePosition : ch + 95;

//       // console.log("cy ", cy);
//       // console.log("needlePosition ", needlePosition);
//       ctx.translate(cx, cy);
//       ctx.rotate(angle);
//       ctx.beginPath();
//       ctx.moveTo(0, -1.5);
//       ctx.lineTo(needleHeight ? needleHeight : ch - 5, 0);
//       ctx.lineTo(0, 1.5);
//       ctx.fillStyle = "rgb(0, 0, 0)";
//       ctx.fill();
//       ctx.rotate(-angle);
//       ctx.translate(-cx, -cy);
//       ctx.beginPath();
//       ctx.arc(cx, cy, 5, 0, Math.PI * 2);
//       ctx.fill();
//     },
//     onHover: (evt, elements) => {
//       console.log('Hovering over needle:');
//       if (elements.length === 0) {
//         return;
//       }

//       const needleElement = elements[0];
//       const needleValue1 = needleElement.dataset.needleValue;
//       const label = needleElement.dataset.label;

//       // Display the label if the cursor is hovering over the needle
//       if (needleElement.element.inRange(evt.x, evt.y)) {
//         console.log('Hovering over needle:', label);
//         // Show label here
//       }
//     }
//   });

//   return (
//     <div sx={{ textAlign: "center" }}>
//       <Typography variant="body2" sx={{ textAlign: "center" }}>
//         {title}
//       </Typography>
//       <Stack {...others}>
//         <DonutMemo dataReports={dataReports} />
//       </Stack>
//     </div>
//   );
// };

// export default SpeedoMeter;

// // // const handleCheckinChange = async (e) => {
// // //     setCheckinValue(e.target.value);
// // //     if (e.target.value === "Red") {
// // //       setCheckinRating(150);
// // //     } else if (e.target.value === "Yellow") {
// // //       setCheckinRating(450);
// // //     } else if (e.target.value === "Green") {
// // //       setCheckinRating(750);
// // //     }

// // //     await dispatch(
// // //       handleUpdateRating(
// // //         (e.target.value === "Red" && 1) ||
// // //           (e.target.value === "Yellow" && 2) ||
// // //           (e.target.value === "Green" && 3)
// // //       )
// // //     );
// // //   };

import { Chart as ChartJS, ArcElement, Legend, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";

// --------------------------------------------------------------------------------
// const DonutMemo = (({ dataReports }) => {
//   return <Doughnut data={dataReports} options={dataReports.options} />;
// });
const DonutMemo = React.memo(({ dataReports }) => {
  return <Doughnut data={dataReports} options={dataReports.options} />;
});
// --------------------------------------------------------------------------------

ChartJS.register(ArcElement, Legend, ...registerables);

const SpeedoMeter = ({
  hoverLeftColor,
  hoverMiddleColor,
  hoverRightColor,
  leftColor,
  middleColor,
  rightColor,
  fixHeight,
  needlePosition,
  needleValue,
  needleHeight,
  title,
  ...others
}) => {
  // ChartJS.register(ArcElement, Legend);
  // ChartJS.register(ArcElement, Legend);

  const chartRef = useRef(null);
  const [hover, setHover] = useState(false);
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const data = {
      // labels: ['Red', 'Yellow', 'Green'],
      datasets: [
        {
          data: [300, 300, 300],
          label: "My Data",
          // needleValue: Boolean(needleValue) ? needleValue > 900 ? 900 : needleValue < 0 ? 0 : 0: 0,
          needleValue: needleValue,
          backgroundColor: ["red", "#FFCE56", "lightgreen"],
          hoverBackgroundColor: ["red", "#FFCE56", "lightgreen"],
        },
      ],
    };

    const options = {
      layout: {},
      rotation: -95,
      circumference: 60 * Math.PI,
      legend: {
        display: false,
      },
      cutoutPercentage: 70,

      plugins: {
        tooltip: {
          enabled: false,
          callbacks: {
            title: (tooltipItems) => {},
          },
        },
      },
    };
    ChartJS.register({
      id: "doughnut",
      afterDraw: (chart, args, opts) => {
        var needleValue = chart.config.data.datasets[0].needleValue;
        var dataTotal = chart.config.data.datasets[0].data.reduce(
          (a, b) => a + b,
          0
        );
        var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
        var ctx = chart.ctx;
        var cw = chart.canvas.offsetWidth;
        var ch = chart.canvas.offsetHeight - fixHeight;

        var cx = cw / 2;
        var cy = needlePosition ? needlePosition : ch + 95;

        // console.log("cy ", cy);
        // console.log("needlePosition ", needlePosition);
        ctx.translate(cx, cy);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(0, -1.5);
        ctx.lineTo(needleHeight ? needleHeight : ch - 5, 0);
        ctx.lineTo(0, 1.5);
        ctx.fillStyle = "rgb(0, 0, 0)";
        ctx.fill();
        ctx.rotate(-angle);
        ctx.translate(-cx, -cy);
        ctx.beginPath();
        ctx.arc(cx, cy, 5, 0, Math.PI * 2);
        ctx.fill();
      },
    });

    const config = {
      type: "doughnut",
      data: data,
      options: options,
    };

    // const options= {
    //   layout: {},
    //   rotation: -95,
    //   circumference: 60 * Math.PI,
    //   legend: {
    //     display: false,
    //   },
    //   cutoutPercentage: 70,
    //   plugins: {
    //     tooltip: {
    //       callbacks: {
    //         label: (context) => {
    //           console.log("coming")
    //           const label = `Needle Value: ${context.raw}`;
    //           return label;
    //         },
    //       },
    //     },
    //   },
    // }

    const myChart = new ChartJS(ctx, config);

    const handleMouseMove = (event) => {
      const canvasPosition = chartRef.current.getBoundingClientRect();
      const offsetX = event.clientX - canvasPosition.left;
      const offsetY = event.clientY - canvasPosition.top;

      const needleRadius = 5; // Adjust this value based on your needle design

      const needleCenterX = myChart.width / 2;
      const needleCenterY = needlePosition
        ? needlePosition
        : myChart.height + 95;

      const distance = Math.sqrt(
        Math.pow(offsetX - needleCenterX, 2) +
          Math.pow(offsetY - needleCenterY, 2)
      );

      if (distance <= needleRadius) {
        setHover(true);
        // console.log('Hovering over needle:', myChart.data.datasets[0].needleValue);
        // Trigger actions or show tooltip here
      } else {
        setHover(false);
        // console.log('Not hovering over needle');
        // Hide tooltip or reset actions here
      }
    };
    chartRef !== null &&
      chartRef.current.addEventListener("mousemove", handleMouseMove);

    return () => {
      // chartRef !== null && chartRef !== undefined && chartRef.current.removeEventListener('mousemove', handleMouseMove);
      myChart.destroy();
    };
  }, [fixHeight, needlePosition, needleValue, needleHeight]);

  return (
    <div>
      {/* <Typography className="speed_para" variant="body2" sx={{ textAlign: "center" }}>
        {title}
      </Typography> */}
      <Stack {...others}>
        <Tooltip title={hover ? needleValue : ""} placement="right">
          <canvas
            className=""
            ref={chartRef}
            width={100}
            // data={dataReports}
            height={100}
          />
        </Tooltip>
      </Stack>
    </div>
  );
};

export default SpeedoMeter;
