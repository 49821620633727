import React, { useEffect } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";

import { DateTime } from "luxon";
// material ui
import {
  Card,
  CardHeader,
  Autocomplete,
  TextField,
  IconButton,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import {
  FormSubmitButton,
  Notifier,
  ErrorNotifier,
  FormTextField as MyTextInput,
  MuiAutocomplete,
} from "../../../components";
// redux
import {
  updateEngagement,
  resetMessage,
  resetErrMessage,
  getEngagement,
  getNotEngagedClients,
  getEngagementByAcc,
} from "../../../features/exec/engagement/engagementSlice";
import { getAccount } from "../../../features/customer/accountSlice";
import { getEngTierName } from "../../../features/config/engTierNameSlice";
import { getEngagementStatus } from "../../../features/config/engagementStatusSlice";
import { getMentor } from "../../../features/mentor/mentorSlice";

// ---------------------------------------------------------------------
import {
  getEngagementDeliverableByTier,
  resetEngDeliverableData,
} from "../../../features/config/engagementDeliverableSlice";

const validationSchema = Yup.object({
  account: Yup.string()
    .uuid("Enter valid UUID")
    .required("Customer Accounts is required"),
  custClient: Yup.string()
    .uuid("Enter valid id")
    .required("Customer Client Name is required"),
  engagementName: Yup.string().required("Engagement Name is required"),

  engTierName: Yup.object()
    .nullable()
    .required("Engagement Tier Name is required"),
  delieverableName: Yup.array()
    .nullable()
    .required("Delivarable Name is required"),
  managerName: Yup.string().required("Manager Name is required "),
  managerEmail: Yup.string()
    .email("Enter valid Email")
    .required("Manager Email is required"),
  // nextUpdateDue: Yup.string().required("Next Update Due date is required"),
  // engStartDate: Yup.string().required("Engagement Start Date is required"),
  contactName: Yup.string().required("HR Partner Name is required "),
  contactEmail: Yup.string()
    .email("Enter valid Email")
    .required("HR Partner Email is required"),

  // mentor: Yup.object().nullable().required("Mentor Name is required"),
  engagementStatus: Yup.string().required("Engagement Status is required"),
  comments: Yup.string(),
});

// ---------------------------------------------------------------------------------------------

const UpdateEngagement = (props) => {
  const { message, errMessage, notEngagedClientsData } = useSelector(
    (state) => ({ ...state.engagement })
  );

  const { accountData } = useSelector((state) => ({ ...state.account }));
  const { engTierNameData } = useSelector((state) => ({
    ...state.engTierName,
  }));
  const { engagementStatusData } = useSelector((state) => ({
    ...state.engStatus,
  }));

  const { mentorsData, selectedData } = useSelector((state) => ({
    ...state.mentor,
  }));

  const { engDeliverableData } = useSelector((state) => ({
    ...state.engDeliverable,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mentor:
        props.values?.clientMentorMapping?.excoMentorDetail?.excoMentor?.id,
      account: props?.values?.excoCustomerUser?.excoAccount?.id,
      custClient: props?.values?.excoCustomerUser?.id,
      engagementName: props?.values?.engagementName,

      engTierName:
        engTierNameData.length > 0 &&
        engTierNameData.find(
          (data) => data?.engagementTierName === props?.values?.engTierName
        ),

      engStartDate: props?.values?.engagementStartDate ? format(
        new Date(props?.values?.engagementStartDate),
        "yyyy-MM-dd"
      ) : "",
      engEndDate: props?.values?.engagementEndDate ? format(
        new Date(props?.values?.engagementEndDate),
        "yyyy-MM-dd"
      ) : "",
      contactName: props?.values?.contactName,
      contactEmail: props?.values?.contactEmail,
      // dialingCode: props?.values?.dialingCode,
      // contactNumber: props?.values?.contactNumber,
      engagementStatus: props.values?.engagementStatus,
      comments: props?.values?.comments,
      delieverableName:
        props?.deliverablesData?.length > 0 ? props?.deliverablesData : [],
      managerName: props?.values?.mangerName,
      managerEmail: props?.values?.mangerEmail,
      nextUpdateDue: props?.values?.nextUpdateDue ? format(
        new Date(props?.values?.nextUpdateDue),
        "yyyy-MM-dd"
      ) : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const date = DateTime.fromISO(values?.engStartDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const localIsoString = date.toISO();

      const dateTwo = DateTime.fromISO(values?.engEndDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const localIsoStringTwo = dateTwo.toISO();

      const dateThree = DateTime.fromISO(values?.nextUpdateDue).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const localIsoStringThree = dateThree.toISO();
      dispatch(
        updateEngagement({
          id: props?.values?.id,
          excoCustomerAccountId: values?.account,
          excoCustomerUserId: values.custClient,
          engagementName: values.engagementName,

          engTierName: values.engTierName?.engagementTierName,
          delievrables: values?.delieverableName.map((item) => item.id),
          managerName: values?.managerName,
          managerEmail: values?.managerEmail,
          nextUpdateDue: localIsoStringThree,
          engagementStartDate: localIsoString,
          engagementEndDate: localIsoStringTwo,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          // dialingCode: values.dialingCode.toString(),
          // contactNumber: values.contactNumber.toString(),
          engagementStatus: values.engagementStatus,
          comments: values.comments,
          excoMentorId: values.mentor,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props?.accountId
              ? dispatch(getEngagementByAcc(props?.accountId))
              : dispatch(getEngagement());
            props.close();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getEngTierName());
    dispatch(getAccount());
    dispatch(getEngagementStatus());
    dispatch(getMentor());
  }, []);

  useEffect(() => {
    formik?.values?.account &&
      dispatch(getNotEngagedClients(formik.values.account));
  }, [formik.values.account]);

  useEffect(() => {
    formik?.values?.engTierName &&
      dispatch(getEngagementDeliverableByTier(formik?.values?.engTierName?.id));
    !formik?.values?.engTierName && dispatch(resetEngDeliverableData());
  }, [formik?.values?.engTierName]);

  console.log(formik?.values?.delieverableName);
  console.log(props.values?.clientEngagementDeliverableMapping);

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={<PageHeader variant="pageHeader">Update Engagement</PageHeader>}
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={formik.touched.account && formik.errors.account}
              >
                <InputLabel id="demo-simple-select-label">
                  Customer Accounts *
                </InputLabel>
                <Select
                   tabIndex="1"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="account"
                  defaultValue={formik?.values?.account}
                  value={formik.values.account}
                  label="Customer Accounts *"
                  onChange={formik.handleChange}
                  required={true}
                >
                  {accountData.length > 0 &&
                    accountData.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.accountName}
                      </MenuItem>
                    ))}
                </Select>
                {formik?.errors?.account && (
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formik.touched.account && formik.errors.account}
                  </FormHelperText>
                )}
              </FormControl>
              <MyTextInput
                 tabIndex="3"
                label="Engagement Name"
                type="text"
                name="engagementName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engagementName"
                value={formik.values.engagementName}
                onChange={formik.handleChange}
                error={
                  formik.touched.engagementName &&
                  Boolean(formik.errors.engagementName)
                }
                helperText={
                  formik.touched.engagementName && formik.errors.engagementName
                }
              />

              <Autocomplete
                 tabIndex="5"
                name="delieverableName"
                multiple
                id="tags-outlined"
                options={
                  engDeliverableData.length > 0 ? engDeliverableData : []
                }
                getOptionLabel={(option) => option.deliverableName}
                value={formik.values.delieverableName}
                onChange={(event, newValue) =>
                  formik.setFieldValue("delieverableName", [...newValue])
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Deliverables"
                    required={true}
                    error={
                      formik.touched.delieverableName &&
                      Boolean(formik.errors.delieverableName)
                    }
                    helperText={
                      formik.touched.delieverableName &&
                      formik.errors.delieverableName
                    }
                  />
                )}
                sx={{ marginTop: "1rem" }}
                disabled={engDeliverableData.length === 0}
              />

              <MyTextInput
                 tabIndex="7"
                label="Engagement End Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="engEndDate"
                // required={true}
                fullWidth={true}
                margin="normal"
                id="engEndDate"
                value={formik.values?.engEndDate}
                onChange={formik.handleChange}
               
                error={
                  formik.touched.engEndDate && Boolean(formik.errors.engEndDate)
                }
                helperText={
                  formik.touched.engEndDate && formik.errors.engEndDate
                }
                sx={{ mb: 0 }}
              />

              <MyTextInput
                 tabIndex="9"
                label="Manager Name"
                type="text"
                name="managerName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerName"
                value={formik.values.managerName}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerName &&
                  Boolean(formik.errors.managerName)
                }
                helperText={
                  formik.touched.managerName && formik.errors.managerName
                }
              />






              {/* <MyTextInput
                label="Manager Email"
                type="text"
                name="managerEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerEmail"
                value={formik.values.managerEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerEmail &&
                  Boolean(formik.errors.managerEmail)
                }
                helperText={
                  formik.touched.managerEmail && formik.errors.managerEmail
                }
              /> */}



              <MyTextInput
                 tabIndex="11"
                label="HR Partner Name"
                type="text"
                name="contactName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactName"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactName &&
                  Boolean(formik.errors.contactName)
                }
                helperText={
                  formik.touched.contactName && formik.errors.contactName
                }
              />

              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={formik.touched.account && formik.errors.account}
              >
                <InputLabel id="demo-simple-select-label">
                  Mentor Name *
                </InputLabel>
                <Select
                   tabIndex="13"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="mentor"
                  defaultValue={formik?.values?.mentor}
                  value={formik.values.mentor}
                  label="Mentor Name *"
                  onChange={formik.handleChange}
                  required={true}
                >
                  {mentorsData.length > 0 &&
                    mentorsData.map((item) => (
                      <MenuItem key={item.id} value={item?.excoMentor?.id}>
                        {item.firstName} {item.lastName}
                      </MenuItem>
                    ))}
                </Select>
                {formik?.errors?.mentor && (
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formik.touched.mentor && formik.errors.mentor}
                  </FormHelperText>
                )}
              </FormControl>

              <MyTextInput
                 tabIndex="15"
                label="Comments"
                type="textarea"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.customerClient && formik.errors.customerClient
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Customer Client *
                </InputLabel>
                <Select
                   tabIndex="2"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="custClient"
                  defaultValue={formik?.values?.custClient}
                  value={formik.values.custClient}
                  label="Customer Client *"
                  onChange={formik.handleChange}
                  required={true}
                  disabled={true}
                >
                  <MenuItem value={props?.values?.excoCustomerUser?.id}>
                    {
                      props?.values?.excoCustomerUser?.excoCustomerUserDetail
                        ?.firstName
                    }
                    &nbsp;
                    {
                      props?.values?.excoCustomerUser?.excoCustomerUserDetail
                        ?.lastName
                    }
                  </MenuItem>
                  {notEngagedClientsData.length > 0 &&
                    notEngagedClientsData.map((item) => (
                      <MenuItem key={item.id} value={item?.id}>
                        {item.excoCustomerUserDetail?.firstName}&nbsp;
                        {item.excoCustomerUserDetail?.lastName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.custClient && formik.errors.custClient}
                </FormHelperText>
              </FormControl>

              <Autocomplete
                 tabIndex="4"
                name="engTierName"
                id="tags-outlined"
                options={engTierNameData.length > 0 && engTierNameData}
                getOptionLabel={(option) => option.engagementTierName}
                value={formik.values.engTierName}
                onChange={(event, newValue) => {
                  formik.setFieldValue("engTierName", newValue);
                  formik.setFieldValue("delieverableName", []);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Eng Tier Name"
                    required={true}
                    error={
                      formik.touched.engTierName &&
                      Boolean(formik.errors.engTierName)
                    }
                    helperText={
                      formik.touched.engTierName && formik.errors.engTierName
                    }
                  />
                )}
                sx={{ marginTop: "1rem" }}
              />

              <MyTextInput
                 tabIndex="6"
                label="Engagement Start Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="engStartDate"
                // required={true}
                fullWidth={true}
                margin="normal"
                id="engStartDate"
                value={formik.values.engStartDate}
                onChange={formik.handleChange}
               
                error={
                  formik.touched.engStartDate &&
                  Boolean(formik.errors.engStartDate)
                }
                helperText={
                  formik.touched.engStartDate && formik.errors.engStartDate
                }
                sx={{ mb: 0 }}
              />

              <MyTextInput
                 tabIndex="8"
                label="Update Due Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="nextUpdateDue"
                // required={true}
                fullWidth={true}
                margin="normal"
                id="nextUpdateDue"
                value={formik.values.nextUpdateDue}
                onChange={formik.handleChange}
             
                error={
                  formik.touched.nextUpdateDue &&
                  Boolean(formik.errors.nextUpdateDue)
                }
                helperText={
                  formik.touched.nextUpdateDue && formik.errors.nextUpdateDue
                }
                sx={{ mb: 0 }}
              />

              <MyTextInput
                 tabIndex="10"
                label="Manager' Email"
                type="text"
                name="managerEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerEmail"
                value={formik.values.managerEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerEmail &&
                  Boolean(formik.errors.managerEmail)
                }
                helperText={
                  formik.touched.managerEmail && formik.errors.managerEmail
                }
              />


              {/* <MyTextInput
                label="Manager Name"
                type="text"
                name="managerName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerName"
                value={formik.values.managerName}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerName &&
                  Boolean(formik.errors.managerName)
                }
                helperText={
                  formik.touched.managerName && formik.errors.managerName
                }
              /> */}



              {/* <div style={{ display: "flex" }}>
                <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                  <MyTextInput
                    label="Dialing Code"
                    type="number"
                    name="dialingCode"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="dialingCode"
                    value={formik.values.dialingCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dialingCode &&
                      Boolean(formik.errors.dialingCode)
                    }
                    helperText={
                      formik.touched.dialingCode && formik.errors.dialingCode
                    }
                  />
                </div>
                <div style={{ width: "65%" }}>
                  <MyTextInput
                    label="HR Contact Number"
                    type="number"
                    name="contactNumber"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="contactNumber"
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactNumber &&
                      Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                    }
                  />
                </div>
              </div> */}



              {/* 
              <MuiAutocomplete
                name="delieverableName"
                options={engDeliverableData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.delieverableName}
                optionValue="deliverableName"
                label="Engagement Deliverable Name"
                required={true}
                error={
                  formik.touched.delieverableName &&
                  Boolean(formik.errors.delieverableName)
                }
                helperText={
                  formik.touched.delieverableName && formik.errors.delieverableName
                }
              /> */}
              <MyTextInput
                 tabIndex="12"
                label="HR Partner's Email"
                type="text"
                name="contactEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactEmail"
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactEmail &&
                  Boolean(formik.errors.contactEmail)
                }
                helperText={
                  formik.touched.contactEmail && formik.errors.contactEmail
                }
              />



              <FormControl
               
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.engagementStatus &&
                    formik.errors.engagementStatus
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Engagement Status *
                </InputLabel>
                <Select
                  tabIndex="14"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="engagementStatus"
                  defaultValue={formik?.values?.engagementStatus.toString()}
                  value={formik.values.engagementStatus}
                  label="Engagement Status *"
                  onChange={formik.handleChange}
                  required={true}
                >
                  {engagementStatusData.length > 0 &&
                    engagementStatusData.map((item) => (
                      <MenuItem key={item.id} value={item.engagementStatus}>
                        {item.engagementStatus}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.engagementStatus &&
                    formik.errors.engagementStatus}
                </FormHelperText>
              </FormControl>




            </Grid>
          </Grid>
          <Box textAlign="center">
            <FormSubmitButton type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Box>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default UpdateEngagement;
