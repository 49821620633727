import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
// material ui
import { TextField, Card, Stack } from "@mui/material";
// components
import Layout from "../../../layout/Layout";
import CollapsibleTable from "./CollaspibleTable";
// redux
import { getCustomers } from "../../../features/exec/customers/customerSlice";

// ---------------------------------------------------------------------

const Clients = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { customersData } = useSelector((state) => ({ ...state.execCustomer }));

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  return (
    <Layout>
      <Card sx={{ minHeight: "80vh", padding: "0.5rem", textAlign: "center" }}>
      <Stack
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            sx={{marginBottom: 2}}
          >
        <PageHeader variant="pageHeader">Customers</PageHeader>
        <TextField
          type="text"
          id="filled-size-small"
          name="search"
          label="Search Customer, Account..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          variant="filled"
          size="small"
          sx={{ width: 300, margin: "auto", marginBottom: "0.5rem" }}
        />
        </Stack>
        <CollapsibleTable data={customersData} search={search} />
      </Card>
    </Layout>
  );
};

export default Clients;
