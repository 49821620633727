import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ADMIN_ENGAGEMENT } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAdminEngagement = createAsyncThunk(
  "adminEngagement/createAdminEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ADMIN_ENGAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdminEngagement = createAsyncThunk(
  "adminEngagement/getAdminEngagement",
  async () => {
    return axios.get(CURD_ADMIN_ENGAGEMENT, { withCredentials: true });
  }
);

export const updateAdminEngagement = createAsyncThunk(
  "adminEngagement/updateAdminEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ADMIN_ENGAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAdminEngagement = createAsyncThunk(
  "adminEngagement/deleteAdminEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ADMIN_ENGAGEMENT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const adminEngagementSlice = createSlice({
  name: "adminEngagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    adminEngData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAdminEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [createAdminEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAdminEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAdminEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [getAdminEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.adminEngData = action?.payload?.data;
    },
    [getAdminEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAdminEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAdminEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAdminEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAdminEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAdminEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAdminEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  adminEngagementSlice.actions;
export default adminEngagementSlice.reducer;
