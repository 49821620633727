import React, { useEffect } from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { countries, continents } from "countries-list";

// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import { getCustomer } from "../../../features/customer/customerSlice";
import { getClientByCust } from "../../../features/customer/custUserSlice";
import { getCustAccount } from "../../../features/customer/accountSlice";
import { getEngTierName } from "../../../features/config/engTierNameSlice";
import { getContactDesignation } from "../../../features/config/contactDesignationSlice";
import { getOpportunityStage } from "../../../features/config/opportunityStageSlice";
import { getOpportunityStatus } from "../../../features/config/opportunityStatusSlice";
import {
  createOpportunity,
  resetMessage,
  resetErrMessage,
  getOpportunity,
} from "../../../features/exec/opportunity/opportunitySlice";

// ---------------------------------------------------------------------

const validationSchema = Yup.object({
  customer: Yup.string()
    .uuid("Enter valid Id")
    .required("Customer Name is required"),
  custClient: Yup.string()
    .uuid("Enter valid Id")
    .required("Customer Client Name is required"),
  account: Yup.string().required("Account Name is required"),
  opportunityName: Yup.string().required("Opportunity Name is required"),
  opportunityTier: Yup.string().required("Opportunity Tier is required"),
  oppSummary: Yup.string("").required("Opportunity Summary is required"),
  contactName: Yup.string().required("Contact Name is required "),
  designation: Yup.string(),
  contactEmail: Yup.string()
    .email("Enter valid Email")
    .required("Contact Email is required"),
  geography: Yup.string().required("Geography is required"),
  country: Yup.string().required("Country is required"),
  dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string().required("ContactNumber is required").matches(/^[0-9]+$/, "Must be only digits")
  .min(10, "Must be exactly 10 digits")
  .max(10, "Must be exactly 10 digits"),
  oppStage: Yup.string().required("Opportunity Stage is required "),
  oppStatus: Yup.string().required("Opportunity Status is required"),
  comments: Yup.string(),
});

// ---------------------------------------------------------------------------------------------

const AddOpportunity = (props) => {
  const { message, errMessage } = useSelector((state) => ({
    ...state.opportunity,
  }));
  const { customersData } = useSelector((state) => ({ ...state.customer }));
  const { custAccountData } = useSelector((state) => ({
    ...state.account,
  }));
  const { clientByCustData } = useSelector((state) => ({ ...state.custUser }));

  const { engTierNameData } = useSelector((state) => ({
    ...state.engTierName,
  }));
  const { contactDesignationData } = useSelector((state) => ({
    ...state.contactDesig,
  }));
  const { opportunityStageData } = useSelector((state) => ({
    ...state.oppStageName,
  }));
  const { opportunityStatusData } = useSelector((state) => ({
    ...state.oppStatus,
  }));

  const dispatch = useDispatch();

  let options = Object.values(countries);

  const formik = useFormik({
    initialValues: {
      customer: "",
      custClient: "",
      account: "",
      opportunityName: "",
      opportunityTier: "",
      oppSummary: "",
      contactName: "",
      designation: "",
      contactEmail: "",
      geography: "",
      country: "",
      dialingCode: "",
      contactNumber: "",
      oppStage: "",
      oppStatus: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createOpportunity({
          excoCustomerId: values.customer,
          excoCustomerClientId: values.custClient,
          accountName: values.account,
          opportunityName: values.opportunityName,
          opportunityTier: values.opportunityTier,
          opportunitySummary: values.oppSummary,
          contactName: values.contactName,
          designation: values.designation,
          contactEmail: values.contactEmail,
          geography: values.geography,
          country: values.country,
          dialingCode: values.dialingCode.toString(),
          contactNumber: values.contactNumber.toString(),
          opportunityStage: values.oppStage,
          opportunityStatus: values.oppStatus,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getOpportunity());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getCustomer());
    dispatch(getEngTierName());
    dispatch(getContactDesignation());
    dispatch(getOpportunityStage());
    dispatch(getOpportunityStatus());
  }, []);

  useEffect(() => {
    formik?.values?.customer &&
      dispatch(getClientByCust(formik.values.customer));
    formik?.values?.customer &&
      dispatch(getCustAccount(formik.values.customer));
  }, [formik.values.customer]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country) {
        return formik?.setFieldValue("dialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={<PageHeader variant="pageHeader">Add Opportunity</PageHeader>}
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.customer && formik.errors.customer
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="customer"
                  value={formik.values.customer}
                  label="Customer"
                  onChange={formik.handleChange}
                  required={true}
                  inputProps={{ tabIndex: "1" }}
                >
                  {customersData.length > 0 &&
                    customersData.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.customerName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.customer && formik.errors.customer}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.custClient && formik.errors.custClient
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Customer Client
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="custClient"
                  value={formik.values.custClient}
                  label="Customer Client"
                  onChange={formik.handleChange}
                  required={true}
                  inputProps={{ tabIndex: "3" }}
                >
                  {clientByCustData.length > 0 &&
                    clientByCustData.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item?.excoCustomerClient?.id}
                      >
                        {item.firstName}&nbsp;{item.lastName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.custClient && formik.errors.custClient}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.opportunityTier &&
                  formik.errors.opportunityTier
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Opportunity Tier
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="opportunityTier"
                  value={formik.values.opportunityTier}
                  label="Opportunity Tier"
                  onChange={formik.handleChange}
                  required={true}
                  inputProps={{ tabIndex: "5" }}
                >
                  {engTierNameData.length > 0 &&
                    engTierNameData.map((item) => (
                      <MenuItem key={item.id} value={item?.engagementTierName}>
                        {item?.engagementTierName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.opportunityTier &&
                    formik.errors.opportunityTier}
                </FormHelperText>
              </FormControl>
              <MyTextInput
                label="Contact Name"
                type="text"
                name="contactName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactName"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactName &&
                  Boolean(formik.errors.contactName)
                }
                helperText={
                  formik.touched.contactName && formik.errors.contactName
                }
                inputProps={{ tabIndex: "7" }}
              />
              <MyTextInput
                label="Contact Email"
                type="text"
                name="contactEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactEmail"
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactEmail &&
                  Boolean(formik.errors.contactEmail)
                }
                helperText={
                  formik.touched.contactEmail && formik.errors.contactEmail
                }
                inputProps={{ tabIndex: "9" }}
              />
              <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="country"
                  value={formik.values.country}
                  label="Country"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  inputProps={{ tabIndex: "11" }}
                >
                  {options.map((option) => {
                    if (formik.values.geography === option.continent) {
                      return (
                        <MenuItem key={option.name} value={option.name}>
                          {option.name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.country && formik.errors.country}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.oppStage && formik.errors.oppStage
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Opportunity Stage
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="oppStage"
                  value={formik.values.oppStage}
                  label="Opportunity stage"
                  onChange={formik.handleChange}
                  required={true}
                  inputProps={{ tabIndex: "13" }}
                >
                  {opportunityStageData.length > 0 &&
                    opportunityStageData.map((item) => (
                      <MenuItem key={item.id} value={item.oppStageName}>
                        {item.oppStageName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.oppStage && formik.errors.oppStage}
                </FormHelperText>
              </FormControl>
              <MyTextInput
                label="Comments"
                type="textarea"
                name="comments"
                required={true}
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
                inputProps={{ tabIndex: "15" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.account && formik.errors.account ? true : false
                }
              >
                <InputLabel id="demo-simple-select-label">Account</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="account"
                  value={formik.values.account}
                  label="account"
                  onChange={formik.handleChange}
                  required={true}
                  inputProps={{ tabIndex: "2" }}
                >
                  {custAccountData.length > 0 &&
                    custAccountData.map((item) => (
                      <MenuItem key={item.id} value={item?.accountName}>
                        {item?.accountName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.account && formik.errors.account}
                </FormHelperText>
              </FormControl>

              <MyTextInput
                label="Opportunity Name"
                type="text"
                name="opportunityName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="opportunityName"
                value={formik.values.opportunityName}
                onChange={formik.handleChange}
                error={
                  formik.touched.opportunityName &&
                  Boolean(formik.errors.opportunityName)
                }
                helperText={
                  formik.touched.opportunityName &&
                  formik.errors.opportunityName
                }
                inputProps={{ tabIndex: "4" }}
              />

              <MyTextInput
                label="Opportunity Summary"
                type="textarea"
                name="oppSummary"
                required={true}
                fullWidth={true}
                margin="normal"
                id="oppSummary"
                value={formik.values.oppSummary}
                onChange={formik.handleChange}
                error={
                  formik.touched.oppSummary && Boolean(formik.errors.oppSummary)
                }
                helperText={
                  formik.touched.oppSummary && formik.errors.oppSummary
                }
                inputProps={{ tabIndex: "6" }}
              />
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.designation && formik.errors.designation
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Designation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="designation"
                  value={formik.values.designation}
                  label="Customer Client"
                  onChange={formik.handleChange}
                  required={true}
                  inputProps={{ tabIndex: "8" }}
                >
                  {contactDesignationData.length > 0 &&
                    contactDesignationData.map((item) => (
                      <MenuItem key={item.id} value={item.designationName}>
                        {item.designationName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.designation && formik.errors.designation}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">Geography</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="geography"
                  value={formik.values.geography}
                  error={
                    formik.touched.geography && Boolean(formik.errors.geography)
                  }
                  label="Geography"
                  onChange={formik.handleChange}
                  inputProps={{ tabIndex: "10" }}
                >
                  {Object.keys(continents).map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}-{continents[item]}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.geography && formik.errors.geography}
                </FormHelperText>
              </FormControl>
              <div style={{ display: "flex" }}>
                <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                  <MyTextInput
                    label="Dialing Code"
                    type="number"
                    name="dialingCode"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="dialingCode"
                    value={formik.values.dialingCode}
                    // onChange={formik.handleChange}
                    disabled
                    error={
                      formik.touched.dialingCode &&
                      Boolean(formik.errors.dialingCode)
                    }
                    helperText={
                      formik.touched.dialingCode && formik.errors.dialingCode
                    }
                  />
                </div>
                <div style={{ width: "65%" }}>
                  <MyTextInput
                    label="Contact Number"
                    type="number"
                    name="contactNumber"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="contactNumber"
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactNumber &&
                      Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                    }
                    inputProps={{ tabIndex: "12" }}
                  />
                </div>
              </div>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.oppStatus && formik.errors.oppStatus
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Opportunity Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="oppStatus"
                  value={formik.values.oppStatus}
                  label="Opportunity Status"
                  onChange={formik.handleChange}
                  required={true}
                  inputProps={{ tabIndex: "14" }}
                >
                  {opportunityStatusData.length > 0 &&
                    opportunityStatusData.map((item) => (
                      <MenuItem key={item.id} value={item.opportunityStatus}>
                        {item.opportunityStatus}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.oppStatus && formik.errors.oppStatus}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Box textAlign="center">
            <FormSubmitButton type="submit" variant="contained" tabIndex="16">
              Submit
            </FormSubmitButton>
          </Box>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddOpportunity;
