import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// routes
import { GET_ALL_LEADERSHIP_PROFILE } from "../../../utils/routes";

// ---------------------------------------------------------------------------------

export const getLeadershipProfile = createAsyncThunk(
  "leadershipProfile/getLeadershipProfile",
  async () => {
    return axios.get(GET_ALL_LEADERSHIP_PROFILE, { withCredentials: true });
  }
);

export const getLeadershipProfileByClient = createAsyncThunk(
  "leadershipProfile/getLeadershipProfileByClient",
  async (id) => {
    return axios.get(`${GET_ALL_LEADERSHIP_PROFILE}/${id}`, {
      withCredentials: true,
    });
  }
);

// ---------------------------------------------------------------------------------

const leadershipProfileSlice = createSlice({
  name: "leadershipProfile",
  initialState: {
    loading: false,
    leadershipProfileData: [],
    message: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
  },
  extraReducers: {
    [getLeadershipProfile.pending]: (state, action) => {
      state.loading = true;
    },
    [getLeadershipProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.leadershipProfileData = action?.payload?.data;
    },
    [getLeadershipProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.message;
    },
    [getLeadershipProfileByClient.pending]: (state, action) => {
      state.loading = true;
    },
    [getLeadershipProfileByClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.leadershipProfileData = action?.payload?.data;
    },
    [getLeadershipProfileByClient.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.message;
    },
  },
});

export const { resetMessage, resetErrMessage } = leadershipProfileSlice.actions;

export default leadershipProfileSlice.reducer;
