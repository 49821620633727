import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import {
  updateEngagementStatus,
  getEngagementStatus,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/engagementStatusSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  engStatus: Yup.string().required("Engagement Status is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const UpdateEngagementStatus = (props) => {
  const { message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.engStatus })
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      engStatus: selectedData?.engagementStatus,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateEngagementStatus({
          id: selectedData.id,
          engagementStatus: values.engStatus,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getEngagementStatus());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">Update Engagement Status</PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
              <MyTextInput
                label="Engagement Status"
                type="text"
                name="engStatus"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engStatus"
                value={formik.values.engStatus}
                onChange={formik.handleChange}
                error={
                  formik.touched.engStatus && Boolean(formik.errors.engStatus)
                }
                helperText={formik.touched.engStatus && formik.errors.engStatus}
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default UpdateEngagementStatus;
