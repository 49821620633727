import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// routes
import {
  IS_LOGGED_IN,
  EMAIL_VERIFY,
  RESEND_EXCO_USER_EMAIL,
  LOGIN_ACCOUNT,
  LOGOUT_ACCOUNT,
  GET_ALL_ROLES,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  CHANGE_PRIMARY_ROLE,
  DELETE_ACCOUNT,
  GET_ACCOUNTS,
  GET_ALL_EXEC,
  GET_ALL_AM,
  ACTIVATE_ACCOUNT,
  GET_CURRENT_USER,
  GET_CURRENT_USER_BY_ROLE,
  GET_CURRENT_ROLES,
  UPLOAD_PROFILE_PIC,
  TOGGLE_ADMIN_API
} from "../../utils/routes";

// ---------------------------------------------------------------------------------

export const getToggleAdminData = createAsyncThunk(
  "toggle/toogleAdmin",
  async (data) => {
    return axios.get(`${TOGGLE_ADMIN_API}/${data}`, { withCredentials: true });
  }
);

export const verifyLogin = createAsyncThunk(
  "auth/verifyLogin",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(IS_LOGGED_IN, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(EMAIL_VERIFY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendEmail = createAsyncThunk(
  "auth/resendEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(RESEND_EXCO_USER_EMAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    let authRQ = data.rq;
    delete data.rq;
    try {
      const response = await axios.post(LOGIN_ACCOUNT, data, {
        headers: {
          Authorization: `Bearer ${authRQ}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(LOGOUT_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllRoles = createAsyncThunk(
  "auth/getAllRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_ROLES, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAccount = createAsyncThunk(
  "auth/createAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAccount = createAsyncThunk(
  "auth/updateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePrimaryRole = createAsyncThunk(
  "auth/changePrimaryRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CHANGE_PRIMARY_ROLE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "auth/deleteAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(DELETE_ACCOUNT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAccount = createAsyncThunk(
  "auth/getAccount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ACCOUNTS, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_CURRENT_USER, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentUserByRole = createAsyncThunk(
  "auth/getCurrentUserByRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_CURRENT_USER_BY_ROLE}/${data}`, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentRoles = createAsyncThunk(
  "auth/getCurrentRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_CURRENT_ROLES, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllExec = createAsyncThunk(
  "auth/getAllExec",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_EXEC, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllAM = createAsyncThunk(
  "auth/getAllAM",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_AM, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const activateAccount = createAsyncThunk(
  "auth/activateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ACTIVATE_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadProfilePic = createAsyncThunk(
  "auth/uploadProfilePic",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPLOAD_PROFILE_PIC, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ---------------------------------------------------------------------------------

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    isLoggedIn: "",
    loginToken: "",
    rq: "",
    loginData: {},
    accountsData: [],
    execData: [],
    amData: [],
    rolesData: [],
    userRolesData: [],
     toggleAdminData:[],
    selectedData: {},
    message: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    selectRow: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getToggleAdminData.pending]:(state,action) =>{
      state.loading = true
    },
    [getToggleAdminData.fulfilled]:(state,action) =>{
      state.loading = false
      state.toggleAdminData = action?.payload?.data
    },
    [getToggleAdminData.rejected]:(state,action) =>{
      state.loading = true
    },

    [verifyLogin.pending]: (state, action) => {
      state.loading = true;
    },
    [verifyLogin.fulfilled]: (state, action) => {
      state.loading = false;
      state.isLoggedIn = action?.payload?.data;
    },
    [verifyLogin.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAllRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.rolesData = action?.payload?.data;
    },
    [getAllRoles.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [createAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [uploadProfilePic.pending]: (state, action) => {
      state.loading = true;
    },
    [uploadProfilePic.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [uploadProfilePic.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [changePrimaryRole.pending]: (state, action) => {
      state.loading = true;
    },
    [changePrimaryRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [changePrimaryRole.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.accountsData = action?.payload?.data;
    },
    [getAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getCurrentUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.loginData = action?.payload?.data;
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getCurrentUserByRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentUserByRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.loginData = action?.payload?.data;
    },
    [getCurrentUserByRole.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getCurrentRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.userRolesData = action?.payload?.data;
    },
    [getCurrentRoles.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [verifyEmail.pending]: (state, action) => {
    },
    [verifyEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.loginToken = action?.payload?.data?.data?.loginToken;
      state.rq = action?.payload?.data?.data?.rq;
      state.message = action?.payload?.data?.message;
    },
    [verifyEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [resendEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [resendEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [resendEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
      state.loginData = action?.payload?.data;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [logout.pending]: (state, action) => {
      state.loading = true;
    },
    [logout.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [logout.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [activateAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [activateAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [activateAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAllExec.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllExec.fulfilled]: (state, action) => {
      state.loading = false;
      state.execData = action?.payload?.data;
    },
    [getAllExec.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAllAM.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllAM.fulfilled]: (state, action) => {
      state.loading = false;
      state.amData = action?.payload?.data;
    },
    [getAllAM.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, selectRow } = authSlice.actions;

export default authSlice.reducer;
