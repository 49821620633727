import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// material ui
import { CssBaseline } from "@mui/material";
// components
import {
  SignIn,
  ActivateAccount,
  ForgotPassword,
  ForgotPasswordReset,
  PageNotFound,
  Sidebar,
  Configuration,
  Dashboard,
  Onboarding,
  CustUserOnboarding,
  UserOnboarding,
  MyProfile,
  MentorOnboarding,
  MentorMapping,
  ActivateMentorAccount,
  Microsites,
  Opportunity,
  Engagement,
  Nomination,
  AdminEngagement,
  AccountContainer,
  Account,
  CustDevAreas,
  EngCheckinFrequency,
  ProgressReviewFrequency,
  Customers,
  Mentor,
  Clients,
  LeadershipProfile,
  CoachingFocusAreas,
  ProgressReview,
  EngagementCheckin,
  ApplicationSetup,
  MenuManagement,
  ApiManagement,
  RoleManagement,
  UserGroups,
  Roles,
  AccessPermissions,
  MenuAccessPermissions,
  Summary,
  Outcomes,
  CoachingFocusArea,
  ToggleFeature,
} from "./pages";
import {
  OpportunityStage,
  OpportunityStatus,
  ContactDesignation,
  EngagementTier,
  EngagementDeliverable,
  EngagementStatus,
  EngagementStage,
  DevelopmentAreas,
  NpsRating,
  LevelOfEngagement,
  ImpactScaleRating,
  NominationStatus,
  NominationMode,
  ExecutiveSponsor,
} from "./pages/configuration";

// -------------------------------------------------------------------

function App() {
  return (
    <>
      <CssBaseline />

      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/activation" element={<ActivateAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password/reset"
            element={<ForgotPasswordReset />}
          />
          <Route
            path="/mentor/activation"
            element={<ActivateMentorAccount />}
          />
          <Route path="/exco" element={<Sidebar />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="customers/onboarding" element={<Onboarding />} />
            <Route
              path="customers/user/onboarding"
              element={<CustUserOnboarding />}
            />
            <Route path="customers/microsite" element={<Microsites />} />
            <Route
              path="customers/account-container"
              element={<AccountContainer />}
            />
            <Route
              path="customers/account-container/account"
              element={<Account />}
            />
            <Route
              path="customers/account-container/cust_dev_areas"
              element={<CustDevAreas />}
            />
            <Route
              path="customers/account-container/toggle-feature"
              element={<ToggleFeature />}
            />
            <Route
              path="customers/account-container/eng_checkin_frequency"
              element={<EngCheckinFrequency />}
            />
            <Route
              path="customers/account-container/progress_review_frequency"
              element={<ProgressReviewFrequency />}
            />
            <Route path="user/onboarding" element={<UserOnboarding />} />
            <Route path="my-profile" element={<MyProfile />} />
            <Route path="mentor/onboarding" element={<MentorOnboarding />} />
            <Route path="mentor/mapping" element={<MentorMapping />} />
            <Route path="customer/insights" element={<Customers />} />
            <Route path="mentor/insights" element={<Mentor />} />
            <Route path="client/insights" element={<Clients />} />
            <Route path="opportunity" element={<Opportunity />} />
            <Route path="engagement" element={<Engagement />} />
            <Route path="nomination" element={<Nomination />} />
            <Route path="admin-engagement" element={<AdminEngagement />} />
            <Route path="config" element={<Configuration />} />
            <Route path="config/opp-stage" element={<OpportunityStage />} />
            <Route path="config/opp-status" element={<OpportunityStatus />} />
            <Route
              path="config/contact-designation"
              element={<ContactDesignation />}
            />
            <Route path="config/eng-tier" element={<EngagementTier />} />
            <Route
              path="config/eng-deliverable"
              element={<EngagementDeliverable />}
            />
            <Route path="config/eng-status" element={<EngagementStatus />} />
            <Route
              path="config/client-eng-stage"
              element={<EngagementStage />}
            />
            <Route path="config/dev-areas" element={<DevelopmentAreas />} />
            <Route path="config/nps-rating" element={<NpsRating />} />
            <Route path="config/level-of-eng" element={<LevelOfEngagement />} />
            <Route
              path="config/impact-scale-rating"
              element={<ImpactScaleRating />}
            />
            <Route
              path="config/nomination-status"
              element={<NominationStatus />}
            />
            <Route
              path="config/executive-sponsor"
              element={<ExecutiveSponsor />}
            />
            <Route path="config/nomination-mode" element={<NominationMode />} />
            <Route path="app-setup" element={<ApplicationSetup />} />
            <Route
              path="app-setup/menu-management"
              element={<MenuManagement />}
            />
            <Route
              path="app-setup/api-management"
              element={<ApiManagement />}
            />
            <Route path="role-management" element={<RoleManagement />} />
            <Route
              path="role-management/user-groups"
              element={<UserGroups />}
            />
            <Route path="role-management/roles" element={<Roles />} />
            <Route
              path="role-management/access-permissions"
              element={<AccessPermissions />}
            />
            <Route
              path="role-management/menu-access-permissions"
              element={<MenuAccessPermissions />}
            />
            <Route
              path="outcomes/leadership-profile"
              element={<LeadershipProfile />}
            />
            <Route
              path="outcomes/coaching-focus-areas"
              element={<CoachingFocusAreas />}
            />
            <Route
              path="outcomes/progress-review"
              element={<ProgressReview />}
            />
            <Route
              path="outcomes/engagement-checkin"
              element={<EngagementCheckin />}
            />
            <Route path="summary" element={<Summary />} />
            <Route path="outcomes" element={<Outcomes />} />
            <Route path="coaching-focus-area" element={<CoachingFocusArea />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
