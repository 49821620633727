import React from 'react'
import Alert from '@mui/material/Alert';
function Notifier(props) {
  return (
    <div className="notification-box">
      <span className="notification">
      <Alert variant="filled" >
      {props.message}
     </Alert>
        
      </span>
    </div>
  )
}
export default Notifier;