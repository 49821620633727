import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Stack, Box } from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import AddApiManagement from "./AddApiManagement";
import UpdateApiManagement from "./UpdateApiManagement";
import { MuiDataGrid, GridCellExpand, Notifier, ErrorNotifier } from "../../../../components";
import ConfirmDialog from "../../../../components/common/ConfirmDialog";
import Layout from "../../../../layout/Layout";
// redux
import {
  getApiManagement,
  deleteApiManagement,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../../features/platform/app_setup/apiManagementSlice";

// ----------------------------------------------------------------------------------

const ApiManagement = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
const location = useLocation()
  const dispatch = useDispatch();
  const { message, errMessage, apiManagementData, selectedData } =
    useSelector((state) => ({ ...state.apiManagement }));

    function getAppName (params) {
      return params?.row?.name.split(".")[0]
    }

    function getModuleName(params) {
      return params?.row?.name.split(".").length > 2 ? params?.row?.name.split(".")[1] : ""
    }

    function getSubModuleName(params) {
      return params?.row?.name.split(".").length > 3 ? params?.row?.name.split(".")[2] : ""
    }

    function getFeatureName(params) {
      return params?.row?.name.split(".").length > 4 ? params?.row?.name.split(".")[3] : ""
    }

    function getServiceName(params) {
      return <GridCellExpand value={params?.row?.name.split(".")[params?.row?.name.split(".").length - 1]} />
    }

    function renderCellExpand(params) {
      return (
        <GridCellExpand
          value={params.value || ""}
          width={params.colDef.computedWidth}
        />
      );
    }

  const data = {
    rows: apiManagementData,
    columns: [
      { field: "applicationName", headerName: "Application Name", width: 180, valueGetter: getAppName },
      { field: "moduleName", headerName: "Module Name", width: 180, valueGetter: getModuleName },
      { field: "subModuleName", headerName: "Sub Module Name", width: 180, valueGetter: getSubModuleName },
      { field: "featureName", headerName: "Feature Name", width: 180, valueGetter: getFeatureName },
      { field: "serviceName", headerName: "Service Name", width: 280, renderCell: getServiceName },
      { field: "url", headerName: "URL", width: 250, renderCell: renderCellExpand },
      { field: "comments", headerName: "Comments", width: 250, renderCell: renderCellExpand },
    ],
  };

  useEffect(() => {
    dispatch(getApiManagement());
  }, []);

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDelete = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteApiManagement(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getApiManagement());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  return (
    <Layout>
      {!showAdd && !showEdit && (
        <>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <PageHeader variant="pageHeader">API Management</PageHeader>
            </Box>
            <Link to="/exco/app-setup" state={{l3Menu: location?.state?.l3Menu}}>
              <CancelIcon color="red" />
            </Link>
          </Stack>
          <MuiDataGrid
            setShowAdd={() => setShowAdd(true)}
            setShowEdit={() => setShowEdit(true)}
            setShowDelete={() =>
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure to delete this record?",
                subTitle: "You can't undo this operation",
                onConfirm: () => handleDelete(),
              })
            }
            toolbarColumn = {true}
            toolbarFilter = {true}
            toolbarDensity = {true}
            toolbarExport = {true}
            data={data}
            select={(data) => dispatch(rowSelect(data))}
          />
        </>
      )}
      {showAdd && (
        <AddApiManagement showAdd={showAdd} close={() => setShowAdd(false)} />
      )}
      {showEdit && (
        <UpdateApiManagement
          showEdit={showEdit}
          close={() => setShowEdit(false)}
        />
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Layout>
  );
};

export default ApiManagement;
