import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import Layout from "../../../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  MuiTooltip,
  MuiAutocomplete,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ConfirmDialog,
  ModalCloseBtn,
} from "../../../../components";
// redux
import { getAccount } from "../../../../features/customer/accountSlice";
import {
  getDevelopmentAreas,
  createCoachingFocusArea,
  updateCoachingDevAreas,
  deleteCoachingDevelopmentArea,
  getCoachingFocus,
  fetchCoachingFocusById,
  fetchAddedCoachingFocusById,
  resetMessage as resetMessageCoaching,
  resetErrMessage as resetErrMessageCoaching,
} from "../../../../features/config/developmentAreasSlice";
import {
  createCustDevAreas,
  getCustDevAreas,
  updateCustDevAreas,
  deleteCustDevAreas,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../../features/customer/custDevAreasSlice";

// ----------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  accountName: Yup.object().required("Account  Name is required"),
  devAreaName: Yup.object().required("Development Area Name is required"),
  comments: Yup.string(),
});

const editValidationSchema = Yup.object({
  accountName: Yup.object().required("Account  Name is required"),
  devAreaName: Yup.string().required("Development Area Name is required"),
  comments: Yup.string(),
});

const coachingValidationSchema = Yup.object({
  coachingFocus: Yup.object().required("Coaching Focus Name is required"),

  comments: Yup.string(),
});

const updatecoachingValidationSchema = Yup.object({
  coachingFocus: Yup.string().required("Coaching Focus Name is required"),

  comments: Yup.string(),
});

// ------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ---------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("md")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  filter_grid: {
    [theme.breakpoints.down("md")]: {
      display: "flex !important",
      flexDirection: "column !important",
    },
  },
  close_btn: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "-2.563rem",
    },
  },
}));

const CustDevAreas = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [storeId, setStoreId] = useState("");
  const [showCoachingFocus, setShowCoachingFocus] = useState(false);
  const [show, setShow] = useState(false);
  const [showEditCoachingFocus, setShowEditCoachingFocus] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();
  const { errMessageArea, messageArea, custDevAreasData, selectedData } =
    useSelector((state) => ({
      ...state.custDevAreas,
    }));

  const { accountData } = useSelector((state) => ({ ...state.account }));

  const {
    devAreasData,
    message,
    errMessage,
    coachingFocusList,
    coachingFocusAreaData,
  } = useSelector((state) => ({
    ...state.devAreas,
  }));

  function getAccountName(params) {
    return params?.row?.excoCustomerAccount?.accountName;
  }

  function getDevAreaName(params) {
    return params?.row?.developmentAreas?.developmentAreaName;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  console.log(coachingFocusList);

  const showCoachingChange = () => {
    setShowCoachingFocus(!showCoachingFocus);
  };

  const showEditCoachingChange = () => {
    setShowEditCoachingFocus(!showEditCoachingFocus);
  };

  const handleSettingClick = (data) => {
    setShow(!show);
    setStoreId(data.row.developmentAreas?.id);
    dispatch(fetchCoachingFocusById(data.row.developmentAreas?.id));
    dispatch(getCoachingFocus());
  };

  const data = {
    rows: custDevAreasData,
    columns: [
      {
        field: "accountName",
        headerName: "Account Name",
        width: 200,
        valueGetter: getAccountName,
      },
      {
        field: "developmentAreaName",
        headerName: "Dev Area Name",
        width: 250,
        valueGetter: getDevAreaName,
      },
      {
        width: 250,
        headerName: "Coaching Focus",
        renderCell: (params) => {
          return (
            <MuiTooltip title="Coaching Focus" placement="bottom">
              <ListItemIcon onClick={() => handleSettingClick(params)}>
                <SettingsApplicationsIcon />
              </ListItemIcon>
            </MuiTooltip>
          );
        },
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 300,
        renderCell: renderCellExpand,
      },


    ],
  };

  const dataThree = {
    rows: coachingFocusList,
    columns: [
      {
        field: "developmentAreaCoachingFocusName",
        headerName: "Coaching Focus",
        width: 200,
      },

      {
        field: "comments",
        headerName: "Comments",
        width: 500,
        renderCell: renderCellExpand,
      },
    ],
  };

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        accountName: "",
        devAreaName: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
    coachingFormik.resetForm({
      values: {
        coachingFocus: "",

        comments: "",
      },
    });
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const closeCoachingModel = () => {
    setShowCoachingFocus(false);
  };

  const closeEditCoachingModel = () => {
    setShowEditCoachingFocus(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: !show
        ? () => handleDeleteCustDevAreas()
        : () => handleDeleteCoachingFocus(),
    });

  const handleDeleteCustDevAreas = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteCustDevAreas(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getCustDevAreas());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleDeleteCoachingFocus = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteCoachingDevelopmentArea(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessageCoaching());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(fetchCoachingFocusById(storeId));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessageCoaching());
        }, 2000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const formik = useFormik({
    initialValues: {
      accountName: "",
      devAreaName: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createCustDevAreas({
          excoCustomerAccountId: values.accountName?.id,
          developmentAreaId: values.devAreaName?.id,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getCustDevAreas());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  console.log(selectedData);

  const coachingFormik = useFormik({
    initialValues: {
      coachingFocus: "",
      comments: "",
    },

    validationSchema: coachingValidationSchema,
    onSubmit: (values) => {
      dispatch(
        createCoachingFocusArea({
          developmentAreaCoachingFocusName:
            values.coachingFocus?.developmentAreaCoachingFocusName,
          developmentAreasId: storeId,
          comments: values.comments,

          // excoCustomerAccountId: values.accountName?.id,
          // developmentAreaName: values.devAreaName?.developmentAreaName,
          // comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessageCoaching());
            closeCoachingModel();
            dispatch(fetchCoachingFocusById(storeId));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessageCoaching());
          }, 3000);
        }
      });
    },
  });

  console.log(selectedData);

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountName: selectedData?.excoCustomerAccount,
      devAreaName: selectedData?.developmentAreas?.id,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      console.log(values?.devAreaName?.id)
      dispatch(
        updateCustDevAreas({
          id: selectedData?.id,
          excoCustomerAccountId: values?.accountName?.id,
          developmentAreaId: values?.devAreaName,
          comments: values?.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getCustDevAreas());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  console.log(selectedData);

  const coachingFormkiEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      coachingFocus: selectedData?.developmentAreaCoachingFocusName,
      comments: selectedData?.comments,
    },

    validationSchema: updatecoachingValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateCoachingDevAreas({
          id: selectedData?.id,
          developmentAreaCoachingFocusName: values.coachingFocus,
          developmentAreasId: storeId,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessageCoaching());
            closeCoachingModel();
            dispatch(fetchCoachingFocusById(storeId));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessageCoaching());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getAccount());
    dispatch(getDevelopmentAreas());
    dispatch(getCustDevAreas());
  }, []);

  const classes = useStyles();
  return (
    <Layout>
      <>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box sx={{ width: "100%" }}>
            {!show ? (
              <PageHeader variant="pageHeader">
                Customer Development Areas
              </PageHeader>
            ) : (
              <PageHeader variant="pageHeader">Coaching Focus</PageHeader>
            )}
          </Box>
          {!show ? (
            <Link
              to="/exco/customers/account-container"
              state={{
                l3Menu: location?.state?.l3Menu,
              }}
            >
              <CancelIcon color="red" />
            </Link>
          ) : (
            <ListItemIcon onClick={() => setShow(false)}>
              <CancelIcon color="red" />
            </ListItemIcon>
          )}
        </Stack>

        {!show ? (
          <MuiDataGrid
            setShowAdd={
              location?.state?.permissions?.find(
                (e) =>
                  e.applicationService?.name?.split(".")[
                  e?.applicationService?.name?.split(".")?.length - 1
                  ] === "create"
              ) && showAddModel
            }
            setShowEdit={
              location?.state?.permissions?.find(
                (e) =>
                  e.applicationService?.name?.split(".")[
                  e?.applicationService?.name?.split(".")?.length - 1
                  ] === "update"
              ) && showEditModal
            }
            setShowDelete={
              location?.state?.permissions?.find(
                (e) =>
                  e.applicationService?.name?.split(".")[
                  e?.applicationService?.name?.split(".")?.length - 1
                  ] === "delete"
              ) && showDelete
            }
            toolbarColumn={true}
            toolbarFilter={true}
            toolbarDensity={true}
            toolbarExport={true}
            data={data}
            select={(data) => dispatch(rowSelect(data))}
          />
        ) : (
          <MuiDataGrid
            // setShowAdd={
            //   location?.state?.permissions?.find(
            //     (e) =>
            //       e.applicationService?.name?.split(".")[
            //         e?.applicationService?.name?.split(".")?.length - 1
            //       ] === "create"
            //   ) && showCoachingChange
            // }
            // setShowEdit={
            //   location?.state?.permissions?.find(
            //     (e) =>
            //       e.applicationService?.name?.split(".")[
            //         e?.applicationService?.name?.split(".")?.length - 1
            //       ] === "update"
            //   ) && showEditCoachingChange
            // }
            // setShowDelete={
            //   location?.state?.permissions?.find(
            //     (e) =>
            //       e.applicationService?.name?.split(".")[
            //         e?.applicationService?.name?.split(".")?.length - 1
            //       ] === "delete"
            //   ) && showDelete
            // }
            toolbarColumn={true}
            toolbarFilter={true}
            toolbarDensity={true}
            toolbarExport={true}
            data={dataThree}
            select={(data) => dispatch(rowSelect(data))}
          />
        )}
      </>

      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />

          <div className={classes.responsiveText}>
            <MuiAutocomplete
              name="accountName"
              options={accountData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.accountName}
              optionValue="accountName"
              label="Account Name"
              required={true}
              error={
                formik.touched.accountName && Boolean(formik.errors.accountName)
              }
              helperText={
                formik.touched.accountName && formik.errors.accountName
              }
            />
            <MuiAutocomplete
              name="devAreaName"
              options={devAreasData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.devAreaName}
              optionValue="developmentAreaName"
              label="Development Area Name"
              required={true}
              error={
                formik.touched.devAreaName && Boolean(formik.errors.devAreaName)
              }
              helperText={
                formik.touched.devAreaName && formik.errors.devAreaName
              }
            />
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              tabIndex={8}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </div>
          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ModalPopUp
        open={showCoachingFocus}
        handleClose={() => setShowCoachingFocus(false)}
        closeModel={() => setShowCoachingFocus(false)}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={coachingFormik.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowCoachingFocus(false)} />

          <div className={classes.responsiveText}>
            <MuiAutocomplete
              name="coachingFocus"
              options={coachingFocusAreaData}
              setValue={(name, newValue) =>
                coachingFormik.setFieldValue(name, newValue)
              }
              value={coachingFormik.values.coachingFocus}
              optionValue="developmentAreaCoachingFocusName"
              label="Coaching Focus Name"
              required={true}
              error={
                coachingFormik.touched.coachingFocus &&
                Boolean(coachingFormik.errors.coachingFocus)
              }
              helperText={
                coachingFormik.touched.coachingFocus &&
                coachingFormik.errors.coachingFocus
              }
            />

            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              tabIndex={8}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </div>
          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ModalPopUp
        open={showEdit}
        handleClose={closeEditModal}
        closeModel={closeEditModal}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={closeEditModal} />

          <div className={classes.responsiveText}>
            <MuiAutocomplete
              name="accountName"
              options={accountData}
              setValue={(name, newValue) =>
                formikEdit.setFieldValue(name, newValue)
              }
              value={formikEdit.values.accountName}
              optionValue="accountName"
              label="Account Name"
              required={true}
              error={
                formikEdit.touched.accountName &&
                Boolean(formikEdit.errors.accountName)
              }
              helperText={
                formikEdit.touched.accountName && formikEdit.errors.accountName
              }
            />
            <FormControl sx={{ marginTop: "1rem", marginBottom: 0 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Development Area Name *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="devAreaName"
                // defaultValue={formikEdit?.values?.devAreaName}
                value={formikEdit.values.devAreaName}
                error={
                  formikEdit.touched.devAreaName &&
                  Boolean(formikEdit.errors.devAreaName)
                }
                label="Development Area Name"
                onChange={formikEdit.handleChange}
                tabIndex={1}
              >
                {devAreasData.length > 0 &&
                  devAreasData.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.developmentAreaName}
                    </MenuItem>
                  ))}
              </Select>
              {formikEdit.touched.devAreaName && (
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formikEdit.errors.devAreaName}
                </FormHelperText>
              )}
            </FormControl>
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              tabIndex={8}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ModalPopUp
        open={showEditCoachingFocus}
        handleClose={() => setShowEditCoachingFocus(false)}
        closeModel={() => setShowEditCoachingFocus(false)}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={coachingFormkiEdit.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowEditCoachingFocus(false)} />

          <div className={classes.responsiveText}>
            <FormControl sx={{ marginTop: "1rem", marginBottom: 0 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Coaching Focus Name *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="coachingFocus"
                defaultValue={coachingFormkiEdit?.values?.coachingFocus}
                value={coachingFormkiEdit.values.devAreaName}
                error={
                  coachingFormkiEdit.touched.coachingFocus &&
                  Boolean(coachingFormkiEdit.errors.coachingFocus)
                }
                label="Development Area Name"
                onChange={coachingFormkiEdit.handleChange}
                tabIndex={1}
              >
                {coachingFocusAreaData.length > 0 &&
                  coachingFocusAreaData.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.developmentAreaCoachingFocusName}
                    >
                      {item.developmentAreaCoachingFocusName}
                    </MenuItem>
                  ))}
              </Select>
              {coachingFormkiEdit.touched.devAreaName && (
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {coachingFormkiEdit.errors.devAreaName}
                </FormHelperText>
              )}
            </FormControl>
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              tabIndex={8}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessageArea && <ErrorNotifier message={errMessageArea} />}
      {messageArea && <Notifier message={messageArea} />}

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default CustDevAreas;
