import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  CURD_DEVELOPMENT_AREAS,
  CURD_COACHING_FOCUS_AREA_DEV_AREAS,
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const createDevelopmentAreas = createAsyncThunk(
  "developmentAreas/createDevelopmentAreas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_DEVELOPMENT_AREAS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCoachingFocusArea = createAsyncThunk(
  "coaching/createCoachingFocus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CURD_COACHING_FOCUS_AREA_DEV_AREAS,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCoachingFocus = createAsyncThunk(
  "coaching/getCoachingFocus",
  async () => {
    return axios.get(CURD_COACHING_FOCUS_AREA_DEV_AREAS, {
      withCredentials: true,
    });
  }
);

export const fetchCoachingFocusById = createAsyncThunk(
  "coaching/getCoachingFocusById",
  async (data) => {
    return axios.get(`${CURD_COACHING_FOCUS_AREA_DEV_AREAS}/get-all/${data}`, {
      withCredentials: true,
    });
  }
);

export const fetchAddedCoachingFocusById = createAsyncThunk(
  "coaching/getCoachingFocusByAdded",
  async (data) => {
    return axios.get(`${CURD_COACHING_FOCUS_AREA_DEV_AREAS}/get-all/${data}`, {
      withCredentials: true,
    });
  }
);

export const getDevelopmentAreas = createAsyncThunk(
  "developmentAreas/getDevelopmentAreas",
  async () => {
    return axios.get(CURD_DEVELOPMENT_AREAS, { withCredentials: true });
  }
);

export const updateDevelopmentAreas = createAsyncThunk(
  "developmentAreas/updateDevelopmentAreas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_DEVELOPMENT_AREAS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCoachingDevAreas = createAsyncThunk(
  "developmentAreasCoaching/updateCoachingDevelopmentAreas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        CURD_COACHING_FOCUS_AREA_DEV_AREAS,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDevelopmentAreas = createAsyncThunk(
  "developmentAreas/deleteDevelopmentAreas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_DEVELOPMENT_AREAS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCoachingDevelopmentArea = createAsyncThunk(
  "developmentAreasCoaching/deleteCoachingDevelopmentAreas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_COACHING_FOCUS_AREA_DEV_AREAS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const developmentAreasSlice = createSlice({
  name: "developmentAreas",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    devAreasData: [],
    coachingFocusAreaData: [],
    coachingFocusList: [],
    selectedData: {},
    coachingFocusCustomerData: [],
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createDevelopmentAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [createDevelopmentAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createDevelopmentAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createCoachingFocusArea.pending]: (state, action) => {
      state.loading = true;
    },
    [createCoachingFocusArea.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createCoachingFocusArea.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getCoachingFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [getCoachingFocus.fulfilled]: (state, action) => {
      state.loading = false;
      state.coachingFocusAreaData = action?.payload?.data;
    },
    [getCoachingFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [fetchCoachingFocusById.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCoachingFocusById.fulfilled]: (state, action) => {
      state.loading = false;
      state.coachingFocusList = action?.payload?.data;
    },
    [fetchCoachingFocusById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
      state.coachingFocusList = [];
    },

    [fetchAddedCoachingFocusById.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAddedCoachingFocusById.fulfilled]: (state, action) => {
      state.loading = false;
      state.coachingFocusCustomerData = action?.payload?.data;
    },
    [fetchAddedCoachingFocusById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
      state.coachingFocusList = [];
    },

    [getDevelopmentAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [getDevelopmentAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.devAreasData = action?.payload?.data;
    },
    [getDevelopmentAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateDevelopmentAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [updateDevelopmentAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateDevelopmentAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateCoachingDevAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCoachingDevAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCoachingDevAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [deleteDevelopmentAreas.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteDevelopmentAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteDevelopmentAreas.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [deleteCoachingDevelopmentArea.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCoachingDevelopmentArea.fulfilled]: (state, action) => {
      console.log( action?.payload?.data?.message)
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteCoachingDevelopmentArea.rejected]: (state, action) => {
      console.log( action?.payload)
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  developmentAreasSlice.actions;
export default developmentAreasSlice.reducer;
