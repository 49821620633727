import React, { useEffect } from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  updateApiManagement,
  getApiManagement,
  resetMessage,
  resetErrMessage,
} from "../../../../features/platform/app_setup/apiManagementSlice";
import { getL1Menu } from "../../../../features/platform/app_setup/menu_management/l1MenuSlice";
import { getL2Menu } from "../../../../features/platform/app_setup/menu_management/l2MenuSlice";
import { getL3Menu } from "../../../../features/platform/app_setup/menu_management/l3MenuSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  appName: Yup.string().required("Application Name is required"),
  moduleName: Yup.string().required("Module Name is required"),
  subModuleName: Yup.string(),
  featureName: Yup.string(),
  serviceName: Yup.string(),
  url: Yup.string().required("URL is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const UpdateApiManagement = (props) => {
  const { message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.apiManagement })
  );

  const {l1MenuData} = useSelector((state) => ({...state.l1Menus}))
  const {l2MenuData} = useSelector((state) => ({...state.l2Menus}))
  const {l3MenuData} = useSelector((state) => ({...state.l3Menus}))

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      appName: selectedData?.name.split(".")[0],
      moduleName: selectedData?.name.split(".").length > 2 ? selectedData?.name?.split(".")[1] : "",
      subModuleName: selectedData?.name.split(".").length > 3 ? selectedData?.name?.split(".")[2] : "",
      featureName: selectedData?.name?.split(".").length > 4 ? selectedData?.name?.split(".")[3] : "",
      serviceName: selectedData?.name?.split(".")[selectedData?.name.split(".").length - 1],
      url: selectedData?.url,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateApiManagement({
          id: selectedData.id,
          applicationName: values?.appName,
          moduleName: values?.moduleName,
          subModuleName: values?.subModuleName,
          featureName: values?.featureName,
          serviceName: values?.serviceName,
          url: values?.url,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getApiManagement());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getL1Menu())
    dispatch(getL2Menu())
    dispatch(getL3Menu())
  }, [])

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">Update API Management</PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
            <MyTextInput
                label="Application Name"
                type="text"
                name="appName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="appName"
                value={formik.values.appName}
                onChange={formik.handleChange}
                error={
                  formik.touched.appName &&
                  Boolean(formik.errors.appName)
                }
                helperText={
                  formik.touched.appName && formik.errors.appName
                }
              />
              <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Module Name *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="moduleName"
                  value={formik.values.moduleName}
                  error={
                    formik.touched.moduleName && Boolean(formik.errors.moduleName)
                  }
                  label="moduleName"
                  onChange={formik.handleChange}
                >
                  {l1MenuData.length > 0 &&
                    l1MenuData.map((item) => (
                      <MenuItem key={item.id} value={item.l1MenuName.replace(/ /g, "")}>
                        {item.l1MenuName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.moduleName && formik.errors.moduleName}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                 Sub Module Name 
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="subModuleName"
                  value={formik.values.subModuleName}
                  error={
                    formik.touched.subModuleName &&
                    Boolean(formik.errors.subModuleName)
                  }
                  label="subModuleName"
                  onChange={formik.handleChange}
                >
                  {l2MenuData.length > 0 &&
                    l2MenuData.map((item) => {
                      if(item.level1Menu?.l1MenuName === formik?.values?.moduleName){
                        return <MenuItem key={item.id} value={item.l2MenuName.replace(/ /g, "")}>
                        {item.l2MenuName}
                      </MenuItem>
                      }
                      
})}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.subModuleName && formik.errors.subModuleName}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Feature Name 
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="featureName"
                  value={formik.values.featureName}
                  error={
                    formik.touched.featureName &&
                    Boolean(formik.errors.featureName)
                  }
                  label="featureName"
                  onChange={formik.handleChange}
                >
                  {l3MenuData.length > 0 &&
                    l3MenuData.map((item) => {
                      if(item.level2Menu?.l2MenuName.replace(/ /g, "") === formik?.values?.subModuleName){
                        return <MenuItem key={item.id} value={item.l3MenuName.replace(/ /g, "")}>
                        {item.l3MenuName}
                      </MenuItem>
                      }
                      
})}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.featureName && formik.errors.featureName}
                </FormHelperText>
              </FormControl>
              <MyTextInput
                label="Service Name"
                type="text"
                name="serviceName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="serviceName"
                value={formik.values.serviceName}
                onChange={formik.handleChange}
                error={
                  formik.touched.serviceName &&
                  Boolean(formik.errors.serviceName)
                }
                helperText={
                  formik.touched.serviceName && formik.errors.serviceName
                }
              />
              <MyTextInput
                label="URL"
                type="text"
                name="url"
                required={true}
                fullWidth={true}
                margin="normal"
                id="url"
                value={formik.values.url}
                onChange={formik.handleChange}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default UpdateApiManagement;
