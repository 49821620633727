import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  CURD_MENTOR,
  RESEND_MENTOR_EMAIL,
  CREATE_MENTOR,
  UPDATE_MENTOR,
  GET_ALL_MENTOR,
  ACTIVATE_MENTOR_ACCOUNT,
} from "../../utils/routes";

// -----------------------------------------------------------------

export const resendEmail = createAsyncThunk(
  "mentor/resendEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(RESEND_MENTOR_EMAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createMentor = createAsyncThunk(
  "mentor/createMentor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_MENTOR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const activateMentorAccount = createAsyncThunk(
  "mentor/activateMentorAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ACTIVATE_MENTOR_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMentor = createAsyncThunk("mentor/getMentor", async () => {
  return axios.get(GET_ALL_MENTOR, { withCredentials: true });
});

export const updateMentor = createAsyncThunk(
  "mentor/updateMentor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_MENTOR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteMentor = createAsyncThunk(
  "mentor/deleteMentor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_MENTOR, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const mentorSlice = createSlice({
  name: "mentor",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    mentorsData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [resendEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [resendEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [resendEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createMentor.pending]: (state, action) => {
      state.loading = true;
    },
    [createMentor.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createMentor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [activateMentorAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [activateMentorAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [activateMentorAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getMentor.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentor.fulfilled]: (state, action) => {
      state.loading = false;
      state.mentorsData = action?.payload?.data;
    },
    [getMentor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateMentor.pending]: (state, action) => {
      state.loading = true;
    },
    [updateMentor.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateMentor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteMentor.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMentor.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteMentor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } = mentorSlice.actions;

export default mentorSlice.reducer;
