import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_PROGRESS_REVIEW_FREQUENCY } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createProgressReviewFrequency = createAsyncThunk(
  "account/createProgressReviewFrequency",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_PROGRESS_REVIEW_FREQUENCY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProgressReviewFrequency = createAsyncThunk(
  "account/getProgressReviewFrequency",
  async () => {
    return axios.get(CURD_PROGRESS_REVIEW_FREQUENCY, { withCredentials: true });
  }
);

export const updateProgressReviewFrequency = createAsyncThunk(
  "account/updateProgressReviewFrequency",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_PROGRESS_REVIEW_FREQUENCY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteProgressReviewFrequency = createAsyncThunk(
  "account/deleteProgressReviewFrequency",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_PROGRESS_REVIEW_FREQUENCY, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const progressReviewFrequencySlice = createSlice({
  name: "progressReviewFrequency",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    progressReviewFrequencyData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createProgressReviewFrequency.pending]: (state, action) => {
      state.loading = true;
    },
    [createProgressReviewFrequency.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createProgressReviewFrequency.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getProgressReviewFrequency.pending]: (state, action) => {
      state.loading = true;
    },
    [getProgressReviewFrequency.fulfilled]: (state, action) => {
      state.loading = false;
      state.progressReviewFrequencyData = action?.payload?.data;
    },
    [getProgressReviewFrequency.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateProgressReviewFrequency.pending]: (state, action) => {
      state.loading = true;
    },
    [updateProgressReviewFrequency.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateProgressReviewFrequency.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteProgressReviewFrequency.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteProgressReviewFrequency.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteProgressReviewFrequency.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  progressReviewFrequencySlice.actions;
export default progressReviewFrequencySlice.reducer;
