import React, { useEffect } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// material ui 
import {Grid, Box, FormGroup, InputLabel,FormControl, Select, MenuItem, FormHelperText} from '@mui/material'
// components
import { MuiAutocomplete, ModalPopUp, FormSubmitButton, ModalCloseBtn, FormTextField as MyTextInput, Notifier, ErrorNotifier } from '../../../../components'
// redux
import {createL2Menu, getL2Menu, resetMessage, resetErrMessage} from '../../../../features/platform/app_setup/menu_management/l2MenuSlice'

// ----------------------------------------------------------------------------------
const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

// ------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
    level1MenuId: Yup.object().required("Level 1 Menu is required"),
    l2MenuName: Yup.string().required("L2 Menu Name is required"),
    l2MenuGroupId: Yup.string().required("L2 Menu Group Id is required"),
    l2PageLink: Yup.string().required("L2 Page Link is required"),
    l2MenuIcon: Yup.string().required("L2 Menu Icon is required"),
  });

//   -------------------------------------------------------------------------------------------

const AddL2Menu = ({showAdd, setShowAdd}) => {

    const {l1MenuData} = useSelector((state) => ({...state.l1Menus}))
    const {message, errMessage} = useSelector((state) => ({...state.l2Menus}))
  const dispatch = useDispatch();

    const handleFile = (e) => {
        formik.setFieldValue("l2MenuIcon", e.target.files[0]);
      };

      const formRefresh = () => {
        formik.resetForm({
            values: {
                level1MenuId: "",
              l2MenuName: "",
              l2MenuGroupId: "",
              l2PageLink: "",
              l2MenuIcon: ""
            },
          });
      }

    const formik = useFormik({
        reset: true,
        initialValues: {
            level1MenuId: "",
          l2MenuName: "",
          l2MenuGroupId: "",
          l2PageLink: "",
          l2MenuIcon: "",
        },
    
        validationSchema: validationSchema,
        onSubmit: (values) => {
          let formdata = new FormData();
          formdata.append("level1MenuId", values.level1MenuId?.id)
          formdata.append("l2MenuName", values.l2MenuName);
          formdata.append("l2MenuGroupId", values.l2MenuGroupId.toString());
          formdata.append("l2PageLink", values.l2PageLink);
          formdata.append("l2MenuIcon", values.l2MenuIcon);
          dispatch(createL2Menu(formdata)).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
                dispatch(getL2Menu());
                setShowAdd(false);
                formRefresh()
              }, 2000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
              }, 3000);
            }
          });
        },
      });

  return (
    <>
    <ModalPopUp
    open={showAdd}
  >
    <Box
      sx={{ ...style, width: 900 }}
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <ModalCloseBtn 
      close={() => {
        setShowAdd(false)
        formRefresh()
    }} 
      />
      <div style={{ width: "50rem" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} sx={{padding: '4rem'}}>
            <MuiAutocomplete
              name="level1MenuId"
              options={l1MenuData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.level1MenuId}
              optionValue="l1MenuName"
              label="L1 Menu Name"
              required={true}
              error={formik.touched.level1MenuId && Boolean(formik.errors.level1MenuId)}
              helperText={formik.touched.level1MenuId && formik.errors.level1MenuId}
            />
          <MyTextInput
                  label="L2 Menu Name"
                  type="text"
                  name="l2MenuName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l2MenuName"
                  value={formik.values.l2MenuName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l2MenuName &&
                    Boolean(formik.errors.l2MenuName)
                  }
                  helperText={
                    formik.touched.l2MenuName && formik.errors.l2MenuName
                  }
                />
          <MyTextInput
                  label="L2 Menu Group Id"
                  type="text"
                  name="l2MenuGroupId"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l2MenuGroupId"
                  value={formik.values.l2MenuGroupId}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l2MenuGroupId &&
                    Boolean(formik.errors.l2MenuGroupId)
                  }
                  helperText={
                    formik.touched.l2MenuGroupId && formik.errors.l2MenuGroupId
                  }
                />
          <MyTextInput
                  label="L2 Page Link"
                  type="text"
                  name="l2PageLink"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="l2PageLink"
                  value={formik.values.l2PageLink}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.l2PageLink &&
                    Boolean(formik.errors.l2PageLink)
                  }
                  helperText={
                    formik.touched.l2PageLink && formik.errors.l2PageLink
                  }
                />
          <Grid container spacing={1} sx={{ marginTop: "0.5rem" }}>
              <Grid item xs={6} sm={6} md={8}>
                <FormGroup className="file-display mr-1">
                  <InputLabel className="bold label-sm">Icon *</InputLabel>
                  <MyTextInput
                    disabled
                    type="text"
                    name="l2MenuIcon"
                    value={formik?.values?.l2MenuIcon?.name || ""}
                    className="form-control-sm "
                    required={true}
                    size="small"
                    error={
                      formik.touched.l2MenuIcon &&
                      Boolean(formik.errors.l2MenuIcon)
                    }
                    helperText={
                      formik.touched.l2MenuIcon &&
                      formik.errors.l2MenuIcon
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <MyTextInput
                      type="file"
                      name="l2MenuIcon"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={handleFile}
                      required={true}
                      size="small"
                      error={
                        formik.touched.l2MenuIcon &&
                        Boolean(formik.errors.l2MenuIcon)
                      }
                      helperText={
                        formik.touched.l2MenuIcon &&
                        formik.errors.l2MenuIcon
                      }
                    />
                  </label>
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <FormSubmitButton type="submit" variant="contained">
        Submit
      </FormSubmitButton>
    </Box>
  </ModalPopUp>
  {message && <Notifier message={message} />}
  {errMessage && <ErrorNotifier message={errMessage} />}
  </>
  )
}

export default AddL2Menu