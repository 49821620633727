import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// material ui
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  Row,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Stack,
  Box,
  Typography,
  Paper,
} from "@mui/material";
// materail icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// redux
import { getClientsByMentor } from "../../../features/exec/mentors/mentorSlice";

// ---------------------------------------------------------------

function CustRow(props) {
  const { row } = props;

  const dispatch = useDispatch();

  const { clientsByMentorData } = useSelector((state) => ({
    ...state.execMentor,
  }));

  useEffect(() => {
    props?.open && dispatch(getClientsByMentor(row.excoMentor?.id));
  }, [props?.open]);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell>{row.excoMentor.email}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => props.onClick(props.open ? "" : row.id)}
          >
            {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={props.open} timeout="auto" unmountOnExit>
            <Stack
              sx={{ margin: 1 }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Participants (Clients)
                </Typography>
                <Table size="small" aria-label="purchases">
                <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
                  <TableBody>
                    {clientsByMentorData.length > 0 && clientsByMentorData.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          {
                            item?.excoCustomerUser
                              ?.excoCustomerUserDetail?.firstName
                          }{" "}
                          {
                            item?.excoCustomerUser
                              ?.excoCustomerUserDetail?.lastName
                          }
                        </TableCell>
                        <TableCell>
                          {item?.excoCustomerUser?.email}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// ------------------------------------------------------------------------------

const CollapsibleTable = ({ data, search }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openRow, setOpenRow] = useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible label">
        <TableHead>
          <TableRow>
            <TableCell>Mentor Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 &&
            data
              .filter((val) => {
                if (val === " ") {
                  return val;
                } else if (
                  (val.firstName &&
                    val.firstName
                      .toLowerCase()
                      .includes(search.toString().toLowerCase())) ||
                  (val.lastName &&
                    val.lastName
                      .toLowerCase()
                      .includes(search.toString().toLowerCase())) ||
                  (val.excoMentor.email &&
                    val.excoMentor.email
                      .toLowerCase()
                      .includes(search.toString().toLowerCase()))
                ) {
                  return val;
                }
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => <CustRow key={row.id} row={row} open={row.id == openRow} onClick={(id) => setOpenRow(id)} />)}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={4} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default CollapsibleTable;
