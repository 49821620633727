import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { countries, continents } from "countries-list";

// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import {
  FormSubmitButton,
  FormTextField as MyTextInput,
} from "../../components";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
// redux
import {
  getNomination,
  getNominationByCust,
  updateNomination,
  resetMessage,
  resetErrMessage,
} from "../../features/nomination/nominationSlice";
import { getNominationStatus } from "../../features/config/nominationStatusSlice";
import { getNominationMode } from "../../features/config/nominationModeSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  leaderName: Yup.string().required("Leader First Name is required"),
  leaderLastName: Yup.string().required("Leader Last Name is required"),
  leaderEmail: Yup.string("Enter your email")
    .required("Leader Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "email is not valid"
    ),
  title: Yup.string().required("Title is required"),
  // level: Yup.string().required("Level is required"),
  location: Yup.string().required("Location is required"),
  managerFirstName: Yup.string().required("Manager First Name is required"),

  managerLastName: Yup.string().required("Manager Last Name is required"),
  hrFirstName: Yup.string().required("HR Partner First Name is required"),
  hrLastName: Yup.string().required("HR Partner Last Name is required"),
  managerEmail: Yup.string("Enter your email")

    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "email is not valid"
    ),

  hePartnerEmail: Yup.string("Enter your email")

    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "email is not valid"
    ),

  reason: Yup.string().required("Reason is required"),
});

//   ------------------------------------------------------------------------------------------

const UpdateNomination = (props) => {
  const [countryArr, setCountryArr] = useState([]);

  const { message, errMessage, selectedData } = useSelector((state) => ({
    ...state.nomination,
  }));

  const { nominationStatusData } = useSelector((state) => ({
    ...state.nominationStatus,
  }));

  const { nominationModeData } = useSelector((state) => ({
    ...state.nominationMode,
  }));

  const dispatch = useDispatch();

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getNominationStatus());
    dispatch(getNominationMode());
  }, []);

  console.log(selectedData);

  const formik = useFormik({
    initialValues: {
      accountName: selectedData?.excoCustomerAccount?.accountName,
      leaderName: selectedData?.leaderFirstName,
      leaderLastName: selectedData?.leaderLastName,
      leaderEmail: selectedData?.leaderEmail,
      managerFirstName: selectedData?.managerFirstName,
      managerLastName: selectedData?.managerLastName,
      hrFirstName: selectedData?.hrPartnerFirstName,
      hrLastName: selectedData?.hrPartnerLastName,
      managerEmail: selectedData?.managerEmail,

      hePartnerEmail: selectedData?.hrPartnerEmail,
      assistent: selectedData?.assistantName,
      assistentEmail: selectedData?.assistantEmail,
      reason: selectedData?.reasonForCoaching,
      comment: selectedData?.additionalComments,
      title: selectedData?.title,
      location: selectedData?.location,
      level: selectedData?.level,
      status: selectedData?.status,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateNomination({
          id: selectedData?.id,
          assistantEmail: values?.assistentEmail,
          assistantName: values?.assistent,
          hrPartnerEmail: values?.hePartnerEmail,
          hrPartnerName: values?.hePartner,
          title: values?.title,
          reasonForCoaching: values?.reason,
          managerEmail: values?.managerEmail,
          leaderEmail: values?.leaderEmail,
          managerName: values?.managerName,
          leaderFirstName: values?.leaderName,
          leaderLastName: values?.leaderLastName,
          location: values?.location,
          level: values?.level,
          additionalComments: values?.comment,
          status: values?.status,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            props.account
              ? dispatch(getNominationByCust(props?.account?.id))
              : dispatch(getNomination());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    let countries = [];
    options.map((option) => {
      if (option.continent === formik?.values?.geography) {
        return countries.push(option);
      }
    });
    setCountryArr(countries);
  }, [formik?.values?.geography]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country) {
        return formik?.setFieldValue("execDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country) {
        return formik?.setFieldValue("managerDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={<PageHeader variant="pageHeader">Update Nomination</PageHeader>}
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <MyTextInput
                tabIndex="1"
                label="Account Name"
                type="text"
                name="accountName"
                required={true}
                margin="normal"
                fullWidth={true}
                disabled={true}
                id="accountName"
                value={formik.values.accountName}
                error={formik.touched.accountName && Boolean(formik.errors.accountName)}
                helperText={formik.touched.accountName && formik.errors.accountName}
              />

              <Grid container item xs={12} sm={12} md={12}>

                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex="3"
                    label="Leader First Name"
                    type="text"
                    name="leaderName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="leaderName"
                    value={formik.values.leaderName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.leaderName &&
                      Boolean(formik.errors.leaderName)
                    }
                    helperText={
                      formik.touched.leaderName && formik.errors.leaderName
                    }
                  />
                </Grid>
                <Grid sx={{ pl: "0.5rem" }} item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex="4"
                    label="Leader Last Name"
                    type="text"
                    name="leaderLastName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="leaderLastName"
                    value={formik.values.leaderLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.leaderLastName &&
                      Boolean(formik.errors.leaderLastName)
                    }
                    helperText={
                      formik.touched.leaderLastName &&
                      formik.errors.leaderLastName
                    }
                  />
                </Grid>
              </Grid>
              <MyTextInput
                tabIndex="6"
                label="Title"
                type="text"
                name="title"
                required={true}
                fullWidth={true}
                margin="normal"
                id="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />

              <Grid container spacing={2} item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="Manager First Name"
                    type="text"
                    name="managerFirstName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="managerFirstName"
                    value={formik.values.managerFirstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.managerFirstName &&
                      Boolean(formik.errors.managerFirstName)
                    }
                    helperText={
                      formik.touched.managerFirstName &&
                      formik.errors.managerFirstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="Manager Last Name"
                    type="text"
                    name="managerLastName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="managerLastName"
                    value={formik.values.managerLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.managerLastName &&
                      Boolean(formik.errors.managerLastName)
                    }
                    helperText={
                      formik.touched.managerLastName &&
                      formik.errors.managerLastName
                    }
                  />
                </Grid>
              </Grid>


              <Grid container spacing={2} item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="HR Partner First Name"
                    type="text"
                    name="hrFirstName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="hrFirstName"
                    value={formik.values.hrFirstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.hrFirstName &&
                      Boolean(formik.errors.hrFirstName)
                    }
                    helperText={
                      formik.touched.hrFirstName && formik.errors.hrFirstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="HR Partner Last Name"
                    type="text"
                    name="hrLastName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="hrLastName"
                    value={formik.values.hrLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.hrLastName &&
                      Boolean(formik.errors.hrLastName)
                    }
                    helperText={
                      formik.touched.hrLastName && formik.errors.hrLastName
                    }
                  />
                </Grid>
              </Grid>


              <MyTextInput
                tabIndex="12"
                label="Assistant"
                type="text"
                name="assistent"
                // required={true}
                fullWidth={true}
                margin="normal"
                id="assistent"
                value={formik.values.assistent}
                onChange={formik.handleChange}
                error={
                  formik.touched.assistent && Boolean(formik.errors.assistent)
                }
                helperText={formik.touched.assistent && formik.errors.assistent}
              />

              <MyTextInput
                tabIndex="14"
                label="City/Location"
                type="text"
                name="location"
                required={true}
                fullWidth={true}
                margin="normal"
                id="location"
                value={formik.values.location}
                onChange={formik.handleChange}

                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MyTextInput
                tabIndex="2"
                label="Leader's Email"
                type="text"
                name="leaderEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="leaderEmail"
                value={formik.values.leaderEmail}
                onChange={formik.handleChange}

                error={
                  formik.touched.leaderEmail &&
                  Boolean(formik.errors.leaderEmail)
                }
                helperText={
                  formik.touched.leaderEmail && formik.errors.leaderEmail
                }
              />
              <MyTextInput
                tabIndex="5"
                label="Level"
                type="text"
                name="level"
                // required={true}
                fullWidth={true}
                margin="normal"
                id="level"
                value={formik.values.level}
                onChange={formik.handleChange}
                error={formik.touched.level && Boolean(formik.errors.level)}
                helperText={formik.touched.level && formik.errors.level}
              />

              <MyTextInput
                tabIndex="7"
                label="Manager Email"
                type="text"
                name="managerEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerEmail"
                value={formik.values.managerEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerEmail &&
                  Boolean(formik.errors.managerEmail)
                }
                helperText={
                  formik.touched.managerEmail && formik.errors.managerEmail
                }
              />

              <MyTextInput
                tabIndex="9"
                label="HR Partner Email"
                type="text"
                name="hePartnerEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="hePartnerEmail"
                value={formik.values.hePartnerEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.hePartnerEmail &&
                  Boolean(formik.errors.hePartnerEmail)
                }
                helperText={
                  formik.touched.hePartnerEmail && formik.errors.hePartnerEmail
                }
              />

              <MyTextInput
                tabIndex="11"
                label="Assistant Email"
                type="text"
                name="assistentEmail"
                fullWidth={true}
                margin="normal"
                id="assistentEmail"
                value={formik.values.assistentEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.assistentEmail &&
                  Boolean(formik.errors.assistentEmail)
                }
                helperText={
                  formik.touched.assistentEmail && formik.errors.assistentEmail
                }
              />

              <MyTextInput
                tabIndex="13"
                label="Reason for Coaching/Mentoring "
                type="text"
                name="reason"
                required={true}
                fullWidth={true}
                margin="normal"
                id="reason"
                value={formik.values.reason}
                onChange={formik.handleChange}

                error={formik.touched.reason && Boolean(formik.errors.reason)}
                helperText={formik.touched.reason && formik.errors.reason}
              />

              <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                <Select
                  tabIndex="15"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={formik.values.status}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  label="Status *"
                  onChange={formik.handleChange}
                >
                  {nominationStatusData.length > 0 &&
                    nominationStatusData.map((item) => (
                      <MenuItem key={item.id} value={item.nominationStatus}>
                        {item.nominationStatus}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.status && formik.errors.status}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Box textAlign="center" width="100%">
              <FormSubmitButton type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default UpdateNomination;
