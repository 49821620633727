import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_NOMINATION_STATUS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createNominationStatus = createAsyncThunk(
  "nominationStatus/createNominationStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_NOMINATION_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNominationStatus = createAsyncThunk(
  "nominationStatus/getNominationStatus",
  async () => {
    return axios.get(CURD_NOMINATION_STATUS, { withCredentials: true });
  }
);

export const updateNominationStatus = createAsyncThunk(
  "nominationStatus/updateNominationStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_NOMINATION_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNominationStatus = createAsyncThunk(
  "nominationStatus/deleteNominationStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_NOMINATION_STATUS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const nominationStatusSlice = createSlice({
  name: "nominationStatus",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    nominationStatusData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createNominationStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [createNominationStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createNominationStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getNominationStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [getNominationStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.nominationStatusData = action?.payload?.data;
    },
    [getNominationStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateNominationStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNominationStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateNominationStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteNominationStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteNominationStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteNominationStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  nominationStatusSlice.actions;
export default nominationStatusSlice.reducer;
