import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// material ui
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Stack,
  Box,
  Typography,
  Paper,
} from "@mui/material";
// materail icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
//redux
import {getClientsByAcc, getActiveEngByAcc} from '../../../features/exec/clients/clientSlice'

// ---------------------------------------------------------------

function CustRow(props) {
  const { row } = props;

  const dispatch = useDispatch();
  const { clientsByAccData, activeEngByAccData } = useSelector(
    (state) => ({ ...state.execClient })
  );

  useEffect(() => {
    props?.open && dispatch(getClientsByAcc(row.id));
    props?.open && dispatch(getActiveEngByAcc(row.id))
  }, [props?.open]);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>{row.excoCustomer?.customerName}</TableCell>
        <TableCell>{row.accountName}</TableCell>
        <TableCell>{row.contactName}</TableCell>
        <TableCell>{row.contactEmail}</TableCell>
        <TableCell>
          {row.dialingCode}-{row.contactNumber}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => props.onClick(props.open ? "" : row.id)}
          >
            {props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={props.open} timeout="auto" unmountOnExit>
            <Stack
              sx={{ margin: 1 }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Clients
                </Typography>
                <Table size="small" aria-label="purchases">
                <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
                  <TableBody>
                    {clientsByAccData.length > 0 &&
                      clientsByAccData.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            {item?.excoCustomerUserDetail?.firstName} {item?.excoCustomerUserDetail?.lastName}
                          </TableCell>
                          <TableCell>
                            {item?.email}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Active Engagements
                </Typography>
                <Table size="small" aria-label="purchases">
                <TableHead>
          <TableRow>
            <TableCell>Engagement Name</TableCell>
            <TableCell>Contact Name</TableCell>
            <TableCell>Number</TableCell>
          </TableRow>
        </TableHead>
                  <TableBody>
                    {activeEngByAccData.length > 0 &&
                      activeEngByAccData.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            {item?.engagementName}
                          </TableCell>
                          <TableCell>
                            {item?.contactName}
                          </TableCell>
                          <TableCell>
                            {item?.dialingCode}-
                            {item?.contactNumber}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
              
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// ------------------------------------------------------------------------------

const CollapsibleTable = ({ data, search }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openRow, setOpenRow] = useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible label">
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Contact Name</TableCell>
            <TableCell>Contact Email</TableCell>
            <TableCell>Contact Number</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 &&
            data
              .filter((val) => {
                if (search === " ") {
                  return val;
                } else if (
                  (val.excoCustomer?.customerName &&
                    val.excoCustomer?.customerName
                      .toLowerCase()
                      .includes(search.toString().toLowerCase())) ||
                  (val.accountName &&
                    val.accountName
                      .toLowerCase()
                      .includes(search.toString().toLowerCase())) ||
                  (val.contactName &&
                    val.contactName
                      .toLowerCase()
                      .includes(search.toString().toLowerCase())) ||
                  (val.contactEmail &&
                    val.contactEmail
                      .toLowerCase()
                      .includes(search.toString().toLowerCase())) ||
                  (val.contactNumber && val.contactNumber.includes(search))
                ) {
                  return val;
                }
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => <CustRow key={row.id} row={row} open={row.id == openRow} onClick={(id) => setOpenRow(id)} />)}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={4} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default CollapsibleTable;
