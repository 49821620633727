import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_COACHING_FOCUS_AREA,COACHING_FOCUS_AREA_PURPOSE } from "../../utils/routes";

// ---------------------------------------------------------------------

export const getCoachingFocusArea = createAsyncThunk(
  "coachingFocusArea/getCoachingFocusArea",
  async (id) => {
    return axios.get(`${CURD_COACHING_FOCUS_AREA}/${id}`, { withCredentials: true });
  }
);

export const getCoachingFocusAreaPurpose = createAsyncThunk(
  "coachingFocusArea/getCoachingFocusAreaPurpose",
  async (id) => {
    return axios.get(`${COACHING_FOCUS_AREA_PURPOSE}/${id}`, { withCredentials: true });
  }
);






const coachingFocusAreaSlice = createSlice({
  name: "coachingFocusArea",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    coachingFocusAreaData: [],
    coachingFocusPurposeData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {

    [getCoachingFocusAreaPurpose.pending]: (state, action) => {
      state.loading = true;
    },
    [getCoachingFocusAreaPurpose.fulfilled]: (state, action) => {
      state.loading = false;
      state.coachingFocusPurposeData = action?.payload?.data;
    },
    [getCoachingFocusAreaPurpose.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [getCoachingFocusArea.pending]: (state, action) => {
      state.loading = true;
    },
    [getCoachingFocusArea.fulfilled]: (state, action) => {
      state.loading = false;
      state.coachingFocusAreaData = action?.payload?.data;
    },
    [getCoachingFocusArea.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  coachingFocusAreaSlice.actions;
export default coachingFocusAreaSlice.reducer;
