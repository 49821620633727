import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns'
import { DateTime } from "luxon";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// components
import {
  ModalPopUp,
  FormSubmitButton,
  ModalCloseBtn,
  Notifier,
  ErrorNotifier,
  FormTextField as MyTextInput
} from "../../../components";
// redux
import {
  updateKeyEngagement,
  getKeyEngagement,
  resetMessage,
  resetErrMessage,
} from "../../../features/exec/engagement/keyEngagementSlice";
import { getEngagementStage } from "../../../features/config/engagementStageSlice";

// -----------------------------------------------------------------------------------------------



// ----------------------------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ----------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({

  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '40.25rem !important',
    [theme.breakpoints.down('md')]: {
      width: '20rem !important',
      height: '60% !important',
      display: 'block !important',
      overflow: 'scroll !important',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft: '-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom: '1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText: {
    width: '28rem',
    [theme.breakpoints.down('md')]: {
      width: '100%'

    }

  },
  filter_grid: {
    [theme.breakpoints.down('md')]: {
      display: 'flex !important',
      flexDirection: 'column !important'

    },
  },
  close_btn: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '-2.563rem'

    },
  }

}))




const UpdateKeyEngagements = (props) => {
  const [disableDate, setDisableDate] = useState(false);
  const [disableEndDate, setDisableEndDate] = useState(false);
  const dispatch = useDispatch();
  const { selectedData, message, errMessage } =
    useSelector((state) => ({ ...state.keyEngagement }));


  const validationSchema = Yup.object({
    engDate: disableDate ? Yup.date() : Yup.date().required("Start Date is required"),
  
    status: Yup.string().required("Status is required"),
    comments: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      custClient: props?.id,
      engDate: selectedData?.engagementStartDate ? format(
        new Date(selectedData?.engagementStartDate),
        "yyyy-MM-dd"
      ) : "",
     

      status: selectedData?.status,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const date = DateTime.fromISO(values?.engDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const localIsoString = date.toISO();
     
      dispatch(
        updateKeyEngagement({
          id: selectedData?.id,
          engagementStartDate: localIsoString,
        
          status: values.status,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.closeModel();
            dispatch(getKeyEngagement(props?.engagement));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getEngagementStage());
  }, []);
  useEffect(() => {
    if (formik.values?.status === "N/A") {
      formik.setFieldValue("engDate", "")

      setDisableDate(true)

    }
    else if (formik.values?.status !== "N/A") {
      setDisableDate(false)

    }


  }, [formik.values.status, selectedData?.engagementStageName])
  const classes = useStyles();
  return (
    <>
      <ModalPopUp open={props.showEdit} closeModel={props.closeModel}>
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={props.closeModel} />
          <div className={classes.responsiveText}>
            <Grid container >
              <Grid item xs={12} sm={12} md={12}>
                <FormControl
                  sx={{ marginTop: "1rem" }}
                  fullWidth
                  error={
                    formik.touched.status && formik.errors.status ? true : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">
                    Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    defaultValue={formik?.values?.status}
                    value={formik?.values?.status}
                    label="Status *"
                    onChange={formik.handleChange}
                    required={true}
                  >

                    <MenuItem
                      value="N/A"
                    >
                      N/A
                    </MenuItem>
                    <MenuItem
                      value="Scheduled"
                    >
                      Scheduled
                    </MenuItem>
                    <MenuItem
                      value="In Progress"
                    >
                      In Progress
                    </MenuItem>
                    <MenuItem
                      value="TBD"
                    >
                      TBD
                    </MenuItem>
                    <MenuItem
                      value="Completed"
                    >
                      Completed
                    </MenuItem>

                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formik.touched.status && formik.errors.status}
                  </FormHelperText>
                </FormControl>

                <Grid container md={12} columnSpacing={2}  >
                  <Grid item md={12}   >
                    <MyTextInput
                      required={true}
                      label="Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      name="engDate"
                      disabled={formik.values?.status === "N/A" ? true : false}
                      fullWidth={true}
                      margin="normal"
                      id="outlined-name"
                      value={formik.values.engDate}
                      onChange={formik.handleChange}
                      error={formik.touched.engDate && Boolean(formik.errors.engDate)}
                      helperText={formik.touched.engDate && formik.errors.engDate}
                    />

                  </Grid>





                </Grid>


                <MyTextInput
                  label="Comments"
                  type="text"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  error={formik.touched.comments && Boolean(formik.errors.comments)}
                  helperText={formik.touched.comments && formik.errors.comments}
                />
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  );
};

export default UpdateKeyEngagements;
