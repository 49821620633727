import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_CUSTOMER } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_CUSTOMER, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustomer = createAsyncThunk(
  "customer/getCustomer",
  async () => {
    return axios.get(CURD_CUSTOMER, { withCredentials: true });
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_CUSTOMER, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_CUSTOMER, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    customersData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [createCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.customersData = action?.payload?.data;
    },
    [getCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  customerSlice.actions;
export default customerSlice.reducer;
