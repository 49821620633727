import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ENGAGEMENT_STATUS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createEngagementStatus = createAsyncThunk(
  "engagementStatus/createEngagementStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ENGAGEMENT_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementStatus = createAsyncThunk(
  "engagementStatus/getEngagementStatus",
  async () => {
    return axios.get(CURD_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

export const updateEngagementStatus = createAsyncThunk(
  "engagementStatus/updateEngagementStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ENGAGEMENT_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementStatus = createAsyncThunk(
  "engagementStatus/deleteEngagementStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ENGAGEMENT_STATUS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementStatusSlice = createSlice({
  name: "engagementStatus",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engagementStatusData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createEngagementStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [createEngagementStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createEngagementStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getEngagementStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementStatusData = action?.payload?.data;
    },
    [getEngagementStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEngagementStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEngagementStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEngagementStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  engagementStatusSlice.actions;
export default engagementStatusSlice.reducer;
