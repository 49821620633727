

import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const ProgressReview = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Layout>
      <Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            fontFamily: "arial",
          }}
        >
          <Typography
            sx={{ fontSize: "1.25rem", fontWeight: "700" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            Mentor Engagement Date for Client
          </Typography>

          <TextField
            sx={{ marginLeft: "4rem" }}
            type="date"
            InputLabelProps={{ shrink: true }}
            name="startDate"
            id="demo-simple-select"
            required={true}
            value={startDate}
            defaultValue=""
          />
          <Typography
            sx={{ fontSize: "1rem", marginLeft: "1rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            To
          </Typography>
          <TextField
            sx={{ marginLeft: "1rem" }}
            type="date"
            InputLabelProps={{ shrink: true }}
            name="startDate"
            value={endDate}
            id="demo-simple-select"
            required={true}
          />
        </Stack>
      </Stack>
    </Layout>
  );
};

export default ProgressReview;
