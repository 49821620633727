import React from "react";
import { Grid, ListItemText, Typography } from "@mui/material";

export const CommonGridData = ({ heading, data }) => {
  return (
    <>
      <Grid
        sx={{
          marginTop:
            (heading === "Manager Name" || heading === "Eng Start Date") &&
            "2rem",
        }}
        item
        md={6}
        sm={6}
        xs={6}
      >
        <ListItemText
          primary={<Typography variant="body1">{heading}</Typography>}
        ></ListItemText>
      </Grid>

      <Grid
        sx={{
          marginTop:
            (heading === "Manager Name" || heading === "Eng Start Date") &&
            "2rem",
        }}
        item
        md={6}
        sm={6}
        xs={6}
      >
        <span style={{ wordWrap: "break-word" }} variant="body1">
          {heading === "Exco Account Team" ? "" : `:${data}`}
        </span>
      </Grid>
    </>
  );
};
