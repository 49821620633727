import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_OPP_GOALS } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createOppGoals = createAsyncThunk(
  "oppGoals/createOppGoals",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_OPP_GOALS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOppGoals = createAsyncThunk(
  "oppGoals/getOppGoals",
  async () => {
    return axios.get(CURD_OPP_GOALS, { withCredentials: true });
  }
);

export const updateOppGoals = createAsyncThunk(
  "oppGoals/updateOppGoals",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_OPP_GOALS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOppGoals = createAsyncThunk(
  "oppGoals/deleteOppGoals",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_OPP_GOALS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const oppGoalsSlice = createSlice({
  name: "oppGoals",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    oppGoalsData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createOppGoals.pending]: (state, action) => {
      state.loading = true;
    },
    [createOppGoals.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createOppGoals.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getOppGoals.pending]: (state, action) => {
      state.loading = true;
    },
    [getOppGoals.fulfilled]: (state, action) => {
      state.loading = false;
      state.oppGoalsData = action?.payload?.data;
    },
    [getOppGoals.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateOppGoals.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOppGoals.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateOppGoals.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteOppGoals.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteOppGoals.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteOppGoals.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  oppGoalsSlice.actions;
export default oppGoalsSlice.reducer;
