import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { MyTextInput } from "@excoleadershipui/pionweblib";
// material ui
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// components
import {
  ModalPopUp,
  FormSubmitButton,
  ModalCloseBtn,
  Notifier,
  ErrorNotifier,
} from "../../../components";
// redux
import {
  createOppScope,
  getOppScope,
  resetMessage,
  resetErrMessage,
} from "../../../features/exec/opportunity/oppScopeSlice";
import { getEngagementDeliverable } from "../../../features/config/engagementDeliverableSlice";

// ------------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  scopeName: Yup.string().required("Scope Name is required"),
  deliverable: Yup.string().required("Deliverable is required"),
  date: Yup.date().required("Date is required"),
  comments: Yup.date(),
});

// ---------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ----------------------------------------------------------------------------

const AddOppScope = (props) => {
  const dispatch = useDispatch();
  const { message, errMessage } = useSelector(
    (state) => ({ ...state.oppScope })
  );
  const { engDeliverableData } = useSelector((state) => ({
    ...state.engDeliverable,
  }));

  const formik = useFormik({
    initialValues: {
      scopeName: "",
      deliverable: "",
      date: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createOppScope({
          opportunityDetailId: props?.oppId,
          opportunityScope: values.scopeName,
          opportunityDeliverables: values.deliverable,
          deliverableDate: new Date(values.date),
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.closeModel();
            dispatch(getOppScope());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getEngagementDeliverable());
  }, []);

  return (
    <>
      <ModalPopUp open={props.showAdd} closeModel={props.closeModel}>
        <Box
          sx={{ ...style, width: 700 }}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={props.closeModel} />
          <div style={{ width: "25rem" }}>
            <MyTextInput
              label="Scope Name"
              type="text"
              name="scopeName"
              required={true}
              fullWidth={true}
              margin="normal"
              id="scopeName"
              value={formik.values.scopeName}
              onChange={formik.handleChange}
              error={
                formik.touched.scopeName && Boolean(formik.errors.scopeName)
              }
              helperText={formik.touched.scopeName && formik.errors.scopeName}
            />
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Deliverable</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="deliverable"
                value={formik.values.deliverable}
                error={
                  formik.touched.deliverable &&
                  Boolean(formik.errors.deliverable)
                }
                label="Deliverable"
                onChange={formik.handleChange}
                inputProps={{ tabIndex: "10" }}
              >
                {engDeliverableData.length > 0 &&
                  engDeliverableData.map((item) => (
                    <MenuItem key={item.id} value={item.deliverableName}>
                      {item?.deliverableName}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formik.touched.deliverable && formik.errors.deliverable}
              </FormHelperText>
            </FormControl>
            <MyTextInput
              label="Date"
              InputLabelProps={{ shrink: true }}
              type="date"
              name="date"
              required={true}
              fullWidth={true}
              margin="normal"
              id="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            />
            <MyTextInput
              label="Comments"
              type="string"
              name="comments"
              required={true}
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  );
};

export default AddOppScope;
