import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import {
  updateDevelopmentAreas,
  getDevelopmentAreas,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/developmentAreasSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  developmentAreaName: Yup.string().required(
    "Development Area Name is required"
  ),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const UpdateDevelopmentAreas = (props) => {
  const { message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.devAreas })
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      developmentAreaName: selectedData?.developmentAreaName,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateDevelopmentAreas({
          id: selectedData.id,
          developmentAreaName: values.developmentAreaName,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getDevelopmentAreas());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">Update Development Areas</PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
              <MyTextInput
                label="Development Area Name"
                type="text"
                name="developmentAreaName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="developmentAreaName"
                value={formik.values.developmentAreaName}
                onChange={formik.handleChange}
                error={
                  formik.touched.developmentAreaName &&
                  Boolean(formik.errors.developmentAreaName)
                }
                helperText={
                  formik.touched.developmentAreaName &&
                  formik.errors.developmentAreaName
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default UpdateDevelopmentAreas;
