import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { GET_MENTORS, GET_CLIENTS_BY_MENTOR } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const getMentors = createAsyncThunk("mentors/getMentors", async () => {
  return axios.get(GET_MENTORS, { withCredentials: true });
});

export const getClientsByMentor = createAsyncThunk(
  "mentors/getClientsByMentor",
  async (id) => {
    return axios.get(`${GET_CLIENTS_BY_MENTOR}/${id}`, {
      withCredentials: true,
    });
  }
);

const mentorSlice = createSlice({
  name: "mentors",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    mentorsData: [],
    clientsByMentorData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getMentors.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentors.fulfilled]: (state, action) => {
      state.loading = false;
      state.mentorsData = action?.payload?.data;
    },
    [getMentors.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getClientsByMentor.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientsByMentor.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientsByMentorData = action?.payload?.data;
    },
    [getClientsByMentor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } = mentorSlice.actions;
export default mentorSlice.reducer;
