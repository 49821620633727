import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_NOMINATION_MODE } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createNominationMode = createAsyncThunk(
  "nominationMode/createNominationMode",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_NOMINATION_MODE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNominationMode = createAsyncThunk(
  "nominationMode/getNominationMode",
  async () => {
    return axios.get(CURD_NOMINATION_MODE, { withCredentials: true });
  }
);

export const updateNominationMode = createAsyncThunk(
  "nominationMode/updateNominationMode",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_NOMINATION_MODE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNominationMode = createAsyncThunk(
  "nominationMode/deleteNominationMode",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_NOMINATION_MODE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const nominationModeSlice = createSlice({
  name: "nominationMode",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    nominationModeData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createNominationMode.pending]: (state, action) => {
      state.loading = true;
    },
    [createNominationMode.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createNominationMode.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getNominationMode.pending]: (state, action) => {
      state.loading = true;
    },
    [getNominationMode.fulfilled]: (state, action) => {
      state.loading = false;
      state.nominationModeData = action?.payload?.data;
    },
    [getNominationMode.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateNominationMode.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNominationMode.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateNominationMode.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteNominationMode.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteNominationMode.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteNominationMode.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  nominationModeSlice.actions;
export default nominationModeSlice.reducer;
