import React, { useState, useEffect } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { countries } from "countries-list";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import {
  FormSubmitButton,
  MuiAutocomplete,
  geographyArr,
  FormTextField as MyTextInput,
} from "../../components";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
// redux
import {
  createNomination,
  getNomination,
  resetMessage,
  resetErrMessage,
} from "../../features/nomination/nominationSlice";
import { getNominationMode } from "../../features/config/nominationModeSlice";
import { getCustHrByAcc } from "../../features/customer/custUserSlice";
import { getAccount } from "../../features/customer/accountSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  account: Yup.object().required("Account is required").nullable(),
  hrId: Yup.object().required("HR Name is required"),
  execFirstName: Yup.string().required("Executive First Name is required"),
  execLastName: Yup.string().required("Executive Last Name is required"),
  execEmail: Yup.string("Enter your email")
    .required("Executive Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  geography: Yup.object().required("Geo Name is required"),
  country: Yup.object().required("Country is required "),
  department: Yup.string().required("Department is required"),
  designation: Yup.string().required("Designation is required"),
  execDialingCode: Yup.string().required("Dialing Code is required"),
  execMobileNo: Yup.string()
    .required("Contact Number is required ")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  managerFirstName: Yup.string().required("Manager First Name is required"),
  managerLastName: Yup.string().required("Manager Last Name is required"),
  managerEmail: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  managerContactNo: Yup.string()
    .required("Contact Number is required ")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  nominationMode: Yup.object().required("Nomination is required"),
});

//   ------------------------------------------------------------------------------------------

const AddNomination = (props) => {
  const [countryArr, setCountryArr] = useState([]);

  const { message, errMessage } = useSelector((state) => ({
    ...state.nomination,
  }));

  const { accountData } = useSelector((state) => ({
    ...state.account,
  }));

  const { custHrData } = useSelector((state) => ({
    ...state.custUser,
  }));

  const { nominationModeData } = useSelector((state) => ({
    ...state.nominationMode,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    reset: true,
    initialValues: {
      account: "",
      hrId: "",
      execFirstName: "",
      execLastName: "",
      execEmail: "",
      geography: "",
      country: "",
      department: "",
      designation: "",
      execDialingCode: "",
      execMobileNo: "",
      managerFirstName: "",
      managerLastName: "",
      managerEmail: "",
      managerDialingCode: "",
      managerContactNo: "",
      nominationMode: "",
      comment: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createNomination({
          hrId: values?.hrId?.excoCustomerUser?.id,
          executiveName: `${values.execFirstName} ${values.execLastName}`,
          executiveEmail: values.execEmail,
          geoName: values?.geography?.code,
          country: values?.country?.name,
          department: values.department,
          designation: values.designation,
          executiveDialingCode: values?.execDialingCode,
          executiveMobileNo: values.execMobileNo.toString(),
          managerName: `${values.managerFirstName} ${values?.managerLastName}`,
          managerEmail: values.managerEmail ? values.managerEmail : null,
          managerDialingCode: values?.managerDialingCode,
          managerMobileNo: values.managerContactNo.toString(),
          modeOfNomination: values?.nominationMode?.mode,
          comment: values.comment,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props?.close();
            dispatch(getNomination());

            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getNominationMode());
    dispatch(getAccount());
    dispatch(getCustHrByAcc());
  }, []);

  useEffect(() => {
    formik?.values?.account &&
      dispatch(getCustHrByAcc(formik?.values?.account?.id));
  }, [formik?.values?.account]);

  useEffect(() => {
    let countries = [];
    options.map((option) => {
      if (option.continent === formik?.values?.geography?.code) {
        return countries.push(option);
      }
    });
    setCountryArr(countries);
  }, [formik?.values?.geography]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("execDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("managerDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={<PageHeader variant="pageHeader">Add Nomination</PageHeader>}
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <MuiAutocomplete
                name="account"
                options={accountData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.account}
                optionValue="accountName"
                label="Account Name"
                required={true}
                error={formik.touched.account && Boolean(formik.errors.account)}
                helperText={formik.touched.account && formik.errors.account}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <MuiAutocomplete
                name="hrId"
                options={custHrData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.hrId}
                optionValue="firstName"
                isNominate={true}
                optionSecValue="lastName"
                label="HR Name"
                required={true}
                error={formik.touched.hrId && Boolean(formik.errors.hrId)}
                helperText={formik.touched.hrId && formik.errors.hrId}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyTextInput
                label="Executive First Name"
                type="text"
                name="execFirstName"
                required={true}
                margin="normal"
                fullWidth={true}
                id="execFirstName"
                value={formik.values.execFirstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.execFirstName &&
                  Boolean(formik.errors.execFirstName)
                }
                helperText={
                  formik.touched.execFirstName && formik.errors.execFirstName
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MyTextInput
                label="Executive Last Name"
                type="text"
                name="execLastName"
                required={true}
                margin="normal"
                fullWidth={true}
                id="execLastName"
                value={formik.values.execLastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.execLastName &&
                  Boolean(formik.errors.execLastName)
                }
                helperText={
                  formik.touched.execLastName && formik.errors.execLastName
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MyTextInput
                label="Executive Email Id"
                type="text"
                name="execEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="execEmail"
                value={formik.values.execEmail}
                onChange={formik.handleChange}
                tabIndex={1}
                error={
                  formik.touched.execEmail && Boolean(formik.errors.execEmail)
                }
                helperText={formik.touched.execEmail && formik.errors.execEmail}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MuiAutocomplete
                name="geography"
                options={geographyArr}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.geography}
                optionValue="code"
                isNominate={true}
                optionSecValue="name"
                label="Geography"
                required={true}
                error={
                  formik.touched.geography && Boolean(formik.errors.geography)
                }
                helperText={formik.touched.geography && formik.errors.geography}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MuiAutocomplete
                name="country"
                options={countryArr}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.country}
                isNominate={true}
                optionValue="name"
                label="Country"
                required={true}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MyTextInput
                label="Department"
                type="text"
                name="department"
                required={true}
                fullWidth={true}
                margin="normal"
                id="department"
                value={formik.values.department}
                onChange={formik.handleChange}
                tabIndex={1}
                error={
                  formik.touched.department && Boolean(formik.errors.department)
                }
                helperText={
                  formik.touched.department && formik.errors.department
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <MyTextInput
                label="Designation"
                type="text"
                name="designation"
                required={true}
                fullWidth={true}
                margin="normal"
                id="designation"
                value={formik.values.designation}
                onChange={formik.handleChange}
                tabIndex={1}
                error={
                  formik.touched.designation &&
                  Boolean(formik.errors.designation)
                }
                helperText={
                  formik.touched.designation && formik.errors.designation
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <MyTextInput
                label="Dialing Code"
                type="number"
                name="execDialingCode"
                required={true}
                fullWidth={true}
                margin="normal"
                id="execDialingCode"
                value={formik.values.execDialingCode}
                disabled
                error={
                  formik.touched.execDialingCode &&
                  Boolean(formik.errors.execDialingCode)
                }
                helperText={
                  formik.touched.execDialingCode &&
                  formik.errors.execDialingCode
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <MyTextInput
                label="Executive Mobile No."
                type="number"
                name="execMobileNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="execMobileNo"
                value={formik.values.execMobileNo}
                onChange={formik.handleChange}
                error={
                  formik.touched.execMobileNo &&
                  Boolean(formik.errors.execMobileNo)
                }
                helperText={
                  formik.touched.execMobileNo && formik.errors.execMobileNo
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MyTextInput
                label="Manager First Name"
                type="text"
                name="managerFirstName"
                required={true}
                margin="normal"
                fullWidth={true}
                id="managerFirstName"
                value={formik.values.managerFirstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerFirstName &&
                  Boolean(formik.errors.managerFirstName)
                }
                helperText={
                  formik.touched.managerFirstName &&
                  formik.errors.managerFirstName
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MyTextInput
                label="Manager Last Name"
                type="text"
                name="managerLastName"
                required={true}
                margin="normal"
                fullWidth={true}
                id="managerLastName"
                value={formik.values.managerLastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerLastName &&
                  Boolean(formik.errors.managerLastName)
                }
                helperText={
                  formik.touched.managerLastName &&
                  formik.errors.managerLastName
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MyTextInput
                label="Manager Email"
                type="text"
                name="managerEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerEmail"
                value={formik.values.managerEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerEmail &&
                  Boolean(formik.errors.managerEmail)
                }
                helperText={
                  formik.touched.managerEmail && formik.errors.managerEmail
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyTextInput
                label="Dialing Code"
                type="number"
                name="managerDialingCode"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerDialingCode"
                value={formik.values.managerDialingCode}
                disabled
                error={
                  formik.touched.managerDialingCode &&
                  Boolean(formik.errors.managerDialingCode)
                }
                helperText={
                  formik.touched.managerDialingCode &&
                  formik.errors.managerDialingCode
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MyTextInput
                label="Manager Mobile No."
                type="number"
                name="managerContactNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerContactNo"
                value={formik.values.managerContactNo}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerContactNo &&
                  Boolean(formik.errors.managerContactNo)
                }
                helperText={
                  formik.touched.managerContactNo &&
                  formik.errors.managerContactNo
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <MuiAutocomplete
                name="nominationMode"
                options={nominationModeData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.nominationMode}
                optionValue="mode"
                isNominate={true}
                label="Nomination Mode"
                required={true}
                error={
                  formik.touched.nominationMode &&
                  Boolean(formik.errors.nominationMode)
                }
                helperText={
                  formik.touched.nominationMode && formik.errors.nominationMode
                }
                sx={{ margin: "1rem 0 0.5rem 0" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MyTextInput
                label="Comments"
                type="text"
                name="comment"
                fullWidth={true}
                margin="normal"
                id="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
              />
            </Grid>
          
            <Box textAlign="center" width="100%">
              <FormSubmitButton type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
          {/* <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
          <MuiAutocomplete
                  name="account"
                  options={accountData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.account}
                  optionValue="accountName"
                  label="Account Name"
                  required={true}
                  error={
                    formik.touched.account && Boolean(formik.errors.account)
                  }
                  helperText={formik.touched.account && formik.errors.account}
                />
              <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Executive First Name"
                  type="text"
                  name="execFirstName"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  id="execFirstName"
                  value={formik.values.execFirstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.execFirstName &&
                    Boolean(formik.errors.execFirstName)
                  }
                  helperText={
                    formik.touched.execFirstName && formik.errors.execFirstName
                  }
                />
              </div>
              <div style={{ width: "65%" }}>
                <MyTextInput
                  label="Executive Last Name"
                  type="text"
                  name="execLastName"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  id="execLastName"
                  value={formik.values.execLastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.execLastName &&
                    Boolean(formik.errors.execLastName)
                  }
                  helperText={
                    formik.touched.execLastName && formik.errors.execLastName
                  }
                />
              </div>
            </div>
                 < MuiAutocomplete
                   name="geography"
                   options={geographyArr}
                   setValue={(name, newValue) =>
                     formik.setFieldValue(name, newValue)
                   }
                   value={formik.values.geography}
                   optionValue="code"
                   isNominate={true}
                   optionSecValue="name"
                   label="Geography"
                   required={true}
                   error={
                     formik.touched.geography && Boolean(formik.errors.geography)
                   }
                   helperText={formik.touched.geography && formik.errors.geography}
                />
<MyTextInput
                 
                 label="Department"
                 type="text"
                 name="department"
                 required={true}
                 fullWidth={true}
                 margin="normal"
                 id="department"
                 value={formik.values.department}
                 onChange={formik.handleChange}
                 tabIndex={1}
                 error={
                   formik.touched.department &&
                   Boolean(formik.errors.department)
                 }
                 helperText={
                   formik.touched.department && formik.errors.department
                 }
               />
                
<div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                   
                    <MyTextInput
                     label="Dialing Code"
                     type="number"
                     name="execDialingCode"
                     required={true}
                     fullWidth={true}
                     margin="normal"
                     id="execDialingCode"
                     value={formik.values.execDialingCode}
                    
                     disabled
                     error={
                       formik.touched.execDialingCode &&
                       Boolean(formik.errors.execDialingCode)
                     }
                     helperText={
                       formik.touched.execDialingCode && formik.errors.execDialingCode
                     }
                    />
                   
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                     label="Executive Mobile No."
                     type="number"
                     name="execMobileNo"
                     required={true}
                     fullWidth={true}
                     margin="normal"
                     id="execMobileNo"
                     value={formik.values.execMobileNo}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.execMobileNo &&
                       Boolean(formik.errors.execMobileNo)
                     }
                     helperText={
                       formik.touched.execMobileNo &&
                       formik.errors.execMobileNo
                     }
                    />
                  </div>
                </div>

               
                <MyTextInput
                     label="Manager Email"
                     type="text"
                     name="managerEmail"
                     required={true}
                     fullWidth={true}
                     margin="normal"
                     id="managerEmail"
                     value={formik.values.managerEmail}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.managerEmail && Boolean(formik.errors.managerEmail)
                     }
                     helperText={
                       formik.touched.managerEmail && formik.errors.managerEmail
                     }
                    />
                 
                 < MuiAutocomplete
                   name="nominationMode"
                   options={nominationModeData}
                   setValue={(name, newValue) =>
                     formik.setFieldValue(name, newValue)
                   }
                   value={formik.values.nominationMode}
                   optionValue="mode"
                   isNominate={true}
                   label="Nomination Mode"
                   required={true}
                   error={
                     formik.touched.nominationMode && Boolean(formik.errors.nominationMode)
                   }
                   helperText={formik.touched.nominationMode && formik.errors.nominationMode}
                   sx={{margin: '1rem 0 0.5rem 0'}}
                />
                

                    

           

                
              
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
              < MuiAutocomplete
                   name="hrId"
                   options={custHrData}
                   setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                }
                value={formik.values.hrId}
                   optionValue="firstName"
                   isNominate={true}
                   optionSecValue="lastName"
                   label="HR Name"
                   required={true}
                   error={
                     formik.touched.hrId && Boolean(formik.errors.hrId)
                   }
                   helperText={formik.touched.hrId && formik.errors.hrId}
                />
              <MyTextInput
                   label="Executive Email Id"
                   type="text"
                   name="execEmail"
                   required={true}
                   fullWidth={true}
                   margin="normal"
                   id="execEmail"
                   value={formik.values.execEmail}
                   onChange={formik.handleChange}
                   tabIndex={1}
                   error={
                     formik.touched.execEmail && Boolean(formik.errors.execEmail)
                   }
                   helperText={
                     formik.touched.execEmail && formik.errors.execEmail
                   }
                />
                 < MuiAutocomplete
                 name="country"
                 options={countryArr}
                 setValue={(name, newValue) =>
                   formik.setFieldValue(name, newValue)
                 }
                 value={formik.values.country}
                 isNominate={true}
                 optionValue="name"
                 label="Country"
                 required={true}
                 error={
                   formik.touched.country && Boolean(formik.errors.country)
                 }
                 helperText={formik.touched.country && formik.errors.country}
                />
                   <MyTextInput
                 
                 label="Designation"
                 type="text"
                 name="designation"
                 required={true}
                 fullWidth={true}
                 margin="normal"
                 id="designation"
                 value={formik.values.designation}
                 onChange={formik.handleChange}
                 tabIndex={1}
                 error={
                   formik.touched.designation &&
                   Boolean(formik.errors.designation)
                 }
                 helperText={
                   formik.touched.designation && formik.errors.designation
                 }
               />
               <div style={{ display: "flex" }}>
              <div style={{width: '35%', paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Manager First Name"
                  type="text"
                  name="managerFirstName"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  id="managerFirstName"
                  value={formik.values.managerFirstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerFirstName &&
                    Boolean(formik.errors.managerFirstName)
                  }
                  helperText={
                    formik.touched.managerFirstName && formik.errors.managerFirstName
                  }
                />
              </div>
              <div style={{ width: "65%" }}>
                <MyTextInput
                  label="Manager Last Name"
                  type="text"
                  name="managerLastName"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  id="managerLastName"
                  value={formik.values.managerLastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerLastName &&
                    Boolean(formik.errors.managerLastName)
                  }
                  helperText={
                    formik.touched.managerLastName && formik.errors.managerLastName
                  }
                />
              </div>
            </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                  <MyTextInput
                        
                     label="Dialing Code"
                     type="number"
                     name="managerDialingCode"
                     required={true}
                     fullWidth={true}
                     margin="normal"
                     id="managerDialingCode"
                     value={formik.values.managerDialingCode}
                    
                     disabled
                     error={
                       formik.touched.managerDialingCode &&
                       Boolean(formik.errors.managerDialingCode)
                     }
                     helperText={
                       formik.touched.managerDialingCode && formik.errors.managerDialingCode
                     }
                      
                      />
                  </div>

                  <div style={{ width: "65%" }}>
                  <MyTextInput
                      label="Manager Mobile No."
                      type="number"
                      name="managerContactNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerContactNo"
                      value={formik.values.managerContactNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.managerContactNo &&
                        Boolean(formik.errors.managerContactNo)
                      }
                      helperText={
                        formik.touched.managerContactNo &&
                        formik.errors.managerContactNo
                      }
                    />
                   
                  </div>
                </div>


             
                <MyTextInput
                  label="Comments"
                  type="text"
                  name="comment"
                 
                  fullWidth={true}
                  margin="normal"
                  id="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                 
                />
              </Grid>
              <Box textAlign="center" width="100%">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid> */}
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddNomination;
