import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// routes
import {
  FORGOT_PASSWORD_EMAIL_VERIFY,
 FORGOT_PASSWORD_RESET
} from "../../utils/routes";

// ---------------------------------------------------------------------------------

export const forgotPassVerifyEmail = createAsyncThunk(
  "forgotPassword/forgotPassVerifyEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(FORGOT_PASSWORD_EMAIL_VERIFY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotPassReset = createAsyncThunk(
    "forgotPassword/forgotPassReset",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.post(FORGOT_PASSWORD_RESET, data, {
          withCredentials: true,
        });
        return response;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  const forgotPasswordSlice = createSlice({
    name: "forgotPassword",
    initialState: {
      loading: false,
      loginToken: "",
      rq: "",
      selectedData: {},
      message: "",
      errMessage: "",
    },
    reducers: {
      resetMessage: (state) => {
        state.message = "";
      },
      resetErrMessage: (state) => {
        state.errMessage = "";
      },
      selectRow: (state, action) => {
        state.selectedData = action?.payload.length > 0 && action?.payload[0];
      },
    },
    extraReducers: {
        [forgotPassVerifyEmail.pending]: (state, action) => {
            state.loading = true;
          },
          [forgotPassVerifyEmail.fulfilled]: (state, action) => {
            state.loading = false;
            state.loginToken = action?.payload?.data?.data?.loginToken;
            state.rq = action?.payload?.data?.data?.rq;
            state.message = action?.payload?.data?.message;
          },
          [forgotPassVerifyEmail.rejected]: (state, action) => {
            state.loading = false;
            state.errMessage = action?.payload?.message;
          },
          [forgotPassReset.pending]: (state, action) => {
            state.loading = true;
          },
          [forgotPassReset.fulfilled]: (state, action) => {
            state.loading = false;
            state.message = action?.payload?.data?.message;
          },
          [forgotPassReset.rejected]: (state, action) => {
            state.loading = false;
            state.errMessage = action?.payload?.message;
          },
        },
    });
    
    export const { resetMessage, resetErrMessage, selectRow } = forgotPasswordSlice.actions;
    
    export default forgotPasswordSlice.reducer;
    