import React from "react";
import { Link } from "react-router-dom";
// material ui
import { Typography, Box } from "@mui/material";
// image
import pagenotfoundImage from "../../assets/images/pagenotfound.jpg";

// --------------------------------------------------------

const PageNotFound = () => {
  return (
    <Box textAlign="center">
      <Typography variant="h4">Oops..! 404 Page Not Found</Typography>
      <Typography variant="body1">
        Looks like you came to wrong page on our server
      </Typography>
      <Box>
        <Link to="/">Back to Home </Link>
      </Box>
      <img src={pagenotfoundImage} height="500" width="500" alt="not found" />
    </Box>
  );
};

export default PageNotFound;
