import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Stack, Box } from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import AddOpportunityStatus from "./AddOpportunityStatus";
import UpdateOpportunityStatus from "./UpdateOpportunityStatus";
import { MuiDataGrid } from "../../../components";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
// redux
import {
  getOpportunityStatus,
  deleteOpportunityStatus,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/opportunityStatusSlice";
import Layout from "../../../layout/Layout";

// ----------------------------------------------------------------------------------

const OpportunityStatus = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
const location = useLocation()
  const dispatch = useDispatch();
  const { opportunityStatusData, selectedData } =
    useSelector((state) => ({ ...state.oppStatus }));

  const data = {
    rows: opportunityStatusData,
    columns: [
      { field: "opportunityStatus", headerName: "Opp Status", width: 250 },
      { field: "comments", headerName: "Comments", width: 250 },
    ],
  };

  const showAddPage = () => {
    setShowAdd(!showAdd);
  };

  const showEditPage = () => {
    setShowEdit(true);
  };

  const showDelete = () =>
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure to delete this record?",
                subTitle: "You can't undo this operation",
                onConfirm: () => handleDelete(),
              })
              
  useEffect(() => {
    dispatch(getOpportunityStatus());
  }, []);

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDelete = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteOpportunityStatus(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getOpportunityStatus());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  return (
    <Layout>
      {!showAdd && !showEdit && (
        <>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <PageHeader variant="pageHeader">Opportunity Status</PageHeader>
            </Box>
            <Link to="/exco/config" state={{l3Menu: location?.state?.l3Menu}}>
              <CancelIcon color="red" />
            </Link>
          </Stack>
          <MuiDataGrid
             setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddPage}
             setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditPage}
             setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
             }
            toolbarColumn = {true}
            toolbarFilter = {true}
            toolbarDensity = {true}
            toolbarExport = {true}
            data={data}
            select={(data) => dispatch(rowSelect(data))}
          />
        </>
      )}
      {showAdd && (
        <AddOpportunityStatus
          showAdd={showAdd}
          close={() => setShowAdd(false)}
        />
      )}
      {showEdit && (
        <UpdateOpportunityStatus
          showEdit={showEdit}
          close={() => setShowEdit(false)}
        />
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
    </Layout>
  );
};

export default OpportunityStatus;
