import React, { useEffect } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// material ui
import {
  Card,
  CardHeader,
  Autocomplete,
  TextField,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import {
  FormSubmitButton,
  MuiAutocomplete,
  FormTextField as MyTextInput,
} from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import { getAccount } from "../../../features/customer/accountSlice";
import { getEngTierName } from "../../../features/config/engTierNameSlice";
import { getMentor } from "../../../features/mentor/mentorSlice";

import {
  createEngagement,
  resetMessage,
  resetErrMessage,
  getEngagement,
  getEngagementByAcc,
  getNotEngagedClients,
} from "../../../features/exec/engagement/engagementSlice";

import {
  getEngagementDeliverableByTier,
  resetEngDeliverableData,
} from "../../../features/config/engagementDeliverableSlice";

// ---------------------------------------------------------------------

const validationSchema = Yup.object({
  account: Yup.object().nullable().required("Customer Accounts is required"),
  custClient: Yup.object().required("Customer Client is required"),
  engagementName: Yup.string().required("Engagement Name is required"),

  engTierName: Yup.object()
    .nullable()
    .required("Engagement Tier Name is required"),
  delieverableName: Yup.array()
    .nullable()
    .required("Delivarable Name is required"),
  managerName: Yup.string().required("Manager Name is required "),
  managerEmail: Yup.string()
    .email("Enter valid Email")
    .required("Manager Email is required"),
  // nextUpdateDue: Yup.string().required("Next Update Due date is required"),
  // engStartDate: Yup.string().required("Engagement Start Date is required"),


  contactName: Yup.string().required("HR Partner Name is required "),
  contactEmail: Yup.string()
    .email("Enter valid Email")
    .required("HR Partner Email is required"),

  // dialingCode: Yup.string().required("Dialing Code is required"),
  // contactNumber: Yup.string()
  //   .required("ContactNumber is required")
  //   .matches(/^[0-9]+$/, "Must be only digits")
  //   .min(10, "Must be exactly 10 digits")
  //   .max(10, "Must be exactly 10 digits"),
  comments: Yup.string(),
  mentor: Yup.object().nullable().required("Mentor Name is required"),
});

// ---------------------------------------------------------------------------------------------

const AddEngagement = (props) => {
  const { message, errMessage, notEngagedClientsData } = useSelector(
    (state) => ({ ...state.engagement })
  );

  const { accountData } = useSelector((state) => ({ ...state.account }));
  const { engTierNameData } = useSelector((state) => ({
    ...state.engTierName,
  }));
  const { mentorsData, selectedData } = useSelector((state) => ({
    ...state.mentor,
  }));

  const { engDeliverableData } = useSelector((state) => ({
    ...state.engDeliverable,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      account: props?.account,
      custClient: "",
      engagementName: "",
      engagementScope: "",
      engTierName: "",
      delieverableName: [],
      managerName: "",
      managerEmail: "",
      nextUpdateDue: "",
      engStartDate: "",
      engEndDate: "",
      contactName: "",
      contactEmail: "",
      dialingCode: "",
      contactNumber: "",
      mentor: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const date = DateTime.fromISO(values?.engStartDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const localIsoString = date.toISO();

      const dateTwo = DateTime.fromISO(values?.engEndDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const localIsoStringTwo = dateTwo.toISO();

      const dateThree = DateTime.fromISO(values?.nextUpdateDue).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const localIsoStringThree = dateThree.toISO();



      console.log(values?.delieverableName);
      dispatch(
        createEngagement({
          excoCustomerAccountId: values?.account?.id,
          excoCustomerUserId: values.custClient?.id,
          engagementName: values.engagementName,
          // engagementScope: values.engagementScope,
          engTierName: values.engTierName?.engagementTierName,
          delievrables: values?.delieverableName.map(item => item.id),
          managerName: values?.managerName,
          managerEmail: values?.managerEmail,
          nextUpdateDue: localIsoStringThree,
          engagementStartDate: localIsoString,
          engagementEndDate: localIsoStringTwo,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          dialingCode: values.dialingCode.toString(),
          contactNumber: values.contactNumber.toString(),
          comments: values.comments,
          excoMentorId: values?.mentor?.excoMentor?.id,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            Object.keys(props.account).length > 0
              ? dispatch(getEngagementByAcc(props.account.id))
              : dispatch(getEngagement());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getAccount());
    dispatch(getEngTierName());
    dispatch(getMentor());
  }, []);

  useEffect(() => {
    formik?.values?.account &&
      dispatch(getNotEngagedClients(formik.values.account?.id));
  }, [formik.values.account]);

  useEffect(() => {
    formik?.values?.engTierName &&
      dispatch(getEngagementDeliverableByTier(formik?.values?.engTierName?.id));
    !formik?.values?.engTierName && dispatch(resetEngDeliverableData());
  }, [formik?.values?.engTierName]);

  useEffect(() => {
    if (Date.parse(formik.values?.engStartDate) > Date.parse(formik.values?.engEndDate)) {
      console.log("comming")
       formik.setFieldTouched("engEndDate",true)
      formik.setFieldError("engEndDate", "Eng Start date should be lesser that eng end date")
    }
    else {
      formik.setFieldTouched("engEndDate",true)
      formik.setFieldError("engEndDate", "")
    }

  }, [formik.values?.engEndDate])


  console.log(formik.errors)

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={<PageHeader variant="pageHeader">Add Engagement</PageHeader>}
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <MuiAutocomplete
                tabIndex="1"
                name="account"
                options={accountData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.account}
                optionValue="accountName"
                label="Customer Account"
                required={true}
                error={formik.touched.account && Boolean(formik.errors.account)}
                helperText={formik.touched.account && formik.errors.account}
              />
              <MyTextInput
                tabIndex="3"
                label="Engagement Name"
                type="text"
                name="engagementName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engagementName"
                value={formik.values.engagementName}
                onChange={formik.handleChange}
                error={
                  formik.touched.engagementName &&
                  Boolean(formik.errors.engagementName)
                }
                helperText={
                  formik.touched.engagementName && formik.errors.engagementName
                }
              />
               {/* <MuiAutocomplete
                tabIndex="5"
                name="delieverableName"
                options={engDeliverableData.length > 0 ? engDeliverableData : []}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name,  [...newValue])
                }
                value={formik.values.delieverableName}
            
                optionValue="deliverableName"
               
                label="Deliverables"
                required={true}
                disabled={engDeliverableData.length === 0}
                error={
                  formik.touched.delieverableName && Boolean(formik.errors.delieverableName)
                }
                helperText={
                  formik.touched.delieverableName && formik.errors.delieverableName
                }
              /> */}
              <Autocomplete
                tabIndex="5"
                name="delieverableName"
                multiple
                id="tags-outlined"
                options={engDeliverableData.length > 0 ? engDeliverableData : []}
                getOptionLabel={(option) => option.deliverableName}
                value={formik.values.delieverableName}
                onChange={(event, newValue) =>
                  formik.setFieldValue("delieverableName", [...newValue])
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Deliverables"
                    required={true}
                    error={
                      formik.touched.delieverableName &&
                      Boolean(formik.errors.delieverableName)
                    }
                    helperText={
                      formik.touched.delieverableName &&
                      formik.errors.delieverableName
                    }
                  />
                )}
                sx={{ marginTop: "1rem" }}
                disabled={engDeliverableData.length === 0}
              />
              <MyTextInput
                tabIndex="7"
                label="Engagement End Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="engEndDate"
                // required={true}
                fullWidth={true}
                margin="normal"
                id="engEndDate"
                value={formik.values?.engEndDate}
                onChange={formik.handleChange}

                error={
                  formik.touched.engEndDate && Boolean(formik.errors.engEndDate)
                }
                helperText={
                  formik.touched.engEndDate && formik.errors.engEndDate
                }
                sx={{ mb: 0 }}
              />

              <MyTextInput
                tabIndex="9"
                label="Manager Name"
                type="text"
                name="managerName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerName"
                value={formik.values.managerName}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerName &&
                  Boolean(formik.errors.managerName)
                }
                helperText={
                  formik.touched.managerName && formik.errors.managerName
                }
              />







              <MyTextInput
                tabIndex="11"
                label="HR Partner Name"
                type="text"
                name="contactName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactName"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactName &&
                  Boolean(formik.errors.contactName)
                }
                helperText={
                  formik.touched.contactName && formik.errors.contactName
                }
              />

              <MuiAutocomplete
                tabIndex="13"
                required={true}
                name="mentor"
                options={mentorsData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.mentor}
                optionValue="firstName"
                optionSecValue="lastName"
                label="Mentor Name"
                error={formik.touched.mentor && Boolean(formik.errors.mentor)}
                helperText={formik.touched.mentor && formik.errors.mentor}
              />




            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MuiAutocomplete
                tabIndex="2"
                name="custClient"
                options={notEngagedClientsData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.custClient}
                innerValue={true}
                optionValue="excoCustomerUserDetail"
                optionSecValue="firstName"
                optionThirdValue="lastName"
                label="Customer Client"
                required={true}
                error={
                  formik.touched.custClient && Boolean(formik.errors.custClient)
                }
                helperText={
                  formik.touched.custClient && formik.errors.custClient
                }
              />
              <MuiAutocomplete
                tabIndex="4"
                name="engTierName"
                options={engTierNameData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.engTierName}
                optionValue="engagementTierName"
                label="Engagement Tier Name"
                required={true}
                error={
                  formik.touched.engTierName &&
                  Boolean(formik.errors.engTierName)
                }
                helperText={
                  formik.touched.engTierName && formik.errors.engTierName
                }
              />
              <MyTextInput
                tabIndex="6"
                label="Engagement Start Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="engStartDate"
                // required={true}
                fullWidth={true}
                margin="normal"
                id="engStartDate"
                value={formik.values.engStartDate}
                onChange={formik.handleChange}

                error={
                  formik.touched.engStartDate &&
                  Boolean(formik.errors.engStartDate)
                }
                helperText={
                  formik.touched.engStartDate && formik.errors.engStartDate
                }
                sx={{ mb: 0 }}
              />


              <MyTextInput
                tabIndex="8"
                label="Update Due Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="nextUpdateDue"
                // required={true}
                fullWidth={true}
                margin="normal"
                id="nextUpdateDue"
                value={formik.values.nextUpdateDue}
                onChange={formik.handleChange}

                error={
                  formik.touched.nextUpdateDue &&
                  Boolean(formik.errors.nextUpdateDue)
                }
                helperText={
                  formik.touched.nextUpdateDue && formik.errors.nextUpdateDue
                }
                sx={{ mb: 0 }}
              />


              <MyTextInput
                tabIndex="10"
                label="Manager's Email"
                type="text"
                name="managerEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="managerEmail"
                value={formik.values.managerEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.managerEmail &&
                  Boolean(formik.errors.managerEmail)
                }
                helperText={
                  formik.touched.managerEmail && formik.errors.managerEmail
                }
              />
              {/* <MyTextInput
                label="Engagement Scope"
                type="text"
                name="engagementScope"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engagementScope"
                value={formik.values.engagementScope}
                onChange={formik.handleChange}
                error={
                  formik.touched.engagementScope &&
                  Boolean(formik.errors.engagementScope)
                }
                helperText={
                  formik.touched.engagementScope &&
                  formik.errors.engagementScope
                }
              /> */}




              {/* <div style={{ display: "flex" }}>
                <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                  <MyTextInput
                    label="Dialing Code"
                    type="number"
                    name="dialingCode"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="dialingCode"
                    value={formik.values.dialingCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dialingCode &&
                      Boolean(formik.errors.dialingCode)
                    }
                    helperText={
                      formik.touched.dialingCode && formik.errors.dialingCode
                    }
                  />
                </div>
                <div style={{ width: "65%" }}>
                  <MyTextInput
                    label="HR Contact Number"
                    type="number"
                    name="contactNumber"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="contactNumber"
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactNumber &&
                      Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                    }
                  />
                </div>
              </div> */}


              {/* <MuiAutocomplete
                multi
                name="delieverableName"
                options={engDeliverableData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.delieverableName}
                optionValue="deliverableName"
                label="Engagement Deliverable Name"
                required={true}
                error={
                  formik.touched.delieverableName &&
                  Boolean(formik.errors.delieverableName)
                }
                helperText={
                  formik.touched.delieverableName && formik.errors.delieverableName
                }
              /> */}

              <MyTextInput
                tabIndex="12"
                label="HR Partner's Email"
                type="text"
                name="contactEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactEmail"
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactEmail &&
                  Boolean(formik.errors.contactEmail)
                }
                helperText={
                  formik.touched.contactEmail && formik.errors.contactEmail
                }
              />
              <MyTextInput
                tabIndex="14"
                label="Comments"
                type="textarea"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />




            </Grid>
          </Grid>
          <Box textAlign="center">
            <FormSubmitButton type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Box>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddEngagement;
