import React from "react";
// components
import Layout from "../../layout/Layout";

// ----------------------------------------------------

const Dashboard = () => {
  return <Layout>Dashboard</Layout>;
};

export default Dashboard;
