import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_KEY_ENGAGEMENT } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createKeyEngagement = createAsyncThunk(
  "keyEngagement/createKeyEngagement",
  async (data, { rejectWithValue }) => {
   
    try {
      const response = await axios.post(CURD_KEY_ENGAGEMENT, data, {
        withCredentials: true,
        // transformRequest: (data) => {
        //   console.log(data,{...data, keyEngagementData:data?.keyEngagementData})
        //   return {...data, keyEngagementData: data?.keyEngagementData}
        // }
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getKeyEngagement = createAsyncThunk(
  "keyEngagement/getKeyEngagement",
  async (id) => {
    return axios.get(`${CURD_KEY_ENGAGEMENT}/${id}`, {
      withCredentials: true,
    });
  }
);

export const updateKeyEngagement = createAsyncThunk(
  "keyEngagement/updateKeyEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_KEY_ENGAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteKeyEngagement = createAsyncThunk(
  "keyEngagement/deleteKeyEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_KEY_ENGAGEMENT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const keyEngagementSlice = createSlice({
  name: "keyEngagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    keyEngagementData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createKeyEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [createKeyEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createKeyEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getKeyEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [getKeyEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.keyEngagementData = action?.payload?.data;
    },
    [getKeyEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateKeyEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateKeyEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateKeyEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteKeyEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteKeyEngagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteKeyEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  keyEngagementSlice.actions;
export default keyEngagementSlice.reducer;
