import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_L1_MENU_PERMISSIONS, GET_L1_MENU_PERMISSIONS_ROLE, CURD_L1_SERVICE_PERMISSIONS, GET_L1_SERVICE_PERMISSIONS_ROLE } from "../../../../utils/routes";

// ---------------------------------------------------------------------

export const createL1MenuPermission = createAsyncThunk(
  "l1AccessPermission/createL1MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_L1_MENU_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getL1MenuPermission = createAsyncThunk("l1AccessPermission/getL1MenuPermission", async () => {
  return axios.get(CURD_L1_MENU_PERMISSIONS, { withCredentials: true });
});

export const getL1MenuPermissionRole = createAsyncThunk("l1AccessPermission/getL1MenuPermissionRole", async (id) => {
  return axios.get(`${GET_L1_MENU_PERMISSIONS_ROLE}/${id}`, { withCredentials: true });
});

export const updateL1MenuPermission = createAsyncThunk(
  "l1AccessPermission/updateL1MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_L1_MENU_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteL1MenuPermission = createAsyncThunk(
  "l1AccessPermission/deleteL1MenuPermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_L1_MENU_PERMISSIONS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createL1ServicePermission = createAsyncThunk(
  "l1AccessPermission/createL1ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_L1_SERVICE_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getL1ServicePermission = createAsyncThunk("l1AccessPermission/getL1ServicePermission", async () => {
  return axios.get(CURD_L1_SERVICE_PERMISSIONS, { withCredentials: true });
});

export const getL1ServicePermissionRole = createAsyncThunk("l1AccessPermission/getL1ServicePermissionRole", async (id) => {
  return axios.get(`${GET_L1_SERVICE_PERMISSIONS_ROLE}/${id}`, { withCredentials: true });
});

export const updateL1ServicePermission = createAsyncThunk(
  "l1AccessPermission/updateL1ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_L1_SERVICE_PERMISSIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteL1ServicePermission = createAsyncThunk(
  "l1AccessPermission/deleteL1ServicePermission",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_L1_SERVICE_PERMISSIONS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const l1AccessPermissionSlice = createSlice({
  name: "l1AccessPermission",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    l1MenuPermissionData: [],
    l1MenuSelectedData: {},
    l1ServicePermissionData: [],
    l1ServiceSelectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    l1MenuRowSelect: (state, action) => {
      state.l1MenuSelectedData = action?.payload.length > 0 && action?.payload[0];
    },
    l1ServiceRowSelect: (state, action) => {
      state.l1ServiceSelectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createL1MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [createL1MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createL1MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getL1MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [getL1MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.l1MenuPermissionData = action?.payload?.data;
    },
    [getL1MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getL1MenuPermissionRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getL1MenuPermissionRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.l1MenuPermissionData = action?.payload?.data;
    },
    [getL1MenuPermissionRole.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateL1MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [updateL1MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateL1MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteL1MenuPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteL1MenuPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteL1MenuPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createL1ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [createL1ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createL1ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getL1ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [getL1ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.l1ServicePermissionData = action?.payload?.data;
    },
    [getL1ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getL1ServicePermissionRole.pending]: (state, action) => {
      state.loading = true;
    },
    [getL1ServicePermissionRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.l1ServicePermissionData = action?.payload?.data;
    },
    [getL1ServicePermissionRole.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateL1ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [updateL1ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateL1ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteL1ServicePermission.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteL1ServicePermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteL1ServicePermission.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, l1MenuRowSelect, l1ServiceRowSelect } = l1AccessPermissionSlice.actions;
export default l1AccessPermissionSlice.reducer;
