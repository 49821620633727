import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_REPORTS_AREA_FOCUS,
  GET_REPORTS_ENGAGEMENT_STATUS,
  GET_ENGAGEMENT_DETAIL,
  CREATE_MENTOR_ENGAGEMENT_NOTES,
  CREATE_MENTOR_REPORTS_FOCUS,
  GET_MENTOR_REPORTS_CHECKIN,
  PUBLISH_MENTOR_REPORTS_SUBMIT,
  UPDATE_MENTOR_RATING_SUBMIT,
  GET_MENTOR_GENERATE_SUMMARY,
  GET_MENTOR_LATEST_SUMMARY,
  GET_MENTOR_SUMMARY_DATE_API,
  GET_MENTOR_SUMMARY_DATE_API_BY_ID,
  GET_ADMIN_LATEST_SUMMARY,
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const getAreaOfFocus = createAsyncThunk(
  "reports/getAreaOfFocus",
  async () => {
    return axios.get(GET_REPORTS_AREA_FOCUS, { withCredentials: true });
  }
);

export const getEngagementNotes = createAsyncThunk(
  "reports/getEngagement",
  async () => {
    return axios.get(GET_REPORTS_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

export const getLatestSummaryRecords = createAsyncThunk(
  "reports/summaryAdminRecords",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };

    const response = await axios.post(GET_ADMIN_LATEST_SUMMARY, body, {
      withCredentials: true,
    });
    return response;
  }
);

export const addEngagementNotes = createAsyncThunk(
  "mentorEngagement/addNotes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_MENTOR_ENGAGEMENT_NOTES, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMentorGenerateSummary = createAsyncThunk(
  "mentorSummary/generate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_MENTOR_GENERATE_SUMMARY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCheckinRating = createAsyncThunk(
  "mentor/updateRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_MENTOR_RATING_SUBMIT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFocusCheckinReports = createAsyncThunk(
  "mentor/updateReportFocus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CREATE_MENTOR_REPORTS_FOCUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEngagementNotes = createAsyncThunk(
  "mentor/updateReportsEngagementNotes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CREATE_MENTOR_ENGAGEMENT_NOTES, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const publishMentorCheckinReports = createAsyncThunk(
  "mentor/reportsPublished",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(PUBLISH_MENTOR_REPORTS_SUBMIT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getClientCheckinReports = createAsyncThunk(
  "mentorReports/checkin",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };
    try {
      const response = await axios.post(GET_MENTOR_REPORTS_CHECKIN, body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addReportsFocus = createAsyncThunk(
  "mentorReports/addFocus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_MENTOR_REPORTS_FOCUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMentorReportsDateById = createAsyncThunk(
  "mentorReportsDateById/getrating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_MENTOR_SUMMARY_DATE_API_BY_ID,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMentorSummaryDateReports = createAsyncThunk(
  "mentorReportsDate/getSummary",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };
    try {
      const response = await axios.post(GET_MENTOR_SUMMARY_DATE_API, body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    areaFocusData: [],
    reportsCheckinData: [],
    engagementFocusData: [],
    generateSummaryData: {},
    latestSummaryRecordsData: [],
    getSummaryDateReports: [],
    dateErrorMessageData: "",
    selectedData: {},
    getClientLatestRatingData: [],
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    dateErrorMessage: (state, action) => {
      state.dateErrorMessageData = action.payload;
    },
    emptyingArry: (state, action) => {
      state.generateSummaryData = [];
    },
  },
  extraReducers: {
    [getAreaOfFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [getAreaOfFocus.fulfilled]: (state, action) => {
      state.loading = false;
      state.areaFocusData = action?.payload?.data;
    },
    [getAreaOfFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getLatestSummaryRecords.pending]: (state, action) => {
      state.loading = true;
    },
    [getLatestSummaryRecords.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateSummaryData = action?.payload?.data;
    },
    [getLatestSummaryRecords.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateSummaryData = {};
    },

    [getMentorSummaryDateReports.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentorSummaryDateReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.getSummaryDateReports = action?.payload?.data;
    },
    [getMentorSummaryDateReports.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getMentorGenerateSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentorGenerateSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateSummaryData = action?.payload?.data;
    },
    [getMentorGenerateSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateSummaryData = {};
    },
    [getClientCheckinReports.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientCheckinReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.reportsCheckinData = action?.payload?.data;
    },
    [getClientCheckinReports.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [addEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [addEngagementNotes.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [addEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getMentorReportsDateById.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentorReportsDateById.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateSummaryData = action?.payload?.data;
    },
    [getMentorReportsDateById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateSummaryData = {};
    },
    [updateFocusCheckinReports.pending]: (state, action) => {
      state.loading = true;
    },
    [updateFocusCheckinReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateFocusCheckinReports.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEngagementNotes.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateCheckinRating.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCheckinRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCheckinRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [publishMentorCheckinReports.pending]: (state, action) => {
      state.loading = true;
    },

    [publishMentorCheckinReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [publishMentorCheckinReports.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [addReportsFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [addReportsFocus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [addReportsFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const {
  resetMessage,
  resetErrMessage,
  rowSelect,
  emptyingArry,
  dateErrorMessage,
} = engagementSlice.actions;
export default engagementSlice.reducer;
